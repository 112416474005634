import { http } from "@/helpers/http";
import Vue from 'vue'

const baseURL = 'https://x1pukio3fj.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';
export default {
    namespaced: true,
    state: {
        settings: {
            accounts: null,
            shippingMethods: [],
            generalSettings: null,
            flashWarehouses: null,
            payment: null
        }
    },

    getters: {
        accounts(state) {
            return state.settings.accounts;
        },
        print(state) {
            return state.settings.print;
        },
        shippingMethods(state) {
            return state.settings.shippingMethods;
        },
        generalSettings(state) {
            return state.settings.generalSettings;
        },
        payments(state) {
            return state.payment;
        },
        flashWarehouses(state) {
            return state.settings.flashWarehouses;
        }
    },

    mutations: {
        setAccounts(state, accounts) {
            Vue.set(state.settings, 'accounts', accounts);
        },

        setPrint(state, print) {
            Vue.set(state.print, 'print', print);
        },

        setFlashWarehouses(state, flashWarehouses) {
            Vue.set(state.settings, 'flashWarehouses', flashWarehouses);
        },

        setShippingMethods(state, shippingMethods) {
            Vue.set(state.settings, 'shippingMethods', shippingMethods);
        },

        setGeneralSettings(state, generalSettings) {
            Vue.set(state.settings, 'generalSettings', generalSettings);
        },
        setPayments(state, payment) {
            Vue.set(state, 'payment', payment);
        }
    },
    actions: {
        changeUsername({ state, commit, rootState }, payload) {
            http.put(baseURL + "/settings/change-username", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        updateAccount({ state, commit, rootState }, payload) {
            http.put(baseURL + "/settings/account", payload.account)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        fetchAccount({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/account")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        fetchPrint({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/print")
                .then(response => {
                    if (payload && payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    if (payload) payload.callback(false, error.response);
                    else console.log(error);
                })
        },

        fetchShippingMethods({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/shippingMethods")
                .then(response => {
                    if (payload && payload.callback) payload.callback(true, response.data);
                    commit("setShippingMethods", response.data);
                })
                .catch(error => {
                    console.log(error);
                    if (payload && payload.callback) payload.callback(false, error.response);
                })
        },

        updateShippingSettings({ state, commit, rootState }, payload) {
            http.put(baseURL + "/settings/shippingSettings", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        fetchShippingSettings({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/shippingSettings")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        updateGeneralSettings({ state, commit, rootState }, payload) {
            // optimistic approach
            commit('setGeneralSettings', payload.generalSettings);

            http.put(baseURL + "/settings/general", payload.generalSettings)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        fetchGeneralSettings({ state, commit, rootState }, payload = {}) {
            http.get(baseURL + "/settings/general")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                    commit('setGeneralSettings', response.data);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false, error.response);
                })
        },
        updatePayment({ state, commit, rootState }, payload) {
            http.put(baseURL + "/settings/payment", payload.payment
            )
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        fetchPayment({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/payment")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        updateCheckout({ state, commit, rootState }, payload) {
            http.put(baseURL + "/settings/checkout", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        fetchCheckout({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/checkout")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        addBalance({ state, commit, rootState }, payload) {
            http.post(baseURL + "/settings/add-balance", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        getBalance({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/get-balance")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        getBalanceHistory({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/balance")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        getBalanceLog({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/balance-logs")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        fetchThaipostInfo({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/thaipost-user")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        fetchFlashWarehouses({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/flash-warehouses")
                .then(response => {
                    if (payload && payload.callback) payload.callback(true, response.data);
                    commit('setFlashWarehouses', response.data);
                })
                .catch(error => {
                    if (payload && payload.callback) payload.callback(false, error.response);
                })
        },

        updateThaipostInfo({ state, commit, rootState }, payload) {
            http.put(baseURL + "/settings/thaipost-user", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        },

        generateFlashURL({ state, commit, rootState }, payload) {
            http.get(baseURL + "/settings/gen-flash-url")
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
        }
    }
}