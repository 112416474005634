import { render, staticRenderFns } from "./ShippingDoughnutChart.vue?vue&type=template&id=d67e264e&scoped=true"
import script from "./ShippingDoughnutChart.vue?vue&type=script&lang=js"
export * from "./ShippingDoughnutChart.vue?vue&type=script&lang=js"
import style0 from "./ShippingDoughnutChart.vue?vue&type=style&index=0&id=d67e264e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d67e264e",
  null
  
)

export default component.exports