<template>
  <div class="reports-page">
    <div class="content-header">
      <h4 class="text-color-2">
        <span class="font-weight-light">{{ $t("general.all") }}</span>
        {{ $t("reports.label") }}
      </h4>
    </div>

    <Card>
      <div slot="body">
        <div class="d-flex flex-wrap">
        <!-- <router-link :to="{name: 'Reports-Manifest'}" class="btn btn-success btn-lg m-4">Thaipost manifest report</router-link> -->
        <router-link :to="{name: 'Reports-Shipments'}" class="btn btn-success btn-lg m-4">Thaipost Shipments report</router-link>
        <!-- <router-link :to="{name: 'Reports-Shipments-V2'}" class="btn btn-success btn-lg m-4">Shipments report V2</router-link> -->
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import ReportsTable from "@/components/derived/ReportsTable";
import Card from "@/components/core/Card";
import DataTable from "@/components/core/DataTable";
import Spinner from "@/components/core/Spinner";
import { getReportStatus } from "@/helpers/core";
import moment from "moment";
import { i18n } from "@/i18n";

export default {
  name: "ReportsList",
  components: { ReportsTable, Card, Spinner, DataTable },
  created() {
    // this.getBalanceHistory();
  },
  data() {
    return {
      loading: true,
      totalBalance: null,
      balanceLog: {
        columns: [
          {
            name: "sn",
            title: "#",
            dataClass: "--checkbox",
            formatter(value) {
              return value + 1;
            },
          },
          {
            name: "amount",
            title: i18n.t("reports.amount"),
            sortField: "amount",
            dataClass: "--width-sm",
            formatter(value) {
              return "THB. " + Math.abs(value);
            },
          },
          {
            name: "status",
            title: i18n.t("reports.status.label"),
            sortField: "amount",
            dataClass: "--width-sm",
            formatter(value) {
              if (!value) return "N/A";
              let title, style;
              if (value < 0) {
                title = i18n.t("status.spent");
                style = "danger";
              } else {
                title = i18n.t("status.added");
                style = "success";
              }

              return `<span class="badge badge-pill badge-${style} text-uppercase status-badge">${title}</span>`;
            },
          },

          {
            name: "purpose",
            title: i18n.t("reports.balance_logs.purpose"),
            sortField: "purpose",
            dataClass: "--width-sm",
            formatter(value) {
              if (!value) return "N/A";
              return value;
            },
          },
          {
            name: "createdAt",
            title: i18n.t("reports.balance_logs.created_at"),
            sortField: "createdAt",
            dataClass: "--width-md",
            formatter(value) {
              return value
                ? moment(value, "x").format("DD MMM YYYY , h:mm:ss a")
                : "";
            },
          },
        ],
        rows: [],
      },
      balanceInfo: {
        columns: [
          {
            name: "sn",
            title: "#",
            dataClass: "--checkbox",
          },

          {
            name: "amount",
            title: i18n.t("reports.amount"),
            sortField: "amount",
            dataClass: "--width-sm",
            formatter(value) {
              return `THB. ${value}`;
            },
          },

          {
            name: "status",
            title: i18n.t("reports.status.label"),
            sortField: "status",
            dataClass: "--width-sm",
            formatter(value) {
              if (!value) return "N/A";
              let title = getReportStatus(value).title;
              let style = getReportStatus(value).variation;
              return `<span class="badge badge-pill text-uppercase status-badge --${style}">${title}</span>`;
            },
          },

          {
            name: "date",
            title: i18n.t("reports.balance_history.date"),
            sortField: "created_at",
            dataClass: "--width-md",
          },
          {
            name: "time",
            title: i18n.t("reports.balance_history.time"),
            sortField: "time",
            dataClass: "--width-sm",
          },
        ],
        data: null,
      },
    };
  },

  methods: {
    getBalanceHistory() {
      this.loading = true;
      this.$store.dispatch("settings/getBalance", {
        callback: (status, data) => {
          if (!data.balance) data = { balance: { total: 0 } };
          this.totalBalance = data.balance.total;
        },
      });
      this.$store.dispatch("settings/getBalanceHistory", {
        callback: (status, data) => {
          if (status) {
            this.balanceInfo.data = data.histories;
            this.balanceInfo.data = data.histories.map((data, index) => {
              return {
                sn: index + 1,
                amount: data.amount,
                date: data.date,
                time: data.time,
                status: data.status,
              };
            });

            this.getBalanceLog();
          }
        },
      });
    },

    getBalanceLog() {
      this.loading = true;
      this.$store.dispatch("settings/getBalanceLog", {
        callback: (status, data) => {
          if (status) {
            this.balanceLog.rows = data.map((log, i) => {
              return {
                sn: i,
                amount: log.details.amount,
                status: log.details.amount,
                purpose: log.details.purpose,
                createdAt: log.createdAt,
              };
            });
          }
          this.loading = false;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports-page {
  margin: 35px;
}
</style>