<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">{{ $t("settings.general.label") }}</h4>
    </div>
    <div class="mb-4"></div>

    <Card grey>
      <div slot="body">
        <h5 class="text-color-4 mb-4">
          {{ $t("settings.general.inventory") }}
        </h5>

        <div v-if="loading">
          <Spinner size="sm" />
        </div>

        <div v-else>
          <div class="d-flex mb-4">
            <!-- UserName -->
            <TextBox
              type="text"
              id="username"
              label="ชื่อ"
              v-model="userSettings.username"
            />

            <Button
              type="button"
              variant="primary"
              custom_class="ml-4 mt-n2 align-self-center"
              :loading="userSettingsLoading"
              @click="handleUsernameChange"
              >{{ $t("general.update") }}</Button
            >
          </div>

          <Toggle
            id="productMgt"
            @input="handleFormSubmit"
            :value="false"
            variant="primary"
            v-model="generalSettings.productMgt"
            :labels="labels.product"
          />

          <div class="mb-4"></div>

          <Toggle
            v-if="generalSettings.productMgt"
            id="inventooryMgt"
            @input="handleFormSubmit"
            :value="false"
            variant="primary"
            v-model="generalSettings.inventoryMgt"
            :labels="labels.inventory"
          />
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Toggle from "@/components/core/Toggle";
import Spinner from "@/components/core/Spinner";
import { i18n } from "@/i18n";
import { mapGetters } from "vuex";
import TextBox from "@/components/core/TextBox";
import Button from "@/components/core/Button";
import auth from "../../../router/modules/auth";

export default {
  name: "SellerSettingsGeneral",

  components: {
    Card,
    Toggle,
    Spinner,
    TextBox,
    Button,
  },

  data() {
    return {
      loading: false,
      generalSettings: {
        productMgt: null,
        inventoryMgt: null,
      },

      userSettings: {
        username: null,
      },
      userSettingsLoading: false,

      labels: {
        product: {
          true: i18n.t("settings.general.product_management"),
          false: i18n.t("settings.general.product_management"),
        },
        inventory: {
          true: i18n.t("settings.general.inventory_management"),
          false: i18n.t("settings.general.inventory_management"),
        },
      },
    };
  },

  created() {
    this.loading = true;
    this.$store.dispatch("settings/fetchGeneralSettings", {
      callback: (status, data) => {
        if (status) this.generalSettings = data;
        this.userSettings.username =
          this.authData && this.authData.user && this.authData.user.username;
        this.loading = false;
      },
    });
  },

  methods: {
    handleUsernameChange() {
      this.userSettingsLoading = true;
      this.$store.dispatch("settings/changeUsername", {
        data: this.userSettings,
        callback: (status, data) => {
          if (status) {
            // change username manually on UI
            let _auth = JSON.parse(localStorage._auth);
            _auth.user.username = this.userSettings.username;
            localStorage._auth = JSON.stringify(_auth);
            this.$store.commit("user/setAuthData", _auth);
          }

          this.userSettingsLoading = false;
        },
      });
    },

    handleFormSubmit() {
      if (!this.generalSettings.productMgt)
        this.generalSettings.inventoryMgt = false;
      this.$store.dispatch("settings/updateGeneralSettings", {
        generalSettings: this.generalSettings,
      });
    },
  },

  computed: {
    ...mapGetters("user", ["authData"]),
  },
};
</script>

<style lang="scss" scoped>
.setting__header {
  font-weight: bold;
  font-size: 18px;
}
.setting__description {
  font-weight: thin;
  font-size: 13px;
}

.setting {
  &.--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.--table {
    .setting__text {
      margin-bottom: 20px;
    }
  }

  margin-bottom: 30px;
}

.settings-wrapper {
  margin-top: 24px;
}

.setting-status-tag {
  text-transform: uppercase;

  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
  width: 68px;
  height: 29px;

  &.--warning {
    background-color: #ffc107;
    color: black;
  }

  &.--success {
    background-color: #28a745;
    color: white;
  }

  &.--danger {
    background-color: #dc3545;
    color: white;
  }

  &.--info {
    background-color: #007bff;
    color: white;
  }
}
</style>