<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-12 col-md-6">
            <h4 class="text-color-2">
              <span class="font-weight-light">{{ $t("general.edit") }}</span>
              {{ $t("products.products_label") }}
            </h4>
          </div>
          <div class="col-12 col-md-6">
            <!-- <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item" v-for="(item, index) in breadcrumbLinks" :key="index">
                <router-link
                  :to="{name: item.pathName}"
                  :class="{'breadcrumb-item': true, 'active': item.isActive}"
                >{{item.title}}</router-link>
              </li>
            </ol>-->
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div v-if="productEditForm">
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="handleFormSubmit" novalidate>
              <div class="row">
                <div class="col-12 col-md-6">
                  <Card>
                    <div slot="body">
                      <h5 class="text-color-4 mb-4">
                        {{ $t("products.edit.label") }}
                      </h5>

                      <TextBox
                        type="text"
                        id="name"
                        :label="$t('form.product.product_name')"
                        v-model="productEditForm.details.name"
                        rules="required"
                      />
                      <div class="row">
                        <div class="col-6">
                          <TextBox
                            type="text"
                            id="item-code"
                            :label="$t('form.product.item_code')"
                            v-model="productEditForm.details.itemCode"
                            rules="required"
                            :disabled="true"
                          />
                        </div>
                        <div class="col-6">
                          <label>{{ $t("general.availability") }}</label>
                          <Toggle
                            id="available"
                            color="blue"
                            :value="false"
                            v-model="productEditForm.details.available"
                            :labels="{
                              true: $t('form.product.available'),
                              false: $t('form.product.unavailable'),
                            }"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-md-6">
                          <NumberField
                            v-if="isSeller"
                            id="cost"
                            :label="$t('form.product.cost_price')"
                            v-model.number="productEditForm.details.cost"
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <NumberField
                            id="sale-price"
                            :label="$t('form.product.sale_price')"
                            v-model.number="productEditForm.details.salePrice"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-md-6">
                          <NumberField
                            id="weight-approx"
                            :label="$t('form.product.weight_approx')"
                            placeholder="(gm)"
                            v-model.number="
                              productEditForm.details.weightApprox
                            "
                            rules="required"
                          />
                        </div>
                        <div class="col-12 col-md-6">
                          <NumberField
                            v-if="
                              generalSettings && generalSettings.inventoryMgt
                            "
                            id="quantity"
                            :label="$t('form.product.quantity')"
                            placeholder="Product quantity"
                            rules="required"
                            v-model.number="productEditForm.details.quantity"
                          />
                        </div>
                      </div>
                      <ImageUpload
                        :label="$t('form.product.product_images')"
                        :dragText="$t('form.product.upload_product_images')"
                        :values="productEditForm.details.images"
                        v-model="productEditForm.details.images"
                      />
                      <br />
                      <TextArea
                        id="description"
                        :label="$t('form.product.description')"
                        v-model="productEditForm.details.description"
                        rows="4"
                      />
                    </div>
                  </Card>
                </div>
                <div class="col-12 col-md-6">
                  <!-- <Card>
                    <div slot="body">
                      <h5 class="text-color-4 mb-4">{{$t("form.product.product_variations")}}</h5>
                      <Toggle
                        id="has-variations"
                        :value="false"
                        color="blue"
                        v-model="productHasVariations"
                        :labels="{true: $t("form.product.product_variations_yes"), false: true: $t("form.product.product_variations_no" }"
                      />
                      <div v-if="productHasVariations">
                        <ProductVariation
                          color_scheme="primary"
                          v-model="productEditForm.details.variations"
                          :value="productEditForm.details.variations"
                          :product="productEditForm"
                          :userName="authData.user.username"
                        />
                      </div>
                    </div>
                  </Card> -->
                  <Card>
                    <div slot="body">
                      <h5 class="text-color-4 mb-4">
                        {{ $t("form.product.bundle_prices") }}
                      </h5>

                      <Toggle
                        id="has-bundles"
                        :value="productHasBundles"
                        color="blue"
                        v-model="productHasBundles"
                        :labels="{
                          true: $t('form.product.bundle_price_yes'),
                          false: $t('form.product.bundle_price_no'),
                        }"
                      />

                      <div v-if="productHasBundles">
                        <div class="mb-3"></div>
                        <RowControls
                          :config="bundlePriceConfig"
                          v-model="productEditForm.details.bundles"
                          :value="productEditForm.details.bundles"
                        />
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div slot="body">
                      <h5 class="text-color-4 mb-4">
                        {{ $t("form.product.product_variations") }}
                      </h5>
                      <div v-if="productEditForm.details.variationData">
                        <div
                          v-for="(value, key) in productEditForm.details
                            .variationData"
                          :key="key"
                        >
                          <TextBox
                            type="text"
                            :id="key"
                            :label="key"
                            v-model="productEditForm.details.variationData[key]"
                            rules="required"
                            wrapper_class="mb-1"
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div class="d-flex mt-4 mb-4 justify-content-center">
                <Button
                  id="btn-submit"
                  type="submit"
                  variant="primary"
                  custom_class="mr-4"
                  size="lg"
                  :disabled="invalid"
                  :loading="updateLoading"
                  >{{ $t("general.submit") }}</Button
                >

                <Button
                  id="btn-cancel"
                  type="button"
                  variant="secondary"
                  custom_class="mr-4"
                  size="lg"
                  @click="cancel()"
                  >{{ $t("general.cancel") }}</Button
                >
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div v-else>
          <Spinner size="md" variation="primary" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import Button from "@/components/core/Button";
import TextArea from "@/components/core/TextArea";
import Card from "@/components/core/Card";
import NumberField from "@/components/core/NumberField";
import ImageUpload from "@/components/core/ImageUpload";
import Select from "@/components/core/Select";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import RowControls from "@/components/core/RowControls";
import ProductVariation from "@/components/derived/ProductVariation";
import Toggle from "@/components/core/Toggle";
import Spinner from "@/components/core/Spinner";
import { mapGetters } from "vuex";

export default {
  name: "Seller-Product-Edit",
  components: {
    TextBox,
    Button,
    NumberField,
    TextArea,
    ImageUpload,
    Select,
    CheckBoxGroup,
    RowControls,
    ProductVariation,
    Toggle,
    Spinner,
    Card,
  },
  data() {
    return {
      breadcrumbLinks: [
        {
          title: "Dashboard",
          isActive: false,
          pathName: "Seller-Dashboard",
        },
        {
          title: "Products",
          isActive: false,
          pathName: "Seller-Product-List",
        },
        {
          title: this.$route.params.id,
          isActive: true,
          pathName: "Seller-Product-Detail",
        },
        {
          title: "Edit",
          isActive: false,
          pathName: "Seller-Product-Edit",
        },
      ],

      productEditForm: null,
      loading: false,
      updateLoading: false,
      productHasVariations: false,
      productHasBundles: false,

      bundlePriceConfig: {
        items: {
          amount: {
            type: "number",
            rules: "required",
            id: "bundle-amount",
            placeholder: "Amount",
          },

          price: {
            type: "number",
            id: "bundle-price",
            rules: "required",
            placeholder: "Price",
          },
        },
        numInstances: 1,
        hasControls: true,
      },
    };
  },

  created() {
    this.fetchProduct();
  },

  methods: {
    handleFormSubmit() {
      if (!this.productHasVariations)
        this.productEditForm.details.variations = null;
      if (!this.productHasBundles) this.productEditForm.details.bundles = null;
      this.updateLoading = true;
      this.$store.dispatch("products/updateProduct", {
        id: this.productEditForm.id,
        product: this.productEditForm.details,
        callback: (data, status) => {
          this.updateLoading = false;
          if (status)
            this.$router.push({
              name: "Seller-Product-Detail",
              params: { id: this.productEditForm.id },
            });
        },
      });
    },

    cancel() {
      this.$router.push({ name: "Seller-Product-List" });
    },

    fetchProduct() {
      this.$store.dispatch("products/fetchProductDetail", {
        id: this.$route.params.id,
        callback: (status, data) => {
          this.productEditForm = data;
          if (
            data.details.variations &&
            data.details.variations.variations &&
            data.details.variations.variations.length
          )
            this.productHasVariations = true;
          if (data.details.bundles && data.details.bundles.length)
            this.productHasBundles = true;
        },
      });
    },
  },

  computed: {
    ...mapGetters({ generalSettings: "settings/generalSettings" }),
    ...mapGetters("user", ["authData"]),

    isSeller() {
      if (this.authData && this.authData.user && this.authData.user.role)
        return this.authData.user.role === "seller";
      return false;
    },
  },
};
</script>

<style>
</style>