import { i18n } from "@/i18n";

export const printFormats = () => [
    // {
    //     ...parseTrans("default"),
    //     size: "normal",
    //     printer: "default",
    //     image: require("@/assets/printSamples/default.png"),
    // },

    {
        ...parseTrans("default_a4"),
        size: "a4",
        printer: "default",
        image: require("@/assets/printSamples/default_a4.png"),
    },

    {
        ...parseTrans("default_a4"),
        name: "4 in 1",
        sizeToShow: "4 in 1",
        description: "Proship format",
        size: "a4",
        printer: "proship",
        image: require("@/assets/printSamples/default_a4.png"),
    },

    {
        ...parseTrans("tsc"),
        size: "normal",
        printer: "tsc",
        image: require("@/assets/printSamples/tsc.png"),
    },

    {
        ...parseTrans("proship"),
        size: "normal",
        printer: "proship",
        image: require("@/assets/printSamples/proship.png"),
    },

    {
        ...parseTrans("paperang"),
        size: "normal",
        printer: "paperang",
        image: require("@/assets/printSamples/paperang.png"),
    },

    {
        ...parseTrans("nj"),
        size: "a4",
        printer: "nj",
        image: require("@/assets/printSamples/nj.png"),
    },

    // {
    //     ...parseTrans("thp_sticker"),
    //     size: "normal",
    //     printer: "thpsticker",
    //     image: require("@/assets/printSamples/thp_sticker.png"),
    // },

    // {
    //     ...parseTrans("a5x2"),
    //     size: "normal",
    //     printer: "a5",
    //     image: require("@/assets/printSamples/a5x2.png"),
    // },

    // {
    //     ...parseTrans("rts"),
    //     size: "normal",
    //     printer: "rts",
    //     image: require("@/assets/printSamples/rts.png"),
    // },
    {
        ...parseTrans("100x100"),
        size: "normal",
        printer: "x100",
        image: require("@/assets/printSamples/rts.png"),
    },

    // {
    //     name: "NJ V2",
    //     sizeToShow: "",
    //     description: "",
    //     size: "a4",
    //     printer: "nj_v2",
    //     image: require("@/assets/printSamples/nj.png"),
    // },

    {
        // ...parseTrans("rts"),
        name: "Proship v2",
        sizeToShow: "3.7x5.9",
        description: "Proship v2",
        size: "normal",
        printer: "proship_v2",
        image: require("@/assets/printSamples/rts.png"),
    },

    // {
    //     ...parseTrans("jt"),
    //     size: "normal",
    //     printer: "jt",
    //     image: require("@/assets/printSamples/jt.png"),
    // }
];

export const printLabels = (printSettings) => {
    const data = {
        paperSizes: {
            "กระดาษ A4": [
                {
                    ...parseTrans("default_a4"),
                    size: "a4",
                    printer: "default",
                    image: require("@/assets/printSamples/default_a4.png"),
                },
                {
                    ...parseTrans("default_a4"),
                    name: "4 in 1(Proship format)",
                    desc: "Proship 4 in 1",
                    size: "a4",
                    printer: "proship",
                    image: require("@/assets/printSamples/default_a4.png"),
                }
            ],
            "กระดาษสติกเกอร์ 100x100 มม. (4 x 4 นิ้ว)": [{
                ...parseTrans("100x100"),
                size: "normal",
                printer: "x100",
                image: require("@/assets/printSamples/rts.png"),
            },],
            "Paperang": [{
                ...parseTrans("paperang"),
                size: "normal",
                printer: "paperang",
                image: require("@/assets/printSamples/paperang.png"),
            },],
            "กระดาษสติกเกอร์ 100x150 มม. (4 x 6 นิ้ว)": [
                {
                    ...parseTrans("proship_v3p"),
                    name: parseTrans("proship").name + "(v2)",
                    size: "normal",
                    printer: "proship_v3p",
                    image: require("@/assets/printSamples/proship.png"),
                },

                // {
                //     ...parseTrans("proship_v3p"),
                //     name: parseTrans("proship").name + "(HTML)",
                //     size: "normal",
                //     printer: "proship_html",
                //     image: require("@/assets/printSamples/proship.png"),
                // },

                // {
                //     ...parseTrans("proship_v3"),
                //     name: parseTrans("proship").name + "(v3)",
                //     size: "normal",
                //     printer: "proship_v3",
                //     image: require("@/assets/printSamples/proship.png"),
                // },
                // {
                //     ...parseTrans("proship"),
                //     name: parseTrans("proship").name + "(v2)",
                //     size: "normal",
                //     printer: "proship_v2",
                //     image: require("@/assets/printSamples/proship.png"),
                // },

                {
                    ...parseTrans("proship"),
                    name: parseTrans("proship").name + "(v1)",
                    size: "normal",
                    printer: "proship",
                    image: require("@/assets/printSamples/proship.png"),
                },
            ],
            "กระดาษสติกเกอร์ 75x100 มม. (3 x 4 นิ้ว)": [
                {
                    ...parseTrans("tsc"),
                    size: "normal",
                    printer: "tsc",
                    image: require("@/assets/printSamples/tsc.png"),
                },

                {
                    ...parseTrans("tsc"),
                    name: parseTrans("tsc").name + "(No products)",
                    size: "normal",
                    printer: "tsc_wp",
                    image: require("@/assets/printSamples/tsc.png"),
                },

                {
                    ...parseTrans("tsc"),
                    name: parseTrans("tsc").name + "(แนวนอน)",
                    size: "normal",
                    printer: "100x75",
                    description: "",
                    image: require("@/assets/printSamples/100x75.png"),
                }
            ],
        },
        selectedPaperSize: "a4"
    }

    if (printSettings && printSettings.customLabels) data.paperSizes['กระดาษ A4'].push(
        // {
        //     ...parseTrans("nj"),
        //     size: "a4",
        //     printer: "nj",
        //     image: require("@/assets/printSamples/nj.png"),
        // },

        {
            ...parseTrans("rts"),
            size: "normal",
            printer: "rts",
            image: require("@/assets/printSamples/rts.png"),
        }
    )

    return data;
}

function parseTrans(trans) {
    let tUnits = i18n.t("printer_format." + trans).split("|");
    return ({
        name: tUnits[0] || "",
        // sizeToShow: tUnits[1] || "",
        description: tUnits[2] || "",
    })
}