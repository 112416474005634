<template>
  <div class="onboard d-flex align-items-center">
   <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Onboard"
};
</script>

<style lang="scss" scoped>
.onboard {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: $dashboard-background;
}

</style>