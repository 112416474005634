<template>
  <div class="orders-page">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h4 class="font-weight-light">
              {{ $t("general.all") }}
              <span class="font-weight-bold">{{ $t("shipment.label") }}</span>
            </h4>
          </div>
          <div class="col-sm-6 d-none d-md-block"></div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <Card>
          <div slot="body">
            <OrdersTable
              id="sales-agents-orders-list"
              create_path_name="Seller-Order-Create"
              edit_path_name="Seller-Order-Edit"
              detail_path_name="Seller-Order-Detail"
              sample_file_link="ordersSample.csv"
              @imported="importOrders"
              :sales_agent="true"
            />
          </div>
        </Card>
      </div>
    </section>
  </div>
</template>

<script>
import OrdersTable from "@/components/derived/OrdersTable";
import DateRangePicker from "@/components/derived/DateRangePicker";
import Card from "@/components/core/Card";
import { getOrderStatus } from "@/helpers/core";
import { FILTERS } from "@/helpers/filters";
import { i18n } from "@/i18n";

export default {
  name: "SalesAgentList",
  components: { OrdersTable, Card, DateRangePicker },
  data() {
    return {
      orderRows: null,
      orderCounts: null,
    };
  },

  created() {
    // this.fetchOrders();
  },

  methods: {
    handleSearch(searchData) {
      this.orderRows = null;
      this.$store.dispatch("orders/searchOrders", {
        ...searchData,
        callback: (status, data) => {
          this.orderRows = data.results.map((d) => {
            return {
              id: d.id,
              details: {
                trackingNo: d.trackingNo,
                status: d.status,
                remarks: d.remarks,
                shippingMethod: d.shippingMethod,
                orderCode: d.orderCode,
                customer: {
                  name: d.customerName,
                  address: {
                    address: d.address,
                    zipcode: d.zipcode,
                    socialLink: d.socialLink,
                  },
                  phoneNo: d.phoneNo,
                },
              },
              tn: d.tn,
              createdAt: d.createdAt,
            };
          });
        },
      });
    },

    handleDelete() {
      this.fetchSalesAgentOrders();
    },

    fetchSalesAgentOrders(value) {
      if (value == 7) value = 8; // 7 was removed
      this.orderRows = null;
      this.$store.dispatch("orders/fetchSalesAgentOrders", {
        statusCode: value > 0 ? value : null,
        callback: (status, data) => {
          this.orderRows = data.orders;
          this.orderCounts = [
            { label: i18n.t("tabs.all"), number: data.orderCounts[0] },
            { label: i18n.t("tabs.pending"), number: data.orderCounts[1] },
            { label: i18n.t("tabs.to_ship"), number: data.orderCounts[2] },
            { label: i18n.t("tabs.shipped"), number: data.orderCounts[3] },
            { label: i18n.t("tabs.delivered"), number: data.orderCounts[4] },
            { label: i18n.t("tabs.cancelled"), number: data.orderCounts[5] },
            { label: i18n.t("tabs.error"), number: data.orderCounts[6] },
            {
              label: i18n.t("status.return_to_sender"),
              number: data.orderCounts[8],
            },
          ];
        },
      });
    },
    importOrders(value) {
      this.$store.dispatch("orders/importOrders", {
        data: { fileUrl: value.url, shopId: value.selectedShop },
      });
    },
    exportProducts() {
      Swal.fire(
        "Export file",
        "File will download in a while",
        "info"
      ).then(() => {});
    },
  },
};
</script>

<style></style>
