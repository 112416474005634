const getProshipCode = () => {
    const timestamp = new Date().getTime();
    return "P-" + generateRandomCode(2) + timestamp + generateRandomCode(2) + '-' + generateRandomCode(3);
};

function generateRandomCode(length) {
    let possible = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let string = '';
    for (let i = 0; i < length; i++) {
        string += possible.charAt(random(0, possible.length - 1));
    }
    return string;
}

function random(min, max) {
    return Math.random() * (max - min) + min;
}

export { getProshipCode };