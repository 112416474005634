<template>
  <div>
    <div class="text-right mr-4 mt-4">
      <button class="btn btn-success d-print-none" @click="print()">
        <i class="fal fa-print"></i>
        Print
      </button>
    </div>
    <div class="printPreview" v-if="shipments && shipments.length">
      <h2 class="title text-center mb-5">ใบส่งมอบสิ่งของ</h2>

      <div class="barcode">
        <canvas id="barcode"></canvas>
      </div>

      <div class="pb-5">
      </div>

      <div class="d-flex justify-content-between mb-4">
        <p class="text-left lead">ผู้ฝากส่ง :{{ username }}</p>

        <p class="text-left lead">วันที่และเวลา : {{ dateToday }}</p>
      </div>

      <table class="table table-bordered">
        <thead>
          <td>#</td>
          <td>บาร์โค้ด</td>
          <td>ปลายทาง</td>
          <td>รหัส ปณ.ปลายทาง</td>
          <td>บริการ</td>
          <td>น้ำหนัก</td>
          <td>ราคา</td>
        </thead>

        <tbody>
          <tr v-for="(shipment, index) in shipments" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ shipment.details.trackingNo }}</td>
            <td>{{ shipment.details.customer.name }}</td>
            <td>{{ shipment.details.customer.address.zipcode }}</td>
            <td>ด่วนพิเศษในประเทศ (EMS)</td>
            <td>
              {{ shipment.details.boxSize }}/{{
                shipment.details.weight || "0.00"
              }}
            </td>
            <td>{{ shipment.details.cost }}</td>
          </tr>
        </tbody>
      </table>

      <p class="lead text-right">ยอดรวมทั้งหมด: {{ shipments.length }}</p>

      <p class="lead text-right">{{ totalPrice }} บาท</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "PrintPreview",
  components: {},
  created() {
    if (this.$route.params.shipments && this.$route.params.manifestNo) {
      this.shipments = this.$route.params.shipments;
    } else {
      alert("Please select a manifest No from reports");
      this.$router.push({ name: "Reports-List" });
    }
  },

  mounted() {
    //GENERATE BARCODE
    try {
      // The return value is the canvas element
      let canvas = bwipjs.toCanvas("#barcode", {
        bcid: "code128", // Barcode type
        text: this.$route.params.manifestNo, // Text to encode
        scale: 2, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        includetext: true, // Show human-readable text
        textxalign: "center", // Always good to set this
      });
    } catch (e) {
      // `e` may be a string or Error object
    }
  },

  data() {
    return {
      shipments: null,
    };
  },

  methods: {
    print() {
      window.print();
    },
  },

  computed: {
    ...mapGetters("user", ["authData"]),

    username() {
      if (this.authData && this.authData.user && this.authData.user.username)
        return this.authData.user.username;
    },

    totalPrice() {
      let total = 0;
      this.shipments.forEach((sh) => {
        total += sh.details.cost;
      });
      return total;
    },

    dateToday() {
      return moment().format("DD/MM/YYYY hh:mm:ss");
    },
  },
};
</script>

<style lang="scss" scoped>
.printPreview {
  position: relative;
  background-color: white;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;

  #barcode {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>