<template>
  <div class="d-flex flex-column">
    <vue-dropzone
      ref="dropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-success="handleSuccess"
      @vdropzone-error="handleError"
    ></vue-dropzone>

    <button type="button" class="btn btn-secondary btn-sm mt-2" v-if="value && value.length > 0 && clearOption" @click="clear()">Clear Files</button>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { http } from "@/helpers/http";
import { i18n } from "@/i18n";

export default {
  name: "DropZone",
  components: { vueDropzone: vue2Dropzone },
  props: {
    value: {
      type: Array,
    },
    label: {
      type: String,
      default: "ลากไฟล์มาวางตรงนี้ หรือกดเพื่อเลือกไฟล์จากเครื่องของคุณ",
    },
    size: {
      type: String,
      default: "md"
    },
    clearOption: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      filesCount: 0,
      formdata: new FormData(),
      dropzoneOptions: {
        // url: "http://localhost:3000/dev/v1/media",
        url:
          "https://8enalpa7e5.execute-api.ap-southeast-1.amazonaws.com/dev/v1/media",
        method: "post",
        headers: {
          "Cache-Control": null,
          "X-Requested-With": null,
        },
        // thumbnailWidth: 100,
        // thumbnailHeight: 100,
        maxFilesize: 5, // 5 MB
      },
    };
  },

  created() {
    this.dropzoneOptions.dictDefaultMessage = this.value
      ? this.value.length + " file(s)"
      : `<i class="fas fa-cloud-upload-alt uploadIcon"></i><br>` +  this.label;

      if(this.label) this.dropzoneOptions.dictDefaultMessage = this.label;
  },

  mounted(){
    $(".dropzone").addClass('--' + this.size);
  },

  methods: {
    clear() {
      this.$refs.dropzone.removeAllFiles();
      this.$emit("input", []);
      this.$emit("uploaded", []);
      this.dropzoneOptions.dictDefaultMessage = `<i class="fas fa-cloud-upload-alt uploadIcon"></i><br>` + this.label;
    },

    handleSuccess(file, response) {
      let arr = this.value ? response.urls.concat(this.value) : response.urls;
      this.$emit("input", arr);
      this.$emit("uploaded", arr);
    },

    handleError(file, message) {
      document
        .querySelectorAll(".dz-error-message")
        .forEach((elem) => (elem.innerHTML = i18n.t("general.error")));
      this.$emit("error");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dropzone {
  
  min-height: 0;
  background-color:lighten($brand-blue-light, 42);
  border-style: dashed;
  border-radius: .75rem;
  border-color: $brand-blue-light;
  color: $brand-blue-mid;
  font-size: .85rem;

  &.--md{
    padding: 5rem 1rem;
  }

    &.--sm{
    padding: 1rem 1rem;
  }

  .dz-message {
    margin: 0;
  }

  .uploadIcon{
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: $brand-blue-light;
  }
}
</style>
