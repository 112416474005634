<template>
  <div class="reports-page">
    <div class="content-header">
      <h4 class="text-color-2">รายงานสินค้า</h4>
    </div>

    <div class="row">
      <div class="col-12">
        <Card>
          <div slot="body">
            <div v-if="loading">
              <Spinner size="md"
                       variation="primary" />
            </div>

            <div v-else>
              <!-- Toolbar -->
              <div class="d-flex mb-4">
                <DatePicker id="date-range"
                            custom_class="align-self-center mr-2 mb-0"
                            v-model="productsReportForm.fromDate"
                            :placeholder="'เลือกวันที่เริ่ม'" />

                <DatePicker id="date-range"
                            custom_class="align-self-center mr-2 mb-0"
                            v-model="productsReportForm.toDate"
                            :placeholder="'เลือกวันที่สิ้นสุด'" />



                <Button id="btnGetOrders"
                        custom_class="align-self-center mr-2"
                        variant="info"
                        size="sm"
                        :disabled="productsReportForm.loading"
                        :loading="productsReportForm.loading"
                        @click="getProductsReports()">กดสร้างรายงาน</Button>

                <Button id="btnExport"
                        variant="success"
                        size="sm"
                        @click="getProductsReports('csv')"
                        :disabled="productsReportForm.loading"
                        :loading="productsReportForm.loading"
                        custom_class="align-self-center">โหลดไฟล์ Excel</Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <Card v-if="productsData">
      <div slot="body">

        <h4 class="mb-5">Total: {{ productsData.totalProducts }}</h4>

        <h6>Products</h6>
        <hr>
        <p v-for="(value, key) of productsData.products"
           :key="key">
          {{ key }}: {{ value }}
        </p>
      </div>
    </Card>
  </div>
</template>

<script>
import DatePicker from "@/components/core/DatePicker";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import Select from "@/components/core/Select";
import DataTable from "@/components/core/DataTable";
import moment from "moment-timezone";
import { i18n } from "@/i18n";
import { FILTERS } from "@/helpers/filters";
import { Toast } from "@/helpers/toastr";
import { getOrderStatus } from "@/helpers/core";
import fileDownload from "js-file-download";
import Spinner from "@/components/core/Spinner";

export default {
  name: "ProductsReport",
  components: { Card, DataTable, DatePicker, Button, Spinner, Select },

  data() {
    return {
      loading: false,
      productsReportForm: {
        fromDate: null,
        toDate: null,
        shopId: null,
        status: null,
        loading: false,
      },
      productsData: null
    };
  },

  created() {
  },

  methods: {
    getProductsReports(format = "json") {
      if (format == "json") this.products = null;
      this.productsReportForm.loading = true;

      let from = moment
        .tz(this.productsReportForm.fromDate, "Asia/Bangkok")
        .startOf("day")
        .valueOf();
      let to = moment
        .tz(this.productsReportForm.toDate, "Asia/Bangkok")
        .endOf("day")
        .valueOf();
      if (!from || !to) {
        Toast("From & To dates must exist", "danger");
        this.productsReportForm.loading = false;
        return;
      }
      this.$store.dispatch("products/fetchProductsReportEs", {
        from,
        to,
        format,
        callback: (status, data) => {
          if (status) {
            if (format == "csv") {
              window.open(data.message, "_blank");
            } else this.productsData = data;
          }
          this.productsReportForm.loading = false;
        },
      });
    }
  },

  computed: {
  },
};
</script>

<style lang="scss"
       scoped>
      .reports-page {
        margin: 35px;
      }
    </style>