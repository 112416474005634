<template>
  <div class="login">
    <div class="auth-box">
      <div class="site-logo">
        <a href>
          <img src="@/assets/logo_small.png" alt />
        </a>
      </div>
      <div class="auth-box__body card">
        <p class="auth-box-body__msg text-center">
          {{ $t("login.description") }}
        </p>

        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="handleLogin">
            <TextBox
              type="text"
              id="email-textbox"
              :placeholder="$t('general.email')"
              rules="required"
              v-model="loginForm.email"
            />
            <TextBox
              type="password"
              id="password-textbox"
              :placeholder="$t('general.password')"
              rules="required"
              v-model="loginForm.password"
            />

            <div class="auth-box__cta-wrapper d-flex justify-content-between">
              <Button
                id="submit-login-form-button"
                :disabled="invalid"
                class="btn-block"
                variant="info"
                size="md"
                :loading="loginLoading"
                >{{ $t("login.sign_in") }}</Button
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    TextBox,
    Button,
  },

  data() {
    return {
      loginLoading: false,
      loginForm: {
        email: null,
        password: null,
      },
    };
  },

  methods: {
    handleLogin() {
      this.loginLoading = true;
      this.$store.dispatch("user/login", {
        data: {
          email: this.loginForm.email,
          password: this.loginForm.password,
        },
        callback: (status, data) => {
          if (status) {
            // if (this.firstVisit && data.user.role === "seller")
            //   this.$router.push({ name: "Onboard" });
            // else this.$router.push({ name: "Seller-Dashboard" });
            // this.$router.push({ name: "Seller-Dashboard" });

            // Set orders table tab status as 1
            localStorage.setItem("currentTabStatus", 1);

            this.$store.dispatch("shops/fetchShops", {
              query: "responseSize=all",
              callback: (status, data) => {
                if (data.length)
                  this.$router.push({ name: "Seller-Dashboard" });
                else this.$router.push({ name: "Seller-Settings-Shops" });
              },
            });
          }
          this.loginLoading = false;
        },
      });
    },
  },

  computed: {
    ...mapGetters("onboard", ["firstVisit"]),
  },
};
</script>


<style scoped>
#submit-login-form-button {
  width: 100%;
}

.auth-box__body {
  width: 100%;
}

.site-logo {
  margin: 25px auto !important;
}
</style>