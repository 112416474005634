<template>
  <div class="buyer">
    <div class="container buyer-container">
      <div class="proship-logo-wrapper text-center">
        <img src="@/assets/images/shared/proship-logo-sm.png" alt class="proship-logo" />
      </div>
      <div class="buyer-content d-flex justify-content-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Buyer"
};
</script>

<style scoped lang="scss">
.buyer {
  background-color: $dashboard-background;
  position: relative;
}
.buyer-container {
  min-height: 100vh;
  max-width: 100%;
}

.proship-logo {
  width: 141px;
  margin-top: 30px;
  margin-bottom: 30px;

  @include for-laptops-only{
    margin: 10px 0;
  }
}

</style>