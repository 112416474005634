import { http } from "@/helpers/http";
// import axios from 'axios';
const baseUrl = "https://k4zb5cppv1.execute-api.ap-southeast-1.amazonaws.com/dev/v1";
// const baseUrl = "http://localhost:3000/dev/v1"; // dev
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},

  actions: {

    createPreShipment({ state, commit, rootState }, payload) {
      http
        .post(baseUrl + "/pre-shipments", payload.order)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    updatePreShipment({ state, commit, rootState }, payload) {
      http
        .put(baseUrl + "/pre-shipments/" + payload.id, payload.order)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    async cancelPreShipmentAsync({ state, commit, rootState }, payload) {
      try {
        let resp = await http
          .delete(baseUrl + "/pre-shipments/" + payload.id);
        return resp.data;
      } catch (e) {
        return null;
      }
    },

    createOrder({ state, commit, rootState }, payload) {
      http
        .post(baseUrl + "/pre-shipments/" + payload.id + "/create-order")
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    async createOrderAsync({ state, commit, rootState }, payload) {
      try {
        let resp = await http.post(baseUrl + "/pre-shipments/" + payload.id + "/create-order");
        return resp.data;
      } catch (error) {
        return null;
      }
    },

    updatePreShipmentStatus({ state, commit, rootState }, payload) {
      http
        .put(baseUrl + "/pre-shipments/" + payload.id + "/status", payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    async updatePreShipmentStatusAsync({ state, commit, rootState }, payload) {
      try {
        let resp = await http.put(baseUrl + "/pre-shipments/" + payload.id + "/status", payload.data)
        return resp.data;
      } catch (error) {
        return null;
      }
    },

    cancelPreShipment({ state, commit, rootState }, payload) {
      http
        .delete(baseUrl + "/pre-shipments/" + payload.id)
        .then((response) => {
          if (payload.callback) payload.callback(true);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },
  },
};

