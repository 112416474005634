<template>
    <button class="btn-dash-date-dropdown" @click.self="toggleDateSelector">
        <template v-if="selectedDateFrom && selectedDateTo">
            {{ selectedDateFrom | formatDate }}
            -
            {{ selectedDateTo | formatDate }}
        </template>
        <template v-else>Select a date</template>

        <div class="dash-date-dropdown" v-if="showDateSelector">
            <vue-datepicker :input-class="'calendar'" @selected="handleSelected" v-model="selectedDateFrom"
                :inline="true"></vue-datepicker>
            <div class="separator"></div>
            <vue-datepicker :input-class="'calendar'" @selected="handleSelected" v-model="selectedDateTo"
                :inline="true"></vue-datepicker>
            <div class="date-presets">
                <h2>Date presets</h2>
                <ul>
                    <li v-for="preset of presets" :key="preset.name"
                        :class="`${selectedPreset && preset.name == selectedPreset.name ? '--selected' : ''}`"
                        @click="handlePresetSelect(preset)">{{
                            preset.name
                        }}</li>
                </ul>
            </div>
        </div>
    </button>
</template>

<script>
import VueDatepicker from "vuejs-datepicker";
import moment from "moment";

export default {
    name: "DashboardDatePicker",
    components: {
        VueDatepicker
    },
    data() {
        return {
            selectedDateFrom: null,
            selectedDateTo: null,
            selectedPreset: null,

            presets: [
                {
                    name: "Last 7 days",
                    valueFrom: moment().subtract(7, 'days').valueOf(),
                    valueTo: moment().valueOf()
                },
                {
                    name: "Last 15 days",
                    valueFrom: moment().subtract(15, 'days').valueOf(),
                    valueTo: moment().valueOf()
                },
                {
                    name: "Last 30 days",
                    valueFrom: moment().subtract(30, 'days').valueOf(),
                    valueTo: moment().valueOf()
                },
                {
                    name: "Last 60 days",
                    valueFrom: moment().subtract(60, 'days').valueOf(),
                    valueTo: moment().valueOf()
                },
                {
                    name: "Last 90 days",
                    value: moment().subtract(90, 'days').valueOf(),
                    valueTo: moment().valueOf()
                },
                {
                    name: "Last 180 days",
                    valueFrom: moment().subtract(180, 'days').valueOf(),
                    valueTo: moment().valueOf()
                }
            ],
            showDateSelector: false
        }
    },

    created() {
        this.handlePresetSelect(this.presets.find(p => p.name == 'Last 30 days'))
    },
    methods: {
        toggleDateSelector() {
            this.showDateSelector = !this.showDateSelector;
        },

        handlePresetSelect(preset) {
            this.selectedPreset = preset;
            this.selectedDateFrom = preset.valueFrom;
            this.selectedDateTo = preset.valueTo;
            this.handleSelected();
        },

        handleSelected() {
            setTimeout(() => {
                if (this.selectedDateFrom) this.selectedDateFrom = moment(this.selectedDateFrom).valueOf()
                if (this.selectedDateTo) this.selectedDateTo = moment(this.selectedDateTo).valueOf()

                if (this.selectedDateFrom && !this.selectedDateTo) this.selectedDateTo = moment().valueOf()
                if (this.selectedDateTo && !this.selectedDateFrom) this.selectedDateFrom = moment().valueOf()
                this.handleChange();
            }, 100);
        },

        handleChange() {
            this.$emit("change", { from: this.selectedDateFrom, to: this.selectedDateTo });
            this.showDateSelector = false;
        }
    }
}
</script>

<style lang="scss">
.btn-dash-date-dropdown {
    position: relative;
    margin-left: 20rem;
    background-color: white;
    border: none;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-radius: 0.5rem;
}

.dash-date-dropdown {
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-100%, 0.5rem);
    padding: 1rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: auto;
    display: flex;
    gap: 1rem;
    z-index: 1000;

    .vdp-datepicker__calendar {
        border-style: none;
        border-width: 0;
    }

    .separator {
        width: 1px;
        height: 200px;
        align-self: center;
        background-color: whitesmoke;
    }

    .selected-date {
        border-top: 1px solid whitesmoke;
        text-align: left;
        padding: 0.75rem 1rem 0.75rem 1rem;
    }

    .date-presets {
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        min-width: 10rem;
        border-left: 1px solid lightgray;

        h2 {
            font-size: 1rem;
            margin-bottom: 1rem;
            text-align: left;
        }

        ul {
            padding-left: 0;

            li {
                list-style-type: none;
                padding: 0.75rem 1rem 0.75rem 1rem;
                border-radius: 0.5rem;
                font-size: 0.8rem;
                text-align: left;
                color: dimgray;

                &:hover {
                    background-color: whitesmoke;
                }

                &.--selected {
                    color: $brand-blue-mid;
                }
            }
        }
    }
}
</style>