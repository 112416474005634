<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <h4 class="font-weight-light">
              {{ $t("general.all") }}
              <span class="font-weight-bold">{{
                $t("products.products_label")
              }}</span>
            </h4>
          </div>
          <div class="col-6 d-none d-md-block">
            <ol class="breadcrumb float-sm-right">
              <li
                class="breadcrumb-item"
                v-for="(item, index) in breadcrumbLinks"
                :key="index"
              >
                <router-link
                  :to="{ name: item.pathName }"
                  :class="{ 'breadcrumb-item': true, active: item.isActive }"
                  >{{ item.title }}</router-link
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <Card>
          <div slot="body">
            <ProductsTable
              id="products-list"
              :columns="productColumns"
              :rows="products"
              create_path_name="Seller-Product-Create"
              edit_path_name="Seller-Product-Edit"
              detail_path_name="Seller-Product-Detail"
              color_scheme="primary"
              @imported="importProducts"
              sample_file_link="ordersSample.csv"
              @deleted="handleDelete()"
            />
          </div>
        </Card>
      </div>
    </section>
  </div>
</template>

<script>
import ProductsTable from "@/components/derived/ProductsTable";
import Card from "@/components/core/Card";
import { FILTERS } from "@/helpers/filters";
import { mapGetters } from "vuex";
import { i18n } from "@/i18n";

export default {
  name: "Products-List",
  components: { ProductsTable, Card },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      breadcrumbLinks: [
        {
          title: i18n.t("dashboard.dashboard_label"),
          isActive: true,
          pathName: "Seller-Dashboard",
        },
        {
          title: i18n.t("products.products_label"),
          isActive: false,
          pathName: "Seller-Products",
        },
      ],
      products: null,
      productColumns: null,
    };
  },

  created() {
    setTimeout(() => this.fetchProducts(), 500);

    this.productColumns = [
      {
        name: "id-slot",
        title: "#",
        dataClass: "--checkbox",
      },

      {
        name: "details.images",
        title: "Image",
        formatter(value) {
          let imgSrc;
          if (value.length === 0) imgSrc = require("@/assets/no-image.png");
          else imgSrc = value[0];
          return `<img src="${imgSrc}" class="productImage">`;
        },
      },

      {
        name: "details.itemCode",
        title: "SKU",
        sortField: "details.itemCode",
        formatter(value) {
          return value;
        },
      },

      {
        name: "details.name",
        title: "Name",
        sortField: "details.name",
        formatter(value) {
          return value;
        },
      },

      {
        name: "details.variationData",
        title: "รูปแบบ",
        formatter(value) {
          if(!value) return "N/A";
          let variationStr = "";
          Object.keys(value).forEach(key => variationStr += `${key}: ${value[key]}<br>`);
          return variationStr;
        },
      },

      // {
      //   name: "details.proshipCode",
      //   title: `Proship ${i18n.t("general.code")}`,
      //   sortField: "details.proshipCode",
      //   formatter(value) {
      //     return `<span title="${value}">${FILTERS.ellipsis(value, 10)}</span>`;
      //   },
      // },

      {
        name: "details.available",
        title: i18n.t("general.availability"),
        sortField: "details.available",
        formatter(value) {
          let val = value ? i18n.t("general.yes") : i18n.t("general.no");
          let style = value ? "--yes" : "--no";
          return `<span class="badge badge-pill text-uppercase availablity-badge ${style}">${val}</span>`;
        },
      },
    ];

    if (this.generalSettings.inventoryMgt) {
      this.productColumns.push({
        name: "details.quantity",
        title: i18n.t("general.quantity"),
        sortField: "details.quantity",
      });
    }

    this.productColumns.push(
      {
        name: "details.salePrice",
        title: i18n.t("products.show.detail_card.sale_price"),
        sortField: "details.salePrice",
        dataClass: "text-right pr-2 pr-md-4",
        titleClass: "text-right pr-2 pr-md-4",
        formatter(value) {
          return value ? value : "N/A";
        },
      },

      {
        name: "details.weightApprox",
        title: `${i18n.t("general.weight")}(G)`,
        sortField: "details.weightApprox",
        dataClass: "text-right",
        dataClass: "text-right pr-2 pr-md-4",
        titleClass: "text-right pr-2 pr-md-4",
      },

      {
        name: "actions",
        title: i18n.t("general.actions"),
        dataClass: "text-center pr-2 pr-md-4",
        titleClass: "text-center pr-2 pr-md-4",
      }
    );
  },

  methods: {
    fetchProducts(perPage = 1) {
      this.products = null;
      this.$store.dispatch("products/fetchProducts", {
        showAll: true,
        callback: (status, data) => {
          this.products = data;
        },
      });
    },
    importProducts(value) {
      this.$store.dispatch("products/importProducts", {
        data: { fileUrl: value },
        callback: (status, data) => {
          if (status) {
            this.fetchProducts();
          }
        },
      });
    },

    handleDelete() {
      this.fetchProducts();
    },
  },

  computed: {
    ...mapGetters({ generalSettings: "settings/generalSettings" }),
  },
};
</script>

<style lang="scss">
.productImage {
  height: 30px;
  width: auto;
}
</style>
