<template>
  <div class="text-center">
    <OnboardHeader :title="$t('onboard.final.title')" :subtitle="$t('onboard.final.subtitle')">
      <img src="@/assets/images/onboard/freeTruck.svg" class="onboard-icon" slot="img" />
    </OnboardHeader>

    <div class="onboard-form-wrapper">
      <div class="onboard-form">
        <div class="shipping-label">
          <div class="left-info">
            <div class="logo-label">
              <img src="@/assets/logo-label.png" alt />
            </div>
            <div class="bottom"></div>
            <div class="vertical_dotted_line"></div>
            <div class="top"></div>
          </div>

          <div class="label-container">
            <canvas ref="canvas" v-if="pdfUrl"></canvas>

            <div v-else class="mt-4">
              <Spinner size="sm" />
            </div>
          </div>
        </div>

        <div class="label-footer">
          <p
            class="body-text label-footer-text"
          >{{$t('onboard.final.endtext')}}</p>
        </div>
      </div>
    </div>

    <OnboardButtons
      :on_next="onContinue"
      :on_secondary_button="printLabel"
      next_button_text="Next"
      secondary_button_text="Print Label"
      secondary_button_icon_class="fal fa-print"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardButtons from "@/components/derived/onboard/OnboardButtons";
import OnboardHeader from "@/components/derived/onboard/OnboardHeader";
import Spinner from "@/components/core/Spinner";

export default {
  name: "Onboard-Final",
  components: {
    OnboardButtons,
    OnboardHeader,
    Spinner
  },

  data() {
    return {
      pdfUrl: null
    };
  },

  created() {
    let orderId = this.$route.params.orderId;
    if (!orderId) this.$router.back();
    this.getLabel(orderId, pdfUrl => {
      this.pdfUrl = pdfUrl;
      this.renderPdf();
    });
  },

  methods: {
    renderPdf(pdfUrl) {
      var pdfjsLib = window["pdfjs-dist/build/pdf"];

      // The workerSrc property shall be specified.
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        "//mozilla.github.io/pdf.js/build/pdf.worker.js";

      // Using DocumentInitParameters object to load binary data.
      var loadingTask = pdfjsLib.getDocument(this.pdfUrl);
      loadingTask.promise.then(
        pdf => {
          // Fetch the first page
          var pageNumber = 1;
          pdf.getPage(pageNumber).then(page => {
            var scale = 1;
            var viewport = page.getViewport({ scale: scale });

            // Prepare canvas using PDF page dimensions
            var canvas = this.$refs.canvas;
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page into canvas context
            var renderContext = { canvasContext: context, viewport: viewport };
            var renderTask = page.render(renderContext);
            renderTask.promise.then(function() {
              console.log("Page rendered");
            });
          });
        },
        function(reason) {
          // PDF loading error
          console.error(reason);
        }
      );
    },

    getLabel(orderId, callback) {
      this.$store.dispatch("orders/printLabels", {
        data: { orders: [orderId], type: "custom_1"  },
        callback: (status, data) => {
          callback(data.url);
        }
      });
    },

    onContinue() {
      return this.$router.push({ name: "Onboard-Options" });
    },
    back() {
      this.$router.go(-1);
    },
    printLabel() {
      var win = window.open(this.pdfUrl, "_blank");
      win.focus();
      win.print();
    }
  },
  computed: {
    ...mapGetters("onboard", ["details", "product", "shippingMethods"])
  }
};
</script>

<style lang="scss">
.shipping-label {
  position: relative;
  height: 271px;
  @include for-laptops-only {
    height: 203px;
  }
  background: #f3f3f3;
  opacity: 1;
  margin: 0 auto;

  .left-info {
    display: none;

    @include for-desktops-and-up {
      display: block;
    }

    .logo-label {
      position: absolute;
      height: 134px;
      width: 55px;
      top: 68px;
      left: 36px;
      @include for-laptops-only {
        top: 40px;
      }
    }
    .top {
      bottom: 0;
      left: 100px;
      position: absolute;
      background: #fff;
      border-top-left-radius: 46px;
      border-top-right-radius: 46px;
      height: 24px;
      width: 46px;
    }
    .vertical_dotted_line {
      position: absolute;
      top: 25px;
      left: 123px;
      border: 1px dashed #cdcdcd;
      height: 223px;
      @include for-laptops-only {
        height: 177px;
      }
    }

    .bottom {
      top: 0;
      left: 100px;
      position: absolute;
      background: #fff;
      border-bottom-left-radius: 46px;
      border-bottom-right-radius: 46px;
      height: 24px;
      width: 46px;
    }
  }

  .label-container {
    height: 100%;
    padding: 5px;
    canvas {
      height: 100%;
      border-radius: 10px;
    }
    @include for-desktops-and-up {
      width: calc(100% - 125px);
      margin-left: 125px;
    }
  }
}
.label-footer-text {
  color: #646464;
  text-align: center;
  font-size: 14px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.option-icon {
  &.--shipping {
    width: 66px;
    height: 40px;
  }
}
</style>