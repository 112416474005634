<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <h4 class="font-weight-light">
              {{ $t("general.all") }}
              <span class="font-weight-bold">ลูกค้า</span>
            </h4>
          </div>
          <div class="col-6 d-none d-md-block">
            <ol class="breadcrumb float-sm-right">
              <li
                class="breadcrumb-item"
                v-for="(item, index) in breadcrumbLinks"
                :key="index"
              >
                <router-link
                  :to="{ name: item.pathName }"
                  :class="{ 'breadcrumb-item': true, active: item.isActive }"
                  >{{ item.title }}</router-link
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <Card>
          <div slot="body">
            <div>
              <div class="d-relative">
                <!-- Search UI -->
                <div class="d-flex justify-content-end mb-2">
                  <select
                    class="form-control form-control-sm mr-2"
                    v-model="searchConfig.column"
                    :style="{ width: '8rem' }"
                  >
                    <option value="phoneNo">{{ $t("search.phone_no") }}</option>
                    <option value="customerName">
                      {{ $t("search.customer") }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control form-control-sm flex-1 mr-2"
                    :placeholder="$t('search.search_keyword')"
                    v-model.trim="searchConfig.keyword"
                    style="width: 8rem"
                    @keydown.enter="search()"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-success btn-sm"
                      type="button"
                      @click="search()"
                    >
                      <i class="fal fa-search"></i>
                    </button>
                  </div>
                </div>

                <table class="table _datatable">
                  <thead>
                    <th>{{ $t("general.phone") }}</th>
                    <th>{{ $t("shipment.table_labels.customer") }}</th>
                    <th>{{ $t("general.sales_channel") }}</th>
                    <th># {{ $t("shipment.label") }}</th>
                    <th></th>
                  </thead>

                  <tbody>
                    <tr
                      v-for="customer in paginatedCustomers"
                      :key="customer.phoneNo"
                    >
                      <td>
                        <router-link
                          :to="{
                            name: 'Seller-Order-List',
                            params: {
                              prefillData: {
                                search: {
                                  column: 'phoneNo',
                                  keyword: customer.phoneNo,
                                },
                              },
                            },
                          }"
                        >
                          {{ customer.phoneNo }}
                        </router-link>
                      </td>
                      <td>{{ customer.custName }}</td>
                      <td>{{ customer.scName }}</td>
                      <td>{{ customer.numOrders }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'Seller-Order-Quick-Create',
                            params: { prefillData: { customer } },
                          }"
                          class="btn btn-outline-primary btn-sm"
                        >
                          <i class="fal fa-truck-loading"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div
                  v-if="loading"
                  class="w-100 h-100 p-4"
                  :style="{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'black',
                    opacity: 0.3,
                  }"
                >
                  <Spinner size="md" />
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <p class="text-right m-0">
                  <span v-if="!searchMode">
                    Showing {{ perPage }} data out of {{ customers.length }}
                  </span>
                  <span v-else>
                    <!-- search results -->
                    {{ customers.length }} results
                  </span>
                </p>

                <div class="d-flex">
                  <label class="align-self-center m-0 mr-2"
                    >Page {{ page }}</label
                  >
                  <Button
                    v-if="!isFirstPage"
                    variant="primary"
                    size="sm"
                    id="btn-page-prev"
                    @click="prev()"
                    :disabled="loading"
                  >
                    <i class="fal fa-arrow-left"></i>
                  </Button>

                  <div class="m-2"></div>

                  <Button
                    v-if="!isLastPage || moreDataLeft"
                    variant="primary"
                    size="sm"
                    id="btn-page-next"
                    @click="next()"
                    :disabled="loading"
                  >
                    <i class="fal fa-arrow-right"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </section>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
// import { mapGetters } from "vuex";
import { i18n } from "@/i18n";
import Spinner from "@/components/core/Spinner";
import Button from "@/components/core/Button";

export default {
  name: "Customers-List",
  components: { Card, Spinner, Button },
  data() {
    return {
      breadcrumbLinks: [
        {
          title: i18n.t("dashboard.dashboard_label"),
          isActive: true,
          pathName: "Seller-Dashboard",
        },
        {
          title: "Customers",
          isActive: false,
          pathName: "Seller-Customers",
        },
      ],
      loading: false,
      customers: [],
      afterKey: null,
      page: 1,
      lastPage: 1,
      moreDataLeft: true,
      perPage: 100,
      searchMode: false,

      searchConfig: {
        column: "phoneNo",
        keyword: null,
      },
    };
  },

  created() {
    this.fetchCustomers();
  },

  methods: {
    search() {
      if (this.searchConfig && !this.searchConfig.keyword) {
        this.fetchCustomers();
        return;
      }

      this.loading = true;
      this.searchMode = true;
      this.$store.dispatch("customers/searchCustomers", {
        ...this.searchConfig,
        callback: (status, data) => {
          if (status) {
            this.customers = data.customers;
            this.moreDataLeft = false;
          }
          this.loading = false;
        },
      });
    },

    fetchCustomers(callback) {
      this.loading = true;
      this.searchMode = false;
      this.$store.dispatch("customers/fetchCustomers", {
        afterKey: this.afterKey,
        searchConfig: this.searchConfig,
        callback: (status, data) => {
          if (status) {
            this.customers = this.customers.concat(data.customers);
            if (data.afterKey) this.afterKey = data.afterKey;
            else this.moreDataLeft = false;
          }
          this.loading = false;
          if (callback) callback();
        },
      });
    },

    next() {
      if (this.isLastPage && !this.moreDataLeft) return;
      if (this.page == this.lastPage)
        this.fetchCustomers(() => {
          this.page++;
          this.lastPage = this.page;
        });
      else this.page++;
    },

    prev() {
      if (this.isFirstPage) return;
      this.page--;
    },
  },

  computed: {
    paginatedCustomers() {
      if (this.customers) {
        let start = (this.page - 1) * this.perPage;
        let end = start + this.perPage;
        return this.customers.slice(start, end);
      }
      return null;
    },

    isLastPage() {
      return this.page == this.lastPage;
    },

    isFirstPage() {
      return this.page == 1;
    },
  },
};
</script>

<style lang="scss">
</style>
