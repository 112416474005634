<template>
  <div style="width: 100%; max-width: 1200px; margin: 0 auto;">
    <div class="content-header">
      <div class="container">
        <div class="row mb-2">
          <div class="col-md-6 col-sm-12">
            <h4 class="font-weight-light">
              API
              <span class="font-weight-bold">documentation</span>
            </h4>
          </div>
          <div class="col-md-6 col-sm-12 d-flex justify-content-end">
            <router-link to="/">Back</router-link>
          </div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container">

        <div class="card">
          <div class="card-body">
            Base URL: https://api.proship.me
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="card">
              <div class="card-body">
                <div class="api-option"
                     v-for="apiOption in apiOptions"
                     :class="{ '--active': activeApi === apiOption }"
                     @click="activeApi = apiOption"
                     :key="apiOption">
                  {{ apiOption }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-8">
            <div class="card">
              <div class="card-body">

                <div v-if="activeApi === '/orders'">
                  <p class="lead mb-2">cURL Request</p>

                  <pre class="theme --light">
curl --location <span class="highlight --green" title="Base URL">'https://api.proship.me/orders/v1/orders'</span>  \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer <span class="highlight --orange">{{ authToken.substring(0, 20) + "..." }} </span>' \
--data '{
    "user": "TEST",
    "shippingMethod": "shopee",
    "weight": 1,
    "custRef": "test_xxxxx",
    "shippingCostCharged": 0,
    "discountAmount": 0,
    "remarks": null,
    "shopId": "shop-xxxxxxx",
    "customer": {
        "name": "TEST",
        "address": {
            "address": "test",
            "province": "ปทุมธานี",
            "district": "เมืองปทุมธานี",
            "subDistrict": "บางกะดี",
            "zipcode": 12000
        },
        "phoneNo": "0980000000",
        "salesChannel": "facebook"
    },
    "products": {},
    "box_quantity": 1,
    "express_insured_value": 3000,
    "parcel_item_name": "test"
}'</pre>
                  <p class="lead mb-2">Response</p>
                  <pre class="theme --dark">
{
    "message": "Success",
    "id": "order-xxxxxx|yyyyyy"
}</pre>
                </div>

                <div v-else-if="activeApi === '/print'">
                  <p class="lead mb-2">cURL Request</p>
                  <pre class="theme --light">
curl --location <span class="highlight --green" title="Base URL">'https://api.proship.me/print/v1/print-label'</span>  \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer <span class="highlight --orange">{{ authToken.substring(0, 20) + "..." }} </span>' \
--data '{
   "orders": [
       "<span class="highlight --purple">order_id</span>|<span class="highlight --purple">createdAt</span>"
   ],
   "size": "normal",
   "printer": "proship_v3p",
   "base64": true
}
'</pre>

                  <p class="lead mb-2">Response</p>
                  <pre class="theme --dark">
{
    "base64": "<span class="highlight --orange" title="base64 encoded string of labels">xxxxxxxxxxx</span>"
}</pre>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Button from "@/components/core/Button";
import CopyButton from "@/components/derived/CopyButton";
import Spinner from "@/components/core/Spinner";

export default {
  name: "ApiDocs",
  components: { Button, Spinner, CopyButton },
  data() {
    return {
      authToken: null,
      activeApi: "/orders",
      apiOptions: [
        "/orders",
        "/print"
      ]
    }
  },
  created() {
    this.authToken = JSON.parse(localStorage.getItem("_auth")).token;
  },

  methods: {

  }
};
</script>

<style lang="scss"
       scoped>
      .api-option {
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        transition: all 0.25s ease-in-out;
        color: #373737;

        &:hover {
          color: black;
        }

        &.--active {
          background-color: #f2f2f3;
        }
      }

      .highlight {
        cursor: pointer;
        font-weight: bold;

        &.--green {
          color: green;
        }

        &.--orange {
          color: orange;
        }

        &.--purple {
          color: purple;
        }
      }

      .theme {
        border-radius: 0.5rem;
        padding: 1rem;

        &.--dark {
          background-color: #373737;
          color: whitesmoke;
        }

        &.--light {
          background-color: whitesmoke;
          color: #373737;
        }
      }

    </style>