<template>
  <div class="test p-5">
    <DashboardDatePicker />
  </div>
</template>

<script>
import ReportsSearch from "@/components/derived/ReportsSearch";
import Button from "@/components/core/Button"
import DashboardDatePicker from "@/components/derived/DashboardDatePicker";


export default {
  name: "Test",
  components: {
    ReportsSearch,
    Button,
    DashboardDatePicker
  },
  data() {
    return {

    };
  },

  created() {
  },

  methods: {



    startTimer() {
      this.duplicateTimeLimit = 7;
      this.duplicateTimer = setInterval(() => {
        this.duplicateTimeLimit--;
        if (this.duplicateTimeLimit <= 0) clearInterval(this.duplicateTimer);
      }, 1000);
    },

    handleFormSubmit() {

    }
  },

  computed: {
    dupTimerPercentage() {
      let percentage = Math.floor(100 - (this.duplicateTimeLimit * 100) / 7);
      return { x: percentage, y: percentage - 45 }
    }
  }
};
</script>

<style lang="scss"></style>