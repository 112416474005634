<template>
  <div class="product_history">
    <div class="toolbar d-flex justify-content-between mb-2">
      <h5 class="text-color-4">
        {{ $t("products.show.history.sales") }}
      </h5>

      <div class="d-flex" style="width: 15rem">
        <DatePicker
          id="date-range"
          custom_class="align-self-center mr-2"
          custom_input_class="py-0 pl-2 text-xs"
          v-model="dateFrom"
          :placeholder="'เลือกวันที่เริ่ม'"
        />

        <DatePicker
          id="date-range"
          custom_class="align-self-center"
          custom_input_class="py-0 pl-2 text-xs"
          v-model="dateTo"
          :placeholder="'เลือกวันที่สิ้นสุด'"
        />

        <Button
          id="btn-clear-product-history-dates"
          custom_class="p-0"
          type="button"
          @click="clearFilter()"
          size="sm"
          v-if="dateFrom || dateTo"
        >
          <i class="fal fa-times-circle ml-2"></i>
        </Button>
      </div>
    </div>

    <table class="_datatable table --small-height">
      <thead>
        <th>
          {{ $t("shipment.view.contact_information.agent") }}
        </th>
        <th>จำนวน</th>
        <th>{{ $t("general.date") }}</th>
        <th>การจัดส่ง</th>
      </thead>
      <tbody>
        <tr v-for="(history, index) in filteredHistories" :key="index">
          <td class="--width-sm">{{ history.user }}</td>
          <td class="--checkbox">
            <span
              :class="{
                'text-success': history.mode == '+',
                'text-danger': history.mode == '-',
              }"
            >
              {{ history.mode }}
              {{ history.qty }}
            </span>
          </td>
          <td>
            <span class="--text-vsm">
              {{ history.timestamp | formatDateTime }}
            </span>
          </td>
          <td class="--width-md">
            <router-link
              :to="{
                name: 'Seller-Order-Detail',
                params: { id: history.orderId },
              }"
            >
              <i class="nav-icon fal fa-truck-loading"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import DatePicker from "@/components/core/DatePicker";
import Button from "@/components/core/Button";
import moment from "moment-timezone";

export default {
  name: "ProductHistory",
  components: {
    DatePicker,
    Button,
  },
  props: ["histories"],
  data() {
    return {
      dateFrom: null,
      dateTo: null,
    };
  },

  methods: {
    clearFilter() {
      this.dateFrom = null;
      this.dateTo = null;
    },
  },

  computed: {
    filteredHistories() {
      let result = this.histories;
      if (this.dateFrom) {
        let from = moment
          .tz(this.dateFrom, "Asia/Bangkok")
          .startOf("day")
          .valueOf();
        result = result.filter((h) => h.timestamp >= from);
      }

      if (this.dateTo) {
        let to = moment.tz(this.dateTo, "Asia/Bangkok").endOf("day").valueOf();
        result = result.filter((h) => h.timestamp <= to);
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
.product_history .vdp-datepicker__calendar {
  right: 0;
}
</style>