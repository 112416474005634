<template>
  <div class="product">
    <OnboardHeader :title="$t('onboard.product.title')" :subtitle="$t('onboard.product.subtitle')">
      <img src="@/assets/images/onboard/box.svg" class="onboard-icon" alt="Products" slot="img" />
    </OnboardHeader>

    <ValidationObserver v-slot="{ invalid }">

      <div v-if="formLoading">
        <Spinner size="md" />
      </div>

      <form @submit.prevent="handleFormSubmit" v-else>
        <div class="onboard-form-wrapper">
          <div class="onboard-form">
            <div class="input-row">
              <TextBox
                type="text"
                id="selling-item"
                rules="required"
                v-model="productForm.name"
                :label="$t('onboard.product.what_sell_field.label')"
              />
            </div>
            <div class="input-row">
              <NumberField
                id="stock-qty"
                rules="required"
                v-model.number="productForm.cost"
                :label="$t('onboard.product.how_much_field.label')"
              />
            </div>

            <div class="input-row" v-if="generalSettings.inventoryMgt">
              <NumberField
                id="stock-qty"
                rules="required"
                v-model.number="productForm.quantity"
                :label="$t('onboard.product.how_much_stock_field.label')"
              />
            </div>
          </div>
        </div>

        <OnboardButtons 
          :on_next="handleFormSubmit" 
          :active="invalid" 
          :on_secondary_button="back" 
          :loading="formLoading || submitLoading" />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import NumberField from "@/components/core/NumberField";
import Card from "@/components/core/Card";
import OnboardButtons from "@/components/derived/onboard/OnboardButtons";
import OnboardHeader from "@/components/derived/onboard/OnboardHeader";
import { mapGetters } from "vuex";
import Spinner from "@/components/core/Spinner";
import {i18n} from "@/i18n"

export default {
  name: "Onboard-Product",
  components: {
    TextBox,
    NumberField,
    Card,
    OnboardButtons,
    OnboardHeader,
    Spinner
  },
  data() {
    return {
      formLoading: false,
      submitLoading: false,
      productForm: {
        name: i18n.t('onboard.product.what_sell_field.initial'),
        cost: 25,
        quantity: 22,
        available: true,
        salePrice: 25, 
        weightApprox: 100
      }
    };
  },

  created(){
    this.fetchRequiredValues();
  },

  methods: {
    fetchRequiredValues() {
      this.formLoading = true;
      this.$store.dispatch("products/fetchProshipCode", {
        callback: (status, data) => {
          if (status) this.productForm.proshipCode = data;
          this.$store.dispatch("products/fetchSku", {
            callback: (status, data) => {
              if (status) this.productForm.itemCode = data;
              this.formLoading = false;
            }
          });
        }
      });
    },

    handleFormSubmit() {
      this.submitLoading = true;
      this.$store.dispatch("products/createProduct", {
        product: this.productForm,
        callback: (status, data) => {
          this.submitLoading = false;
          if (status) this.$router.push({ name: "Seller-Order-List" });
        }
      });
    },

    back() {
      this.$router.go(-1);
    }
  },

  computed: {
    ...mapGetters({ generalSettings: "settings/generalSettings" })
  },
};
</script>

<style lang="scss" scoped>
.go-back-button {
  margin-right: 24px;
}

.submit-next-buttons {
  @include for-phone-only {
    display: flex;
    justify-content: center;
  }
}
</style>