<template>
  <div class="reports-page">
    <div class="content-header">
      <h4 class="text-color-2">
        ค้นหา
      </h4>
    </div>

    <div class="row">
      <div class="col">
        <ReportsSearch @input="handleSearchParamsChange" />

        <div class="card">
          <div class="card-body">
            <OrdersTableReports
              detail_path_name="Seller-Order-Detail"
              :_searchParams="searchParams"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportsSearch from "@/components/derived/ReportsSearch";
import OrdersTableReports from "@/components/derived/OrdersTableReports";

export default {
  name: "ShipmentsReportV2",
  components: { ReportsSearch, OrdersTableReports },
  created() {},
  data() {
    return {
      loading: true,
      searchParams: null,
      orders: null,
    };
  },

  methods: {
    handleSearchParamsChange(params) {
      this.searchParams = params;
    },
  },
};
</script>

<style lang="scss" scoped>
.reports-page {
  margin: 35px;
}
</style>