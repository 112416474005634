
import { http } from "@/helpers/http";
import Vue from 'vue'

const baseURL = 'https://a3jczw1yof.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
    namespaced: true,
    state: {},

    actions: {
        async orderReturnPkgHistory({ state, commit, rootState }, payload) {
            try {
                let resp = await http.get(`${baseURL}/order-return-pkg-history?phoneNo=${payload.phoneNo}`);
                return resp.data;
            } catch (e) {
                console.log(e);
                return null;
            }
        },

        async orderHistoryWithShop({ state, commit, rootState }, payload) {
            try {
                let resp = await http.get(`${baseURL}/order-history-with-shop?phoneNo=${payload.phoneNo}&shopId=${payload.shopId}`);
                return resp.data;
            } catch (e) {
                console.log(e);
                return null;
            }
        }
    }
}