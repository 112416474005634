<template>
  <div>
    <div class="dashboard">
      <div class="content-header">
        <div class="container-fluid">
          <div class="d-flex justify-content-between">
            <h5 class="align-self-center">
              <span class="font-weight-light">{{ $t("dashboard.hi") }}</span>
              {{ $t("dashboard.seller") }}
            </h5>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">

          <!-- toolbar -->
          <div class="d-flex justify-content-end mb-4">
            <DashboardDatePicker @change="(range) => getDashboardData(range)" />
          </div>

          <!-- contents -->
          <div v-if="dashboardData">
            <!-- total sales daily -->
            <div class="row">
              <div class="col-6">
                <Card>
                  <div slot="body">
                    <p class="lead">
                      Total sales daily
                    </p>
                    <OrdersLineChart :id="'daily'" :data="Object.values(dashboardData.orderCountDaily)"
                      :labels="Object.keys(dashboardData.orderCountDaily)" />
                    <p></p>
                  </div>
                </Card>
              </div>

              <div class="col-6">
                <Card>
                  <div slot="body">
                    <p class="lead">
                      Orders monthly
                    </p>
                    <OrdersLineChart :id="'monthly'" :data="Object.values(dashboardData.orderCountMonthly)"
                      :labels="Object.keys(dashboardData.orderCountMonthly)" />
                    <p></p>
                  </div>
                </Card>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <Card>
                  <div slot="body">
                    <p class="lead mb-4">
                      Top repeat customers
                    </p>

                    <table class="table --text-sm">
                      <thead>
                        <tr>
                          <th>Phone</th>
                          <th>Repeat rate</th>
                          <th>Total orders</th>
                        </tr>
                      </thead>
                      <tr v-for="(value, key) in dashboardData.topRepeatCustomers" :key="key">
                        <td>{{ key }}</td>
                        <td>{{ value[1] }} </td>
                        <td><span class="badge badge-primary">{{ value[0] }}</span></td>
                      </tr>
                    </table>
                  </div>
                </Card>

                <Card>
                  <div slot="body">
                    <p class="lead mb-4">
                      Shipper leaderboard
                    </p>

                    <table class="table --text-sm">
                      <thead>
                        <tr>
                          <th>Shipper</th>
                          <th># orders</th>
                        </tr>
                      </thead>
                      <tr v-for="(value, key) in dashboardData.topShippers" :key="key">
                        <td>
                          <img :src="shippingInfo(key).src" style="width: auto; height: 2rem;" />
                        </td>
                        <td><span class="badge badge-primary">{{ value }}</span></td>
                      </tr>
                    </table>
                  </div>
                </Card>
              </div>

              <div class="col-6">
                <Card>
                  <div slot="body">
                    <p class="lead">
                      Orders per agents(Top 10)
                    </p>

                    <table class="table --text-sm">
                      <thead>
                        <tr>
                          <th>Agent</th>
                          <th>Orders rate</th>
                          <th>Total orders</th>
                        </tr>
                      </thead>
                      <tr v-for="(value, key) in dashboardData.ordersPerAgents" :key="key">
                        <td>{{ key }}</td>
                        <td>{{ value[1] }}</td>
                        <td><span class="badge badge-primary">{{ value[0] }}</span></td>
                      </tr>
                    </table>
                  </div>
                </Card>

                <Card>
                  <div slot="body">
                    <p class="lead">
                      <span style="color: #2E7D32">COD</span>/ <span style="color: #757575">Non-COD</span>
                    </p>

                    <PieChart :id="'cod-pie-chart'" :chartdata="codPieChartData" />
                  </div>
                </Card>

                <Card>
                  <div slot="body">
                    <p class="lead mb-4">
                      Order statistics
                    </p>

                    <table class="table --text-sm">
                      <tr>
                        <td> Average time between <br> Order creation and print </td>
                        <td>
                          {{ dashboardData.avgTimeCreatePrint }}
                        </td>
                      </tr>

                      <tr>
                        <td>Return to sender rate</td>
                        <td>{{ dashboardData.returnToSenderRate.toFixed(2) }} %</td>
                      </tr>

                      <tr>
                        <td>Orders per hour</td>
                        <td>{{ dashboardData.ordersPerHour.toFixed(2) }}</td>
                      </tr>

                      <tr v-if="dashboardData.ordersPerHour > 60">
                        <td>Orders per minute</td>
                        <td>{{ dashboardData.ordersPerMinute }}</td>
                      </tr>
                    </table>

                    <h3></h3>
                  </div>
                </Card>


                <Card>
                  <div slot="body">
                    <p class="lead mb-4">
                      Top provinces
                    </p>

                    <table class="table --text-sm">
                      <thead>
                        <tr>
                          <th>Province</th>
                          <th>Total orders</th>
                        </tr>
                      </thead>
                      <tr v-for="(value, key) in dashboardData.topProvinces" :key="key">
                        <td>{{ key }}</td>
                        <td><span class="badge badge-primary">{{ value }}</span></td>
                      </tr>
                    </table>


                    <!-- <TopProvincesMapChart /> -->
                  </div>
                </Card>



              </div>
            </div>
          </div>

          <div class="spinner" v-else>
            <Spinner size="lg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import SmallBox from "@/components/core/SmallBox";
import Spinner from "@/components/core/Spinner";
import OrdersLineChart from "@/components/derived/charts/OrdersLineChart";
import PieChart from "@/components/derived/charts/PieChart";
import ShippingDoughnutChart from "@/components/derived/charts/ShippingDoughnutChart";
import CodDoughnutChart from "@/components/derived/charts/CodDoughnutChart";
import SalesChannelChart from "@/components/derived/charts/SalesChannelChart";
import TopProvincesChart from "@/components/derived/charts/TopProvincesChart";
import TopProductsChart from "@/components/derived/charts/TopProductsChart";
import TopProvincesMapChart from "@/components/derived/charts/TopProvincesMapChart";
import PhoneNumber from "@/components/derived/PhoneNumber";
import OTPField from "@/components/derived/OTPField";
import Toggle from "@/components/core/Toggle";
import DashboardDatePicker from "@/components/derived/DashboardDatePicker";
import { shippingInfo } from "@/helpers/core";

export default {
  name: "DashboardV2",
  components: {
    Card,
    OrdersLineChart,
    ShippingDoughnutChart,
    CodDoughnutChart,
    SalesChannelChart,
    TopProvincesChart,
    SmallBox,
    PhoneNumber,
    OTPField,
    Spinner,
    TopProductsChart,
    Toggle,
    DashboardDatePicker,
    PieChart,
    TopProvincesMapChart
  },
  data() {
    return {
      dashboardData: null,
    };
  },

  created() {
    // this.getDashboardData();
  },

  mounted() { },

  methods: {
    getDashboardData(dateRange) {
      this.dashboardData = null;
      this.$store.dispatch("dashboard/getDashboardDataV2", {
        dateRange,
        callback: (status, data) => {
          // console.log(data);
          if (status) {
            this.dashboardData = data;
          }
        },
      });
      // this.dashboardData = null;
      // this.dashboardData = { "topRepeatCustomers": { "0815591110": 1904, "0895555555": 184, "0815596117": 25, "0634961593": 11, "0888888888": 7, "0968017066": 7, "0813762899": 6, "0653270669": 5, "0283139556": 4, "null": 4 }, "ordersPerAgents": {}, "codPieChart": { "cod": 7162, "nonCod": 1280 }, "orderCountMonthly": { "2022-03": 3, "2022-04": 6, "2022-05": 4, "2022-06": 9, "2022-07": 8, "2022-08": 2, "2022-09": 18, "2022-10": 5, "2022-11": 0, "2022-12": 7, "2023-01": 1112, "2023-02": 57, "2023-03": 8 }, "orderCountDaily": { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0, "6": 0, "7": 0, "8": 0, "9": 0, "10": 0, "11": 0, "12": 0, "13": 0, "14": 0, "15": 0, "16": 0, "17": 0, "18": 0, "19": 0, "20": 0, "21": 0, "22": 0, "23": 0, "24": 0, "25": 0, "26": 0, "27": 0, "28": 0, "29": 0, "30": 0, "31": 0 }, "topShippers": { "thaipost": 8427, "flash": 98, "shopee": 85, "jt": 80, "ems": 29, "kerry": 21, "thaipost0": 8, "ems-cod": 5, "EMS": 2, "scg": 2 }, "avgTimeCreatePrint": "40 hours", "returnToSenderRate": 0 };
    },

    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },

    shippingInfo(sm) {
      return shippingInfo(sm);
    }
  },

  computed: {
    codPieChartData() {
      if (!this.dashboardData.codPieChart) return false;
      return {
        data: {
          labels: ["COD", "Non-COD"],
          datasets: [
            {
              data: Object.values(this.dashboardData.codPieChart),
              backgroundColor: [
                "#2E7D32",
                "#757575"
              ],
            },
          ],
        },
      };
    },

    salesChannelChartData() {
      let sortedData = [
        this.dashboardData.salesChannelCount.facebook,
        this.dashboardData.salesChannelCount.instagram,
        this.dashboardData.salesChannelCount.line,
        this.dashboardData.salesChannelCount.web,
        this.dashboardData.salesChannelCount.other,
      ];

      return {
        data: {
          labels: ["Facebook", "Instagram", "Line", "Web", "Other"],
          datasets: [
            {
              data: sortedData,
              backgroundColor: [
                "#2196F3",
                "#37474F",
                "#43A047",
                "#FB8C00",
                "#C0CA33",
              ],
            },
          ],
        },
      };
    },

    topProductsChartData() {
      if (!this.dashboardData.topProducts) return false;

      let topProducts;
      if (this.topProductsMode == "all-time")
        topProducts = this.dashboardData.topProducts;
      else if (this.topProductsMode == "daily")
        topProducts = this.dashboardData.topProductsDaily;

      return {
        data: {
          labels: Object.keys(topProducts),
          datasets: [
            {
              data: Object.values(topProducts),
              backgroundColor: [
                "#2196F3",
                "#37474F",
                "#43A047",
                "#FB8C00",
                "#C0CA33",
                "#8D6E63",
                "#673AB7",
                "#DD2C00",
                "#D500F9",
                "#004D40",
              ],
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  @include for-larger-than-phone {
    margin: toRem(40px);
  }
}

.spinner {
  margin-top: 100px;
}

.product-items {
  // max-width: toRem(680px);
  margin: 0 auto;

  img {
    height: toRem(79px);
    width: auto;
  }
}
</style>