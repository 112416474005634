<template>
  <div class="tabs-container d-flex flex-nowrap" :id="id" :class="custom_class">
    <div
      class="tab-list-item d-flex"
      :class="tabClass(tab, index)"
      v-for="(tab, index) in tabs"
      :key="index"
    >
      <div @click="setActiveTab(index)">
        {{ tab.label }}
        <span v-if="tab.number != null" class="--text-sm">{{
          tab.number
        }}</span>
      </div>

      <!-- <i
        class="fal fa-sync ml-1 align-self-center"
        :class="{ 'fa-spin': manualCronLoading && manualCronLoadingStatus == tab.value }"
        v-if="manualCron && [1, 2, 3, 6].includes(tab.value)"
        @click="$emit('runCron', tab.value)"
      ></i> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    active_tab_index: Number,
    custom_class: String,
    id: String,
    tabs: Array,
    show_quantity: true,
    tabClasses: null,
    manualCron: false,
    manualCronLoading: false,
    manualCronLoadingStatus: null
  },

  data() {
    return {
      activeTab: false,
      activeTabIndex: null,
    };
  },

  created() {
    this.activeTabIndex = this.tabs.findIndex(
      (t) => t.value == this.active_tab_index
    );
    this.setActiveTab(this.activeTabIndex || 0);
  },

  methods: {
    setActiveTab(index) {
      this.activeTab = this.tabs[index];
      if (this.activeTab)
        this.$emit("tabSelected", this.activeTab.value || index);
    },

    tabClass(tab, index) {
      let classObj = { "--active": index === this.activeTabIndex };
      if (this.tabClasses && this.tabClasses.length > 0) {
        classObj["text-" + this.tabClasses[index]] = true;
      }
      return classObj;
    },
  },

  watch: {
    active_tab_index: function (newVal, oldVal) {
      // newVal is the new selected status
      if (this.tabs[0].hasOwnProperty("value"))
        this.activeTabIndex = this.tabs.findIndex((t) => t.value == newVal);
      else this.activeTabIndex = newVal;
    },
  },
};
</script>

<style lang="scss">
.tabs-container {
  overflow-x: auto;

  .tab-list-item {
    display: inline-block;
    padding: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: 0.5s all;
    color: map-get($text-colors, 5);
    border-bottom: 2px solid white;
    width: auto;
    white-space: nowrap;
    @include for-larger-than-phone {
      border-bottom-width: 4px;
      margin-right: 1rem;
    }

    span {
      margin-left: 0.25rem;
      padding: 0.25rem 0.5rem;
      border-radius: 5px;
      font-weight: $font-weight-medium;
    }
  }

  @each $variant, $color in $variants {
    &.is-#{$variant} {
      .tab-list-item {
        &.--active {
          border-bottom-color: $color;
          color: $color;
          span {
            background-color: rgba($color, 0.1);
          }
        }
      }
    }
  }
}
</style>