import {i18n} from "@/i18n";

export const getOrderStatus = (statusCode) => {
  if (statusCode == 0) statusCode = 6;
  const STATUS = {
    "-1": {
      title: "Draft",
      variation: "default",
    },
    1: {
      title: i18n.t("status.pending"),
      variation: "warning",
    },
    2: {
      title: i18n.t("status.to_ship"),
      variation: "primary",
    },
    3: {
      title: i18n.t("status.shipped"),
      variation: "info",
    },
    4: {
      title: i18n.t("status.delivered"),
      variation: "success",
    },
    5: {
      title: i18n.t("status.cancelled"),
      variation: "danger",
    },
    6: {
      title: i18n.t("status.error"),
      variation: "danger",
    },
    8: {
      title: i18n.t("status.return_to_sender"),
      variation: "info",
    },

    100: {
      title: i18n.t("status.pending"),
      variation: "default",
    },

    101: {
      title: "Unverified",
      variation: "warning",
    },

    102: {
      title: "Paid",
      variation: "primary",
    }
  };
  if(!statusCode) return STATUS;
  return STATUS[statusCode];
};

export const getReportStatus = (statusCode) => {
  const STATUS = {
    1: {
      title: i18n.t("status.pending"),
      variation: "warning"
    },
    2: {
      title: i18n.t("status.active"),
      variation: "active"
    },
    3: {
      title: i18n.t("status.denied"),
      variation: "danger"
    },
  };
  return STATUS[statusCode];
};

export const generateOrderCode = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const navigateObj = (obj, prop) => {
  // debugger;
  if (prop.includes(".")) {
    let keys = prop.split(".");
    let val = obj[keys[0]];
    for (let i = 1; i < keys.length; i++) {
      val = val[keys[i]];
    }
    return val;
  } else return obj[prop];
};


export const shippingInfo = (shippingMethodName) => {
  var methods = {};
  var shippingMethods = [
    {
      name: "kerry",
      available: true,
      src: require("@/assets/kerry_big.jpg"),
    },
    {
      name: "self-ship",
      available: true,
      src: require("@/assets/self-ship.png")
    },
    {
      name: "thaipost",
      available: true,
      src: require("@/assets/thaipost.png")
    },
    {
      name: "thaipost0",
      available: true,
      src: require("@/assets/thaipost0.png")
    },
    {
      name: "jt",
      available: true,
      src: require("@/assets/jt.png")
    },

    // {
    //   name: "scg",
    //   available: true,
    //   src: require("@/assets/scg.png")
    // },

    {
      name: "flash",
      available: true,
      src: require("@/assets/flash.png")
    },

    {
      name: "shopee",
      available: true,
      src: require("@/assets/shopee.png")
    }
  ];

  if(shippingMethodName === "all") return shippingMethods;

  shippingMethods.forEach((method) => {
    if (method.name.toLowerCase() == shippingMethodName.toLowerCase()) {
      methods = method;
    }
  });
  return methods;
};

export const bankInfo = (bankName) => {
  const banks = {
    bbl: { code: "002", color: "#1e4598", nice_name: "Bangkok Bank" },
    bay: {
      code: "025",
      color: "#fec43b",
      nice_name: "Bank of Ayudhya (Krungsri)",
    },
    gsb: {
      code: "030",
      color: "#eb198d",
      nice_name: "Government Savings Bank",
    },
    ghb: {
      code: "033",
      color: "#f57d23",
      nice_name: "Government Housing Bank",
    },
    ibank: {
      code: "066",
      color: "#184615",
      nice_name: "Islamic Bank of Thailand",
    },
    kbank: { code: "004", color: "#138f2d", nice_name: "Kasikornbank" },
    ktb: { code: "006", color: "#1ba5e1", nice_name: "Krungthai Bank" },
    kk: { code: "069", color: "#199cc5", nice_name: "Kiatnakin Bank" },
    lhb: {
      code: "073",
      color: "#6d6e71",
      nice_name: "Land and Houses Bank",
    },
    scb: {
      code: "014",
      color: "#4e2e7f",
      nice_name: "Siam Commercial Bank",
    },
    tbank: {
      code: "065",
      color: "#fc4f1f",
      nice_name: "Thanachart Bank",
    },
    tmb: { code: "011", color: "#1279be", nice_name: "TMB Bank" },
    uob: {
      code: "024",
      color: "#0b3979",
      nice_name: "United Overseas Bank (Thai)",
    },
    cod: {
      code: "000",
      color: "#ffffff",
      nice_name: "Cash On Delivery",
    },
  };
  return banks[bankName];
};

export const isMobile = () => {
  let condition_1 = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  let condition_2 = window.innerWidth < 768;
  return condition_1 || condition_2;
};
