<template>
  <div>
    <div
      class="
        toolbar
        d-flex
        flex-wrap
        justify-content-center justify-content-md-between
        mb-2
      "
    >
      <div class="d-flex">
        <button
          v-if="showSelectAll"
          class="btn btn-outline-primary btn-sm mr-2"
          @click="selectAll()"
        >
          <i class="fal fa-ballot-check mr-2"></i>
          {{ $t("shipment.buttons.select_all") }}
        </button>

        <button
          v-if="showSelectAll"
          class="btn btn-outline-primary btn-sm mr-2"
          @click="selectAll(true)"
        >
          <i class="fal fa-ballot-check mr-2"></i>
          {{ $t("shipment.buttons.select_all_not_printed") }}
        </button>

        <button
          v-if="currentTabStatus == 101 && selectedOrders.length > 0"
          class="btn btn-outline-success btn-sm mr-2"
          @click="massAction.name = 'mark-paid'"
          data-toggle="modal"
          data-target="#mass-action-modal"
        >
          <i class="fal fa-check-circle mr-2"></i>
          กดรับชำระเงิน
        </button>

        <button
          v-if="currentTabStatus == 102 && selectedOrders.length > 0"
          class="btn btn-outline-danger btn-sm mr-2"
          @click="massAction.name = 'mark-unpaid'"
          data-toggle="modal"
          data-target="#mass-action-modal"
        >
          <i class="fal fa-times-circle mr-2"></i>
          ไมได้รับเงิน
        </button>

        <button
          v-if="currentTabStatus == 101 && selectedOrders.length > 0"
          class="btn btn-outline-danger btn-sm mr-2"
          @click="massAction.name = 'mark-pending'"
          data-toggle="modal"
          data-target="#mass-action-modal"
        >
          <i class="fal fa-times-circle mr-2"></i>
          กดรอตรวจสอบ
        </button>

        <button
          v-if="selectedOrders.length > 0"
          class="btn btn-outline-danger btn-sm mr-2"
          @click="massAction.name = 'cancel'"
          data-toggle="modal"
          data-target="#mass-action-modal"
        >
          <i class="fal fa-times-circle mr-2"></i>
          ยกเลิก
        </button>

        <button
          v-if="currentTabStatus == 102 && selectedOrders.length > 0"
          class="btn btn-outline-success btn-sm mr-2"
          @click="massAction.name = 'ship'"
          data-toggle="modal"
          data-target="#mass-action-modal"
        >
          <i class="fal fa-truck-loading mr-2"></i>
          จัดส่ง
        </button>
      </div>

      <div
        class="d-flex flex-wrap justify-content-center justify-content-md-start"
      >
        <router-link
          v-if="!sales_agent"
          :to="{ name: 'Seller-PreShipment-Create' }"
          class="btn btn-success btn-sm"
          :class="{
            'mt-2 mt-md-0': selectedOrders && selectedOrders.length > 0,
          }"
          style="width: 11rem"
        >
          <i class="fal fa-plus-circle mr-2"></i>
          เปิดบิลขาย
        </router-link>
      </div>
    </div>

    <div class="order-status-list">
      <Tabs
        v-if="orderCounts"
        id="order-list-tabs"
        class="is-primary"
        :tabs="orderCounts"
        :tabClasses="['default', 'warning', 'primary', 'success']"
        @tabSelected="handleTabChange"
        :active_tab_index="currentTabStatus"
      />
    </div>

    <slot name="top"></slot>

    <div v-if="paginatedOrders && !loading">
      <div v-if="paginatedOrders.length" class="table-responsive">
        <table class="table _datatable orders-table --very-small-height">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t("general.created_at") }}</th>
              <th>{{ $t("shipment.table_labels.order_code") }}</th>
              <th>{{ $t("shipment.table_labels.customer") }}</th>
              <th>{{ $t("general.phone") }}</th>
              <th>{{ $t("shipment.table_labels.status") }}</th>
              <th>{{ $t("shipment.table_labels.shipping") }}</th>
              <th v-if="selectedStatus == 1">
                {{ $t("shipment.table_labels.tracking_no") }}
              </th>
              <th>{{ $t("general.actions") }}</th>
            </tr>
          </thead>
          <tbody :class="{ '--loading': miniLoading }">
            <tr v-for="(row, index) in paginatedOrders" :key="index">
              <td class="--checkbox">
                <input
                  v-if="showSelectAll"
                  type="checkbox"
                  :id="'order-checkbox-' + row.id"
                  :ref="'order-checkbox-' + row.id"
                  class="table-checkbox"
                  @change="() => toggleSelect(row, true)"
                />

                <i
                  v-if="row.printed"
                  title="Printed"
                  class="fal fa-print text-success mr-2"
                ></i>
              </td>
              <td v-html="tdHtml('createdAt', row.createdAt)"></td>

              <td
                v-html="tdHtml('ellipsis', row.orderCode)"
                class="text-nowrap"
              ></td>

              <td
                v-html="tdHtml('ellipsis', row.custName)"
                class="text-nowrap"
              ></td>

              <td>{{ row.custPhone }}</td>

              <td v-html="tdHtml('status', row.status)" class="--width-md"></td>

              <td class="--width-md">
                {{ row.shippingMethod }}
              </td>

              <td v-if="selectedStatus == 1">
                <a
                  :href="
                    'https://track.proship.co.th/#/?barcode=' + row.trackingNo
                  "
                  class="mr-1"
                  target="_blank"
                  >{{ row.trackingNo }}</a
                >
              </td>

              <td>
                <Button
                  variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-show"
                  @click="goto('Edit', row.id)"
                  v-if="
                    row.status === 5 ||
                    (row.hasError && row.shippingMethod == 'jt')
                  "
                >
                  <i class="fal fa-redo"></i>
                </Button>

                <Button
                  variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-show"
                  @click="goto('Show', row.id)"
                >
                  <i class="fal fa-eye"></i>
                </Button>

                <Button
                  v-if="allowEditStatus.includes(row.status)"
                  variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-edit"
                  @click="goto('Edit', row.id)"
                >
                  <i class="fal fa-edit"></i>
                </Button>

                <Button
                  variant="link"
                  size="sm"
                  custom_class="p-0 text-color-5"
                  id="btn-action-public-link"
                  @click="gotoPublicLink(row.id)"
                >
                  <i class="fal fa-users-class"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between">
          <div>
            <select
              class="form-control"
              v-model="perPage"
              @change="handlePerPageChange()"
            >
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="500">500</option>
            </select>
          </div>

          <div class="d-flex">
            <p class="align-self-center mr-2 text-color-4">
              Loaded {{ orderRows.length }} | Page {{ page }}
            </p>

            <div class="btn-group" role="group">
              <Button
                type="button"
                variant="outline-primary"
                size="lg"
                id="btn-paginate-prev"
                @click="paginate(false)"
                :disabled="page === 1"
              >
                <i class="fal fa-long-arrow-left"></i>
              </Button>

              <Button
                type="button"
                variant="outline-primary"
                size="lg"
                id="btn-paginate-prev"
                @click="paginate(true)"
                :disabled="page === lastPage"
                :loading="miniLoading"
              >
                <i class="fal fa-long-arrow-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="!paginatedOrders.length && search.keyword">
        <div class="alert alert-warning">
          ระบบค้นหาไม่พบข้อมูลที่คุณค้นหา ข้อมูลของคุณอาจผิดพลาด
          หรือระบบค้นหาอาจทำงานผิดพลาด
        </div>
      </div>
    </div>

    <div v-else>
      <Spinner :variant="color_scheme" size="md" />
    </div>

    <!-- Mass Action Modal -->
    <Modal id="mass-action-modal" custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div>
          <p class="lead text-center mb-3">
            ทำหลายรายการ: {{ massAction.name }}
          </p>

          <p class="text-color-4 text-center" v-if="massAction.loading">
            รายการ
            {{ massAction.total - massAction.remaining + 1 }} /
            {{ massAction.total }}
          </p>
          <p class="text-color-4 text-center" v-else>
            ยืนยันการทำรายการ {{ selectedOrders.length }} Orders ?
          </p>

          <div class="d-flex justify-content-center mt-4">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              data-dismiss="modal"
            >
              {{ $t("general.no") }}
            </button>

            <Button
              id="btn-mass-cancel"
              variant="primary"
              size="sm"
              @click="handleMassAction()"
              :loading="massAction.loading"
              :disabled="massAction.loading"
            >
              {{ $t("general.yes") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Alert } from "@/helpers/alert";
import Spinner from "@/components/core/Spinner";
import Tabs from "@/components/core/Tabs";
import DropZone from "@/components/derived/DropZone";
import CopyButton from "@/components/derived/CopyButton";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getOrderStatus } from "@/helpers/core";
import { i18n } from "@/i18n";
import { printLabels } from "@/helpers/print";
import Select from "@/components/core/Select";
import { FILTERS } from "@/helpers/filters";
import { Toast, ToastFix } from "@/helpers/toastr";
import { http } from "@/helpers/http";

export default {
  name: "PreShipmentsTable",
  components: {
    TextBox,
    DataTable,
    Modal,
    vueDropzone: vue2Dropzone,
    Button,
    Spinner,
    Tabs,
    DropZone,
    Select,
    CopyButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    create_path_name: {
      type: String,
    },
    detail_path_name: {
      type: String,
    },
    edit_path_name: {
      type: String,
    },
    color_scheme: {
      type: String,
    },
    sample_file_link: {
      type: String,
    },
    sales_agent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderRows: null,
      orderCounts: null,
      LastEvaluatedKey: null,
      paginatedOrders: null,

      loading: false,
      miniLoading: false,
      modalLoading: false,
      statusUpdateLoading: false,
      importLoading: false,

      massAction: {
        name: null,
        loading: false,
        total: 0,
        remaining: 0,
      },

      allowEditStatus: [100, 101, 102],
      selectedOrders: [],
      selectedStatus: null,

      page: 1,
      perPage: 100,
      lastPage: null,
      selectAllStatus: false,
      currentTabStatus: 0,

      printLabelLoading: false,
      printLabels: null,
      selectedPaperSize: localStorage.getItem("paperSize"),
      printUrl: null,

      showOnlyErrorOrders: false,

      shops: null,
      shopsApiData: null,
      selectedShop: null,
      sampleFileLink: null,
      shippingMethods: null,
      selectedShippingMethod: null,
      hasBoxSizes: false,

      search: {
        column: "trackingNo",
        Keyword: null,
      },

      returnProcessingForm: {
        trackingNo: null,
        reason: null,
      },
    };
  },

  created() {
    this.orderCounts = [
      { label: "รายการใหม่", value: 100 },
      { label: "รอยืนยัน", value: 101 },
      { label: "ชำระแล้ว", value: 102 },
      { label: "จัดส่ง", value: 1 },
    ];

    // retrieve selected tab from storage
    this.currentTabStatus =
      parseInt(localStorage.getItem("currentPreShipmentTabStatus")) || 100; //default to Pending
    this.handleTabChange();
  },

  methods: {
    gotoPublicLink(id) {
      let publicLink =
        "https://public-order.proship.co.th/?res=" +
        id.replace("order-", "");
      window.open(publicLink, "_blank");
    },

    markAsShipped(preShipmentId) {
      this.$store.dispatch("preShipments/createOrder", {
        id: preShipmentId,
        payload: (status) => {
          if (status) {
            this.$router.push({
              name: "Seller-Order-Detail",
              params: { id: preShipmentId },
            });
          }
        },
      });
    },

    setPaperSize() {
      localStorage.setItem("paperSize", this.selectedPaperSize);
      this.printUrl = null;
    },

    tdHtml(type, value) {
      const types = {
        status: (value) => {
          let statusData = getOrderStatus(value);
          if (!statusData) return value;
          return `<span class="badge badge-pill text-uppercase status-badge --${statusData.variation}">${statusData.title}</span>`;
        },

        createdAt: (value) => {
          let dateTime = FILTERS.formatDateTime(value).split(",");
          return `${dateTime[0]}<br><span class="--text-vsm text-color-4 mt-n5">${dateTime[1]}</span>`;
        },

        ellipsis: (value) => {
          return FILTERS.ellipsis(value, 15);
        },
      };
      return types[type] ? types[type](value) : value;
    },

    paginate(forward = true) {
      if (forward) {
        if (!(this.lastPage && this.page === this.lastPage))
          this.page = this.page + 1;
      } else {
        this.page = Math.max(--this.page, 1);
      }
      this.handlePageChange();
    },

    fetchOrders(value, firstPage = true, _callback) {
      if (firstPage) this.loading = true;
      else this.miniLoading = true;

      // let ACTION = this.sales_agent ? "fetchSalesAgentOrders" : "fetchOrders";
      let ACTION = "fetchOrders";
      this.$store.dispatch("orders/" + ACTION, {
        statusCode: value > 0 ? value : null,
        LastEvaluatedKey: !firstPage
          ? JSON.stringify(this.LastEvaluatedKey)
          : null,
        perPage: this.perPage,
        onlyError: this.showOnlyErrorOrders,
        callback: (status, data) => {
          if (status) {
            this.LastEvaluatedKey = data.LastEvaluatedKey;

            if (firstPage) {
              this.orderRows = data.orders;
              this.page = 1;
              this.lastPage = null;
            } else {
              this.orderRows = this.orderRows.concat(data.orders);
            }

            this.orderCounts = [
              // {
              //   label: "All",
              //   number: data.orderCounts[99] || 0,
              //   value: 99,
              // },
              {
                label: "รายการใหม่",
                number: data.orderCounts[100] || 0,
                value: 100,
              },
              {
                label: "รอยืนยัน",
                number: data.orderCounts[101] || 0,
                value: 101,
              },
              {
                label: "ชำระแล้ว",
                number: data.orderCounts[102] || 0,
                value: 102,
              },
              {
                label: "จัดส่ง",
                number: data.orderCounts[1] || 0,
                value: 1,
              },
            ];

            // LOGIC: check from counter
            this.lastPage =
              data.orders.length < data.orderCounts[this.currentTabStatus]
                ? null
                : Math.ceil(this.orderRows.length / this.perPage);

            this.handlePageChange();
          }

          if (firstPage) this.loading = false;
          else this.miniLoading = false;

          if (_callback) _callback();
        },
      });
    },

    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    handlePageChange() {
      let startIndex = (this.page - 1) * this.perPage;
      let endIndex = this.perPage * this.page - 1;

      // check if it needs data to load from server
      if (
        this.orderRows.length <= endIndex &&
        this.LastEvaluatedKey &&
        this.page != this.lastPage
      )
        this.fetchOrders(this.currentTabStatus, false, () => {
          this.paginatedOrders = this.orderRows.slice(startIndex, endIndex + 1);
        });
      else {
        this.paginatedOrders = this.orderRows.slice(startIndex, endIndex + 1);
      }

      //handle empty page
      if (this.paginatedOrders.length === 0) {
        this.page--;
        this.lastPage = this.page;
        this.handlePageChange();
        this.LastEvaluatedKey = null;
      }
      this.selectAllStatus = false;
      this.selectedOrders = [];
    },

    handlePerPageChange() {
      this.page = 1;
      this.handlePageChange();
    },

    selectAll(notPrinted = false) {
      if (this.selectAllStatus) {
        $("tr.--selected").removeClass("--selected");
        $(".table-checkbox").prop("checked", false);
        this.selectedOrders = [];
      } else {
        let ordersToSelect = notPrinted
          ? this.paginatedOrders.filter((o) => !o.printed)
          : this.paginatedOrders;
        ordersToSelect.forEach((order) => this.toggleSelect(order));
      }
      this.selectAllStatus = !this.selectAllStatus;
    },

    toggleSelect(order, manual = false) {
      let checkboxElem = this.$refs["order-checkbox-" + order.id][0];
      if (manual) checkboxElem.checked = !checkboxElem.checked;
      let tableRow = checkboxElem.parentElement.parentElement;
      if (!tableRow) return;
      if (!checkboxElem.checked) {
        checkboxElem.checked = true;
        tableRow.classList.add("--selected");
        this.selectedOrders.push({
          id: order.id,
          status: order.status,
          createdAt: order.createdAt,
        });
      } else {
        let index = this.selectedOrders.findIndex((a) => a.id === order.id);
        this.selectedOrders.splice(index, 1);
        checkboxElem.checked = false;
        tableRow.classList.remove("--selected");
      }
    },

    handleTabChange(value) {
      if (value) this.currentTabStatus = value;
      localStorage.setItem(
        "currentPreShipmentTabStatus",
        this.currentTabStatus
      );
      this.LastEvaluatedKey = null;

      this.fetchOrders(this.currentTabStatus, true, () => {
        //clear configs on tabs change
        this.selectAllStatus = false;
        this.selectedOrders = [];
        this.search.keyword = null;
        this.showOnlyErrorOrders = false;
      });
    },

    goto(type, id, newTab = false) {
      let routerData;

      if (type === "Show")
        routerData = {
          name: "Seller-PreShipment-Show",
          params: { id },
        };
      else if (type === "Edit")
        routerData = {
          name: "Seller-PreShipment-Edit",
          params: { id },
        };

      if (newTab) window.open(this.$router.resolve(routerData).href, "_blank");
      else this.$router.push(routerData);
    },

    async handleMassAction() {
      if (!this.selectedOrders.length) return;

      this.massAction.total = this.selectedOrders.length;
      this.massAction.remaining = this.selectedOrders.length;
      this.massAction.loading = true;

      for (let i = 0; i < this.selectedOrders.length; i++) {
        const orderId = this.selectedOrders[i].id;
        if (this.massAction.name == "mark-paid") {
          await this.$store.dispatch(
            "preShipments/updatePreShipmentStatusAsync",
            { id: orderId, data: { status: 102 } }
          );
        } else if (this.massAction.name == "mark-unpaid") {
          await this.$store.dispatch(
            "preShipments/updatePreShipmentStatusAsync",
            { id: orderId, data: { status: 101 } }
          );
        } else if (this.massAction.name == "ship") {
          await this.$store.dispatch("preShipments/createOrderAsync", {
            id: orderId,
          });
        } else if (this.massAction.name == "cancel") {
          await this.$store.dispatch("preShipments/cancelPreShipmentAsync", {
            id: orderId,
          });
        } else if (this.massAction.name == "mark-pending") {
          await this.$store.dispatch(
            "preShipments/updatePreShipmentStatusAsync",
            { id: orderId, data: { status: 100 } }
          );
        }
        this.massAction.remaining--;

        if (this.massAction.remaining === 0) {
          this.massAction = {
            loading: false,
            total: 0,
            remaining: 0,
          };
          this.selectedOrders = [];
          $("#mass-action-modal").modal("hide");
          this.handleTabChange(this.currentTabStatus);
        }
      }
    },
  },

  computed: {
    activetabIndex() {
      let tabObj = {
        100: 0,
        101: 1,
        102: 2,
        1: 3,
      };
      return tabObj[this.currentTabStatus] || 0;
    },

    showSelectAll() {
      return (
        (this.currentTabStatus && this.currentTabStatus === 100) ||
        this.currentTabStatus === 101 ||
        this.currentTabStatus === 102
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-label-format {
  text-align: center;
  padding: 0.5rem;
  background-color: rgba($brand-blue-light, 0.1);
  border-radius: toRem(10px);
  margin: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: toRem(100px);
    width: auto;
  }
  span {
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }
}
.btn-outline-primary {
  border: toRem(1.5px) solid;
}

.printerFormatContainer {
  width: 210px;

  @include for-larger-than-phone {
    width: 420px;
  }

  margin: 0 auto;
  overflow: auto;
  .printerFormat {
    width: 200px;
    background-color: rgb(252, 252, 252);
    padding: 0.25rem;
    border-radius: 3px;
    margin: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    .icon {
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      margin-top: 0.4rem;
    }
  }
}
</style>