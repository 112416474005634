<template>
  <div>
    <div class="dashboard">
      <div class="content-header">
        <div class="container-fluid">
          <div class="d-flex justify-content-between">
            <h5 class="align-self-center">
              <span class="font-weight-light">{{ $t("dashboard.hi") }}</span>
              {{ $t("dashboard.seller") }}
            </h5>

            <!-- <Toggle
              id="has-agents"
              :value="toggleAgents"
              variant="primary"
              v-model="toggleAgents"
              :labels="{
                true: 'Show Agents data',
                false: 'Do not show agents data',
              }"
              @input="getDashboardData()"
            /> -->
          </div>
        </div>
      </div>

      <div class="content" v-if="dashboardData">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <Card>
                <div slot="body">
                  <div class="product-items row">
                    <div
                      class="
                        col-12 col-sm-6
                        d-flex
                        justify-content-center justify-content-md-start
                      "
                    >
                      <div class="products d-flex flex-wrap">
                        <img
                          src="@/assets/images/admin_panel/products.svg"
                          class="align-self-center mr-3"
                        />
                        <div class="info d-flex flex-column align-self-center">
                          <p class="--sm text-color-5 text-uppercase">
                            {{ $t("dashboard_v2.dashboard_total_sales") }}
                          </p>
                          <h3 class="text-color-2">
                            ฿ {{ dashboardData.totalSalesPrice }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="seperator d-none d-md-block"></div> -->
                    <div
                      class="
                        col-12 col-sm-6
                        d-flex
                        justify-content-center justify-content-md-end
                      "
                    >
                      <div class="items d-flex flex-wrap mt-sm-0 mt-4">
                        <img
                          src="@/assets/images/admin_panel/items.svg"
                          class="align-self-center mr-3"
                        />
                        <div class="info d-flex flex-column align-self-center">
                          <p class="--sm text-color-5 text-uppercase">
                            {{ $t("dashboard_v2.dashboard_total_orders") }}
                          </p>
                          <h3 class="text-color-2">
                            {{ dashboardData.totalOrders }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col-12 pr-0">
              <Card>
                <div slot="body">
                  <p class="lead">
                    {{ $t("dashboard_v2.dashboard_orders_this_month") }}
                  </p>
                  <OrdersLineChart
                    :data="Object.values(dashboardData.orderCountDaily)"
                    :labels="Object.keys(dashboardData.orderCountDaily)"
                  />
                  <p></p>
                </div>
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 pr-0">
              <div class="card-shipping">
                <Card>
                  <div slot="body">
                    <p class="lead">{{ $t("dashboard_v2.dashboard_sales") }}</p>

                    <div class="d-flex justify-content-around">
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_today") }}
                        <br />
                        ฿ {{ dashboardData.sales.today | commas }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_month") }}
                        <br />
                        ฿ {{ dashboardData.sales.month | commas }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_year") }}
                        <br />
                        ฿ {{ dashboardData.sales.year | commas }}
                      </p>
                    </div>
                  </div>
                </Card>
                <Card>
                  <div slot="body">
                    <p class="lead mb-3">
                      {{ $t("dashboard_v2.dashboard_cod_count") }}
                    </p>

                    <div class="d-flex justify-content-around mb-4">
                      <p class="mr-3" style="width: 5rem">
                        {{ $t("dashboard_v2.dashboard_cod_total") }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_today") }}
                        <br />
                        {{ dashboardData.cod.count.total.today }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_month") }}
                        <br />
                        {{ dashboardData.cod.count.total.month }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_year") }}
                        <br />
                        {{ dashboardData.cod.count.total.year }}
                      </p>
                    </div>

                    <!-- <div class="d-flex justify-content-around">
                      <p class="mr-3" style="width: 5rem">
                        {{ $t("dashboard_v2.dashboard_cod_paid") }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_today") }}
                        <br />
                        {{ dashboardData.cod.count.paid.today }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_month") }}
                        <br />
                        {{ dashboardData.cod.count.paid.month }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_year") }}
                        <br />
                        {{ dashboardData.cod.count.paid.year }}
                      </p>
                    </div> -->

                    <hr />

                    <p class="lead mb-3">
                      {{ $t("dashboard_v2.dashboard_cod_amount") }}
                    </p>

                    <div class="d-flex justify-content-around mb-4">
                      <p class="mr-3" style="width: 5rem">
                        {{ $t("dashboard_v2.dashboard_cod_total") }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_today") }}
                        <br />
                        ฿ {{ dashboardData.cod.amount.total.today | commas }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_month") }}
                        <br />
                        ฿ {{ dashboardData.cod.amount.total.month | commas }}
                      </p>
                      <p class="text-color-3 text-center">
                        {{ $t("dashboard_v2.dashboard_this_year") }}
                        <br />
                        ฿ {{ dashboardData.cod.amount.total.year | commas }}
                      </p>
                    </div>
                  </div>
                </Card>

                <Card v-if="topProductsChartData">
                  <div slot="body">
                    <div class="d-flex justify-content-between">
                      <p class="lead">
                        <span v-if="topProductsMode == 'all-time'">{{
                          $t("dashboard_v2.dashboard_top_products")
                        }}</span>
                        <span v-else>สินค้ายอดนิมยมประจำวัน</span>
                      </p>
                      <select v-model="topProductsMode" class="form-control-sm">
                        <option value="all-time">รวมทั้งหมด</option>
                        <option value="daily">วันนี้</option>
                      </select>
                    </div>
                    <TopProductsChart
                      v-if="topProductsChartData"
                      :chartdata="topProductsChartData"
                    />
                  </div>
                </Card>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <Card>
                <div slot="body">
                  <p class="lead">
                    {{ $t("dashboard_v2.dashboard_sales_channel") }}
                  </p>
                  <SalesChannelChart
                    v-if="salesChannelChartData"
                    :chartdata="salesChannelChartData"
                  />
                </div>
              </Card>

              <Card>
                <div slot="body">
                  <p class="lead mb-2">
                    {{ $t("dashboard_v2.dashboard_top_provinces") }}
                  </p>

                  <TopProvincesChart
                    v-if="topProvincesChartData"
                    :chartdata="topProvincesChartData"
                  />
                </div>
              </Card>
            </div>
          </div>

          <p class="text-info">Note: {{ $t("dashboard_v2.dashboard_note") }}</p>
        </div>
      </div>

      <div class="spinner" v-else>
        <Spinner size="lg" />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import SmallBox from "@/components/core/SmallBox";
import Spinner from "@/components/core/Spinner";
import OrdersLineChart from "@/components/derived/charts/OrdersLineChart";
import ShippingDoughnutChart from "@/components/derived/charts/ShippingDoughnutChart";
import CodDoughnutChart from "@/components/derived/charts/CodDoughnutChart";
import SalesChannelChart from "@/components/derived/charts/SalesChannelChart";
import TopProvincesChart from "@/components/derived/charts/TopProvincesChart";
import TopProductsChart from "@/components/derived/charts/TopProductsChart";
import PhoneNumber from "@/components/derived/PhoneNumber";
import OTPField from "@/components/derived/OTPField";
import Toggle from "@/components/core/Toggle";
import { i18n } from "@/i18n";

export default {
  name: "Dashboard",
  components: {
    Card,
    OrdersLineChart,
    ShippingDoughnutChart,
    CodDoughnutChart,
    SalesChannelChart,
    TopProvincesChart,
    SmallBox,
    PhoneNumber,
    OTPField,
    Spinner,
    TopProductsChart,
    Toggle,
  },
  data() {
    return {
      dashboardData: null,
      toggleAgents: false,
      topProductsMode: "all-time", // all-time, daily
    };
  },

  created() {
    this.getDashboardData();
  },

  mounted() {},

  methods: {
    getDashboardData() {
      this.dashboardData = null;
      this.$store.dispatch("dashboard/getDashboardData", {
        agents: this.toggleAgents,
        callback: (status, data) => {
          if (status) {
            this.dashboardData = data.dashboard;
            this.dashboardData.totalOrders = data.orders[0];
          }
        },
      });
    },

    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },

  computed: {
    topProvincesChartData() {
      if (!this.dashboardData.topProvinces) return false;

      this.dashboardData.topProvinces = Object.fromEntries(
        Object.entries(this.dashboardData.topProvinces).sort(
          ([, a], [, b]) => b - a
        )
      );

      return {
        data: {
          labels: Object.keys(this.dashboardData.topProvinces),
          datasets: [
            {
              data: Object.values(this.dashboardData.topProvinces),
              backgroundColor: [
                "#2196F3",
                "#37474F",
                "#43A047",
                "#FB8C00",
                "#C0CA33",
                "#8D6E63",
                "#673AB7",
                "#DD2C00",
                "#D500F9",
                "#004D40",
              ],
            },
          ],
        },
      };
    },

    salesChannelChartData() {
      let sortedData = [
        this.dashboardData.salesChannelCount.facebook,
        this.dashboardData.salesChannelCount.instagram,
        this.dashboardData.salesChannelCount.line,
        this.dashboardData.salesChannelCount.web,
        this.dashboardData.salesChannelCount.other,
      ];

      return {
        data: {
          labels: ["Facebook", "Instagram", "Line", "Web", "Other"],
          datasets: [
            {
              data: sortedData,
              backgroundColor: [
                "#2196F3",
                "#37474F",
                "#43A047",
                "#FB8C00",
                "#C0CA33",
              ],
            },
          ],
        },
      };
    },

    topProductsChartData() {
      if (!this.dashboardData.topProducts) return false;

      let topProducts;
      if (this.topProductsMode == "all-time")
        topProducts = this.dashboardData.topProducts;
      else if (this.topProductsMode == "daily")
        topProducts = this.dashboardData.topProductsDaily;

      return {
        data: {
          labels: Object.keys(topProducts),
          datasets: [
            {
              data: Object.values(topProducts),
              backgroundColor: [
                "#2196F3",
                "#37474F",
                "#43A047",
                "#FB8C00",
                "#C0CA33",
                "#8D6E63",
                "#673AB7",
                "#DD2C00",
                "#D500F9",
                "#004D40",
              ],
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  @include for-larger-than-phone {
    margin: toRem(40px);
  }
}
.spinner {
  margin-top: 100px;
}

.product-items {
  // max-width: toRem(680px);
  margin: 0 auto;
  img {
    height: toRem(79px);
    width: auto;
  }
}
</style>