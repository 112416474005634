<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">ตั้งค่าระบบรับออเดอร์</h4>
    </div>
    <div v-if="loading">
      <Spinner size="sm" />
    </div>
    <div v-else id="checkout-form">
      <div class="row">
        <div class="col-6">
          <!-- Promptpay settings -->
          <div class="bg-light p-3 rounded">
            <h5 class="mb-4 text-primary">รายละเอียดพร้อมเพย์</h5>

            <PromptpayId
              :id="'promptpay-id'"
              :label="'เบอร์โทร/ บัตรประชาชน / เลขที่นิติบุคคล'"
              v-model="checkoutSettingsForm.promptPay.id"
            />

            <TextBox
              type="text"
              :id="'promptpayName'"
              v-model="checkoutSettingsForm.promptPay.name"
              :placeholder="'ชื่อ'"
              :label="'ชื่อ'"
              wrapper_class="mb-0"
              input_wrapper_class="mb-0 pb-1"
            />
          </div>

          <div class="bg-light p-3 rounded mt-4">
            <h5 class="mb-4 text-primary">ธนาคาร</h5>

            <BankSelectDropdown
              @input="(name) => (checkoutSettingsForm.bank.name = name)"
              :value="checkoutSettingsForm.bank.name"
              :wrapper_class="'mb-4'"
            />

            <TextBox
              type="text"
              :id="'bankAccNo'"
              v-model="checkoutSettingsForm.bank.accNo"
              :placeholder="'เลขบัญชี'"
              :label="'เลขบัญชี'"
              wrapper_class="mb-4"
              input_wrapper_class="mb-0 pb-1"
            />

            <TextBox
              type="text"
              :id="'bankAccName'"
              v-model="checkoutSettingsForm.bank.accName"
              :placeholder="'ชื่อบัญชี'"
              :label="'ชื่อบัญชี'"
              wrapper_class="mb-4"
              input_wrapper_class="mb-0 pb-1"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="bg-light p-3 rounded">
            <h5 class="mb-4 text-primary">ข้อความแสดงให้ลูกค้า</h5>

            <vue-editor
              id="editor"
              v-model="checkoutSettingsForm.preOrderMessageFormat"
              useCustomImageHandler
              @image-added="handleImageAdded"
              :editor-toolbar="customToolbar"
            />

            <div v-if="checkoutSettingsForm.preOrderMessageFormat" class="mt-4">
              <p>ตัวอย่าง</p>
              <div
                class="rounded border p-2"
                v-html="checkoutSettingsForm.preOrderMessageFormat"
                :style="{ fontFamily: 'arial' }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <Button
        type="button"
        variant="primary"
        custom_class="btn-block mr-4"
        @click="handleFormSubmit()"
        :loading="formLoading"
        >{{ $t("general.update") }}</Button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button";
import NumberField from "@/components/core/NumberField";
import Card from "@/components/core/Card";
import TextBox from "@/components/core/TextBox";
import PromptpayId from "@/components/derived/PromptpayId";
import SmartAddress from "@/components/derived/SmartAddress";
import Spinner from "@/components/core/Spinner";
import { VueEditor } from "vue2-editor";
import axios from "axios";
import BankSelectDropdown from "@/components/derived/BankSelectDropdown";

export default {
  name: "SellerSettingsCheckout",
  components: {
    Card,
    TextBox,
    Spinner,
    Button,
    SmartAddress,
    NumberField,
    PromptpayId,
    VueEditor,
    BankSelectDropdown,
  },

  data() {
    return {
      loading: false,
      formLoading: false,
      checkoutSettingsForm: null,

      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],

        ["link", "image"],
        ["clean"],
      ],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      try {
        let fd = new FormData();
        fd.append("file", file);
        let resp = await axios.post(
          "https://8enalpa7e5.execute-api.ap-southeast-1.amazonaws.com/dev/v1/media",
          fd,
          { headers: { "content-type": "multipart/form-data" } }
        );

        if (resp.data && resp.data.urls && resp.data.urls.length) {
          Editor.insertEmbed(cursorLocation, "image", resp.data.urls[0]);
          resetUploader();
        } else {
          alert("Error image URL");
        }
      } catch (e) {
        console.log(e);
        alert("Error image upload");
      }
    },

    initForm() {
      return {
        preOrderMessageFormat: null,

        promptPay: {
          id: null,
          name: null,
        },

        bank: {
          name: null,
          accNo: null,
          amount: null,
        },
      };
    },

    fetchData() {
      this.loading = true;
      this.$store.dispatch("settings/fetchCheckout", {
        callback: (status, data) => {
          if (status) {
            this.checkoutSettingsForm = data ? data : this.initForm();
          }
          this.loading = false;
        },
      });
    },

    autofillCustDetails(value) {
      if (value && value.phone) this.checkoutSettingsForm.phoneNo = value.phone;
    },

    handleFormSubmit() {
      this.formLoading = true;
      this.$store.dispatch("settings/updateCheckout", {
        data: this.checkoutSettingsForm,
        callback: (status) => (this.formLoading = false),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.setting__header {
  font-weight: bold;
  font-size: 18px;
}
.setting__description {
  font-weight: thin;
  font-size: 13px;
}

.setting {
  &.--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.--table {
    .setting__text {
      margin-bottom: 20px;
    }
  }

  margin-bottom: 30px;
}

.settings-wrapper {
  margin-top: 24px;
}

.setting-status-tag {
  text-transform: uppercase;

  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
  width: 68px;
  height: 29px;

  &.--warning {
    background-color: #ffc107;
    color: black;
  }

  &.--success {
    background-color: #28a745;
    color: white;
  }

  &.--danger {
    background-color: #dc3545;
    color: white;
  }

  &.--info {
    background-color: #007bff;
    color: white;
  }
}
</style>