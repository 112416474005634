<template>
  <div class="register">
    <div class="auth-box">
      <div class="site-logo">
        <a href>
          <a href>
            <img src="@/assets/logo_small.png" alt />
          </a>
        </a>
      </div>
      <div class="auth-box__body card">
        <Stepper :steps="2" :currentStepIndex="step" variant="primary" has_number />

        <!-- Step 1 -->
        <div class="initial-register" v-if="step === 1">
          <p class="auth-box-body__msg text-center">{{$t('register.description')}}</p>

          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="handleRegister">
              <div class="row">
                <div class="col">
                  <TextBox
                    type="text"
                    id="name-textbox"
                    :placeholder="$t('general.name')"
                    v-model="registerForm.username"
                    rules="required"
                  />

                  <PhoneNumber
                    id="phone-textbox"
                    :placeholder="$t('general.phone')"
                    rules="required"
                    v-model="registerForm.phoneNo"
                  />

                  <TextBox
                    type="email"
                    id="email-textbox"
                    :placeholder="$t('general.email')"
                    v-model="registerForm.email"
                    rules="required"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col pr-1">
                  <TextBox
                    type="password"
                    id="register-password"
                    v-model="registerForm.password"
                    :placeholder="$t('general.password')"
                    rules="required"
                  />
                </div>
                <div class="col pl-1">
                  <TextBox
                    type="password"
                    id="register-password-confirmation"
                    v-model="registerForm.passwordConfirmation"
                    :placeholder="$t('general.password')"
                    rules="required"
                  />
                </div>
              </div>

              <div class="auth-box__cta-wrapper d-flex justify-content-center">
                <Button
                  type="submit"
                  id="register-form-button"
                  variant="info"
                  size="md"
                  :disabled="invalid"
                  :loading="registerLoading"
                >{{$t('register.label')}}</Button>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <!-- Step 2 -->
        <div class="otp-verification" v-if="step === 2">
          <p class="auth-box-body__msg">
            {{$t('register.message')}}
            <span class="font-weight-bold">{{registerForm.phoneNo}}</span>
          </p>
          <form @submit.prevent="handleOtp">
            <OTPField v-model="otp" />
            <!-- <p class="auth-box-body__msg">{{$t('register.otp_message')}}</p> -->

            <p class="auth-box-body__msg" v-if="resenOtpLoading">Resending OTP...</p>
            <p class="auth-box-body__msg" v-else>
              {{$t('register.otp_message')}}
              <span v-if="otpTime === 0">
                <a
                  href="#/register"
                  class="otp-resend"
                  @click="resendOtp()"
                >{{$t('register.resend')}}</a>
              </span>
              <span v-else>
                <a>{{$t('register.resend')}}</a>
                in {{otpTime}} seconds
              </span>
            </p>

            <div class="auth-box__cta-wrapper d-flex justify-content-center">
              <!-- <router-link :to="{name: 'Login'}">{{$t('register.login_now')}}</router-link> -->
              <Button
                type="submit"
                id="register-form-button"
                variant="info"
                size="md"
                :disabled="otpLoading || !otp"
                :loading="otpLoading"
              >Verify</Button>
            </div>
          </form>
        </div>

        <!-- Step 3
        <div class="final-login-prompt" v-if="step === 3">
          <div class="sucess-icon mb-2">
            <i class="fal fa-check-circle"></i>
          </div>
          <p
            class="auth-box-body__msg sucessfully-registered-messege"
          >{{$t('register.success_message')}}</p>
          <p class="text-center auth-box-body__msg">
            {{$t('register.you_can')}}
            <router-link :to="{name: 'Login'}">{{$t('register.login_now')}}</router-link>
          </p>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import NumberField from "@/components/core/NumberField";
import DatePicker from "@/components/core/DatePicker";
import Stepper from "@/components/core/Stepper";
import OTPField from "@/components/derived/OTPField";
import PhoneNumber from "@/components/derived/PhoneNumber";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    TextBox,
    Button,
    CheckBoxGroup,
    NumberField,
    DatePicker,
    Stepper,
    OTPField,
    PhoneNumber
  },

  data() {
    return {
      registerLoading: false,
      otpLoading: false,
      resenOtpLoading: false,
      step: 1,
      otp: null,
      otpTimer: null,
      otpTime: 10,
      userId: null,

      registerForm: {
        username: null,
        email: null,
        phoneNo: null,
        password: null,
        passwordConfirmation: null
      }
    };
  },

  created() {
    // this.startOtpTimer();
  },

  methods: {
    handleOtp() {
      this.otpLoading = true;
      this.$store.dispatch("user/verify", {
        data: {
          code: this.otp
        },
        callback: (status, data) => {
          if (status) {
            this.$store.dispatch("user/login", {
              data: {
                email: this.registerForm.email,
                password: this.registerForm.password
              },
              callback: (status, data) => {
                this.$store.commit("onboard/setFirstVisit", true);
                if (status) {
                  if (this.firstVisit)
                    // if (confirm("Go yo Onboard. {Only for testing}"))
                    this.$router.push({ name: "Onboard" });
                  else this.$router.push({ name: "Seller-Dashboard" });
                }
                this.loginLoading = false;
              }
            });
          }
          this.otpLoading = false;
        }
      });
    },

    resendOtp() {
      this.resenOtpLoading = true;
      this.$store.dispatch("user/resendOtp", {
        data: {
          userId: this.userId
        },
        callback: (status, data) => {
          if (status) alert("Success");
          this.resenOtpLoading = false;
        }
      });
    },

    handleRegister: function() {
      this.registerLoading = true;
      this.$store.dispatch("user/register", {
        data: this.registerForm,
        callback: (status, data) => {
          if (status) {
            this.userId = data.user;
            this.step++;
            // alert(data.otp);
            this.startOtpTimer();
          }
          this.registerLoading = false;
        }
      });
    },

    startOtpTimer() {
      this.otpTimer = setInterval(() => {
        this.otpTime--;
        if (this.otpTime === 0) clearInterval(this.otpTimer);
      }, 1000);
    }
  },
    computed: {
    ...mapGetters("onboard", ["firstVisit"])
  }
};
</script>


<style lang="scss" scoped>
.otp-resend-msg {
  text-transform: uppercase;
  font-weight: bold;
}

.otp-resend {
  text-decoration: underline;
}

.auth-link {
  text-decoration: underline;
}

.sucessfully-registered-messege {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.sucess-icon {
  margin-top: -30px;
  text-align: center;
  font-size: 50px;
  color: #28a745;
  @include for-phone-only {
    margin-top: -10px;
    font-size: 30px;
  }
}

.site-logo {
  margin: 25px auto !important;
}
</style>