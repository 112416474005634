<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-md-6 col-sm-12">
            <h4 class="font-weight-light">
              {{ $t("general.edit") }}
              <span class="font-weight-bold">เปิดบิลขาย</span>
            </h4>
          </div>
          <div class="col-md-6 col-sm-12"></div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div v-if="loading">
          <Spinner size="md" variation="primary" />
        </div>
        <div v-else>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="handleFormSubmit" novalidate>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <Card id="order-details-card">
                    <div slot="body">
                      <h5 class="header-text text-color-4 mb-2">
                        {{ $t("shipment.edit.order_details") }}
                      </h5>
                      <div class="row">
                        <div class="col">
                          <TextBox
                            type="text"
                            id="order"
                            :label="$t('shipment.edit.fields.order_no')"
                            v-model="orderEditForm.details.orderCode"
                            rules="required"
                            :disabled="true"
                            input_wrapper_class="pb-0"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 col-md-6">
                          <Select
                            v-if="
                              salesPersonOptions &&
                              salesPersonOptions.length > 1
                            "
                            id="sales-person"
                            :label="$t('shipment.edit.fields.sales_person')"
                            :options="salesPersonOptions"
                            v-model="orderEditForm.details.salesPerson"
                            :value="orderEditForm.details.salesPerson"
                          />
                        </div>

                        <div class="col-12 col-md-6">
                          <Select
                            id="shops"
                            :label="$t('shipment.edit.fields.shop')"
                            :options="shopOptions"
                            v-model="orderEditForm.details.shopId"
                            :value="orderEditForm.details.shopId"
                            @input="defaultWeight"
                          />
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-12">
                          <label>{{
                            $t("shipment.edit.fields.shipping_methods")
                          }}</label>

                          <ValidationProvider rules="required">
                            <ShippingMethodInput
                              :options="shippingMethods"
                              :values="orderEditForm.details.shippingMethod"
                              v-model="orderEditForm.details.shippingMethod"
                              variant="info"
                              size="sh"
                              justify="left"
                              :multiple="false"
                              wrapper_class="mt-2 mb-2"
                              :disable="true"
                            />
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <NumberField
                            id="totalSalesPrice"
                            v-model.number="
                              orderEditForm.details.totalSalesPrice
                            "
                            :label="
                              $t('shipment.edit.fields.total_sales_price')
                            "
                            wrapper_class="pb-0"
                            @input="handleDiscountChange()"
                          />
                        </div>

                        <div class="col">
                          <NumberField
                            id="shippingCostCharged"
                            v-model.number="
                              orderEditForm.details.shippingCostCharged
                            "
                            :label="
                              $t('shipment.edit.fields.shipping_cost_charged')
                            "
                            wrapper_class="pb-0"
                            @input="handleDiscountChange()"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <NumberField
                            id="weight"
                            v-model.number="orderEditForm.details.weight"
                            :label="$t('general.weight')"
                            wrapper_class="pb-0"
                          />
                        </div>
                        <div
                          class="col"
                          v-if="
                            orderEditForm.details.shippingMethod &&
                            orderEditForm.details.shippingMethod[0].toLowerCase() ===
                              'thaipost' &&
                            boxSizeOptions &&
                            boxSizeOptions.length
                          "
                        >
                          <Select
                            id="box-sizes"
                            :label="$t('shipment.edit.fields.box_size')"
                            :options="boxSizeOptions"
                            v-model="orderEditForm.details.boxSize"
                            rules="required"
                          />
                        </div>
                      </div>

                      <!-- Discount -->
                      <div class="row">
                        <div class="col-6">
                          <label>{{
                            $t("shipment.edit.fields.discount_field")
                          }}</label>

                          <div class="mb-3">
                            <Toggle
                              id="has-discount"
                              :value="discountToggle"
                              variant="primary"
                              v-model="discountToggle"
                              :labels="{
                                true: $t(
                                  'shipment.edit.fields.order_has_discount'
                                ),
                                false: $t(
                                  'shipment.edit.fields.orders_has_no_discount'
                                ),
                              }"
                              @input="handleDiscountChange()"
                            />
                          </div>
                        </div>
                        <div class="col-6" v-if="discountToggle">
                          <NumberField
                            id="discountAmount"
                            v-model.number="
                              orderEditForm.details.discountAmount
                            "
                            @input="handleDiscountChange()"
                            :label="$t('shipment.edit.fields.discount_amount')"
                            wrapper_class="pb-0"
                            fg_wrapper_class="mb-0"
                          />
                          <label class="--text-vvsm text-danger"
                            >ระบบจะลดจากยอด COD อัตโนมัติ</label
                          >
                        </div>
                      </div>

                      <!-- Bank Select -->
                      <Toggle
                        id="has-bank-info"
                        :value="bankToggle"
                        variant="primary"
                        v-model="bankToggle"
                        :labels="{
                          true: $t('bank_input.save_bank_info'),
                          false: $t('bank_input.do_not_save_bank_info'),
                        }"
                        @input="handleBankChange()"
                      />

                      <div class="row mt-3" v-if="bankToggle">
                        <div class="col-12">
                          <label>{{ $t("bank_input.select_a_bank") }}</label>

                          <BankSelectInput
                            v-model="orderEditForm.details.bank.name"
                            :value="orderEditForm.details.bank.name"
                          />

                          <NumberField
                            id="amount"
                            :label="$t('bank_input.amount')"
                            wrapper_class="pb-0"
                            fg_wrapper_class="mb-0 mt-4"
                            v-model.number="orderEditForm.details.bank.amount"
                          />

                          <div class="row mt-4">
                            <div class="col-6">
                              <DatePicker
                                id="date-picker"
                                :label="$t('bank_input.date')"
                                custom_class="align-self-center mr-2 mb-0"
                                v-model="orderEditForm.details.bank.date"
                              />
                            </div>

                            <div class="col-6">
                              <TextBox
                                type="text"
                                id="time-picker"
                                :label="$t('bank_input.time')"
                                v-model="orderEditForm.details.bank.time"
                              />
                            </div>
                          </div>

                          <div class="mt-4"></div>

                          <DropZone
                            ref="dropzone"
                            v-model="orderEditForm.details.bank.slip"
                            :value="orderEditForm.details.bank.slip"
                            :label="$t('bank_input.upload_bank_slip')"
                            size="sm"
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div class="col-md-6 col-sm-12">
                  <Card id="customer-details-card">
                    <div slot="body">
                      <h5 class="header-text text-color-4 mb-2">
                        {{ $t("shipment.edit.customer_details") }}
                      </h5>
                      <div class="row">
                        <div class="col-12">
                          <TextBox
                            type="text"
                            id="customer-phone"
                            :label="$t('general.phone')"
                            v-model="orderEditForm.details.customer.phoneNo"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <TextBox
                            type="text"
                            id="customer-name"
                            :label="$t('general.name')"
                            v-model="orderEditForm.details.customer.name"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextBox
                            type="text"
                            id="customer-email"
                            :label="$t('general.email')"
                            v-model="orderEditForm.details.customer.email"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <ValidationProvider rules="address">
                            <SmartAddress
                              id="customer-address"
                              :label="$t('general.address.address')"
                              :value="orderEditForm.details.customer.address"
                              v-model="orderEditForm.details.customer.address"
                              rows="3"
                            />
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <ValidationProvider>
                            <Select
                              id="sales-channel"
                              :label="$t('form.shipment.sales_channel')"
                              :options="[
                                { name: 'Facebook', value: 'facebook' },
                                { name: 'Line', value: 'line' },
                                { name: 'Instagram', value: 'instagram' },
                                { name: 'Web', value: 'web' },
                                { name: 'Other', value: 'other' },
                              ]"
                              v-model="
                                orderEditForm.details.customer.salesChannel
                              "
                            />
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <TextBox
                            type="text"
                            id="customer-social"
                            :label="$t('form.shipment.customer_fb_line')"
                            v-model="orderEditForm.details.customer.socialLink"
                            input_wrapper_class="pb-0"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <label>{{
                            $t("shipment.edit.fields.payment_mode")
                          }}</label>
                          <Toggle
                            id="is-cod"
                            :value="codToggle"
                            variant="primary"
                            v-model="codToggle"
                            :labels="{
                              true: $t(
                                'shipment.edit.fields.payment_method_cod'
                              ),
                              false: $t(
                                'shipment.edit.fields.payment_method_online'
                              ),
                            }"
                            @input="handleDiscountChange()"
                          />
                        </div>
                        <div class="col-6">
                          <div v-if="codToggle">
                            <NumberField
                              id="codAmount"
                              v-model.number="orderEditForm.details.codAmount"
                              :label="$t('shipment.edit.fields.cod_amount')"
                              wrapper_class="pb-0"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <TextArea
                            id="remark"
                            :label="$t('shipment.edit.fields.remarks')"
                            v-model="orderEditForm.details.remarks"
                            rows="3"
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <OrdersProductTable
                v-if="showProducts"
                @change="handleProductsChange"
                :_selectedProducts="orderEditForm.details.products"
              />

              <div class="row mt-2 mb-4">
                <div class="col">
                  <div class="d-flex mt-4 justify-content-center">
                    <Button
                      type="submit"
                      class="btn btn-primary mr-4"
                      :disabled="invalid"
                      size="lg"
                      :loading="updateLoading"
                      >{{ $t("general.submit") }}</Button
                    >
                    <Button
                      type="button"
                      class="btn btn-outline-primary"
                      @click="cancel()"
                      size="lg"
                      >{{ $t("general.cancel") }}</Button
                    >
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import TextBoxAddon from "@/components/core/TextBoxAddon";
import Spinner from "@/components/core/Spinner";
import TextArea from "@/components/core/TextArea";
import Toggle from "@/components/core/Toggle";
import NumberField from "@/components/core/NumberField";
import Select from "@/components/core/Select";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import RowControls from "@/components/core/RowControls";
import CardWidget from "@/components/core/CardWidget.vue";
import Modal from "@/components/core/Modal";
import RadioGroup from "@/components/core/RadioGroup";
import ShippingMethodInput from "@/components/derived/ShippingMethodInput";
import TimePicker from "@/components/core/TimePicker";
import DatePicker from "@/components/core/DatePicker";
import SmartAddress from "@/components/derived/SmartAddress";
import BankSelectInput from "@/components/derived/BankSelectInput";
import DropZone from "@/components/derived/DropZone";
import OrdersProductTable from "@/components/derived/OrdersProductTable";
import { shippingInfo } from "@/helpers/core";
import { ToastFix } from "@/helpers/toastr";
import { mapGetters } from "vuex";
import axios from "axios";
import Vue from "vue";
import _ from "lodash";

export default {
  name: "Seller-Order-Edit",
  components: {
    Card,
    TextBox,
    NumberField,
    Modal,
    TextArea,
    Select,
    CheckBoxGroup,
    RowControls,
    CardWidget,
    RadioGroup,
    TextBoxAddon,
    Button,
    Spinner,
    SmartAddress,
    Toggle,
    OrdersProductTable,
    ShippingMethodInput,
    BankSelectInput,
    TimePicker,
    DropZone,
    DatePicker,
  },
  data() {
    return {
      shops: null,
      loading: true,
      updateLoading: null,
      phoneCheckLoading: false,
      shopOptions: [],
      salesPersonOptions: [],
      codToggle: false,
      bankToggle: false,
      showCod: true,
      discountToggle: false,
      productWeightChangedFromTable: false,
      boxSizeOptions: null,

      orderEditForm: {
        details: {
          user: null,
          products: null,
          orderCode: null,
          weight: null,
          codAmount: null,
          remarks: null,
          customer: {
            name: null,
            address: null,
            mobile: null,
            phone: null,
            email: null,
          },
          products: {},
          shippingMethod: [],
        },
      },
      shippingMethods: [],
    };
  },

  created() {
    this.loading = true;
    this.fetchOrder(() => {
      this.fetchShops(() => {
        this.fetchShipping(() => {
          this.fetchSalesAgents(() => {
            this.loading = false;
          });
        });
      });
    });

    //pre load it
    this.fetchBoxSizes();
  },

  methods: {
    handleDiscountChange() {
      if (!this.discountToggle) this.orderEditForm.details.discountAmount = 0;

      if (this.codToggle && this.orderEditForm.details.totalSalesPrice)
        this.orderEditForm.details.codAmount =
          Math.max(
            this.orderEditForm.details.totalSalesPrice -
              this.orderEditForm.details.discountAmount +
              this.orderEditForm.details.shippingCostCharged,
            0
          ) || 0;

      if (this.codToggle) {
        this.bankToggle = false;
        if (this.$refs.dropzone) this.$refs.dropzone.clear();

        if (this.orderEditForm.details.bank)
          this.orderEditForm.details.bank.slip = null;
      }
    },

    handleBankChange() {
      if (this.bankToggle) {
        this.codToggle = false;

        if (!this.orderEditForm.details.bank)
          this.orderEditForm.details.bank = {
            name: null,
            amount: null,
            date: null,
            time: null,
            slip: null,
          };
      }
      this.handleDiscountChange();
    },

    handleProductsChange(val) {
      Vue.set(
        this.orderEditForm.details,
        "totalSalesPrice",
        val.selectedProductsPrice
      );
      Vue.set(this.orderEditForm.details, "weight", val.selectedProductsWeight);
      Vue.set(this.orderEditForm.details, "products", val.products);
    },

    handleShopChange() {
      let shop = this.shops.find(
        (shop) => shop.id === this.orderEditForm.details.shopId
      );
      if (shop) {
        if (
          this.orderEditForm.shippingMethod.includes("thaipost") &&
          !shop.details.walletId
        ) {
          this.codToggle = false;
          this.showCod = false;
        } else this.showCod = true;
      }
    },

    defaultWeight(shopId = this.orderEditForm.details.shopId) {
      let shop = this.shops.find((shop) => shop.id === shopId);
      if (shop) {
        if (shop.details.defaultWeight)
          this.orderEditForm.details.weight = shop.details.defaultWeight;
        if (
          shop.details.shippingMethods &&
          shop.details.shippingMethods.length
        ) {
          this.shippingMethods = shop.details.shippingMethods.map((sm) =>
            shippingInfo(sm)
          );
        }

        if (
          this.orderEditForm.shippingMethod.includes("thaipost") &&
          !shop.details.walletId
        ) {
          this.codToggle = false;
          this.showCod = false;
        } else this.showCod = true;
      }
    },

    handleFormSubmit() {
      this.updateLoading = true;
      var valueToUpdate = JSON.parse(
        JSON.stringify(this.orderEditForm.details)
      );
      // valueToUpdate.products = this.product.selectedProducts;
      valueToUpdate.shippingMethod = valueToUpdate.shippingMethod.toString();
      if (valueToUpdate.salesPerson)
        valueToUpdate.salesPerson = this.salesPersonOptions.find(
          (s) => s.value == valueToUpdate.salesPerson
        );

      if (
        this.generalSettings &&
        this.generalSettings.productMgt &&
        this.productWeightChangedFromTable
      ) {
        valueToUpdate.cost = this.selectedProductsPrice;
        valueToUpdate.weight = this.selectedProductsWeight;
      }

      // delete unnecessary stuffs
      delete valueToUpdate.cod;
      if (!this.codToggle) valueToUpdate.codAmount = null;
      if (!this.boxSizeOptions || !this.boxSizeOptions.length)
        delete valueToUpdate.boxSize;
      if (!this.bankToggle) delete valueToUpdate.bank;

      //validate weight for "thaipost"
      if (valueToUpdate.shippingMethod == "thaipost") {
        //weight cannot me more than 20,000gm
        if (valueToUpdate.weight > 20000) {
          ToastFix("Weight cannot be greater than 20,000 grams.", "danger");
          this.updateLoading = false;
          return;
        }
      }

      // auto detect showSocialLinkOnLabel
      if (
        valueToUpdate.customer.socialLink &&
        valueToUpdate.customer.socialLink.length
      )
        valueToUpdate.customer.showSocialLinkOnLabel = true;
      else valueToUpdate.customer.showSocialLinkOnLabel = false;

      if (!valueToUpdate.customer.address) valueToUpdate.customer.address = {};

      this.$store.dispatch("preShipments/updatePreShipment", {
        id: this.orderEditForm.id,
        order: valueToUpdate,
        callback: (status, data) => {
          this.updateLoading = false;
          if (status) {
            ToastFix(data.message, "info");
            this.$router.push({
              name: "Seller-Order-Detail",
              params: { id: this.orderEditForm.id },
            });
          }
        },
      });
    },

    fetchSalesAgents(callback) {
      this.$store.dispatch("salesAgent/fetchSalesAgents", {
        callback: (status, data) => {
          if (status) {
            this.salesPersonOptions = data.map((d) => {
              return {
                name: d.details.username,
                value: d.id,
              };
            });
            this.salesPersonOptions.unshift({ name: "None", value: null });
          }
          if (callback) callback();
        },
      });
    },

    cancel() {
      this.$router.push({ name: "Seller-Order-List" });
    },

    checkPhoneNo(phone) {
      if (!phone) return;
      this.phoneCheckLoading = true;
      let url = `https://iwjkvg2m94.execute-api.ap-southeast-1.amazonaws.com/dev/get-address-by-phone/?phone=${phone}&secret=zaa123cvberty`;
      axios.get(url).then((response) => {
        if (response.data.not_found) alert("Not found");
        this.orderEditForm.details.customer.address = {
          raw: response.data.original,
        };
        if (response.data.parsed && response.data.parsed.phone)
          this.orderEditForm.details.customer.phoneNo =
            response.data.parsed.phone;
        this.phoneCheckLoading = false;
      });
    },

    fetchOrder(callback) {
      this.$store.dispatch("orders/fetchOrderDetail", {
        id: this.$route.params.id,
        callback: (status, data) => {
          if (status) {
            this.orderEditForm = data;
            this.orderEditForm.details.shippingMethod = [
              data.details.shippingMethod,
            ];

            if (this.orderEditForm.details.salesPerson)
              this.orderEditForm.details.salesPerson =
                this.orderEditForm.details.salesPerson.value;

            // agent name
            if (!this.orderEditForm.details.user)
              this.orderEditForm.details.user = this.authData.user.username;

            // COD
            if (this.orderEditForm.details.codAmount) {
              this.codToggle = true;
            } else {
              this.orderEditForm.details.codAmount = null;
              this.codToggle = false;
            }

            // discount
            if (this.orderEditForm.details.discountAmount) {
              this.discountToggle = true;
            } else {
              this.discountToggle = false;
            }

            // PhoneNo & MobileNo
            if (
              !this.orderEditForm.details.customer.mobileNo &&
              this.orderEditForm.details.customer.phoneNo
            )
              this.orderEditForm.details.customer.mobileNo =
                this.orderEditForm.details.customer.phoneNo;

            // bank
            if (
              this.orderEditForm.details.bank &&
              this.orderEditForm.details.bank.name
            )
              this.bankToggle = true;

            //sales channel, social-link, social-link-label-toggle
            if (!this.orderEditForm.details.customer.salesChannel)
              this.orderEditForm.details.customer.salesChannel = null;
            if (!this.orderEditForm.details.customer.socialLink)
              this.orderEditForm.details.customer.socialLink = null;
            if (!this.orderEditForm.details.salesPerson)
              this.orderEditForm.details.salesPerson = null;
            if (!this.orderEditForm.details.customer.showSocialLinkOnLabel)
              this.orderEditForm.details.customer.showSocialLinkOnLabel = false;

            // customer address
            if (_.isEmpty(this.orderEditForm.details.customer.address))
              this.orderEditForm.details.customer.address = null;

            if (callback) callback();
          }
        },
      });
    },

    fetchShops(callback) {
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shops = data;
            this.shops.forEach((shop) => {});
            if (this.shops) {
              this.shopOptions = this.shops.map((shop) => {
                return {
                  name: shop.details.name,
                  value: shop.id,
                };
              });
              this.orderEditForm.details.shop = this.shopOptions[0].value;
            }
          }
          if (callback) callback();
        },
      });
    },

    fetchShipping(callback) {
      this.shippingMethods.push(shippingInfo("thaipost"));
      if (callback) callback();
    },

    fetchBoxSizes(callback) {
      if (this.generalSettings.thaiPostUserName) {
        this.$store.dispatch("orders/fetchBoxSizes", {
          username: this.generalSettings.thaiPostUserName,
          callback: (status, data) => {
            if (status) {
              this.boxSizeOptions = data;
            }
            if (callback) callback();
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    showProducts() {
      return this.generalSettings && this.generalSettings.productMgt;
    },
  },
};
</script>

<style lang="scss" scoped>
.variationsList {
  display: none;
  position: absolute;
  width: 150px;
  max-height: 150px;
  overflow: auto;
  top: 0;
  left: 0;
  transform: translate(-100%, -50%);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: whitesmoke;
  text-align: left;
  z-index: 100;

  .variationsListItem {
    background-color: white;
    padding: 0.2rem 1rem;
    margin: 0.2;

    &:hover {
      background-color: whitesmoke;
    }
  }
}

.childProductsList {
  max-width: 200px;
  .childProductsListItem {
    border: 1px solid map-get($variants, "success");
    padding: 0.1rem;
    margin: 0.1rem;
    border-radius: 0.2rem;
    font-size: 0.7rem;

    .btnCancel {
      cursor: pointer;
      margin-right: 0.2rem;
    }
  }
}

.btn-caret-up:hover .variationsList {
  display: block;
}

.fa-plus-circle,
.fa-expand {
  color: $brand-green-mid;
  font-size: 18px;
}

.fa-trash-alt {
  color: $brand-pink;
  font-size: 18px;
}
.shipping-warning:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
