<template>
  <div class="form-group">
    <TextBox
      :derived="true"
      type="Number"
      :maxval="13"
      :placeholder="placeholder"
      :id="id"
      :value="value"
      :label="label"
      @input="emitUpdate()"
      v-model="vat"
      :rules="'promptpayId|' + rules"
    />
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
export default {
  name: "PromptpayId",
  components: {
    TextBox
  },
  props: {
    label: {
      type: String
    },
    value: {
      type: null
    },
    placeholder: {
      type: String
    },
    id: {
      type: String
    },
    rules: {
      type: String,
      default: ""
    }
  },

  created() {
    this.vat = this.value + '';
    this.emitUpdate();
  },

  data() {
    return {
      vat: null
    };
  },

  methods: {
    emitUpdate() {
      this.$emit("input", this.vat + "");
    }
  },

  watch: {
    value: function(newVal, oldVal) {
      this.vat = newVal + '';
      this.emitUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>