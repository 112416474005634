<template>
  <div class="d-flex flex-column justify-content-center text-center h-100">
    <OnboardHeader :title="$t('onboard.options.title')" :subtitle="$t('onboard.options.subtitle')">
      <img src="@/assets/images/onboard/services.svg" class="onboard-icon" slot="img" />
    </OnboardHeader>

    <div class="onboard-form-wrapper --no-mw">
      <div class="onboard-form">
        <div class="options-wrapper text-center d-flex justify-content-center">
          <div class="row">
            <div class="col-6">
              <div
                class="option d-flex align-items-center justify-content-center"
                v-bind:class="{'--active':productMgt}"
                @click="toggleProductMgt()"
              >
                <div class="tick-icon" v-bind:class="{'d-block':productMgt}">
                  <img src="@/assets/images/onboard/check.svg" />
                </div>
                <div class="option-image">
                  <img src="@/assets/images/onboard/product-management.svg" />
                  <div class="option-name-wrapper w-100">
                    <span class="option-name body-text">{{$t('onboard.options.product_mgmt')}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div
                class="option d-flex align-items-center justify-content-center"
                v-bind:class="{'--active':inventoryMgt, '--disabled': !productMgt}"
                @click="toggleInventoryMgt()"
              >
                <div class="tick-icon" v-bind:class="{'d-block':inventoryMgt}">
                  <img src="@/assets/images/check.svg" alt />
                </div>
                <div class="option-image">
                  <img src="@/assets/images/onboard/inventory-management.svg" alt />
                  <div class="option-name-wrapper w-100">
                    <span class="option-name body-text">{{$t('onboard.options.inventory_mgmt')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <OnboardButtons :on_next="next" :active="false" :on_secondary_button="back" :loading="loading" />
  </div>
</template>

<script>
import Button from "@/components/core/Button";
import OnboardButtons from "@/components/derived/onboard/OnboardButtons";
import OnboardHeader from "@/components/derived/onboard/OnboardHeader";

export default {
  name: "Onboard-Options",
  components: {
    Button,
    OnboardButtons,
    OnboardHeader
  },
  data() {
    return {
      loading: false,
      showInventoryMgtOption: false,
      inventoryMgt: false,
      productMgt: false
    };
  },

  methods: {
    toggleProductMgt() {
      if (this.productMgt) {
        this.productMgt = false;
        this.inventoryMgt = false;
      } else this.productMgt = true;
      this.saveToStore();
    },

    toggleInventoryMgt(val) {
      if (this.productMgt) this.inventoryMgt = !this.inventoryMgt;
      this.saveToStore();
    },

    saveToStore() {
      this.$store.dispatch("onboard/storeOptions", {
        options: {
          productMgt: this.productMgt,
          inventoryMgt: this.inventoryMgt
        }
      });
    },

    next() {
      this.loading = true;
      this.$store.dispatch('settings/updateGeneralSettings', {
        generalSettings: {
          productMgt: this.productMgt,
          inventoryMgt: this.inventoryMgt
        },
        callback: (status) => {
          if (this.productMgt) this.$router.push({ name: "Onboard-Product" });
          else this.$router.push({ name: "Seller-Order-List" });
          this.loading = false;
        }
      });
    },

    back() {
      this.$router.go(-1);
    }
  },

  computed: {
    invalid() {
      return !this.productMgt && !this.inventoryMgt;
    }
  }
};
</script>

<style lang="scss" scoped>
.options-wrapper {
  @include for-phone-only {
    margin-bottom: 20px;
  }
}

.option {
  background-color: $dashboard-background;
  @include for-desktops-and-up {
    width: 315px;
    height: 330px;
  }

  @include for-phone-only {
    margin-left: 0;
    padding: 10px;
  }

  @include for-laptops-only {
    margin-left: 0;
    padding: 15px;
  }
  border-radius: 20px;
  margin-left: 40px;
  position: relative;
  cursor: pointer;
  &.--active {
    background-color: $brand-green-mid;
    .option-name {
      color: white;
    }
  }

  &.--disabled {
    cursor: not-allowed;
    .option-image {
      img {
        filter: grayscale(100%);
      }
    }
    .option-name {
      color: map-get($text-colors, 4);
    }
  }
}

.option-image {
  img {
    height: 160px;

    @include for-phone-only {
      height: 80px;
    }

    @include for-laptops-only {
      height: 120px;
    }
  }
}

.option-name-wrapper {
  margin-top: 50px;
  font-size: 18px;
  @include for-phone-only {
    font-size: 14px;
    margin-top: 20px;
  }

  @include for-laptops-only {
    margin-top: 20px;
  }
}

.tick-icon {
  position: absolute;
  left: 30px;
  top: 30px;
  display: none;
  img {
    width: 32px;
    height: 32px;
  }

  @include for-phone-only {
    left: 10px;
    top: 10px;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
</style>