<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">
        {{ $t("settings.thaipost.label") }}
      </h4>
    </div>
    <div class="mb-4"></div>

    <Card grey>
      <div slot="body">
        <div v-if="loading">
          <Spinner size="sm" />
        </div>

        <div v-else>
          <div v-if="filled">
            <table class="table table-sm">
              <tr>
                <td>{{ $t("settings.thaipost.username") }}:</td>
                <td>{{ thaipostInfo.username }}</td>
              </tr>
              <tr>
                <td>{{ $t("settings.thaipost.password") }}:</td>
                <td>{{ password }}</td>
              </tr>
              <tr>
                <td>{{ $t("settings.thaipost.license_no") }}:</td>
                <td>{{ thaipostInfo.licenseNo }}</td>
              </tr>
              <tr>
                <td>{{ $t("settings.thaipost.prefix") }}:</td>
                <td>{{ thaipostInfo.prefix }}</td>
              </tr>
              <tr>
                <td>{{ $t("settings.thaipost.prefix_type") }}:</td>
                <td>{{ thaipostInfo.prefixType }}</td>
              </tr>
            </table>
          </div>

          <ValidationObserver v-slot="{ invalid }" v-else-if="thaipostForm">
            <form @submit.prevent="handleFormSubmit">
              <div class="row">
                <div class="col-6">
                  <TextBox
                    type="text"
                    id="username"
                    :label="$t('settings.thaipost.username')"
                    v-model="thaipostForm.username"
                    rules="required"
                  />
                </div>
                <div class="col-6">
                  <TextBox
                    type="password"
                    id="password"
                    :label="$t('settings.thaipost.password')"
                    v-model="thaipostForm.password"
                    rules="required"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <TextBox
                    type="text"
                    id="licenseNo"
                    :label="$t('settings.thaipost.license_no')"
                    v-model="thaipostForm.licenseNo"
                    rules="required"
                  />
                </div>

                <div class="col-6">
                  <TextBox
                    type="text"
                    id="prefix"
                    :label="$t('settings.thaipost.prefix')"
                    v-model="thaipostForm.prefix"
                    rules="required"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <label>{{ $t("settings.thaipost.prefix_type") }}</label>
                  <br />
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="E"
                      value="E"
                      class="form-check-input"
                      v-model="thaipostForm.prefixType"
                    />
                    <label class="form-check-label" for="E">E(EMS)</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="O"
                      value="O"
                      class="form-check-input"
                      v-model="thaipostForm.prefixType"
                    />
                    <label class="form-check-label" for="O">O(ECO-POST)</label>
                  </div>
                </div>
              </div>
              <hr />

              <div class="text-center">
                <Button
                  id="emsThaiPostFormSubmitBtn"
                  variant="success"
                  :loading="formLoading"
                  :disabled="formLoading || invalid"
                >
                  {{ $t("general.update") }}
                </Button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Toggle from "@/components/core/Toggle";
import Spinner from "@/components/core/Spinner";
import { i18n } from "@/i18n";
import { Toast } from "@/helpers/toastr";
import TextBox from "@/components/core/TextBox";
import Button from "@/components/core/Button";

export default {
  name: "SellerSettingsThaipost",

  components: {
    Card,
    Toggle,
    Spinner,
    TextBox,
    Button,
  },

  data() {
    return {
      loading: false,
      thaipostInfo: null,
      thaipostForm: null,
      hasBoxSize: false,
      formLoading: false,
    };
  },

  created() {
    this.fetchThaipostInfo(() => {
      if (!this.thaipostInfo) this.initForm();
    });
  },

  mounted() {},

  methods: {
    fetchThaipostInfo(callback) {
      this.loading = true;
      this.$store.dispatch("settings/fetchThaipostInfo", {
        callback: (status, data) => {
          if (status) this.thaipostInfo = data;
          this.loading = false;
          if (callback) callback();
        },
      });
    },

    initForm() {
      this.thaipostForm = {
        username: null,
        password: null,
        licenseNo: null,
        prefix: null,
        boxSizes: null,
        weightPriceConfig: null,
        prefixType: null,
      };
    },

    handleFormSubmit() {
      this.formLoading = true;
      this.$store.dispatch("settings/updateThaipostInfo", {
        data: this.thaipostForm,
        callback: (status, data) => {
          if (status) {
            this.fetchThaipostInfo(() => {
              if (!this.thaipostInfo) this.initForm();
            });
          }
          this.formLoading = false;
        },
      });
    },
  },

  computed: {
    filled() {
      return this.thaipostInfo;
    },

    password() {
      return this.thaipostInfo.password.split("").map((m) => "*").join("");
    },
  },
};
</script>

