<template>
  <div>
    <div v-if="loading" class="pt-5">
      <Spinner size="md" />
    </div>
    <div v-else>
      <div class="content-header">
        <div class="container-fluid">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="font-weight-light">
                {{ $t("general.view") }}
                <span class="font-weight-bold">{{
                  $t("products.products_label")
                }}</span>
              </h4>
            </div>
            <div class="buttons">
              <button
                v-if="this.generalSettings && this.generalSettings.inventoryMgt"
                class="btn btn-outline-success btn-sm mr-2"
                data-toggle="modal"
                data-target="#manage-stock"
              >
                {{ $t("products.show.buttons.manage_stock") }}
              </button>
              <router-link
                :to="{
                  name: 'Seller-Product-Edit',
                  params: { id: $route.params.id },
                }"
                class="btn btn-outline-primary btn-sm mr-2"
                >{{ $t("general.edit") }}</router-link
              >
              <button
                class="btn btn-outline-danger btn-sm mr-2"
                @click="deleteProduct()"
              >
                {{ $t("general.delete") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <section class="content">
        <div class="row">
          <div class="col-md-7">
            <Card>
              <div slot="body">
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="header-left">
                    <span class="text-color-4 mr-3"
                      >SKU: {{ productDetail.details.itemCode }}</span
                    >
                    <br />
                    <span class="text-color-4"
                      >{{ $t("products.show.detail_card.code") }}:
                      {{ productDetail.details.proshipCode }}</span
                    >
                    <br />
                    <h4 class="text-color-2 mb-1">
                      {{ productDetail.details.name }}
                    </h4>
                    <span class="text-color-4">
                      {{ $t("general.available") }}
                      <span v-html="status"></span>
                    </span>
                  </div>
                  <div class="header-right">
                    <div class="product-image-section d-flex">
                      <img
                        v-for="(image, index) in productDetail.details.images"
                        :src="image"
                        :key="index"
                      />
                    </div>
                    <span
                      v-if="productDetail.details.weightApprox"
                      class="weight text-color-4 mr-3 mt-2"
                      >{{ $t("products.show.detail_card.weight") }}:
                      {{ productDetail.details.weightApprox }}kg</span
                    >

                    <div></div>
                    <span
                      v-if="productDetail.details.quantity"
                      class="weight text-color-4 mr-1"
                      >{{ $t("general.quantity") }}:
                      {{ productDetail.details.quantity }}</span
                    >
                  </div>
                </div>
                <hr />
                <div v-if="productDetail.details.variationData">
                  <p class="text-color-3">
                    {{ $t("products.show.product_variations.label") }}
                  </p>
                  <p
                    class="text-color-4"
                    v-for="(value, key) in productDetail.details.variationData"
                    :key="key"
                  >
                    {{ key }}: {{ value }}
                  </p>
                </div>

                <p class="text-color-4">
                  {{ productDetail.details.description }}
                </p>
                <br />
                <div class="row">
                  <div
                    v-if="
                      productDetail.details.cost ||
                      productDetail.details.salePrice
                    "
                    class="col-6"
                  >
                    <p class="text-color-3">
                      {{ $t("products.show.detail_card.price") }}
                    </p>
                    <div class="row">
                      <div class="col-6">
                        <p class="text-color-5">
                          {{ $t("products.show.detail_card.cost") }}
                        </p>
                        <p class="text-color-5">
                          {{ $t("products.show.detail_card.sale_price") }}
                        </p>
                      </div>
                      <div class="col-6 pl-0" v-if="isSeller">
                        <p class="text-color-4">
                          {{ productDetail.details.cost }} THB
                        </p>
                        <p class="text-color-4">
                          {{ productDetail.details.salePrice }} THB
                        </p>
                      </div>
                    </div>
                    <br />

                    <!-- <div v-if="productDetail.details.variations.variations">
                      <p class="text-color-3 mb-1">Product Variations</p>
                      <div class="row">
                        <div class="col-4 pr-0"></div>
                        <div class="col-4 pr-0">
                          <p class="text-color-5">Options</p>
                        </div>
                        <div class="col-4 pr-0">
                          <p class="text-color-5">Quantity</p>
                        </div>
                      </div>

                      <div
                        class="row"
                        v-for="(variation, index) in productDetail.details.variations.variations"
                        :key="index"
                      >
                        <div class="col-4 pr-0">
                          <p class="text-color-5">{{variation.name}}</p>
                        </div>
                        <div class="col-8 pr-0">
                          <div
                            class="row"
                            v-for="(option, index) in variation.options"
                            :key="index"
                          >
                            <div class="col-6 pr-0">
                              <p class="text-color-4">{{option.name}}</p>
                            </div>
                            <div class="col-6 pr-0">
                              <p class="text-color-4 body-tex text-left">{{option.quantity}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>-->
                  </div>
                  <div class="col-6 pr-0">
                    <div v-if="productDetail.details.bundles">
                      <p class="text-color-3 mb-1">Bundle Prices</p>

                      <!-- Bundle price heading -->
                      <div class="row">
                        <div class="col-6 pr-0">
                          <p class="text-color-5">Amount</p>
                        </div>
                        <div class="col-6">
                          <p class="text-color-5">Price</p>
                        </div>
                      </div>

                      <!-- Bundle price values -->
                      <div
                        class="row"
                        v-for="(bundle, index) in productDetail.details.bundles"
                        :key="index"
                      >
                        <div class="col-6 pr-0">
                          <p class="text-color-4">{{ bundle.amount }}</p>
                        </div>
                        <div class="col-6">
                          <p class="text-color-4">{{ bundle.price }} THB</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <!-- Variation Table -->
                    <!-- <div v-if="variations">
                      <p class="text-color-3">Product Variations</p>
                      <table class="table table-bordered">
                        <thead>
                          <th
                            v-for="variation in variations"
                            :key="variation.name"
                          >{{variation.name}}</th>
                          <th>SKU</th>
                        </thead>
                        <tbody v-if="variations.length === 1">
                          <tr
                            v-for="variationOption in variations[0].options"
                            :key="variationOption.name"
                          >
                            <td class="vertical-align: center">{{variationOption.name}}</td>
                            <td class="vertical-align: center">{{variationOption.sku}}</td>
                          </tr>
                        </tbody>
                        <tbody v-if="variations.length === 2">
                          <tr
                            v-for="variationOption in variations[0].options"
                            :key="variationOption.name"
                          >
                            <td class="vertical-align: center">{{variationOption.name}}</td>
                            <td class="p-0">
                              <p
                                class="p-2"
                                style="border-bottom: 1px solid #dee2e6"
                                v-for="childVariation in variationOption.variations"
                                :key="childVariation.name"
                              >{{childVariation.name}}</p>
                            </td>
                            <td class="p-0">
                              <p
                                class="p-2"
                                style="border-bottom: 1px solid #dee2e6"
                                v-for="childVariation in variationOption.variations"
                                :key="childVariation.sku"
                              >{{childVariation.sku}}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div class="col-md-5">
            <Card>
              <div slot="body">
                <product-history
                  v-if="productDetail.details.histories"
                  :histories="productDetail.details.histories"
                />

                <div class="alert alert-warning" role="alert" v-else>
                  <i
                    class="fa fa-exclamation-triangle mr-2"
                    aria-hidden="true"
                  ></i>
                  <span class="shipping-warning text-color-4">{{
                    $t("products.show.history.no_sales_history")
                  }}</span>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </section>
    </div>

    <Modal
      id="manage-stock"
      ref="manage_inventory"
      custom_dialog_class="modal-dialog-centered"
    >
      <template slot="body">
        <div>
          <div class="d-flex justify-content-start">
            <div class="ml-2">
              <img src="@/assets/images/warehouse.png" />
            </div>
            <div class="ml-2 d-flex align-items-end">
              <h5 class="text-color-4">
                {{ $t("products.show.stock.manage_stock") }}
              </h5>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-12">
              <NumberField
                id="total"
                :label="$t('general.quantity')"
                rules="required"
                v-model.number="manageStockForm.quantity"
                value="manageStockForm.quantity"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <Button
              id="btn-manage-stock"
              @click="updateStock()"
              variant="primary"
              :loading="updateStockLoading"
              size="md"
              class="mr-3"
              >{{ $t("general.submit") }}</Button
            >
            <Button
              id="btn-manage-stock"
              @click="close()"
              variant="secondary"
              :loading="updateStockLoading"
              size="md"
              >{{ $t("general.cancel") }}</Button
            >
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import DataTable from "@/components/core/DataTable";
import Swal from "sweetalert2";
import Spinner from "@/components/core/Spinner";
import NumberField from "@/components/core/NumberField";
import Button from "@/components/core/Button";
import Modal from "@/components/core/Modal";
import Card from "@/components/core/Card";
import { Alert } from "@/helpers/alert";
import { mapGetters } from "vuex";
import { i18n } from "@/i18n";
import ProductHistory from "@/components/derived/ProductHistory";

export default {
  name: "Seller-Product-Show",
  components: {
    DataTable,
    Spinner,
    Card,
    Modal,
    Button,
    NumberField,
    ProductHistory,
  },
  data() {
    return {
      loading: true,
      updateLoading: false,
      updateStockLoading: false,
      productDetail: null,
      variations: null,
      manageStockForm: {
        quantity: null,
      },
      columns_sales_history: [
        {
          name: "sn",
          title: "#",
          dataClass: "--checkbox",
        },
        {
          name: "date",
          title: "DATE SHIPPED",
          dataClass: "--width-sm",
        },
        {
          name: "sale",
          title: "SALE PRICE",
          dataClass: "--width-sm",
          formatter(value) {
            return `$${value}`;
          },
        },
      ],
      rows_sales_history: [
        // { sn: "2", date: "13th March, 2020", sale: "200" },
      ],
    };
  },

  created() {
    this.fetchProduct();
  },

  methods: {
    cancel() {
      this.$router.push({ name: "Seller-Product-List" });
    },

    close() {
      this.stock = this.productDetail.details.quantity.stock;
      this.remaining = this.productDetail.details.quantity.remaining;
      $("#manage-stock").modal("hide");
    },

    updateStock() {
      this.updateStockLoading = true;

      let prodDetails = JSON.parse(JSON.stringify(this.productDetail));
      prodDetails.details.quantity = this.manageStockForm.quantity;

      this.$store.dispatch("products/updateProduct", {
        id: prodDetails.id,
        product: prodDetails.details,
        callback: (status, data) => {
          this.updateStockLoading = false;
          $("#manage-stock").modal("hide");
          this.fetchProduct(false);
        },
      });
    },

    fetchProduct(openStockDialog = true) {
      this.loading = true;
      this.$store.dispatch("products/fetchProductDetail", {
        id: this.$route.params.id,
        callback: (status, data) => {
          if (status) {
            this.productDetail = data;
            this.manageStockForm.quantity = data.details.quantity;
            if (openStockDialog && this.$route.params.manageInventory)
              $("#manage-stock").modal("show");
          }
          this.loading = false;
        },
      });
    },
    deleteProduct() {
      Alert(
        i18n.t("general.delete"),
        i18n.t("alert.delete.message"),
        null,
        () => {
          this.$store.dispatch("products/deleteProduct", {
            id: this.$route.params.id,
            callback: (status) => {
              if (status) this.$router.push({ name: "Seller-Product-List" });
            },
          });
        }
      );
    },
    exportSalesHistory() {
      Swal.fire("Export file", "File will download in a while", "info").then(
        () => {}
      );
    },

    exportProductHistory() {
      Swal.fire("Export file", "File will download in a while", "info").then(
        () => {}
      );
    },
  },

  computed: {
    status() {
      if (this.productDetail.details.available)
        return `<span class="badge badge-pill pr-2 pl-2 badge-success">${i18n.t(
          "general.yes"
        )}</span>`;
      else
        return `<span class="badge badge-pill pr-2 pl-2 badge-danger">${i18n.t(
          "general.no"
        )}</span>`;
    },
    ...mapGetters({ generalSettings: "settings/generalSettings" }),
    ...mapGetters("user", ["authData"]),

    isSeller() {
      if (this.authData && this.authData.user && this.authData.user.role)
        return this.authData.user.role === "seller";
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin: 20px;
  .product-image-section {
    img {
      height: 50px;
      width: auto;
    }
  }
}
</style>