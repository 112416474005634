<template>
  <div>
    <div v-if="rows">
      <slot name="top"></slot>
      <div class="row mb-2">
        
        <div class="col-md-8">
          <h5 class="text-color-4 header-text">{{$t("reports.balance_history.title")}}</h5>
          <!-- <input type="text" class="form-control search-bar" placeholder="Search Keywords..." />
          <i class="fa fa-search text-color-5" aria-hidden="true"></i> -->
          <br>
          <p class="text-color-5">{{$t("reports.balance_history.total")}}: THB. {{total}}</p>
        </div>
        <div class="col-md-4 d-none d-sm-block">
          <div class="image-section">
            <img src="@/assets/images/reports/flat.svg" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <DataTable
            :id="id"
            :columns="columns"
            :rows="rows"
            :per_page="5"
            responsive
            custom_class="reports-table"
          ></DataTable>
        </div>
      </div>
    </div>
    <div v-else>
      <Spinner :variant="color_scheme" size="lg" />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Swal from "sweetalert2";
import { Alert } from "@/helpers/alert";
import Spinner from "@/components/core/Spinner";

export default {
  name: "ProductsTable",
  components: { DataTable, Modal, vueDropzone: vue2Dropzone, Button, Spinner },
  props: {
    id: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: null,
      required: true
    },
    create_path_name: {
      type: String
    },
    detail_path_name: {
      type: String
    },
    edit_path_name: {
      type: String
    },
    color_scheme: {
      type: String
    },
    sample_file_link: {
      type: String
    },
    total:{
      type:Number
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.row {
  position: relative;
  h4 {
    position: absolute;
    top: 20px;
    left: 15px;
    @include for-phone-only {
      position: static;
      margin-left: 15px;
      margin-bottom: 10px;
    }
  }
  i {
    position: absolute;
    right: 30px;
    top: 12px;
  }
}
.image-section {
  height: 80x;
  width: 80px;
}
</style>