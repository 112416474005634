<template>
  <div class="details">
    <OnboardHeader
      :title="$t('onboard.details.title')"
      :subtitle="$t('onboard.details.subtitle')"
    >
      <img
        src="@/assets/images/onboard/shop.svg"
        class="onboard-icon mt-2"
        slot="img"
      />
    </OnboardHeader>

    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="handleFormSubmit">
        <!-- Shop Name && Phone Number -->
        <div class="onboard-form-wrapper">
          <div class="onboard-form">
            <div class="input-row">
              <TextBox
                type="text"
                size="sm"
                id="shop-name"
                placeholder="My Awesome Store"
                rules="required"
                v-model="shopForm.name"
                :label="$t('onboard.details.shop_name.label')"
              />
            </div>

            <!-- Shipping Address && Zip Code -->
            <div class="input-row">
              <PhoneNumber
                size="sm"
                id="shop-phone"
                placeholder="0 1213 12322"
                rules="required"
                v-model="shopForm.phoneNo"
                :label="$t('onboard.details.phone_number.label')"
              />
            </div>

            <!-- Shipping Address && Zip Code -->
            <div class="input-row">
              <SmartAddress
                id="shop-address"
                placeholder="Shop address"
                rules="required"
                :label="$t('onboard.details.shop_address.label')"
                v-model="shopForm.address"
                rows="4"
              />
            </div>
          </div>
        </div>
        <div class="button-section">
          <OnboardButtons
            :on_next="handleFormSubmit"
            :active="invalid"
            :show_secondary_button="false"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import TextBox from "@/components/core/TextBox";
import Select from "@/components/core/Select";
import SmartAddress from "@/components/derived/SmartAddress";
import PhoneNumber from "@/components/derived/PhoneNumber";
import Vue from "vue";
import TextArea from "@/components/core/TextArea";
import { http } from "@/helpers/http";
import { mapGetters } from "vuex";
import OnboardButtons from "@/components/derived/onboard/OnboardButtons";
import OnboardHeader from "@/components/derived/onboard/OnboardHeader";

export default {
  name: "Onboard-Details",
  components: {
    TextBox,
    Select,
    Card,
    PhoneNumber,
    TextArea,
    OnboardButtons,
    OnboardHeader,
    SmartAddress,
  },
  data() {
    return {
      shopForm: {
        name: null,
        phoneNo: null,
        address: null,
        shippingMethods: [],
        paymentMethods: [],
      },
    };
  },

  created() {
    if (this.registrationData) {
      this.shopForm.name = this.registrationData.username;
      this.shopForm.phoneNo = this.registrationData.phoneNo;
    }
  },

  methods: {
    handleFormSubmit() {
      this.$store.dispatch("onboard/storeDetails", {
        details: this.shopForm,
        callback: (data) => {
          this.$router.push({ name: "Onboard-Shipping" });
        },
      });
    },
  },

  computed: {
    ...mapGetters("user", ["registrationData"]),
  },
};
</script>

<style lang="scss">
.onboard-form-wrapper {
  max-width: 444px;
  margin: 0 auto;
  @include for-desktops-and-up {
    min-height: 405px;
  }

  @include for-laptops-only {
    min-height: 290px;
  }

  display: flex;
  align-items: center;

  &.--no-mw {
    max-width: none;
  }

  @include for-laptops-only {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.onboard-form {
  width: 100%;
  height: 100%;
}

.input-row {
  margin-bottom: toRem(10px);
  margin-left: 0;
  margin-right: 0;
  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

.details {
  height: 100%;
}

.go-back-button {
  margin-right: 24px;
}

.button-section {
  margin: 10px;
}
</style>