var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product_history"},[_c('div',{staticClass:"toolbar d-flex justify-content-between mb-2"},[_c('h5',{staticClass:"text-color-4"},[_vm._v(" "+_vm._s(_vm.$t("products.show.history.sales"))+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"width":"15rem"}},[_c('DatePicker',{attrs:{"id":"date-range","custom_class":"align-self-center mr-2","custom_input_class":"py-0 pl-2 text-xs","placeholder":'เลือกวันที่เริ่ม'},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('DatePicker',{attrs:{"id":"date-range","custom_class":"align-self-center","custom_input_class":"py-0 pl-2 text-xs","placeholder":'เลือกวันที่สิ้นสุด'},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),(_vm.dateFrom || _vm.dateTo)?_c('Button',{attrs:{"id":"btn-clear-product-history-dates","custom_class":"p-0","type":"button","size":"sm"},on:{"click":function($event){return _vm.clearFilter()}}},[_c('i',{staticClass:"fal fa-times-circle ml-2"})]):_vm._e()],1)]),_c('table',{staticClass:"_datatable table --small-height"},[_c('thead',[_c('th',[_vm._v(" "+_vm._s(_vm.$t("shipment.view.contact_information.agent"))+" ")]),_c('th',[_vm._v("จำนวน")]),_c('th',[_vm._v(_vm._s(_vm.$t("general.date")))]),_c('th',[_vm._v("การจัดส่ง")])]),_c('tbody',_vm._l((_vm.filteredHistories),function(history,index){return _c('tr',{key:index},[_c('td',{staticClass:"--width-sm"},[_vm._v(_vm._s(history.user))]),_c('td',{staticClass:"--checkbox"},[_c('span',{class:{
              'text-success': history.mode == '+',
              'text-danger': history.mode == '-',
            }},[_vm._v(" "+_vm._s(history.mode)+" "+_vm._s(history.qty)+" ")])]),_c('td',[_c('span',{staticClass:"--text-vsm"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(history.timestamp))+" ")])]),_c('td',{staticClass:"--width-md"},[_c('router-link',{attrs:{"to":{
              name: 'Seller-Order-Detail',
              params: { id: history.orderId },
            }}},[_c('i',{staticClass:"nav-icon fal fa-truck-loading"})])],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }