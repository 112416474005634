<template>
  <div :class="'form-group ' + fg_wrapper_class">
    <label v-if="label" :for="id">{{label}}</label>

    <validation-provider :rules="rules" v-slot="{ errors }">
      <div class="input-wrapper" :class="wrapper_class">
        <input
          type="number"
          ref="input"
          class="form-control"
          :class="{'is-invalid': errors && errors.length, 'form-control-sm': small}"
          :id="id"
          :value="value"
          :placeholder="placeholder"
          @input="updateValue()"
          @keyup="$emit('keyup', $event)"
          :autocomplete="autocomplete ? 'on' : 'off'"
          :disabled="disabled"
        />
        <div class="error" v-if="errors && !hide_error">
          <span class="text-danger text-sm" v-for="(error, index) in errors" :key="index">{{error}}</span>
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "NumberField",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    value: {
      type: Number
    },
    placeholder: {
      type: String
    },

    fg_wrapper_class: {
      type: String,
      default: ''
    },

    wrapper_class: {
      type: String
    },

    rules: {
      type: String
    },

    autocomplete: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxval: {
      type: Number
    },
    minval:{
      type:Number
    },
    small: {
      type: Boolean,
      default: false
    },

    hide_error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateValue() {
      if (this.maxval) {
        if (this.$refs.input.value.length > this.maxval)
          this.$refs.input.value = this.$refs.input.value.slice(
            0,
            this.maxval
          );
      }
      this.$emit("input", this.$refs.input.value || null);
      this.$emit("_input", this.$refs.input);
    }
  }
};
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  border-radius: 5px;
  border-width: 1px;
}
.input-wrapper {
  position: relative;
  padding-bottom: toRem(25px);
  .error {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
