<template>
  <div>
    <div class="
                          toolbar
                          d-flex
                          flex-wrap
                          justify-content-center
                          mb-2
                        ">

      <div class="d-flex flex-wrap justify-content-center justify-content-md-start">
        <div class="input-group align-self-center mr-2 mt-2 mt-md-0 mb-2 mb-md-0"
             style="width: 25rem">
          <select class="form-control"
                  v-model="search.column">
            <option value="trackingNo">{{ $t("search.tracking_no") }}</option>
            <option value="phoneNo">{{ $t("search.phone_no") }}</option>
            <option value="customerName">{{ $t("search.customer") }}</option>
            <option value="remarks">{{ $t("search.remarks") }}</option>
          </select>

          <input type="text"
                 class="form-control flex-1"
                 :placeholder="$t('search.search_keyword')"
                 v-model.trim="search.keyword"
                 style="width: 8rem"
                 @keydown.enter="searchOrders()" />
          <div class="input-group-append">
            <button class="btn btn-outline-success"
                    type="button"
                    @click="searchOrders()">
              <i class="fal fa-search"></i>
            </button>
          </div>
        </div>

        <router-link v-if="!sales_agent"
                     :to="{ name: 'Seller-Order-Quick-Create' }"
                     class="btn btn-success btn-sm"
                     :class="{
                       'mt-2 mt-md-0': selectedOrders && selectedOrders.length > 0,
                     }"
                     style="width: 8rem">
          <i class="fal fa-plus-circle mr-2"></i>
          {{ $t("form.shipment.quick_create") }}
        </router-link>
      </div>
    </div>

    <slot name="top"></slot>

    <div v-if="paginatedOrders && !loading">
      <div v-if="paginatedOrders.length"
           class="table-responsive">
        <table class="table _datatable orders-table --very-small-height">
          <thead>
            <tr>
              <th>วันที่</th>
              <th>เวลา</th>
              <th>{{ $t("general.sales_agent") }}</th>
              <th>{{ $t("shipment.table_labels.customer") }}</th>
              <th>{{ $t("general.phone") }}</th>
              <th>{{ $t("shipment.table_labels.status") }}</th>
              <th>{{ $t("shipment.table_labels.shipping") }}</th>
              <th>{{ $t("shipment.table_labels.tracking_no") }}</th>
              <th>COD</th>
              <th>{{ $t("general.actions") }}</th>
            </tr>
          </thead>
          <tbody :class="{ '--loading': miniLoading }">
            <tr v-for="(row, index) in paginatedOrders"
                :key="index">
              <td v-html="tdHtml('createdAtDate', row.createdAt)"></td>
              <td v-html="tdHtml('createdAtTime', row.createdAt)"></td>

              <td>{{ row.user }}</td>

              <td v-html="tdHtml('ellipsis', row.customerName)"
                  class="text-nowrap"></td>

              <td>{{ row.phoneNo }}</td>

              <td v-html="tdHtml('status', row.status)"
                  class="--width-md"></td>

              <td class="--width-md">
                {{ row.shippingMethod.replace("thaipost0", "eps") }}
              </td>

              <td>
                <span class="mr-1">
                  {{ row.trackingNo }}
                  <span v-if="row.trackingNo"
                        class="--text-vsm">📨</span>
                </span>

                <i class="fas fa-store text-color-3"
                   v-if="row.returnReason === 'shop'"></i>
                <i class="fas fa-user text-color-3"
                   v-else-if="row.returnReason === 'customer'"></i>
              </td>

              <td class="--width-md">
                {{ row.codAmount }}

                <i class="fas fa-check-circle text-success ml-1"
                   v-if="row.codPaid"></i>
              </td>

              <td>
                <span v-if="row.hasError"
                      class="text-danger mr-2"
                      :title="$t('warning.import_error_message')">
                  <i class="fal fa-exclamation-triangle"></i>
                </span>

                <Button variant="link"
                        size="sm"
                        custom_class="mr-2 p-0 text-color-5"
                        id="btn-action-show"
                        @click="goto('Show', row.id, true)">
                  <i class="fal fa-eye"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else-if="!paginatedOrders.length && search.keyword">
        <div class="alert alert-warning">
          ระบบค้นหาไม่พบข้อมูลที่คุณค้นหา ข้อมูลของคุณอาจผิดพลาด
          หรือระบบค้นหาอาจทำงานผิดพลาด
        </div>
      </div>
    </div>

    <div v-else>
      <div class="alert alert-info m-5">
        <p class="text-center lead">
          กรอกข้อมูลในช่องเพื่อค้นหาทุกรายการ
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Alert } from "@/helpers/alert";
import Spinner from "@/components/core/Spinner";
import Tabs from "@/components/core/Tabs";
import DropZone from "@/components/derived/DropZone";
import CopyButton from "@/components/derived/CopyButton";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getOrderStatus } from "@/helpers/core";
import { i18n } from "@/i18n";
import { printFormats } from "@/helpers/print";
import Select from "@/components/core/Select";
import { FILTERS } from "@/helpers/filters";
import { Toast, ToastFix } from "@/helpers/toastr";
import axios from "axios";
import { printLabels } from "@/helpers/print";

export default {
  name: "OrdersTableEsSuperAgent",
  components: {
    TextBox,
    DataTable,
    Modal,
    vueDropzone: vue2Dropzone,
    Button,
    Spinner,
    Tabs,
    DropZone,
    Select,
    CopyButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    create_path_name: {
      type: String,
    },
    detail_path_name: {
      type: String,
    },
    edit_path_name: {
      type: String,
    },
    color_scheme: {
      type: String,
    },
    sample_file_link: {
      type: String,
    },
    sales_agent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderRows: null,
      orderCounts: null,
      LastEvaluatedKey: null,
      paginatedOrders: null,

      loading: false,
      miniLoading: false,

      page: 1,
      perPage: 100,
      lastPage: null,
      currentTabStatus: 0,

      search: {
        column: "trackingNo",
        Keyword: null
      }
    };
  },

  created() {
    // retrieve selected tab from storage
    this.currentTabStatus = parseInt(localStorage.getItem("currentTabStatus"));
  },

  mounted() {
    document.addEventListener("print-success", (data) => {
      this.printUrl = data.detail;
      this.printLabelLoading = false;
    });
  },

  methods: {



    tdHtml(type, value) {
      const types = {
        status: (value) => {
          let statusData = getOrderStatus(value);
          if (!statusData) return value;
          return `<span class="badge badge-pill text-uppercase status-badge --${statusData.variation}">${statusData.title}</span>`;
        },

        createdAtDate: (value) => {
          return FILTERS.formatDate(value);
        },

        createdAtTime: (value) => {
          return FILTERS.formatTime(value);
        },

        ellipsis: (value) => {
          return FILTERS.ellipsis(value, 15);
        },
      };
      return types[type] ? types[type](value) : value;
    },

    paginate(forward = true) {
      if (forward) {
        this.page++;
      } else {
        this.page = Math.max(--this.page, 1);
      }

      this.fetchOrders();
    },

    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    searchOrders() {
      let searchData = {
        ...this.search,
        status: this.currentTabStatus,
        salesAgent: this.sales_agent,
        mode: "super"
      };
      this.loading = true;
      // this.handleTabChange(0);
      this.$store.dispatch("orders/searchOrders", {
        ...searchData,
        callback: (status, data) => {
          this.orderRows = data.results.map((d) => {
            return {
              id: d.id,
              trackingNo: d.trackingNo,
              status: d.status,
              shippingMethod: d.shippingMethod,
              orderCode: d.orderCode,
              customerName: d.customerName,
              phoneNo: d.phoneNo,
              user: d.user,
              createdAt: d.createdAt,
              codAmount: d.codAmount,
              codPaid: d.codPaid,
              printed: d.printCount && d.printCount > 0,
            };
          });
          this.page = 1;
          this.lastPage = null;
          this.LastEvaluatedKey = null;
          this.handlePageChange();
          this.loading = false;
        },
      });
    },

    handlePageChange() {
      // let startIndex = (this.page - 1) * this.perPage;
      // let endIndex = this.perPage * this.page - 1;

      this.paginatedOrders = this.orderRows;

      this.selectAllStatus = false;
      this.selectedOrders = [];
    },

    handlePerPageChange() {
      this.page = 1;
    },



    goto(type, id, newTab = false) {
      let routerData;
      if (type === "Show")
        routerData = { name: this.detail_path_name, params: { id } };
    },
  },

  computed: {
    ...mapGetters("orders", ["activeStatus"]),
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    username() {
      if (this.authData && this.authData.user && this.authData.user.username)
        return this.authData.user.username;
      return false;
    },

    buttonClass() {
      return `btn btn-sm btn-outline-${this.color_scheme} mr-2`;
    },

  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: toRem(1.5px) solid;
}

.printerFormatContainer {
  width: 210px;

  @include for-larger-than-phone {
    width: 420px;
  }

  margin: 0 auto;
  overflow: auto;

  .printerFormat {
    width: 200px;
    background-color: rgb(252, 252, 252);
    padding: 0.25rem;
    border-radius: 3px;
    margin: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    .icon {
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
      margin-top: 0.4rem;
    }
  }
}
</style>