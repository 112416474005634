<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">{{ $t("settings.sales_agents.label") }}</h4>

      <Button id="btn-add-sales-agent"
              variant="success"
              size="sm"
              custom_class="mr-1 align-self-center"
              data-toggle="modal"
              data-target="#salesAgentModal"
              @click="handleAddForm()">
        <i class="fal fa-plus-circle mr-1"></i>
        {{ $t("settings.sales_agents.button.add_sales_agent") }}
      </Button>
    </div>
    <div v-if="loading">
      <Spinner size="sm" />
    </div>
    <div v-else>
      <DataTable id="sales-agents-table"
                 :columns="salesAgentsColumns"
                 :rows="salesAgentsDataFormatted"
                 :per_page="5"
                 responsive>
        <div slot="actions"
             slot-scope="props">
          <Button variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-edit"
                  @click="editItem(props.props.rowData.id)"
                  data-toggle="modal"
                  data-target="#salesAgentModal">
            <i class="fal fa-edit"></i>
          </Button>
          <Button variant="link"
                  custom_class="p-0 text-color-5"
                  size="sm"
                  id="btn-action-delete"
                  @click="deleteItem(props.props.rowData.id)">
            <i class="fal fa-trash-alt"></i>
          </Button>
        </div>
        <!-- 
        <div slot="agent-slot" slot-scope="props">
          {{props.props.rowData.name}}
          <br />
          <span class="text-color-5 --text-sm">{{props.props.rowData.email}}</span>
        </div>-->
      </DataTable>
    </div>

    <Modal id="salesAgentModal">
      <template slot="header">
        <h5 class="modal-title"
            id="addSalesAgentLabel">
          <i class="fas fa-users mr-2"></i>
          <span v-if="formMode === 0">{{
            $t("settings.sales_agents.button.add_sales_agent")
          }}</span>
          <span v-if="formMode === 1">{{
            $t("settings.sales_agents.button.update_sales_agent")
          }}</span>
        </h5>
      </template>

      <template slot="body">
        <div v-if="loading">
          <Spinner size="sm" />
        </div>
        <div v-else>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="handleFormSubmit">
              <!-- Name & Email -->
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextBox type="text"
                           id="create-name"
                           :label="$t('general.name')"
                           v-model="salesAgentForm.username"
                           rules="required" />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextBox type="email"
                           id="create-email"
                           :label="$t('general.email')"
                           v-model="salesAgentForm.email"
                           rules="required" />
                </div>
              </div>

              <!-- Phone Line Id -->
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <PhoneNumber :label="$t('general.phone')"
                               placeholder="Phone Number"
                               id="phone-number"
                               rules="required"
                               v-model="salesAgentForm.phoneNo" />
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextBox type="text"
                           id="create-line-id"
                           :label="$t('settings.sales_agents.line_id')"
                           v-model="salesAgentForm.lineId" />
                </div>
              </div>
              <TextBox type="password"
                       id="create-password"
                       :label="$t('general.password')"
                       v-model="salesAgentForm.password"
                       rules="required" />
              <!-- Id Card -->
              <div class="row">
                <div class="col">
                  <label>{{ $t("settings.sales_agents.id_card") }}</label>
                  <div v-if="salesAgentForm.idCardUrl &&
                    salesAgentForm.idCardUrl.length
                    ">
                    <a v-for="(link, index) in salesAgentForm.idCardUrl"
                       :href="link"
                       target="_black"
                       :key="index">{{ $t("settings.sales_agents.view_id_card") }}
                      {{ index + 1 }}</a>
                  </div>

                  <DropZone ref="dropzone"
                            v-model="salesAgentForm.idCardUrl"
                            :label="$t('settings.sales_agents.id_card')" />
                </div>
              </div>

              <!-- Address  -->
              <div class="row">
                <div class="col">
                  <div class="mb-2"></div>
                  <SmartAddress :label="$t('general.address.address')"
                                :placeholder="$t('general.address.address')"
                                id="create-address"
                                v-model="salesAgentForm.address"
                                rows="3"
                                :key="componentKey" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <Toggle id="manage-products-toggle"
                          :value="false"
                          variant="primary"
                          color="blue"
                          v-model="salesAgentForm.permissions.productMgt"
                          :labels="{
                            true: $t(
                              'settings.sales_agents.switches.manage_products'
                            ),
                            false: $t(
                              'settings.sales_agents.switches.manage_products'
                            ),
                          }" />
                  <br />
                  <Toggle id="manage-shipments-toggle"
                          :value="false"
                          variant="primary"
                          color="blue"
                          v-model="salesAgentForm.permissions.inventoryMgt"
                          :labels="{
                            true: $t('settings.sales_agents.switches.manage_orders'),
                            false: $t('settings.sales_agents.switches.manage_orders'),
                          }" />

                  <br />
                  <Toggle id="sales-agent-status-toggle"
                          :value="false"
                          variant="primary"
                          color="blue"
                          v-model="salesAgentForm.status"
                          :labels="{
                            true: 'Active',
                            false: 'Inactive',
                          }" />
                </div>
              </div>
              <!-- Submit & Cancel Buttons -->
              <div class="row">
                <div class="col text-center">
                  <div class="d-flex mt-4 justify-content-center">
                    <Button id="btn-sales-agent-add-submit"
                            custom_class="pl-4 pr-4"
                            type="submit"
                            variant="primary"
                            class="btn-block"
                            size="md"
                            :disabled="invalid"
                            :loading="formLoading">
                      <span v-if="formMode === 0">{{
                        $t("settings.sales_agents.button.add_sales_agent")
                      }}</span>
                      <span v-if="formMode === 1">{{
                        $t("settings.sales_agents.button.update_sales_agent")
                      }}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import DataTable from "@/components/core/DataTable";
import TextBox from "@/components/core/TextBox";
import Toggle from "@/components/core/Toggle";
import TextArea from "@/components/core/TextArea";
import NumberField from "@/components/core/NumberField";
import FileUpload from "@/components/core/FileUpload";
import Select from "@/components/core/Select";
import RadioGroup from "@/components/core/RadioGroup";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import RowControls from "@/components/core/RowControls";
import ProductVariation from "@/components/derived/ProductVariation";
import Modal from "@/components/core/Modal";
import Spinner from "@/components/core/Spinner";
import PhoneNumber from "@/components/derived/PhoneNumber";
import DropZone from "@/components/derived/DropZone";
import SmartAddress from "@/components/derived/SmartAddress";
import { Alert } from "@/helpers/alert";
import { FILTERS } from "@/helpers/filters";
import { i18n } from "@/i18n";
import Swal from "sweetalert2";

export default {
  name: "SellerSettingsSalesAgents",
  components: {
    Card,
    DataTable,
    Button,
    TextBox,
    NumberField,
    TextArea,
    FileUpload,
    Select,
    Toggle,
    CheckBoxGroup,
    RadioGroup,
    Modal,
    Spinner,
    PhoneNumber,
    SmartAddress,
    DropZone,
  },
  data() {
    return {
      componentKey: 0,
      loading: false,
      salesAgentForm: null,
      formLoading: false,
      activeId: null,
      salesAgentsData: [],
      formMode: 0, //0: create, 1: edit

      alertInstance: null,
      deleteButtonTimer: null,
      deleteButtonTime: 0
    };
  },
  created() {
    this.fetchSalesAgents();
    this.initForm();
  },

  methods: {
    initForm() {
      this.salesAgentForm = {
        username: null,
        email: null,
        phoneNo: null,
        lineId: null,
        password: null,
        idCardUrl: null,
        address: null,
        permissions: {
          productMgt: false,
          inventoryMgt: false,
        },
        status: true,
      };
    },
    handleFormSubmit() {
      if (this.formMode === 0) {
        this.formLoading = true;
        this.$store.dispatch("salesAgent/createSalesAgent", {
          salesAgent: {
            ...this.salesAgentForm,
            userId: null, //later updated from server
            idCardUrl: this.salesAgentForm.idCardUrl
              ? this.salesAgentForm.idCardUrl[0]
              : null,
          },
          callback: (status) => {
            if (status) {
              this.fetchSalesAgents();
              $("#salesAgentModal").modal("hide");
            }
            this.formLoading = false;
          },
        });
      } else if (this.formMode === 1) {
        this.formLoading = true;

        this.$store.dispatch("salesAgent/updateSalesAgent", {
          id: this.activeId,
          data: {
            ...this.salesAgentForm,
            idCardUrl: this.salesAgentForm.idCardUrl
              ? this.salesAgentForm.idCardUrl[0]
              : null,
          },
          callback: () => {
            $("#salesAgentModal").modal("hide");
            this.fetchSalesAgents();
            this.formLoading = false;
          },
        });
      }
    },
    handleAddForm() {
      this.componentKey += 1;
      this.formMode = 0;
      this.initForm();
    },
    editItem(id) {
      this.initForm();
      this.loading = true;
      this.activeId = id;
      this.formMode = 1;
      this.$store.dispatch("salesAgent/fetchSalesAgentDetail", {
        id: id,
        callback: (status, data) => {
          if (status) {
            this.salesAgentForm = JSON.parse(JSON.stringify(data.details));
            if (this.salesAgentForm.idCardUrl)
              this.salesAgentForm.idCardUrl = [this.salesAgentForm.idCardUrl];
          }
          this.loading = false;
        },
      });
    },
    deleteItem(id) {
      let deleteMsg = "คุณต้องการลบแอดมินท่านนี้ใช่หรือไม่ ข้อมูลทั้งหมดจะถูกลบ หากต้องการกู้คืน จะมีค่าใช้จ่าย 50,000บาท";

      this.alertInstance = Swal.fire({
        showConfirmButton: false,
        html: `
        <h3> Delete </h3>

        <p class="lead mt-5 mb-5"> ${deleteMsg} </p>

        <div class="d-flex justify-content-center">
            <button class="btn btn-default mr-4" id="delete-button-no"> ยกเลิก </button>
            <button class="btn btn-primary" id="delete-button-yes" disabled> ใช่ (10) </button>
        </div>
       `,
        onOpen: () => {
          const btnYes = document.getElementById("delete-button-yes")
          const btnNo = document.getElementById("delete-button-no")

          btnNo.addEventListener('click', () => {
            this.alertInstance.close()
            clearInterval(this.deleteButtonTimer);
          })
          btnYes.addEventListener('click', () => {
            this.$store.dispatch("salesAgent/deleteSalesAgent", {
              id: id,
              callback: (status) => {
                this.fetchSalesAgents();
                this.alertInstance.close()
                clearInterval(this.deleteButtonTimer);
              },
            });
          })

          this.deleteButtonTime = 10;
          this.deleteButtonTimer = setInterval(() => {
            console.log("Timer running")
            this.deleteButtonTime--;
            btnYes.innerHTML = `ใช่ (${this.deleteButtonTime})`
            if (this.deleteButtonTime <= 0) {
              clearInterval(this.deleteButtonTimer);
              btnYes.innerHTML = "ใช่"
              btnYes.removeAttribute("disabled")
            }
          }, 1000);
        },

        onclose: () => {
          clearInterval(this.deleteButtonTimer);
        }
      })
    },
    fileHandle(value) {
      this.salesAgentForm.idCardUrl = value.urls[0];
    },
    fetchSalesAgents() {
      // make API Call here...
      this.loading = true;
      this.$store.dispatch("salesAgent/fetchSalesAgents", {
        query: "responseSize=all",
        callback: (status, data) => {
          if (status) this.salesAgentsData = data;
          this.loading = false;
        },
      });
      this.salesAgentsColumns = [
        {
          name: "agent",
          title: i18n.t("general.agent"),
          sortField: "agent",
        },

        {
          name: "address",
          title: i18n.t("general.address.address"),
          sortField: "address",
          formatter: (val) => {
            if (!val) return "N/A";
            return `<span title="${val}">${FILTERS.ellipsis(val, 20)}</span>`;
          },
        },

        {
          name: "phone_no",
          title: i18n.t("general.phone"),
          sortField: "phone_no",
        },

        {
          name: "line_id",
          title: i18n.t("settings.sales_agents.line_id"),
          sortField: "line_id",
          formatter: (val) => {
            if (!val) return "N/A";
            return val;
          },
        },

        {
          name: "id_card_url",
          title: i18n.t("settings.sales_agents.id_card"),
          formatter: (value) => {
            if (!value) return "N/A";
            return `<a href="${value}" target="_blank">${FILTERS.ellipsis(
              value,
              10
            )}</a>`;
          },
        },

        { name: "actions", title: i18n.t("general.actions") },
      ];
    },
  },

  computed: {
    salesAgentsDataFormatted() {
      if (this.salesAgentsData.length > 0) {
        return this.salesAgentsData.map((salesAgent) => {
          return {
            id: salesAgent.id,
            agent: `${salesAgent.details.username}<br><span class="text-color-5 --text-sm">${salesAgent.details.email}</span>`,
            address: salesAgent.details.address
              ? salesAgent.details.address.address
              : "N/A"
                .split(",")
                .map((a, i) =>
                  i > 0
                    ? `<span class="text-color-5 --text-sm">${a}</span>`
                    : a
                )
                .join("<br>"),
            phone_no: salesAgent.details.phoneNo,
            line_id: salesAgent.details.lineId,
            id_card_url: salesAgent.details.idCardUrl,
          };
        });
      } else return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.setting__header {
  font-weight: bold;
  font-size: 18px;
}

.setting__description {
  font-weight: thin;
  font-size: 13px;
}

.setting {
  &.--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.--table {
    .setting__text {
      margin-bottom: 20px;
    }
  }

  margin-bottom: 30px;
}

.settings-wrapper {
  margin-top: 24px;
}

.setting-status-tag {
  text-transform: uppercase;

  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
  width: 68px;
  height: 29px;

  &.--warning {
    background-color: #ffc107;
    color: black;
  }

  &.--success {
    background-color: #28a745;
    color: white;
  }

  &.--danger {
    background-color: #dc3545;
    color: white;
  }

  &.--info {
    background-color: #007bff;
    color: white;
  }
}
</style>