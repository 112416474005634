<template>
  <div v-bind:class="parentClass">
    <label v-if="label" :for="id">{{label}}</label>
    <validation-provider :rules="rules" v-slot="{ errors }" :immediate="true">
      <div class="input-wrapper" :class="input_wrapper_class">
        <input
          v-on:keypress="limitKeypress(value, maxval)"
          :type="type"
          ref="input"
          class="form-control"
          :class="{'is-invalid': errors && errors.length, 'form-control-sm': small}"
          :id="id"
          :placeholder="placeholder"
          :value="value"
          @input="updateValue()"
          @change="handleChange()"
          :disabled="disabled"
        />
        <div class="error pt-1" v-if="errors.length && !hide_error">
          <span class="text-danger text-xs" v-for="(error, index) in errors" :key="index">{{error}}</span>
        </div>
      </div>
    </validation-provider>
  </div>
</template>

<script>
export default {
  name: "TextBox",
  props: {
    id: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    },
    maxval: {
      type: Number
    },
    value: {
      type: String
    },
    derived: {
      type: Boolean
    },
    label: {
      type: String
    },

    placeholder: {
      type: String
    },

    rules: {
      type: String
    },

    wrapper_class: {
      type: String
    },

    input_wrapper_class: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    hide_error: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateValue() {
      this.$emit("input", this.$refs.input.value);
    },

    handleChange() {
      this.$emit("change", this.$refs.input.value);
    },

    limitKeypress(value, maxLength) {
      if (value != undefined && value.toString().length >= maxLength) {
        event.preventDefault();
      }
    }
  },

  computed: {
    parentClass() {
      let classObj = { "form-group": !this.derived };
      classObj[this.wrapper_class] = true;
      return classObj;
    }
  }
};
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="password"] {
  letter-spacing: 1px;
}

input {
  border-radius: toRem(5px);
  border-width: toRem(1.5px);
}
.input-wrapper {
  position: relative;
  padding-bottom: toRem(25px);
  .error {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>