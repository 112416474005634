<template>
  <header class="onboard-header">
    <div class="onboard-icon-wrapper">
      <slot name="img"></slot>
    </div>
    <h4 class="text-center header-text">{{title}}</h4>
    <p class="subtitle text-center body-text">{{subtitle}}</p>
  </header>
</template>

<script>
export default {
  name: "OnboardHeader",
  props: {
    icon_url: String,
    title: String,
    subtitle: String
  }
};
</script>

<style scoped lang="scss">
.onboard-header {
  width: 100%;
  margin-top: toRem(40px);
  color: map-get($text-colors, 4);
  font-weight: bold;
  padding: 0;

  @include for-larger-than-phone {
    padding-top: 10px;
  }

  .subtitle {
    font-size: 18px;
    color: map-get($text-colors, 4);
    margin-bottom: 40px;
    font-weight: normal;

    @include for-phone-only {
      font-size: 14px;
      margin-top: 12px;
    }

    @include for-laptops-only {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.onboard-icon-wrapper {
  text-align: center;
  margin-bottom: 24px;
  @include for-laptops-only {
    margin-bottom: 10px;
  }
}

.onboard-icon {
  height: 40px;
}
</style>