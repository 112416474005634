<template>
  <div class="otp-wrapper">
    <form autocomplete="off" class="w-100 d-flex justify-content-between">
      <NumberField
        id="phone-textbox-0"
        class="otp-field ml-1 mr-1"
        @keyup="movetoNext"
        :maxval="1"
        v-model.number="otp[0]"
      />
      <NumberField
        id="phone-textbox-1"
        class="otp-field ml-1 mr-1"
        @keyup="movetoNext"
        :maxval="1"
        v-model.number="otp[1]"
      />
      <NumberField
        id="phone-textbox-2"
        class="otp-field ml-1 mr-1"
        @keyup="movetoNext"
        :maxval="1"
        v-model.number="otp[2]"
      />
      <NumberField
        id="phone-textbox-3"
        class="otp-field ml-1 mr-1"
        @keyup="movetoNext"
        :maxval="1"
        v-model.number="otp[3]"
      />
      <NumberField
        id="phone-textbox-4"
        class="otp-field ml-1 mr-1"
        v-model.number="otp[4]"
        :maxval="1"
        @keyup="movetoNext"
      />
    </form>
  </div>
</template>

<script>
import NumberField from "@/components/core/NumberField";

export default {
  name: "OTPField",
  components: {
    NumberField
  },
  mounted() {},
  data() {
    return {
      otp: [null, null, null, null, null]
    };
  },
  methods: {
    movetoNext(event) {
      let value = parseInt(event.target.value);
      if (!isNaN(value)) {
        let nextFieldId =
          "phone-textbox-" +
          ((parseInt(event.target.id.split("-").pop()) + 1) % 5);
        document.getElementById(nextFieldId).focus();
      }
      let otpCode = this.otp.join("");
      if (otpCode.length === 5) this.$emit("input", otpCode);
    }
  }
};
</script>

<style scoped>
.otp-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>