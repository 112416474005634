<template>
  <div v-if="loading">
    <div class="container p-5 m-5">
      <Spinner size="md" />
    </div>
  </div>
  <div v-else
       class="orders-page">
    <div class="content-header">
      <div class="container-fluid">
        <div class="d-flex justify-content-between">
          <h4 class="font-weight-light">
            {{ $t("general.view") }}
            <span class="font-weight-bold">{{ $t("shipment.label") }}</span>
          </h4>
          <div class="buttons">
            <Button variant="outline-info"
                    size="sm"
                    custom_class="mr-2"
                    @click="runManualCron()"
                    :disabled="manualCronLoading">
              <i class="fal fa-sync"
                 :class="{ 'fa-spin': manualCronLoading }"></i>
            </Button>

            <router-link :to="{ name: 'Seller-Order-Quick-Create' }"
                         class="btn btn-outline-success btn-sm mr-2">{{ $t("shipment.view.create_another_shipment")
              }}</router-link>

            <!-- <Button
              v-if="
                orderDetails.details.status === 2 ||
                (orderDetails.details.shippingMethod === 'thaipost' &&
                  orderDetails.details.status <= 2)
              "
              variant="outline-info"
              size="sm"
              custom_class="mr-2"
              @click="printLabel()"
              :loading="printLabelLoading"
              :disabled="printLabelLoading"
              >{{ $t("shipment.buttons.print_label") }}</Button> -->

            <button v-if="orderDetails.details.status === 2 ||
    ((orderDetails.details.shippingMethod === 'thaipost' ||
      orderDetails.details.shippingMethod === 'thaipost0' ||
      orderDetails.details.shippingMethod === 'jt' ||
      orderDetails.details.shippingMethod === 'scg' ||
      orderDetails.details.shippingMethod === 'flash' ||
      orderDetails.details.shippingMethod === 'kerry') &&
      orderDetails.details.status <= 2)
    "
                    class="btn btn-outline-info btn-sm mr-2"
                    data-toggle="modal"
                    data-target="#orders-print-labels"
                    :disabled="printLabelLoading">
              <i class="fal fa-print mr-2"></i>
              {{ $t("shipment.buttons.print_label") }}
            </button>

            <router-link v-if="orderDetails.details.status <= 2 &&
    orderDetails.details.shippingMethod != 'kerry' &&
    orderDetails.details.shippingMethod != 'jt'
    "
                         :to="{
    name: 'Seller-Order-Edit',
    params: { id: $route.params.id },
  }"
                         class="btn btn-outline-primary btn-sm mr-2">{{ $t("general.edit") }}</router-link>

            <Button v-if="orderDetails.details.status != 5 &&
    orderDetails.details.status <= 2 &&
    orderDetails.details.shippingMethod != 'kerry'
    "
                    variant="outline-danger"
                    size="sm"
                    custom_class="mr-2"
                    @click="deleteOrder()"
                    :loading="cancelOrderLoading"
                    :disabled="cancelOrderLoading">{{ $t("general.cancel") }}</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <div class="customer-details">
              <Card>
                <div slot="body">
                  <div class="
                      customer-header
                      d-flex
                      flex-wrap
                      justify-content-between
                    ">
                    <div class="header-left">
                      <span class="text-color-4">#{{ orderDetails.details.orderCode }}</span>
                      <h4 class="header-text text-color-2">
                        {{ orderDetails.details.customer.name }}

                        <span class="text-success --text-sm"
                              v-if="orderDetails.details.printed">
                          <i class="fal fa-print"></i>
                        </span>
                      </h4>
                      <span class="text-color-5">{{
    orderDetails.details.customer.email
  }}</span>
                    </div>
                    <div class="header-right">
                      <button v-if="orderDetails.details.status &&
    allowedStatus.includes(orderDetails.details.status)
    "
                              :class="'status-button bg-' +
    orderStatus(orderDetails.details.status).variation +
    ' btn align-self-center mt-2'
    "
                              data-toggle="modal"
                              data-target="#orders-status">
                        <i class="fas fa-truck mr-3 ml-1"></i>
                        <span class="body-text">{{
    orderStatus(orderDetails.details.status).title
  }}</span>
                      </button>
                      <button v-else
                              :class="'status-button bg-' +
    orderStatus(orderDetails.details.status).variation +
    ' btn align-self-center mt-2'
    ">
                        <i class="fas fa-truck mr-3 ml-1"></i>
                        <span class="body-text">{{
    orderStatus(orderDetails.details.status).title
  }}</span>
                      </button>
                      <br />

                      <span class="weight text-color-4 mr-2">{{ orderDetails.details.weight }} gm</span>
                      <!-- <span
                        class="weight float-right text-color-4"
                        v-if="orderDetails.details.actualWeight !== 'N/A'"
                      >
                        <mark>{{ orderDetails.details.actualWeight }} gm</mark>
                      </span> -->
                    </div>
                  </div>
                  <p class="text-color-4 --sm">
                    {{ $t("general.created_at") }}:
                    {{ orderDetails.createdAt | formatDateTime }}
                  </p>

                  <hr class="mt-0" />
                  <div class="customer-body">
                    <h6 class="text-color-2 header-text">
                      {{ $t("shipment.view.contact_information.label") }}
                    </h6>
                    <br />
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-6">
                          <table border="0"
                                 class="w-100">
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.phone"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  <a :href="'tel:' +
    orderDetails.details.customer.phoneNo
    "
                                     v-if="orderDetails.details.customer.phoneNo">{{
    orderDetails.details.customer.phoneNo
  }}</a>
                                  <span v-else>"N/A"</span>
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
      $t(
        "shipment.view.contact_information.mobile"
      )
    }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  <a :href="'tel:' +
    orderDetails.details.customer.mobileNo
    "
                                     v-if="orderDetails.details.customer.mobileNo
    ">{{
    orderDetails.details.customer.mobileNo
  }}</a>
                                  <span v-else>"N/A"</span>
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("form.shipment.customer_fb_line") }}
                                  <span v-if="orderDetails.details.customer
    .showSocialLinkOnLabel
    ">
                                    <i class="fal fa-print text-success ml-1"></i>
                                  </span>
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
    orderDetails.details.customer.socialLink ||
    "N/A"
  }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("form.shipment.sales_channel") }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
    orderDetails.details.customer
      .salesChannel || "N/A"
  }}
                                </p>
                              </td>
                            </tr>

                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.province"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.customer.address
        .province || "N/A"
    }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.district"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.customer.address
        .district || "N/A"
    }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.sub_district"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.customer.address
        .subDistrict || "N/A"
    }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.postal_code"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.customer.address
        .zipcode || "N/A"
    }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left"
                                  colspan="2">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.address"
    )
  }}
                                </p>
                                <p class="text-color-4">
                                  {{
      orderDetails.details.customer.address
        .address || "N/A"
    }}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-md-6"
                             :style="{ 'overflow-x': 'auto' }">
                          <table border="0"
                                 class="w-100 mt-4 mt-md-0">
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("shipment.edit.fields.sales_person") }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ salesPerson }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.agent"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.user || "N/A" }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.shipper"
    )
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.shippingMethod.replace("thaipost0", "eps") || "N/A"
    }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
      $t(
        "shipment.view.contact_information.tracking_no"
      )
    }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  <a v-if="orderDetails.details.trackingNo"
                                     :href="'https://track.proship.co.th/#/?barcode=' +
    orderDetails.details.trackingNo
    "
                                     target="_blank"
                                     rel="noopener noreferrer">{{
    orderDetails.details.trackingNo || "N/A"
  }}</a>

                                  <span v-else>
                                    {{
      orderDetails.details.trackingNo || "N/A"
    }}
                                  </span>

                                  <button class="btn btn-default btn-sm"
                                          v-clipboard:copy="orderDetails.details.trackingNo
    "
                                          v-clipboard:success="() => copyHandler('success')
    "
                                          v-clipboard:error="() => copyHandler('danger')
    ">
                                    <i class="fal fa-copy"></i>
                                  </button>
                                </p>
                              </td>
                            </tr>

                            <tr v-if="orderDetails.details.sokochanOrderId">
                              <td class="text-left">
                                <p class="text-color-5">Order Id</p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.sokochanOrderId }}
                                </p>
                              </td>
                            </tr>

                            <tr v-if="orderDetails.details.manifestNo">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("shipment.view.manifest_no") }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.manifestNo }}
                                </p>
                              </td>
                            </tr>

                            <tr v-if="orderDetails.details.sortingCode">
                              <td class="text-left">
                                <p class="text-color-5">Sort Code</p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.sortingCode }}
                                </p>
                              </td>
                            </tr>

                            <!-- Price calculated via backend -->
                            <!-- <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
                                    $t("shipment.view.contact_information.cost")
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.cost || "N/A" }}
                                </p>
                              </td>
                            </tr> -->

                            <!-- Price from Thp -->
                            <!-- <tr v-if="thpDetails">
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
                                    $t("shipment.view.contact_information.cost")
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4 --sm">
                                  {{ $t("shipment.show.ems_price") }}:
                                  {{ thpDetails.emsPrice }}
                                  <br />
                                  {{ $t("shipment.show.service_price") }}:
                                  {{ thpDetails.servicePrice }}
                                  <br />
                                </p>
                              </td>
                            </tr> -->

                            <!-- Total Sales Price -->
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
    $t("shipment.edit.fields.total_sales_price")
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.totalSalesPrice ||
      "N/A"
    }}
                                </p>
                              </td>
                            </tr>

                            <!-- Discount -->
                            <tr v-if="orderDetails.details.discountAmount">
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
    $t("shipment.edit.fields.discount_field")
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.discountAmount || "N/A"
    }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
      $t(
        "shipment.edit.fields.shipping_cost_charged"
      )
    }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
      orderDetails.details.shippingCostCharged ||
      "N/A"
    }}
                                </p>
                              </td>
                            </tr>

                            <tr v-if="orderDetails.details.boxSize">
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{ $t("shipment.edit.fields.box_size") }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.boxSize }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
    $t("shipment.view.contact_information.cod")
  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.codAmount || "N/A" }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left"
                                  colspan="2">
                                <p class="text-color-5">
                                  {{
    $t(
      "shipment.view.contact_information.remarks"
    )
  }}
                                </p>
                                <p class="text-color-4"
                                   :style="{
      'overflow-wrap': 'break-word',
      'max-width': '400px',
    }">
                                  {{ orderDetails.details.remarks || "N/A" }}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <Card v-if="orderDetails.details.reconData">
                <div slot="body">
                  <h5 class="text-color-4">
                    {{ $t("shipment.view.reconciliation_data.label") }}
                  </h5>
                  <br />
                  <div class="row">
                    <div class="col-6">
                      <p class="text-color-5">
                        {{
    $t("shipment.view.reconciliation_data.charge_amount")
  }}
                      </p>
                      <p class="text-color-5">
                        {{
      $t(
        "shipment.view.reconciliation_data.weight_difference"
      )
    }}
                      </p>
                      <p class="text-color-5">
                        {{ $t("shipment.view.reconciliation_data.timestamp") }}
                      </p>
                    </div>
                    <div class="col-6">
                      <p class="text-color-4">
                        B. {{ orderDetails.details.reconData.chargeAmt || "0" }}
                      </p>
                      <p class="text-color-4">
                        {{ orderDetails.details.reconData.weightDiff }} gm
                      </p>
                      <p class="text-color-4">
                        {{
    orderDetails.details.reconData.timestamp | formatDate
  }}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>

              <Card v-if="orderDetails.details.errorDetail">
                <div slot="body">
                  <div class="alert alert-danger mb-0">
                    <h6 class="alert-heading">
                      {{ $t("shipment.view.error_heading") }}
                    </h6>
                    {{ orderDetails.details.errorDetail }}
                  </div>
                </div>
              </Card>

              <!-- Payment Slips -->
              <Card v-if="orderDetails.details.slips &&
    orderDetails.details.slips.length > 0
    ">
                <div slot="body">
                  <h5 class="text-color-4">
                    {{ $t("shipment.view.payment_slips.label") }}
                  </h5>
                  <br />
                  <h6 class="text-color-4">
                    {{ $t("shipment.view.payment_slips.download") }}
                  </h6>
                  <br />
                  <table class="_datatable table --small-height">
                    <thead>
                      <tr>
                        <td>{{ $t("shipment.view.payment_slips.slips") }}</td>
                        <td>
                          {{ $t("shipment.view.payment_slips.timestamp") }}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(slip, index) in orderDetails.details.slips"
                          :key="index">
                        <td>
                          <a :href="slip.url">
                            <h6>
                              {{
    $t("shipment.view.payment_slips.payment_slip")
  }}
                              {{ index + 1 }}
                            </h6>
                          </a>
                        </td>
                        <td>
                          <h6>{{ slip.createdAt | formatDate }}</h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="order-items-list">
              <Card v-if="products">
                <div slot="body">
                  <h5 class="header-text text-color-4 mb-3">
                    {{ $t("shipment.view.order_items.label") }}
                  </h5>

                  <table class="_datatable table --small-height">
                    <thead>
                      <th>{{ $t("general.item") }}</th>
                      <th>{{ $t("general.quantity") }}</th>
                      <th>{{ $t("general.price") }}</th>
                      <th>{{ $t("general.weight") }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(product, index) in products"
                          :key="index">
                        <td class="--width-sm --image">
                          <a :href="product.id
    ? '#/seller/products/' + product.id
    : null
    ">
                            {{ product.sku + " " + product.name }}
                          </a>
                        </td>
                        <td class="--width-sm">{{ product.qty }}</td>
                        <td class="--width-sm">
                          <span class="--text-sm text-color-4">{{
    calculateProductPrice(
      product.qty,
      product.price,
      product.bundles
    ).rate
  }}x{{ product.qty }}</span>
                          <br />
                          {{
    calculateProductPrice(
                          product.qty,
                          product.price,
                          product.bundles
                          ).price
                          }}
                          THB
                        </td>
                        <td class="--width-sm">
                          <span class="--text-sm text-color-4">{{ product.weight }}x{{ product.qty }}</span>
                          <br />
                          {{ product.weight * product.qty }} gm
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>

            <div class="order-history-list">
              <Card>
                <div class="title"></div>
                <div slot="body">
                  <h5 class="header-text text-color-4">
                    {{ $t("shipment.view.order_history.label") }}
                  </h5>
                  <br />
                  <table class="_datatable table --small-height">
                    <thead>
                      <th>
                        {{ $t("shipment.view.contact_information.agent") }}
                      </th>
                      <th>{{ $t("status.label") }}</th>
                      <th>{{ $t("general.date") }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(history, index) in orderDetails.details
    .histories"
                          :key="index">
                        <td class="--width-sm">{{ history.user }}</td>
                        <td class="--width-sm"
                            v-html="status(history.status)"></td>
                        <td class="--width-md">
                          {{ history.timestamp | formatDateTime }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>

            <Card v-if="orderDetails.details.changeHistories">
              <div slot="body">
                <h5 class="header-text text-color-3 mb-4">ประวัติการแก้ไข</h5>

                <table class="_datatable table --small-height">
                  <thead>
                    <th>
                      {{ $t("shipment.view.contact_information.agent") }}
                    </th>
                    <th></th>
                    <th>{{ $t("general.date") }}</th>
                  </thead>
                  <tbody>
                    <tr v-for="(changeHis, i) in orderDetails.details
    .changeHistories"
                        :key="i">
                      <td class="--width-sm">
                        {{ changeHis.username }}
                      </td>

                      <td>
                        <p v-for="(val, key) in changeHis.diff"
                           :key="key"
                           class="--text-vsm">
                          {{ key }}
                          <br />
                          <span v-if="val.f"
                                class="badge badge-secondary p-1">
                            {{ val.f }}
                          </span>


                          <i class="fal fa-arrow-right mx-2"
                             v-if="val.f"></i>

                          <span class="badge badge-primary p-1"> {{ val.t }} </span>
                        </p>
                      </td>

                      <td class="--width-md">
                        {{ changeHis.createdAt | formatDateTime }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>

            <div class="bank-details"
                 v-if="orderDetails.details.bank">
              <Card>
                <div slot="body">
                  <h5 class="header-text text-color-3 mb-4">
                    {{ orderDetails.details.bank.name }}
                  </h5>
                  <p class="text-color-4">
                    {{ $t("bank_input.amount") }}:
                    {{ orderDetails.details.bank.amount }}
                  </p>
                  <p class="text-color-4">
                    {{ $t("bank_input.date") }}:
                    {{ orderDetails.details.bank.date | formatDate }} <br />
                    {{ $t("bank_input.time") }}:
                    {{ orderDetails.details.bank.time }}
                  </p>

                  <template v-if="orderDetails.details.bank.slip &&
    orderDetails.details.bank.slip.length
    ">
                    <img v-for="slip in orderDetails.details.bank.slip"
                         :src="slip"
                         :key="slip"
                         class="mt-3 img-fluid" />
                  </template>
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <Card>
              <div slot="body">
                <Spinner v-if="shipmentTrackingsLoading"
                         size="sm"
                         message="Loading" />
                <div v-else>
                  <h5 class="text-color-4">Order Tracking</h5>
                  <br />
                  <table class="table _datatable --small-height"
                         v-if="shipmentTrackings && shipmentTrackings.length">
                    <thead>
                      <th>Barcode</th>
                      <th>Station</th>
                      <th>Status Date</th>
                      <th>Status Description</th>
                    </thead>
                    <tbody>
                      <tr v-for="(tracking, index) in shipmentTrackings"
                          :key="index">
                        <td>{{ tracking.barcode }}</td>
                        <td>{{ tracking.station }}</td>
                        <td>{{ tracking.statusDate }}</td>
                        <td>{{ tracking.statusDescription }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div v-else-if="this.orderDetails.details.shippingMethod === 'thaipost0'">
                    <a :href="'https://track.thailandpost.co.th/?trackNumber=' + orderDetails.details.trackingNo"
                       target="_blank"
                       rel="noopener noreferrer"
                       class="btn btn-primary"> ติดตามสถานะ <i class="fa fa-external-link ml-2"
                         aria-hidden="true"></i></a>
                  </div>

                  <div v-else
                       class="alert alert-warning"
                       role="alert">
                    {{ $t("shipment.show.no_trackings_data") }}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>

    <Modal id="orders-status"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <div v-if="nextStatus(orderDetails.details.status) !== -1">
          <p class="lead text-center">
            {{ $t("shipment.view.order_status.change") }}
            <br />
            <span class="text-color-3 --text-sm">
              {{ orderStatus(orderDetails.details.status).title }}
              <i class="fal fa-arrow-right"></i>
              {{ orderStatus(nextStatus(orderDetails.details.status)).title }}
            </span>
          </p>
          <div class="text-center mt-4">
            <Button id="btn-change-status"
                    variant="primary"
                    size="md"
                    @click="changeStatus(nextStatus(orderDetails.details.status))"
                    :loading="statusUpdateLoading">{{
              $t("shipment.view.order_status.change_status_button")
              }}</Button>
            <br />
            <br />
            <div v-if="orderDetails.details.status == 2 ||
    orderDetails.details.status == 3
    ">
              <a class="link"
                 href="#"
                 @click="changeStatus(1)">{{
                $t("shipment.view.order_status.revert_message")
                }}</a>
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Print Label modal -->
    <Modal id="orders-print-labels"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body">
        <p class="lead text-center mb-4">
          {{ $t("shipment.shipping_label.message") }}
        </p>

        <div class="alert alert-success mb-2"
             v-if="printLabelLoading"
             style="font-size: 1rem">
          <i class="fal fa-circle-notch fa-spin"></i>
          {{ $t("print_dialog.generating_link") }}
        </div>

        <div class="text-center mb-2 mt-2">
          <a :href="printUrl"
             target="_blank"
             rel="noopener noreferrer"
             v-if="printUrl"
             class="btn btn-success">
            {{ $t("print_dialog.click_to_download") }}
          </a>
        </div>

        <label>โปรดเลือกขนาดกระดาษที่ต้องการใช้งาน</label>
        <select class="form-control form-control-sm"
                v-model="selectedPaperSize"
                @change="setPaperSize">
          <option :value="paperSize"
                  v-for="paperSize in Object.keys(printLabels.paperSizes)"
                  :key="paperSize">
            {{ paperSize }}
          </option>
        </select>

        <div class="d-flex flex-wrap printerFormatContainer mt-4">
          <div class="printerFormat"
               v-for="(prtFr, index) in printLabels.paperSizes[selectedPaperSize]"
               :key="index"
               @click="printLabel(prtFr.size, prtFr.printer)">
            <div>
              <div class="d-flex">
                <img :src="prtFr.image"
                     class="icon align-self-start" />
                <div class="d-flex flex-column align-self-center">
                  <p class="mb-0 --text-vsm">{{ prtFr.name }}</p>
                  <p class="--text-vsm text-color-5 mt-n2"
                     v-if="prtFr.sizeToShow">
                    {{ prtFr.sizeToShow }}
                  </p>
                </div>
              </div>

              <p class="--text-vvsm text-color-5">{{ prtFr.description }}</p>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { Alert } from "@/helpers/alert";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import DataTable from "@/components/core/DataTable";
import Spinner from "@/components/core/Spinner";
import Modal from "@/components/core/Modal";
import { getOrderStatus } from "@/helpers/core";
import moment from "moment";
import { i18n } from "@/i18n";
import { printLabels } from "@/helpers/print";
import { Toast } from "@/helpers/toastr";
import { http } from "@/helpers/http";

export default {
  name: "Orders-Show",
  components: {
    Card,
    Button,
    DataTable,
    Spinner,
    Modal,
  },
  data() {
    return {
      allowedStatus: [1],
      loading: false,
      statusUpdateLoading: false,
      printLabelLoading: false,
      cancelOrderLoading: false,
      shipmentTrackingsLoading: false,
      products: null,
      orderDetails: null,
      printLabels: null,
      selectedPaperSize: localStorage.getItem("paperSize"),

      shipmentTrackings: [],
      thpDetails: null,
      printUrl: null,

      manualCronLoading: false,
    };
  },

  created() {
    this.fetchOrder(() => {
      if (this.orderDetails.details.shippingMethod === "thaipost") {
        this.fetchThpDetails();
      }
      this.fetchTrackings();
    });
    this.printLabels = printLabels();
  },

  methods: {

    runManualCron() {
      this.manualCronLoading = true;
      this.$store.dispatch("orders/submitManualCron", {
        data: { orderStatus: this.orderDetails.status },
        callback: (status, data) => {
          if (status) Toast("ระบบกำลังปรับสถานะการจัดส่ง", "success");
          this.manualCronLoading = false;
        },
      });
    },

    fetchTrackings() {
      // sm to ignore
      if (
        ["thaipost0", "scg"].includes(this.orderDetails.details.shippingMethod)
      )
        return;

      this.shipmentTrackingsLoading = true;
      if (this.orderDetails.details.shippingMethod == "thaipost") {
        if (this.orderDetails.status == 1) {
          this.shipmentTrackingsLoading = false;
          return;
        }
        this.$store.dispatch("orders/fetchThpTrackings", {
          barcode: this.orderDetails.details.trackingNo,
          thaiPostUserName: this.orderDetails.details.thaiPostUserName,
          callback: (status, data) => {
            if (status) this.shipmentTrackings = data;
            this.shipmentTrackingsLoading = false;
          },
        });
      } else {
        this.$store.dispatch("orders/fetchTrackings", {
          barcode: this.orderDetails.details.trackingNo,
          callback: (status, data) => {
            if (status)
              this.shipmentTrackings = data.trackings.map((t) => {
                return {
                  ...t,
                  barcode: this.orderDetails.details.trackingNo,
                };
              });
            this.shipmentTrackingsLoading = false;
          },
        });
      }
    },

    setPaperSize() {
      localStorage.setItem("paperSize", this.selectedPaperSize);
      this.printUrl = null;
    },

    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    cancel() {
      this.$router.push({ name: "Seller-Order-List" });
    },

    printLabel(size, printer) {
      this.printLabelLoading = true;
      this.printUrl = null;
      this.$store.dispatch("orders/printLabels", {
        data: {
          orders: [this.orderDetails.id + "|" + this.orderDetails.createdAt],
          size,
          printer,
        },
        callback: (status, data) => {
          if (status) {
            this.printUrl = data.url;
          }
          this.printLabelLoading = false;
        },
      });
    },

    fetchOrder(callback) {
      this.loading = true;

      let id = this.$route.params.id;
      if (this.$route.params.id && this.$route.params.id.includes("|")) {
        id = this.$route.params.id.split("|")[0]
      }

      this.$store.dispatch("orders/fetchOrderDetail", {
        id,
        callback: async (status, data) => {
          if (status) {
            this.orderDetails = data;
            this.orderDetails.details.status
              ? this.orderDetails.details.status
              : 1;
            this.loading = false;

            if (data.details.products) {
              this.products = [];
              let keys = Object.keys(data.details.products);
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                let bundles = await this.fetchProductsBundlePrice(
                  data.details.products[key].id
                );
                this.products.push({
                  id: data.details.products[key].id,
                  sku: data.details.products[key].sku || key,
                  name: data.details.products[key].name,
                  code: key,
                  qty: data.details.products[key].qty,
                  price: data.details.products[key].price || 0,
                  weight: data.details.products[key].weight,
                  bundles,
                });
              }
            }

            if (callback) callback();
          }
        },
      });
    },

    async fetchProductsBundlePrice(id) {
      try {
        let bundlesArr = (
          await http.get(
            "https://oyljzciuy1.execute-api.ap-southeast-1.amazonaws.com/dev/v1/products/" +
            id
          )
        ).data.details.bundles;
        if (!bundlesArr) return null;

        let bundles = {};
        for (let i = 0; i < bundlesArr.length; i++) {
          const b = bundlesArr[i];
          bundles[b.amount] = b.price;
        }
        return bundles;
      } catch (e) {
        console.log(e);
        return null;
      }
    },

    fetchThpTrackings() {
      this.shipmentTrackingsLoading = true;
      this.$store.dispatch("orders/fetchThpTrackings", {
        barcode: this.orderDetails.details.trackingNo,
        thaiPostUserName: this.orderDetails.details.thaiPostUserName,
        callback: (status, data) => {
          if (status) this.shipmentTrackings = data;
          this.shipmentTrackingsLoading = false;
        },
      });
    },

    fetchThpDetails() {
      // this.$store.dispatch("orders/fetchThpDetails", {
      //   barcode: this.orderDetails.details.trackingNo,
      //   thaiPostUserName: this.orderDetails.details.thaiPostUserName,
      //   callback: (status, data) => {
      //     if (status) {
      //       // this.thpDetails = data;
      //       // this.orderDetails.details.actualWeight = data.productWeight;
      //     }
      //   },
      // });
    },

    orderStatus(i) {
      var status = getOrderStatus(i);
      return status;
    },

    nextStatus(status) {
      if (status == 2 && this.orderDetails.details.shippingMethod == "kerry")
        return 4;
      let nxtStatus = status + 1;
      if (nxtStatus == 7) return 8;
      if (nxtStatus == 8) return -1;
      if (nxtStatus == 9) return -1;
      return status + 1;
    },

    changeStatus(index) {
      this.statusUpdateLoading = true;

      let id = this.$route.params.id;
      if (id && id.includes("|")) id = id.split("|")[0]

      this.$store.dispatch("orders/updateOrderStatus", {
        id,
        data: { status: index },
        callback: (status, data) => {
          if (status) {
            this.fetchOrder();
            $("#orders-status").modal("hide");
          }
          this.statusUpdateLoading = false;
        },
      });
    },

    deleteOrder() {
      Alert(
        i18n.t("general.cancel"),
        i18n.t("alert.cancel.message"),
        null,
        () => {
          this.cancelOrderLoading = true;
          let id = this.$route.params.id;
          if (this.$route.params.id && this.$route.params.id.includes("|")) {
            const [_id, createdAt] = this.$route.params.id.split("|");
            id = _id;
          }

          this.$store.dispatch("orders/deleteOrder", {
            id,
            callback: (status) => {
              if (status) this.$router.push({ name: "Seller-Order-List" });
              this.cancelOrderLoading = false;
            },
          });
        }
      );
    },

    status(value) {
      let statusData = getOrderStatus(value);
      return `<span class="badge badge-pill text-uppercase status-badge badge-${statusData.variation}">${statusData.title}</span>`;
    },

    formatEmsTrackingDate(date) {
      return moment(date, "DD/MM/YYYY HH:mm:ss+-").format(
        "DD MMM YYYY, h:mm:ss A"
      );
    },

    back() {
      this.$router.push({ name: "Seller-Order-List" });
    },

    calculateProductPrice(qty, unitPrice, bundles) {
      if (!bundles) return qty * unitPrice;
      else {
        let price = 0;
        let sortedAmounts = Object.keys(bundles)
          .map((p) => parseInt(p))
          .sort((a, b) => a - b);
        let runLoop = true;
        let count = sortedAmounts.length * 10;

        while (runLoop && count > 0) {
          let maxAmount = sortedAmounts.pop();
          if (qty >= maxAmount) {
            price += qty * bundles[maxAmount];
            return { price, rate: bundles[maxAmount] };
          } else {
            if (sortedAmounts.length === 0 || count === 0) runLoop = false;
          }
          count--;
        }

        return { price: price + qty * unitPrice, rate: unitPrice };
      }
    },
  },

  computed: {
    salesPerson() {
      if (this.orderDetails.details.salesPerson) {
        if (this.orderDetails.details.salesPerson.name)
          return this.orderDetails.details.salesPerson.name;
        else this.orderDetails.details.salesPerson;
      }
      return "";
    },
  },
};
</script>

<style lang="scss"
       scoped>
      .spinner {
        margin-top: 100px;
      }

      .shipping-label {
        width: toRem(150px);
        height: toRem(100px);
        text-align: center;
        line-height: toRem(100px);
        border-radius: toRem(10px);
        margin: 1rem;
        cursor: pointer;
      }

      .orders-page {

        // margin: 35px;
        .content-body {
          .card-body {
            // padding: 20px;
            // col-6 span {
            //   margin-right: 10px;
            // }

            .right-info {
              background: $dashboard-background;
            }
          }
        }
      }

      @include for-phone-only {
        .orders-page {
          margin: auto 0;

          .content-body .card-body {
            margin: 0;

            .row {
              margin: 0 !important;
              padding: 0 !important;

              .col-6 {
                padding: 0 !important;
              }
            }

            .right-info {
              background: #fff;
            }
          }
        }
      }

      .productListImage {
        height: 10px;
        width: auto;
        max-width: 100px;
      }

      .shipping-label-format {
        text-align: center;
        padding: 0.5rem;
        background-color: rgba($brand-blue-light, 0.1);
        border-radius: toRem(10px);
        margin: 0.25rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          height: toRem(100px);
          width: auto;
        }

        span {
          margin-top: 0.25rem;
          font-size: 0.8rem;
        }
      }

      .printerFormatContainer {
        width: 210px;

        @include for-larger-than-phone {
          width: 420px;
        }

        margin: 0 auto;
        overflow: auto;

        .printerFormat {
          width: 200px;
          min-height: 50px;
          background-color: rgb(252, 252, 252);
          padding: 0.5rem;
          border-radius: 3px;
          margin: 0.25rem;
          cursor: pointer;

          &:hover {
            background-color: whitesmoke;
          }

          .icon {
            height: 2rem;
            width: auto;
            margin-right: 0.5rem;
            margin-top: 0.4rem;
          }
        }
      }
    </style>
