<template>
  <div class="onboard-content d-flex align-items-center">
    <div class="onboard-card-logo">
      <div class="onboard-site-logo-wrapper">
        <img src="@/assets/images/proship-logo-icon.png" alt class="onboard-site-logo" />
      </div>
      <div class="stepper">
        <Stepper
          :steps="5"
          :currentStepIndex="currentStep"
          variant="primary"
          :has_number="false"
          v-if="currentStep != 0"
        />
      </div>
      <div class="card content-card">
        <div class="onboard-card">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div class="onboard-steps d-none d-md-block">
      <SideStepper :steps="steps" :currentStepIndex="currentStep" />
    </div>
  </div>
</template>

<script>
import Stepper from "@/components/core/Stepper";
import Card from "@/components/core/Card";
import SideStepper from "@/components/derived/SideStepper";
import {i18n} from "@/i18n"


export default {
  name: "Onboard",
  components: { Stepper, Card, SideStepper },
  data() {
    return {
      currentStep: 0,
      steps: [
        i18n.t('onboard.details.title'),        
        i18n.t('onboard.shipping_method.title'),
        i18n.t('onboard.final.title'),
        i18n.t('onboard.options.title'),
        i18n.t('onboard.product.title')
      ]
    };
  },

  created() {
    this.setCurrentStep(this.$route.name);
  },

  methods: {
    setCurrentStep(routeName) {
      const routeStep = {
        Onboard: 0,
        "Onboard-Details": 1,        
        "Onboard-Shipping": 2,
        "Onboard-Final": 3,
        "Onboard-Options": 4,
        "Onboard-Product": 5,
      };
      this.currentStep = routeStep[routeName];
    }
  },

  watch: {
    "$route.name": function(val) {
      this.setCurrentStep(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.stepper {
  display: none;
  @include for-small-devices {
    display: block;
  }
}

.onboard-site-logo {
  @include for-small-devices {
    display: none;
  }
}

.onboard-content {
  max-width: 72%;
  width: 100%;
  margin: 0 auto;
  z-index: 999;

  @include for-phone-only {
    width: 100%;
    max-width: 100%;
  }

  @include for-smaller-than-desktops {
    max-width: 100%;
  }
}

.onboard-card-logo {
  width: 70%;
  @include for-small-devices {
    width: 95%;
    margin: 0 auto;
  }
}

.content-card {
  border-radius: 20px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @include box-shadow(0, 5px, 15px, #cdcdcd);

  @include for-phone-only {
    padding: 20px;
  }

  @include for-desktops-and-up {
    min-height: 700px;
  }

  @include for-laptops-only {
    min-height: 488px;
  }
}

.onboard-site-logo {
  width: 75px;
  height: 81px;
  margin-bottom: 24px;
  @include for-laptops-only {
    margin-bottom: 10px;
    width: 60px;
    height: 64px;
  }
}

.onboard-card {
  width: 100%;
}
.onboard-steps{
  padding-top: toRem(100px);
}
</style>