<template>
  <div>
    <TextBox type="text"
             size="sm"
             id="shop-name"
             :placeholder="'Fb page ID'"
             :input_wrapper_class="'mb-0 pb-0'"
             :wrapper_class="'mb-0 pb-0'"
             v-model="pageId"
             :value="value"
             @input="emitUpdate()" />
    <span v-if="hasError && !loading"
          class="text-danger">Invalid page id</span>

    <span v-if="loading"
          class="text-info">Checking page id...</span>

    <div class="mb-4"></div>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import axios from "axios";

export default {
  name: "FbIdInput",
  components: { TextBox },

  props: {
    value: {
      type: String
    }
  },

  created() {
    this.pageId = this.value;
    this.emitUpdate();
  },

  data() {
    return {
      pageId: null,
      timer: null,
      hasError: false,
      loading: false
    };
  },

  methods: {
    async emitUpdate() {
      this.$emit("input", this.pageId);

      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {

        try {
          this.loading = true;
          const { data } = await axios.get(`https://urr58tp7nl.execute-api.ap-southeast-1.amazonaws.com/dev/check-fb-page?pageId=${this.pageId}`)
          this.hasError = data.pageIsConnected === false
            try {
              // set value on localstorate so that check gets trigger on next reload
              let localstorageValue = JSON.parse(localStorage.getItem("fbPageIdsChecker"));
              if (localstorageValue) localstorageValue.lastUpdatedAt = 0;
              localStorage.setItem("fbPageIdsChecker", JSON.stringify(localstorageValue));
            } catch (e) { }
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false;
        }
      }, 1000);
    }
  },

  watch: {
    value: function (newVal, oldVal) {
      this.pageId = newVal;
      this.emitUpdate();
    }
  }
};
</script>

<style lang="scss"
       scoped>

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    </style>