<template>
  <div class="reportsSearch">
    <div class="card">
      <div class="card-body">
        <div class="advancedSearch m-1">
          <div class="fieldsList d-flex flex-column">
            <div
              class="field mb-3"
              v-for="(selectedField, index) in selectedFields"
              :key="index"
            >
              <div class="d-flex">
                <!-- Select Field Name -->
                <select
                  class="form-control form-control-sm mr-2"
                  style="width: 10rem; text-transform: capitalize"
                  v-model="selectedFields[index].name"
                  @change="handleFieldChange(index)"
                >
                  <option :value="null">---เลือกตัวกรอง---</option>
                  <option
                    v-for="field in fieldsList"
                    :value="field"
                    :key="field"
                  >
                    {{ fields[field].title }}
                  </option>
                </select>

                <div v-if="selectedFields[index].name" class="d-flex mr-2">
                  <!-- Select Comparator -->
                  <!-- Don't show if only 1 possible value -->
                  <select
                    class="form-control form-control-sm"
                    style="width: 10rem; text-transform: capitalize"
                    :value="selectedFields[index].comparator"
                    v-model="selectedFields[index].comparator"
                    @change="handleComparatorChange(index)"
                    v-if="
                      fieldComparators[selectedFields[index].type].length > 1
                    "
                  >
                    <option :value="null">---เลือกตัวกรอง---</option>

                    <option
                      v-for="(comparator, index) in fieldComparators[
                        fields[selectedFields[index].name].type
                      ]"
                      :value="comparator"
                      :key="comparator + '-' + index"
                    >
                      {{ comparator }}
                    </option>
                  </select>

                  <!-- comparator values -->
                  <div v-if="selectedFields[index].comparator" class="d-flex">
                    <!-- options -->
                    <div
                      v-if="
                        fields[selectedFields[index].name].type == 'options'
                      "
                    >
                      <select
                        class="form-control form-control-sm ml-2"
                        style="width: 10rem; text-transform: capitalize"
                        v-model="selectedFields[index].values"
                        @change="emitUpdate()"
                      >
                        <option :value="null">---เลือกตัวกรอง---</option>

                        <option
                          v-for="option in fields[selectedFields[index].name]
                            .options"
                          :value="option.value"
                          :key="option.name"
                        >
                          {{ option.name }}
                        </option>
                      </select>
                    </div>

                    <!-- other field types -->
                    <div v-else>
                      <!--  multi values -->
                      <div
                        v-if="
                          selectedFields[index].values &&
                          typeof selectedFields[index].values == 'object'
                        "
                        class="d-flex"
                      >
                        <div
                          v-for="(val, index2) in selectedFields[index].values"
                          :key="index2"
                          class="ml-2"
                        >
                          <input
                            :type="fields[selectedFields[index].name].type"
                            v-model="selectedFields[index].values[index2]"
                            class="form-control form-control-sm mr-2"
                            @keyup="emitUpdate()"
                          />
                        </div>
                      </div>

                      <!-- single value -->
                      <div v-else>
                        <input
                          :type="fields[selectedFields[index].name].type"
                          v-model="selectedFields[index].values"
                          class="form-control form-control-sm mr-1 ml-2"
                          @keyup="emitUpdate()"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="m-2"></div>

                <button
                  class="btn btn-sm btn-secondary"
                  @click="removeFilter(index)"
                >
                  <i class="fal fa-minus-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <div class="field d-flex">
            <button
              class="btn btn-sm btn-secondary align-self-center"
              @click="addFilter()"
            >
              <i class="fal fa-plus-circle mr-2"></i>
              เพิ่มตัวกรองเพิ่มเติม
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TextArea from "@/components/core/TextArea";
import Button from "@/components/core/Button";
import { getOrderStatus } from "@/helpers/core";
import _ from "lodash";
import moment from "moment";

export default {
  name: "ReportsSearch",
  components: { TextArea, Button },

  data() {
    return {
      generalSearchKeyword: null,
      selectedFields: null,

      fieldComparators: {
        text: ["contains"],
        options: ["equals"],
        number: [">", "<", "between"],
        "datetime-local": [">", "<", "between"],
      },

      comparatorValues: {
        equals: 1,
        contains: 1,
        ">": 1,
        "<": 1,
        between: 2,
      },

      comparatorInputTypes: {},

      fields: null,

      updateTimer: null,
    };
  },

  created() {
    if (localStorage.selectedFields) {
      let parsed = JSON.parse(localStorage.selectedFields);
      if (parsed && typeof parsed === "object" && parsed.length)
        this.selectedFields = parsed;
      else this.selectedFields = [];
    } else this.selectedFields = [];

    this.generateSearchFields();
    this.emitUpdate();
  },

  mounted() {},

  methods: {
    generateSearchFields() {
      this.fields = {
        zipcode: { type: "text", title: "รหัสไปรษณีย์" },
        totalSalesPrice: { type: "number", title: "ราคาขายรวม" },
        remarks: { type: "text", title: "หมายเหตุ" },
        address: { type: "text", title: "ที่อยู่" },
        phoneNo: { type: "text", title: "หมายเลขโทรศัพท์" },
        customerName: { type: "text", title: "ชื่อลูกค้า" },
        codAmount: { type: "number", title: "จำนวนเงิน COD" },
        printCount: { type: "number", title: "จำนวนครั้งพิมพ์" },
        codPaid: {
          type: "options",
          title: "วิธีการชำระเงิน",
          options: [
            { name: "COD", value: true },
            { name: "Non COD", value: false },
          ],
        },
        hasError: {
          type: "options",
          title: "มีข้อผิดพลาด ?",
          options: [
            { name: "Yes", value: true },
            { name: "No", value: false },
          ],
        },
        // shippingMethod: {
        //   type: "options",
        //   title: "Shipping Method",
        //   options: [
        //     { name: "Thaipost", value: "thaipost" },
        //     { name: "Kerry", value: "kerry" },
        //     { name: "JT", value: "jt" },
        //     { name: "SCG", value: "scg" },
        //     { name: "Self Ship", value: "self-ship" },
        //   ],
        // },
        status: {
          type: "options",
          title: "สถานะการสั่งซื้อ",
          options: [],
        },
        // createdAt: {
        //   type: "datetime-local",
        //   title: "วันที่",
        // },
        salesChannel: {
          type: "options",
          title: "ช่องทางการขาย",
          options: [
            { name: "Facebook", value: "facebook" },
            { name: "Line", value: "line" },
            { name: "Instagram", value: "instagram" },
            { name: "Web", value: "web" },
            { name: "Other", value: "other" },
          ],
        },

        shopId: {
          type: "options",
          title: "ร้านค้า",
          options: [],
        },

        user: {
          type: "options",
          title: "ตัวแทน",
          options: [],
        },
      };

      // order status
      let statusObj = getOrderStatus();
      for (const val in statusObj)
        this.fields.status.options.push({
          name: statusObj[val].title,
          value: val,
        });

      // shops
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.fields.shopId.options = data.map((shop) => {
              return { name: shop.details.name, value: shop.id };
            });
          }
        },
      });

      // agents
      this.$store.dispatch("salesAgent/fetchSalesAgents", {
        callback: (status, data) => {
          if (status) {
            this.fields.user.options = data.map((d) => {
              return { name: d.details.username, value: d.details.username };
            });
          }
        },
      });
    },

    removeFilter(index) {
      this.selectedFields.splice(index, 1);
      this.emitUpdate();
    },

    addFilter() {
      this.selectedFields.push({
        name: null,
        comparator: null,
        values: null,
      });
    },

    handleFieldChange(index) {
      this.selectedFields[index].comparator = null;
      this.selectedFields[index].type =
        this.fields[this.selectedFields[index].name].type;

      // auto assign comparator if only one
      if (this.fieldComparators[this.selectedFields[index].type].length == 1) {
        this.selectedFields[index].comparator =
          this.fieldComparators[this.selectedFields[index].type][0];
        this.handleComparatorChange(index);
      }
    },

    handleComparatorChange(index) {
      let comparator = this.selectedFields[index].comparator;

      this.selectedFields[index].values =
        this.comparatorValues[comparator] == 1 ? null : [null, null];
      this.emitUpdate();
    },

    emitUpdate() {
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(() => {
        this.$emit("input", this.selectedFields);
        localStorage.selectedFields = JSON.stringify(this.selectedFields);
      }, 500);
    },
  },
  computed: {
    fieldsList() {
      return Object.keys(this.fields);
    },
  },
};
</script>

<style scoped lang="scss">
.clipboardArea {
  background-color: whitesmoke;
  padding: 1rem;
  color: map-get($text-colors, 5);
  text-align: center;
  cursor: pointer;
}

.parsingStatus {
  min-width: 55px;
}

.manualInput {
  input {
    padding: 3px 6px;
    font-size: 12px;
    margin: 0.1rem;
  }
}
</style>