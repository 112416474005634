import { http } from "@/helpers/http";
import Vue from "vue";

// const baseURL = 'https://qz88s5gim3.execute-api.ap-southeast-1.amazonaws.com/dev/v1'; v1
const baseURL = 'https://x1pukio3fj.execute-api.ap-southeast-1.amazonaws.com/dev/v1'; // v2
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
  namespaced: true,
  state: {
    salesAgents: [],
    salesAgent: null,
  },

  getters: {
    salesAgents(state) {
      return state.salesAgents;
    },

    salesAgent(state) {
      return state.salesAgent;
    },
  },

  mutations: {
    addSalesAgents(state, salesAgent) {
      state.salesAgents.push(salesAgent);
    },
    setSalesAgents(state, salesAgents) {
      Vue.set(state, "salesAgents", salesAgents);
    },
    setSalesAgents(state, salesAgent) {
      Vue.set(state, "salesAgent", salesAgent);
    },
  },
  actions: {
    createSalesAgent({ state, commit, rootState }, payload) {
      http.post(baseURL + "/sales-agents", payload.salesAgent)
        .then(response => {
          if (payload.callback) payload.callback(true);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },
    

    fetchSalesAgents({ state, commit, rootState }, payload) {
      http.get(baseURL + "/sales-agents?" + (payload.query || ''))
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    payload.callback(false, error.response);
                })
    },

    fetchSalesAgentDetail({ state, commit, rootState }, payload) {
      http.get(baseURL + "/sales-agents/"+ payload.id )
      .then(response => {
          if (payload.callback) payload.callback(true, response.data);
      })
      .catch(error => {
          payload.callback(false, error.response);
      })
    },


    updateSalesAgent({ state, commit, rootState }, payload) {
      http.put(baseURL + "/sales-agents/" + payload.id, payload.data)
      .then(response => {
        if (payload.callback) payload.callback(true);
      })
      .catch(error => {
        if (payload.callback) payload.callback(false);
      })
    },

    deleteSalesAgent({ state, commit, rootState }, payload) {
      http.delete(baseURL + "/sales-agents/" + payload.id)
      .then(response => {
        if (payload.callback) payload.callback(true);
      })
      .catch(error => {
        if (payload.callback) payload.callback(false);
      })
    },
  },
};
