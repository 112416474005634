<template>
  <div class="form-group">
    <ValidationProvider :rules="rules" v-slot="{ errors }">
      <TextArea
        wrapper_class="mb-1"
        :placeholder="placeholder"
        :id="id"
        :label="label"
        @keyup="watchChanges()"
        :rows="rows"
        :cols="cols"
        v-model="address"
      />

      <div class="template-error d-flex justify-content-between">
        <!-- Error or Value -->
        <template v-if="errors && errors.length">
          <span
            class="text-danger text-sm"
            v-for="(error, index) in errors"
            :key="index"
            >{{ error }}</span
          >
        </template>

        <!-- parsing message -->
        <span
          v-if="state === 1"
          class="parsingStatus text-info text-sm ml-auto"
        >
          <i class="fal fa-sync-alt mr-1"></i>
          Parsing
        </span>
        <span
          v-if="state === 2"
          class="parsingStatus text-success text-sm ml-auto"
        >
          <i class="fas fa-check-circle mr-1"></i>Parsed
        </span>
        <span
          v-if="state === 3"
          class="parsingStatus text-warning text-sm ml-auto"
        >
          <i class="fas fa-exclamation-circle mr-1"></i>
          ตรวจสอบที่อยู่
        </span>
      </div>
    </ValidationProvider>

    <div v-if="state >= 1 && parsedAddress" class="manualInput w-100">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <input
            :id="id + 'sa-address'"
            type="text"
            class="form-control"
            v-model="parsedAddress.address"
            placeholder="address"
            autocomplete="off"
            @input="handleAddressChange"
          />
        </div>
        <div class="d-flex">
          <input
            :id="id + 'sa-amphoe'"
            type="text"
            class="form-control"
            v-model="parsedAddress.subDistrict"
            placeholder="subdistrict"
            autocomplete="off"
          />
          <input
            :id="id + 'sa-district'"
            type="text"
            class="form-control"
            v-model="parsedAddress.district"
            placeholder="district"
            autocomplete="off"
          />
        </div>
        <div class="d-flex">
          <input
            :id="id + 'sa-province'"
            type="text"
            class="form-control"
            v-model="parsedAddress.province"
            placeholder="province"
            autocomplete="off"
          />

          <ValidationProvider rules="required|zipcode" v-slot="{ errors }">
            <input
              :id="id + 'sa-zipcode'"
              type="text"
              class="form-control"
              v-model="parsedAddress.zipcode"
              placeholder="zipcode"
              autocomplete="off"
              @input="handleZipcodeChange"
            />
            <label
              class="text-danger --text-vsm"
              v-if="errors && errors.length"
              >{{ errors[0] }}</label
            >
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextArea from "@/components/core/TextArea";
import Button from "@/components/core/Button";
import { http } from "@/helpers/http";
import _ from "lodash";

export default {
  name: "SmartAddress",
  components: { TextArea, Button },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    id: {
      type: String,
      default: "sa-" + Math.random(),
    },
    value: {
      type: null,
    },
    rules: {
      type: String,
      default: "",
    },
    rows: {
      type: String,
    },

    cols: {
      type: String,
    },

    smart: {
      type: Boolean,
      default: true,
    },

    isThaiPost: {
      type: Boolean,
      default: true,
    },

    sm: {
      type: String,
      default: "thaipost",
    },
  },

  data() {
    return {
      parseTimer: null,
      state: 0, //0: initial, 1: parsing, 2: success, 3: error
      parsedAddress: null,
      address: null,
      changedByUser: false,
    };
  },

  created() {
    if (this.value) {
      if (this.value.raw) this.address = this.value.raw;
      else
        this.address = `${this.value.address}\n${this.value.district}\n${
          this.value.subDistrict || ""
        }\n${this.value.province}\n${this.value.zipcode}`;
      this.watchChanges();
    }
  },

  mounted() {
    if (this.parsedAddress) this.applyThaiAddParse();
  },

  methods: {
    handleAddressChange() {
      this.parsedAddress.raw = `${this.parsedAddress.address}\n${this.parsedAddress.district}\n${this.parsedAddress.subDistrict}\n${this.parsedAddress.province}\n${this.parsedAddress.zipcode}`;
    },

    handleZipcodeChange() {
      this.emitChanges();
    },

    applyThaiAddParse() {
      setTimeout(() => {
        const DB = {
          shopee:
            "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/shopee-db.json",
          other:
            "https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/db.json",
        };

        $.Thailand({
          database: DB[this.sm] || DB["other"],
          $district: $(`#${this.id}sa-district`),
          $amphoe: $(`${this.id}sa-amphoe`),
          $province: $(`#${this.id}sa-province`),
          $zipcode: $(`#${this.id}sa-zipcode`),
          onDataFill: (data) => {
            this.parsedAddress.district = data.amphoe;
            this.parsedAddress.subDistrict = data.district;
            this.parsedAddress.province = data.province;
            this.parsedAddress.zipcode = data.zipcode;
            this.state = 2;
            this.address = `${this.parsedAddress.address}\n${data.district}\n${data.amphoe}\n${data.province}\n${data.zipcode}`;
            this.parsedAddress.raw = this.address;
            this.emitChanges();
          },
        });
      }, 500);
    },

    watchChanges(address) {
      if (!this.smart) return;
      clearTimeout(this.parseTimer);
      this.parseTimer = setTimeout(() => {
        this.parseAddress(this.address);
        clearTimeout(this.parseTimer);
      }, 500);
    },

    parseAddress(address) {
      address = JSON.parse(JSON.stringify(address));
      if (!address) {
        this.state = 0;
        this.$emit("input", null);
        return;
      }
      this.state = 1;
      let addressToSend = address ? address : (" " + this.address).slice(1);

      // https://a3jczw1yof.execute-api.ap-southeast-1.amazonaws.com/dev/v1/parse-address
      // https://iwjkvg2m94.execute-api.ap-southeast-1.amazonaws.com/dev/parse-address
      let url = `https://a3jczw1yof.execute-api.ap-southeast-1.amazonaws.com/dev/v1/parse-address?text=${encodeURIComponent(
        addressToSend.replace(/\n/g, "~")
      )}`;
      http.get(url).then((response) => {
        let validAddress = this.getValidAddress({
          ...response.data,
          raw2: this.address,
        });
        if (validAddress) {
          this.parsedAddress = validAddress;
          this.emitChanges();
        }
        this.applyThaiAddParse();
      });
    },

    emitChanges() {
      this.parsedAddress.state = this.state;
      this.$emit("input", this.parsedAddress);
    },

    getValidAddress(d) {
      let valid =
        (d.nameAddress && d.zipcode && d.provinceName && d.districtName) ||
        (this.isThaiPost && d.zipcode);
      d.subdistrictName;
      if (valid) this.state = 2;
      else {
        this.state = 3;
      }

      var address, name;
      if (d.nameAddress.includes("~")) {
        let nameAddress = d.nameAddress.split("~");
        name = nameAddress[0];
        address = nameAddress[1];
        if (!address || !address.length)
          address = d.nameAddress.replace(/~/g, "");
      } else {
        name = null;
        address = d.nameAddress.replace(/~/g, "");
      }

      return {
        raw: d.raw2,
        // name,
        address: d.nameAddress.replace(/~/g, " "),
        province: d.provinceName,
        district: d.districtName,
        subDistrict: d.subdistrictName,
        zipcode: d.zipcode,
        nameAddress: d.nameAddress,
        phone: d.phone,
      };
    },
  },

  watch: {
    value: function (newVal, oldVal) {
      if (!this.smart) {
        if (newVal && !_.isEqual(newVal, oldVal)) this.address = newVal;
      }

      // Note: raw property must exist
      if (newVal && !_.isEqual(newVal, oldVal)) {
        if (newVal && newVal.raw) {
          if (newVal.raw) this.address = newVal.raw;
          else
            this.address = `${newVal.address}\n${newVal.district}\n${
              newVal.subDistrict || ""
            }\n${newVal.province}\n${newVal.zipcode}`;
          this.watchChanges();
        }
      }
    },

    isThaiPost: function (newVal) {
      this.watchChanges();
    },

    sm: function () {
      this.applyThaiAddParse();
    },
  },
};
</script>

<style scoped lang="scss">
.clipboardArea {
  background-color: whitesmoke;
  padding: 1rem;
  color: map-get($text-colors, 5);
  text-align: center;
  cursor: pointer;
}

.parsingStatus {
  min-width: 55px;
}

.manualInput {
  input {
    padding: 3px 6px;
    font-size: 12px;
    margin: 0.1rem;
  }
}
</style>