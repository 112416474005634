<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" :data-backdrop="backdrop ? 'static' : false">
    <div class="modal-dialog" :class="custom_dialog_class" role="document">
      <div class="modal-content">
        <div class="modal-header" v-if="hasHeaderSlot">
          <slot name="header"></slot>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    id: {
      type: String
    },
    custom_dialog_class:{
      type: String,
      default: ""
    },
    backdrop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots['header'];
    }
  }
};
</script>

<style>
</style>