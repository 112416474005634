<template>
  <div>
    <!-- Products Table -->
    <Card>
      <div slot="body">
        <div class="products-section">
          <div class="d-flex justify-content-between">
            <div>
              <h5 class="text-color-1">
                {{ $t("shipment.edit.fields.total_weight") }}
                {{ selectedProductsWeight }} gm
              </h5>
              <h6 class="text-color-1">
                {{ $t("shipment.total_selected_products") }}
                {{ selectedProductsCount }}
              </h6>
            </div>
          </div>
          <br />
          <div class="toolbar d-flex justify-content-end mt-4">
            <div class="product-basket d-md-none">
              <button type="button"
                      class="btn btn-primary --circle"
                      data-toggle="modal"
                      data-target="#selected-products-modal">
                <span class="item-count">{{ selectedProductsCount }}</span>
                <i class="fal fa-shopping-basket"></i>
              </button>
            </div>
          </div>

          <h5 class="header-text text-color-4 mb-2">
            {{ $t("shipment.edit.all_products") }}
          </h5>
          <DataTable v-if="product.rows && product.rows.length"
                     id="show-products"
                     ref="productsDatatable"
                     :columns="product.columns"
                     :rows="product.rows"
                     :per_page="5"
                     searchable
                     :search_fields="[
                         'details.itemCode',
                         'details.name',
                         'details.salePrice',
                       ]"
                     :search_placeholder="$t('shipment.create.select_products_field.placeholder')
                       ">
            <div slot="actions"
                 slot-scope="props">
              <input type="number"
                     class="form-control form-control-sm"
                     v-model="products[props.props.rowData.details.proshipCode].qty"
                     @input="emitChange(props.props.rowData.details.proshipCode)" />
            </div>
          </DataTable>
        </div>
      </div>
    </Card>
    <!-- For Desktop -->
    <popper v-if="generalSettings && generalSettings.productMgt"
            trigger="clickToOpen"
            :options="{
                placement: 'top',
                modifiers: { offset: { offset: '0,10px' } },
              }">
      <div class="popper selected-products-popper">
        <h5 class="header-text text-color-4 mb-2">
          {{ $t("shipment.edit.fields.select_products") }}
        </h5>

        <table class="_datatable table --small-height"
               v-if="selectedProductsCount">
          <thead>
            <th>{{ $t("shipment.edit.fields.product_code") }}</th>
            <th>{{ $t("general.quantity") }}</th>
            <th>{{ $t("general.actions") }}</th>
          </thead>
          <tbody>
            <tr v-for="(count, product_code) in selectedProducts"
                :key="product_code">
              <td style="text-align: left"
                  class="d-flex flex-column">
                <span>{{ count["name"] }}</span>
                <span class="--sm"
                      style="font-size: 0.5rem">
                  ({{ product_code }})
                </span>
              </td>
              <td class="--width-sm">
                {{ count["qty"] ? count["qty"] : "N/A" }}
              </td>
              <td style="width: 30px">
                <Button type="button"
                        variant="default"
                        size="sm"
                        :id="'btn-delete-product-' + product_code"
                        @click="removeProduct(product_code)">
                  <i class="fal fa-trash-alt text-danger"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else
             class="alert alert-warning"
             role="alert">
          {{ $t("shipment.edit.fields.no_products") }}
        </div>
      </div>

      <div class="product-basket d-none d-md-block"
           slot="reference">
        <button type="button"
                class="btn btn-primary --circle">
          <span class="item-count">{{ selectedProductsCount }}</span>
          <i class="fal fa-shopping-basket"></i>
        </button>
      </div>
    </popper>

    <!-- For Mobile -->
    <Modal id="selected-products-modal"
           custom_dialog_class="modal-dialog-centered"
           v-if="generalSettings && generalSettings.productMgt">
      <div slot="body">
        <h5 class="header-text text-color-4 mb-2">Selected Products</h5>

        <table class="_datatable table --small-height"
               v-if="selectedProductsCount">
          <thead>
            <th>{{ $t("shipment.edit.fields.product_code") }}</th>
            <th>{{ $t("general.quantity") }}</th>
            <th>{{ $t("general.actions") }}</th>
          </thead>
          <tbody>
            <tr v-for="(count, product_code) in selectedProducts"
                :key="product_code">
              <td style="text-align: left"
                  class="d-flex flex-column">
                <span>{{ count["name"] }}</span>
                <span class="--sm"
                      style="font-size: 0.5rem">
                  ({{ product_code }})
                </span>
              </td>
              <td class="--width-sm">
                {{ count["qty"] ? count["qty"] : "N/A" }}
              </td>
              <td style="width: 30px">
                <Button type="button"
                        variant="default"
                        size="sm"
                        :id="'btn-delete-product-' + product_code"
                        @click="removeProduct(product_code)">
                  <i class="fal fa-trash-alt text-danger"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else
             class="alert alert-warning mt-4"
             role="alert">
          {{ $t("shipment.edit.fields.no_products") }}
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin";
import { mapGetters } from "vuex";
import { i18n } from "@/i18n";
import _ from "lodash";
import Vue from "vue";

export default {
  name: "OrdersProductTable",
  components: {
    Card,
    DataTable,
    Modal,
    Button,
    Popper,
  },
  mixins: [VuetableFieldMixin],

  props: {
    _selectedProducts: {
      type: null,
    },
  },

  data() {
    return {
      products: {},
      product: {
        columns: [
          {
            name: "details.itemCode",
            title: i18n.t("general.item") + " #",
            sortField: "details.itemCode",
            dataClass: "--width-sm --height-sm",
          },

          {
            name: "details.name",
            title: "Product",
            sortField: "details.name",
            dataClass: "--width-md --height-sm",
            formatter: (val) => {
              return val;
            },
          },

          {
            name: "details.variationData",
            title: "รูปแบบ",
            dataClass: "--width-md --height-sm",
            formatter(value) {
              if (!value) return "N/A";
              let variationStr = "";
              Object.keys(value).forEach(
                (key) => (variationStr += `${key}: ${value[key]}<br>`)
              );
              return variationStr;
            },
          },

          {
            name: "details.salePrice",
            title: i18n.t("form.product.sale_price"),
            sortField: "details.salePrice",
            dataClass: "--width-md --height-sm",
            formatter: (val) => {
              return val ? val + " THB" : "N/A";
            },
          },

          {
            name: "details.quantity",
            title: i18n.t("form.product.quantity"),
            sortField: "details.quantity",
            dataClass: "--width-md --height-sm",
            formatter: (val) => {
              return val || "N/A";
            },
          },

          {
            name: "actions",
            title: i18n.t("general.actions"),
            dataClass: "--width-sm --height-sm",
          },
        ],
        rows: [],
      },
      selectedProducts: null,
    };
  },

  created() {
    this.fetchProducts();
    this.selectedProducts = this._selectedProducts;
  },

  mounted() {
    $('product-basket btn.--circle[data-toggle="popover"]').popover();
  },

  methods: {
    emitChange(proshipCode) {
      this.products[proshipCode].qty = Math.max(
        this.products[proshipCode].qty,
        0
      );

      // limit qty to MAX exactQuantity
      if (this.products[proshipCode].qty > this.products[proshipCode].exactQty)
        this.products[proshipCode].qty = this.products[proshipCode].exactQty;

      //filter products wit qty > 0
      let productsToEmit = {};
      Object.keys(this.products).forEach((proshipCode) =>
        this.products[proshipCode].qty > 0
          ? (productsToEmit[proshipCode] = this.products[proshipCode])
          : null
      );

      // add sortIndex when its first added
      if (this.products[proshipCode].qty === 1) {
        productsToEmit[proshipCode].sortIndex =
          Object.keys(productsToEmit).length;
      }

      this.selectedProducts = productsToEmit;
      this.$emit("change", {
        products: productsToEmit,
        selectedProductsCount: this.selectedProductsCount,
        selectedProductsWeight: this.selectedProductsWeight,
        selectedProductsPrice: this.calculateSelectedProductsPrice(),
      });
    },

    removeProduct(proshipCode) {
      if (this.products[proshipCode]) {
        Vue.set(
          this.products[proshipCode],
          "qty",
          Math.max(this.products[proshipCode].qty - 1, 0)
        );
        this.emitChange(proshipCode);
      }
    },

    fetchProducts(callback) {
      this.$store.dispatch("products/fetchProducts", {
        callback: (status, data) => {
          if (status) {
            // map
            for (let i = 0; i < data.length; i++) {
              const product = data[i];
              let pCode = product.details.proshipCode;
              Vue.set(this.products, pCode, {
                id: product.id,
                sku: product.details.itemCode,
                exactQty: product.details.quantity,
                qty:
                  this.selectedProducts && this.selectedProducts[pCode]
                    ? parseInt(this.selectedProducts[pCode].qty)
                    : 0,
                name: product.details.name,
                price: product.details.salePrice,
                weight: product.details.weightApprox,
                bundles: product.details.bundles,
              });
            }
            this.product.rows = data;
            this.$refs.productsDatatable.refresh();
          }
          if (callback) callback();
        },
      });
    },

    calculateProductPrice(qty, unitPrice, bundles) {
      // format bundles
      if (!bundles) return qty * unitPrice;
      bundles = JSON.parse(JSON.stringify(bundles));

      let _bundles = {};
      for (let bundle of bundles) _bundles[bundle.amount] = bundle.price;
      bundles = _bundles;

      let totalPrice = 0;
      let bundleAmounts = Object.keys(bundles).sort((a, b) => b - a); // sort by descending
      let amountPointer = 0;
      while (qty > 0) {
        let amount = bundleAmounts[amountPointer];

        while (qty >= amount) {
          qty -= amount;
          totalPrice += bundles[amount];
        }
        amountPointer++;

        // no bundle left to calculate, now calcualte individually
        if (amountPointer >= bundleAmounts.length) {
          totalPrice += qty * unitPrice;
          qty = 0;
        }
      }

      return totalPrice;
    },

    calculateSelectedProductsPrice() {
      if (!this.products) return 0;
      let t = 0;
      Object.values(this.products).forEach((p) => {
        let temp = this.calculateProductPrice(p.qty, p.price, p.bundles);
        t += temp;
      });
      return t || 0;
    },
  },

  computed: {
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    selectedProductsCount() {
      if (!this.products) return 0;
      let t = 0;
      Object.values(this.products).forEach((p) => (t += parseInt(p.qty)));
      return t || 0;
    },

    selectedProductsWeight() {
      if (!this.products) return 0;
      let t = 0;
      Object.values(this.products).forEach(
        (p) => (t += parseFloat(p.weight * p.qty))
      );
      return t || 0;
    },
  },

  watch: {
    _selectedProducts: function (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) this.selectedProducts = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-basket {
  $btn-size: toRem(70px);
  $count-size: toRem(35px);
  z-index: 1000;

  .btn {
    &.--circle {
      position: absolute;
      $size: toRem(72px);
      height: $btn-size;
      width: $btn-size;
      border-radius: 50%;
      font-size: toRem(24px);
      transform: translate(-75%, -60%);
      z-index: 1000;

      span.item-count {
        $size: toRem(30px);
        position: absolute;
        display: block;
        height: $count-size;
        width: $count-size;
        top: 0;
        left: 0;
        transform: translate(-25%, -25%);
        border-radius: 50%;
        text-align: center;
        line-height: $count-size;
        background-color: white;
        font-size: toRem(12px);
        color: map-get($text-colors, 4);
      }
    }
  }

  @include for-larger-than-phone {
    position: fixed;
    bottom: toRem(70px);
    right: toRem(80px);

    $btn-size: toRem(72px);
    $count-size: toRem(30px);

    &.--circle {
      height: $btn-size;
      width: $btn-size;
      font-size: toRem(24px);

      span.item-count {
        height: $count-size;
        width: $count-size;
        font-size: toRem(14px);
      }
    }
  }
}

.popper {
  min-width: 300px !important;
  min-height: 300px !important;
  @include box-shadow(0, 2px, 5px, map-get($text-colors, 6));
}
</style>