import Vue from 'vue'
import Default from "@/layouts/Default.vue";
import Seller from "@/layouts/Seller.vue";
import Auth from "@/layouts/Auth.vue";
import Onboard from "@/layouts/Onboard.vue";
import Buyer from "@/layouts/Buyer.vue";
import Developer from "@/layouts/Developer.vue";

export const registerLayouts = function () {
    Vue.component('default-layout', Default);
    Vue.component('seller-layout', Seller);
    Vue.component('auth-layout', Auth);
    Vue.component('onboard-layout', Onboard);
    Vue.component('buyer-layout', Buyer);
    Vue.component('developer-layout', Developer);
}
