import { http } from "@/helpers/http";
const baseUrl = "https://oyljzciuy1.execute-api.ap-southeast-1.amazonaws.com/dev/v1";
// const baseUrl = "http://localhost:3000/dev/v1";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {
        fetchProshipCode({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/products/code").then(response => {
                if (payload.callback) payload.callback(true, response.data.proshipCode);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchSku({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/products/sku").then(response => {
                if (payload.callback) payload.callback(true, response.data.sku);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchProducts({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/products" + (payload.showAll ? "?showAll=true" : '')).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchProductDetail({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/products/" + payload.id).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        createProduct({ state, commit, rootState }, payload) {
            http.post(baseUrl + "/products", payload.product).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        updateProduct({ state, commit, rootState }, payload) {
            http.put(baseUrl + "/products/" + payload.id, payload.product).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        deleteProduct({ state, commit, rootState }, payload) {
            http.delete(baseUrl + "/products/" + payload.id).then(response => {
                if (payload.callback) payload.callback(true);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        exportProducts({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/products/reports").then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },
        importProducts({ state, commit, rootState }, payload) {
            http.post(baseUrl + "/products/import", payload.data).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchProductsReportEs({ state, commit, rootState }, payload) {
            http
              .get(baseUrl + "/products/reports-es?from=" + payload.from + "&to=" + payload.to + "&format=" + payload.format)
              .then((response) => {
                if (payload.callback) payload.callback(true, response.data);
              })
              .catch((error) => {
                if (payload.callback) payload.callback(false);
              });
          },
    }
}