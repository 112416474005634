export const checkSurcharge = (SURCHARGE, sm, zipcode) => {
    zipcode = zipcode + "";

    let _SURCHARGE = {
        flash: [
            "84320",
            "84330",
            "84310",
            "84140",
            "84220",
            "84280",
            "84360",
            "81210",
            "83110",
            "83000",
            "83130",
            "83100",
            "83150",
            "83120",
            "71180",
            "71240",
            "50260",
            "50270",
            "50310",
            "50350",
            "55130",
            "55130",
            "55160",
            "55220",
            "58110",
            "58110",
            "58120",
            "58130",
            "58130",
            "58130",
            "58140",
            "58150",
            "63150",
            "63170",
            "67260",
            "20120",
            "23000",
            "23170",
            "81130",
            "81150",
            "82150",
            "82160",
            "84280",
            "84360",
            "92110",
            "92120",
            "94000",
            "94110",
            "94120",
            "94130",
            "94140",
            "94140",
            "94150",
            "94160",
            "94170",
            "94180",
            "94180",
            "94190",
            "94190",
            "94190",
            "94190",
            "94220",
            "94230",
            "95000",
            "95000",
            "95110",
            "95120",
            "95120",
            "95130",
            "95140",
            "95150",
            "95160",
            "95170",
            "96000",
            "96110",
            "96120",
            "96130",
            "96130",
            "96140",
            "96150",
            "96160",
            "96170",
            "96180",
            "96190",
            "96210",
            "96220"
        ],
        jt: [
            "50240", "63170", "50310", "96190", "96140", "96120", "71240", "50250", "58110", "96210", "96150", "96130", "96220", "58130", "58150", "95130", "55220", "67260", "95150", "95170", "51160", "71180", "96110", "50260", "50160", "58140", "57340", "96160", "57180", "58120", "52180", "52230", "50270", "57170", "57260", "50350", "57310", "58000", "95110", "52160", "56160", "81120", "81150", "82160", "83000", "84280", "84360", "23170"
        ],
        kerry: [
            "50240", "50250", "50260", "50270", "50310", "50350", "58130", "58000", "58110", "58120", "58140", "58150", "67260", "63170", "71180", "71240", "23170", "81210", "81150", "51160", "52160", "52180", "55220", "56160", "57170", "57260", "57180", "57310", "57340", "82160", "84320", "84330", "84140", "84310", "84280", "84360", "95110", "95130", "95150", "95170", "95160", "96110", "96120", "96130", "96220", "96140", "96150", "96160", "96190", "96210", "83150", "83120", "83110", "83000", "83130", "83100"
        ],
        shopee: [
            "20120", "23170", "50160", "50240", "50250", "50260", "50270", "50310", "50350", "51160", "52160", "52180", "52230", "55220", "56160", "57170", "57180", "57260", "57310", "57340", "58000", "58110", "58120", "58130", "58140", "58150", "63170", "67260", "71180", "71240", "81120", "81150", "81210", "82160", "84280", "84360", "84370", "94120", "94230", "95110", "95130", "95150", "95160", "95170", "96110", "96120", "96130", "96140", "96150", "96160", "96190", "96210", "96220"
        ]
    }
    if (!SURCHARGE) SURCHARGE = _SURCHARGE;

    if (SURCHARGE[sm] && SURCHARGE[sm].includes(zipcode)) return true;
    return false;
}