import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentTranslationWarn: true,
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  axios({
    url: 'https://proship-dev.s3-ap-southeast-1.amazonaws.com/proship_files/translations.json',
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const blob = new Blob([response.data], { type: 'application/json' });
    var reader = new FileReader();
    reader.onload = () => {
      let json = JSON.parse(reader.result);
      i18n.setLocaleMessage(lang, json);
      setI18nLanguage(lang);
    }
    reader.readAsText(blob);
  });
}
