<template>
  <div class="shipping-chart">
    <div class="chart ml-auto mr-auto mt-4">
      <canvas id="doughnutChart" class="canvas-doughnut"></canvas>
    </div>
    <div class="info d-flex flex-wrap justify-content-between">
      <!-- 1st Column -->
      <div class="d-flex flex-column text-right mt-4">
        <p class="mb-1 --sm">
          {{$t("dashboard.shipments_card.graph_labels.pending")}}
          <span class="order-badge badge-pending">{{chartdata.data.datasets[0].data[0]}}</span>
        </p>
        <p class="text-right --sm">
{{ $t("dashboard.shipments_card.graph_labels.to_ship"),
          }}          <span class="order-badge badge-to-ship">{{chartdata.data.datasets[0].data[1]}}</span>
        </p>
      </div>

      <!-- 2nd Column -->
      <div class="d-flex flex-column text-left mt-4">
        <p class="mb-1 --sm">
          <span class="order-badge badge-shipped">{{chartdata.data.datasets[0].data[2]}}</span>
{{ $t("dashboard.shipments_card.graph_labels.shipped"),
          }}        </p>
        <p class="text-right --sm">
          <span class="order-badge badge-delivered">{{chartdata.data.datasets[0].data[3]}}</span>
            {{ $t("dashboard.shipments_card.graph_labels.delivered"),
          }}
        </p>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <p class="text-right --sm mt-2">
        <span class="order-badge badge-cancelled">{{chartdata.data.datasets[0].data[4]}}</span>
        {{ $t("dashboard.shipments_card.graph_labels.cancelled"),
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DoughnutChart",
  props: {
    chartdata: {
      type: Object
    }
  },
  methods: {
    changeIconColor(i) {
      return "color: " + this.chartdata.data.datasets[0].backgroundColor[i];
    }
  },
  mounted() {
    var doughnutChart = new Chart($("#doughnutChart"), {
      type: "doughnut",
      data: this.chartdata.data,
      options: {
        cutoutPercentage: 70,
        aspectRatio: 1,
        responsive: true,
        legend: false,
        tooltips: false
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.shipping-chart {
  .info {
    max-width: 200px;
    margin: 0 auto;

    p {
      font-size: 14px;
    }
    .badge {
      color: white;
    }
  }

  .chart {
    height: 110px;
    width: 110px;
  }
}

.order-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: white;
}

@each $variant, $color in $order-status-colors {
  &.badge-#{$variant} {
    background-color: $color;
  }
}
</style>