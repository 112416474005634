<template>
  <div>
    <label>{{ label }}</label>
    <div class="imageUpload d-flex justify-content-center">
      <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        @edit-image="editImage"
        :data-images="images"
        idUpload="imageUploader-1"
        editUpload="imageUploadEdit"
        :dragText="dragText"
        browseText
        primaryText
        markIsPrimaryText
        popupText
        dropText
        :showPrimary="false"
        :maxImage="5"
      ></vue-upload-multiple-image>
    </div>
  </div>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { http } from "@/helpers/http";
import {i18n} from "@/i18n";

export default {
  name: "ImageUpload",
  components: { VueUploadMultipleImage },
  props: {
    values: {
      type: Array,
    },
    dragText: {
      type: String,
      default: "อัปโหลดภาพสินค้า",
    },
    label: {
      type: String,
      default: "อัปโหลดภาพสินค้า",
    },
  },

  data() {
    return {
      images: null,
    };
  },

  created() {
    if (this.values)
      this.images = this.values.map((imgPath) => {
        return {
          path: imgPath,
        };
      });
    else this.images = [];
    this.emitUpdate();
  },

  methods: {
    uploadImageSuccess(formData, index, fileList) {
      let url =
        "https://8enalpa7e5.execute-api.ap-southeast-1.amazonaws.com/dev/v1/media";
      // let url = "http://localhost:3000/dev/v1/media";
      http.post(url, formData).then((response) => {
        let newImageData = response.data.urls.map((url) => {
          return { highlight: 0, path: url };
        });
        this.images = this.images.concat(newImageData);
        this.emitUpdate();
      });
    },

    beforeRemove(index, done, fileList) {
      var r = confirm("remove image");
      if (r == true) {
        done();
        this.images.splice(index, 1);
        this.emitUpdate();
      }
    },

    editImage(formData, index, fileList) {
      let url =
        "https://8enalpa7e5.execute-api.ap-southeast-1.amazonaws.com/dev/v1/media";
      // let url = "http://localhost:3000/dev/v1/media";
      http.post(url, formData).then((response) => {
        // debugger;
        let newImageData = response.data.urls.map((url) => {
          return { highlight: 0, path: url };
        });
        this.images = this.images.concat(newImageData);
        // this.images = [];
        this.images = response.data.urls;
        this.emitUpdate();
      });
    },

    emitUpdate() {
      this.$emit(
        "input",
        this.images.map((img) => img.path)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.imageUpload {
  ::v-deep [editupload="imageUploadEdit"] {
    width: 100%;
    .image-container {
      height: 100px;
      width: 100%;
    }

    .preview-image {
      height: 100px;
      width: 100px;
      margin: 0 auto;
    }
  }
}
</style>
