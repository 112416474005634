<template>
  <button class="btn btn-default btn-sm" @click="copyHandler()">
    <i class="fal fa-copy"></i>
  </button>
</template>

<script>
import { Toast } from "@/helpers/toastr";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

export default {
  name: "CopyButton",
  props: {
    text: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      textContent: null,
    };
  },

  mounted() {
    this.textContent = this.text;
  },
  methods: {
    async copyHandler(type) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(this.text);
        Toast("Copied to Clipboard", "success");
      } else Toast("Error copying", "danger");
    },
  },
};
</script>

<style scoped>
</style>