<template>
  <div class="sub-nav pt-2">
    <div class="container">
      <div class="stepper-section">
        <div class="row">
          <div class="col-12">
            <div class="steps">
              <div class="d-flex justify-content-around">
                <template v-for="(step, i) in steps">
                  <div
                    class="step"
                    :class="{'--active': currentStepIndex - 1 === i, '--completed': i < currentStepIndex - 1, '--small': !has_number}"
                    :key="i"
                  >
                    <h5 class="text-center">{{i+1}}</h5>
                  </div>
                  <div
                    class="line align-self-center"
                    :class="{'--complete': i < currentStepIndex - 1, '--small': !has_number }"
                    v-if="i < steps - 1"
                    :key="'line-' + i"
                  >
                    <br />
                    <div></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info-section">
        <div class="row">
          <div class="col-12">
            <h6 class="header-text text-color-2">{{info}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stepper",

  props: {
    steps: {
      type: Number,
      required: true
    },
    currentStepIndex: {
      type: Number,
      required: true
    },
    variant: {
      type: String,
      default: "primary"
    },
    info: {
      type: String
    },
    has_number: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
$step-size: toRem(52px);
$step-color: #aaaaaa;

$step-focus-color: $brand-blue-mid;
.stepper-section {
  margin: 0 toRem(20px) toRem(60px) toRem(20px);
  .steps {
    .line {
      flex: 1;
      height: 6px;
      width: 100%;
      background: #cdcdcd;
    }
    .step {
      position: relative;
      width: $step-size;
      height: $step-size;
      text-align: center;
      line-height: $step-size;
      border-radius: 50%;
      color: #fff;
      background-color: $step-color;
      h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.--active {
        background-color: $step-focus-color;
      }
    }
    &.--small {
      height: 5px;
    }
  }
}

.info-section {
  margin-left: -15px;
}

@media (max-width: 700px) {
  $step-size: toRem(35px);
  .stepper-section {
    margin: 0 0 30px 0;
    .steps {
      .line {
        height: 2px;
      }
      .step {
        width: $step-size;
        height: $step-size;
        line-height: $step-size;
      }
    }
  }
}
</style>