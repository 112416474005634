<template>
  <div class="form-buttons">
    <div
      class="submit-next-buttons d-flex justify-content-center justify-content-md-end"
    >
      <div class="go-back-button">
        <div class="text-center" v-if="show_secondary_button">
          <button
            type="button"
            class="btn btn-outline-primary onboard-button"
            @click="on_secondary_button"
          >
            <i
              v-if="secondary_button_icon_class"
              :class="secondary_button_icon_class"
            ></i>
            {{ secondary_button_text }}
          </button>
        </div>
      </div>
      <div class="submit-button">
        <div class="text-center">
          <Button
            id="btn-onboard-next"
            type="submit"
            variant="primary"
            custom_class="onboard-button"
            @click="on_next"
            :disabled="active"
            :loading="loading"
            >{{ next_button_text }}</Button
          >
          <!-- <button
            type="submit"
            class="btn btn-primary onboard-button"
            @click="on_next"
            :disabled="active"
          >{{next_button_text}}</button>-->
          <router-link :to="{ name: 'Seller-Home' }" class="skip-link">{{
            $t("general.skip")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button";
import { i18n } from "@/i18n.js";

export default {
  name: "OnboardButtons",
  components: { Button },

  props: {
    has_secondary_button: {
      type: Boolean,
      default: true,
    },
    secondary_button_text: {
      type: String,
      default: i18n.t("general.back"),
    },
    secondary_button_icon_class: {
      type: String,
    },
    next_button_text: {
      type: String,
      default: i18n.t("general.next"),
    },
    next_button_icon_class: String,
    on_next: {
      type: Function,
    },
    on_secondary_button: {
      type: Function,
    },
    active: {
      type: Boolean,
    },
    show_secondary_button: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.cancel-link {
  color: $brand-blue-mid !important;
  font-weight: 500;
}

.skip-link {
  display: block;
  font-size: 12px;
  text-align: right;
  margin-top: 5px;
  :hover {
    cursor: pointer;
  }
}

.form-buttons {
  padding: toRem(40px) toRem(20px);
  width: 100%;
  align-items: center;

  @include for-phone-only {
    padding: 0;
  }

  @include for-laptops-only {
    padding: toRem(10px);
  }
}

.submit-next-buttons {
  @include for-phone-only {
    display: flex;
    justify-content: center;
  }
}

.onboard-button {
  padding: toRem(10px) toRem(44px) !important;
  @include for-phone-only {
    padding: toRem(10px) toRem(20px) !important;
  }
}

i {
  margin-right: 6px;
}

.btn-outline-primary {
  border: toRem(1.5px) solid;
}
</style>
