import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// styles
import "./styles/main.scss";

// layouts
import { registerLayouts } from "./helpers/layouts";
registerLayouts();

// axios setup
import { setupHttp } from "./helpers/http";
setupHttp();

Vue.config.productionTip = false;

// form validator
import { applyValidators } from "./helpers/validators";
applyValidators();

// Toast
import { initToastr } from "./helpers/toastr";
initToastr();

// Filters
import { registerFilters } from "./helpers/filters";
registerFilters();

import { i18n } from "./i18n";
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
