<template>
  <div class="reports-page">
    <div class="content-header">
      <h4 class="text-color-2">รายงานการจัดส่ง</h4>
    </div>

    <div class="row">
      <div class="col-12">
        <Card>
          <div slot="body">
            <div v-if="loading">
              <Spinner size="md"
                       variation="primary" />
            </div>

            <div v-else>
              <!-- Toolbar -->
              <div class="d-flex mb-4">
                <DatePicker id="date-range"
                            custom_class="align-self-center mr-2 mb-0"
                            v-model="shipmentsReportForm.fromDate"
                            :placeholder="'เลือกวันที่เริ่ม'" />

                <DatePicker id="date-range"
                            custom_class="align-self-center mr-2 mb-0"
                            v-model="shipmentsReportForm.toDate"
                            :placeholder="'เลือกวันที่สิ้นสุด'" />

                <Select id="shops"
                        form_group_class="align-self-center mr-4 mb-0"
                        :options="shopOptions"
                        v-model="shipmentsReportForm.shopId" />

                <Select id="status"
                        form_group_class="align-self-center mr-4 mb-0"
                        :options="statusOptions"
                        v-model="shipmentsReportForm.status" />

                <!-- <Button
                  id="btnGetOrders"
                  custom_class="align-self-center mr-2"
                  variant="info"
                  size="sm"
                  :disabled="shipmentsReportForm.loading"
                  :loading="shipmentsReportForm.loading"
                  @click="getShipmentsReports()"
                  >กดสร้างรายงาน</Button
                > -->

                <Button id="btnExport"
                        variant="success"
                        size="sm"
                        @click="getShipmentsReports('csv')"
                        :disabled="shipmentsReportForm.loading"
                        :loading="shipmentsReportForm.loading"
                        custom_class="align-self-center">โหลดไฟล์ Excel</Button>
              </div>
            </div>

            <div v-if="shipments && shipments.length">
              <div class="d-flex justify-content-between mb-2">
                <h6 class="mr-4 align-self-center text-color-4">
                  รวม {{ shipments.length }} รายการ
                </h6>
                <Button id="btnExport"
                        variant="success"
                        size="sm"
                        @click="getShipmentsReports('csv')"
                        :disabled="shipmentsReportForm.loading"
                        :loading="shipmentsReportForm.loading"
                        custom_class="align-self-center">โหลดไฟล์ Excel</Button>
              </div>
              <DataTable id="shipments"
                         custom_class="orders-table"
                         :columns="shipmentsColumn"
                         :rows="shipments"
                         :per_page_range="[100, 200, 300, 500]"
                         responsive />
            </div>
          </div>
        </Card>
      </div>
    </div>

    <h4 class="text-color-2 mt-5 mb-2">รายงานสินค้า</h4>
    <div class="row">
      <div class="col-12">
        <Card>
          <div slot="body">
            <div v-if="loading">
              <Spinner size="md"
                       variation="primary" />
            </div>

            <div v-else>
              <!-- Toolbar -->
              <div class="d-flex mb-4">
                <DatePicker id="date-range"
                            custom_class="align-self-center mr-2 mb-0"
                            v-model="productsReportForm.fromDate"
                            :placeholder="'เลือกวันที่เริ่ม'" />

                <DatePicker id="date-range"
                            custom_class="align-self-center mr-2 mb-0"
                            v-model="productsReportForm.toDate"
                            :placeholder="'เลือกวันที่สิ้นสุด'" />



                <Button id="btnGetOrders"
                        custom_class="align-self-center mr-2"
                        variant="info"
                        size="sm"
                        :disabled="productsReportForm.loading"
                        :loading="productsReportForm.loading"
                        @click="getProductsReports()">กดสร้างรายงาน</Button>

                <Button id="btnExport"
                        variant="success"
                        size="sm"
                        @click="getProductsReports('csv')"
                        :disabled="productsReportForm.loading"
                        :loading="productsReportForm.loading"
                        custom_class="align-self-center">โหลดไฟล์ Excel</Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <Card v-if="productsData">
      <div slot="body">

        <h4 class="mb-5">Total: {{ productsData.totalProducts }}</h4>

        <h6>Products</h6>
        <hr>
        <p v-for="(value, key) of productsData.products"
           :key="key">
          {{ key }}: {{ value }}
        </p>
      </div>
    </Card>
  </div>
</template>

<script>
import DatePicker from "@/components/core/DatePicker";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import Select from "@/components/core/Select";
import DataTable from "@/components/core/DataTable";
import moment from "moment-timezone";
import { i18n } from "@/i18n";
import { FILTERS } from "@/helpers/filters";
import { Toast } from "@/helpers/toastr";
import { getOrderStatus } from "@/helpers/core";
import fileDownload from "js-file-download";
import Spinner from "@/components/core/Spinner";

export default {
  name: "ShipmentsReport",
  components: { Card, DataTable, DatePicker, Button, Spinner, Select },

  data() {
    return {
      loading: false,
      shipmentsReportForm: {
        fromDate: null,
        toDate: null,
        shopId: null,
        status: null,
        loading: false,
      },
      shops: null,
      shopOptions: null,
      shipments: null,
      shipmentsColumn: [
        {
          name: "details.trackingNo",
          title: "เลขแทร็ค",
          sortField: "details.trackingNo",
          formatter(value) {
            return value || "N/A";
          },
        },

        {
          name: "details.status",
          title: "สถานะ",
          sortField: "details.status",
          formatter: (value) => {
            let statusData = getOrderStatus(value);
            if (!statusData) return value;
            return `<span class="badge badge-pill text-uppercase status-badge --${statusData.variation}">${statusData.title}</span>`;
          },
        },

        {
          name: "details.customer.name",
          title: "ผู้รับ",
          sortField: "details.customer.name",
        },

        {
          name: "details.customer",
          title: "เบอร์โทร",
          formatter(value) {
            return value.phoneNo || value.mobileNo;
          },
        },

        {
          name: "details.customer.address.address",
          title: "ที่อยู่",
          sortField: "details.customer.address.address",
        },

        {
          name: "details.customer.address.zipcode",
          title: "รหัสปณ.",
          sortField: "details.customer.address.zipcode",
        },

        {
          name: "details.remarks",
          title: "หมายเหตุ",
          sortField: "details.remarks",
          formatter(value, d) {
            return value || "N/A";
          },
        },

        {
          name: "createdAt",
          title: "วันที่สร้าง",
          sortField: "createdAt",
          formatter(value) {
            let dateTime = FILTERS.formatDateTime(value).split(",");
            return `${dateTime[0]}<br><span class="--text-sm">${dateTime[1]}</span>`;
          },
        },
      ],

      productsReportForm: {
        fromDate: null,
        toDate: null,
        shopId: null,
        status: null,
        loading: false,
      },
      productsData: null
    };
  },

  created() {
    this.fetchShops();
  },

  methods: {
    getShipmentsReports(format = "json") {
      if (format == "json") this.shipments = null;
      this.shipmentsReportForm.loading = true;

      let from = moment
        .tz(this.shipmentsReportForm.fromDate, "Asia/Bangkok")
        .startOf("day")
        .valueOf();
      let to = moment
        .tz(this.shipmentsReportForm.toDate, "Asia/Bangkok")
        .endOf("day")
        .valueOf();
      if (!from || !to) {
        Toast("From & To dates must exist", "danger");
        this.shipmentsReportForm.loading = false;
        return;
      }
      this.$store.dispatch("orders/fetchOrdersReport", {
        from,
        to,
        format,
        shopId: this.shipmentsReportForm.shopId,
        status: this.shipmentsReportForm.status,
        callback: (status, data) => {
          if (status) {
            if (format == "csv") {
              window.open(data.message, "_blank");
            } else this.shipments = data.Items;
          }
          this.shipmentsReportForm.loading = false;
        },
      });
    },

    fetchShops() {
      this.loading = true;
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shops = data;
            this.shops.forEach((shop) => { });
            if (this.shops) {
              this.shopOptions = this.shops.map((shop) => {
                return {
                  name: shop.details.name,
                  value: shop.id,
                };
              });
              this.shopOptions.unshift({ name: "All", value: "all" });
              this.shipmentsReportForm.shopId = this.shopOptions[0].value;
            }
          }
          this.loading = false;
        },
      });
    },

    getProductsReports(format = "json") {
      if (format == "json") this.products = null;
      this.productsReportForm.loading = true;

      let from = moment
        .tz(this.productsReportForm.fromDate, "Asia/Bangkok")
        .startOf("day")
        .valueOf();
      let to = moment
        .tz(this.productsReportForm.toDate, "Asia/Bangkok")
        .endOf("day")
        .valueOf();
      if (!from || !to) {
        Toast("From & To dates must exist", "danger");
        this.productsReportForm.loading = false;
        return;
      }
      this.$store.dispatch("products/fetchProductsReportEs", {
        from,
        to,
        format,
        callback: (status, data) => {
          if (status) {
            if (format == "csv") {
              window.open(data.message, "_blank");
            } else this.productsData = data;
          }
          this.productsReportForm.loading = false;
        },
      });
    }
  },

  computed: {
    statusOptions() {
      let arr = [{ name: i18n.t("tabs.all"), value: 0 }];
      let ORD_STS = getOrderStatus();
      Object.keys(ORD_STS).forEach((status) => {
        arr.push({ name: ORD_STS[status].title, value: status });
      });
      return arr;
    },
  },
};
</script>

<style lang="scss"
       scoped>
      .reports-page {
        margin: 35px;
      }
    </style>