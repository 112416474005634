<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">{{ $t("settings.api.label") }}</h4>
    </div>
    <div class="mb-4"></div>

    <Card grey>
      <div slot="body">
        <div v-if="loading">
          <Spinner size="sm" />
        </div>

        <div v-else style="width: 305px; overflow-wrap: break-word">
          {{ authToken }}
          <button
            class="btn btn-default btn-sm"
            v-clipboard:copy="authToken"
            v-clipboard:success="() => copyHandler('success')"
            v-clipboard:error="() => copyHandler('danger')"
          >
            <i class="fal fa-copy"></i>
          </button>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Toggle from "@/components/core/Toggle";
import Spinner from "@/components/core/Spinner";
import { i18n } from "@/i18n";
import { Toast } from "@/helpers/toastr";

export default {
  name: "SellerSettingsApi",

  components: {
    Card,
    Toggle,
    Spinner,
  },

  data() {
    return {
      loading: false,
      authToken: null,
    };
  },

  created() {
    // this.loading = true;
    this.authToken = JSON.parse(localStorage.getItem("_auth")).token;
  },

  mounted() {},

  methods: {
    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },
  },
};
</script>

<style lang="scss" scoped>
.setting__header {
  font-weight: bold;
  font-size: 18px;
}
.setting__description {
  font-weight: thin;
  font-size: 13px;
}

.setting {
  &.--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.--table {
    .setting__text {
      margin-bottom: 20px;
    }
  }

  margin-bottom: 30px;
}

.settings-wrapper {
  margin-top: 24px;
}

.setting-status-tag {
  text-transform: uppercase;

  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
  width: 68px;
  height: 29px;

  &.--warning {
    background-color: #ffc107;
    color: black;
  }

  &.--success {
    background-color: #28a745;
    color: white;
  }

  &.--danger {
    background-color: #dc3545;
    color: white;
  }

  &.--info {
    background-color: #007bff;
    color: white;
  }
}
</style>

