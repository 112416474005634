<template>
  <div v-if="loading">
    <div class="container p-5 m-5">
      <Spinner size="md" />
    </div>
  </div>
  <div v-else class="orders-page">
    <div class="content-header">
      <div class="container-fluid">
        <div class="d-flex justify-content-between">
          <h4 class="font-weight-light">
            {{ $t("general.view") }}
            <span class="font-weight-bold">{{ $t("shipment.label") }}</span>
          </h4>
          <div class="buttons">
            <router-link
              :to="{ name: 'Seller-PreShipment-Create' }"
              class="btn btn-outline-success btn-sm mr-2"
              >Create another Order</router-link
            >

            <router-link
              :to="{
                name: 'Seller-PreShipment-Edit',
                params: { id: $route.params.id },
              }"
              class="btn btn-outline-primary btn-sm mr-2"
              >{{ $t("general.edit") }}</router-link
            >

            <Button
              variant="outline-danger"
              size="sm"
              custom_class="mr-2"
              @click="deleteOrder()"
              :loading="cancelOrderLoading"
              :disabled="cancelOrderLoading"
              >{{ $t("general.cancel") }}</Button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <div class="customer-details">
              <Card>
                <div slot="body">
                  <div
                    class="
                      customer-header
                      d-flex
                      flex-wrap
                      justify-content-between
                    "
                  >
                    <div class="header-left">
                      <span class="text-color-4"
                        >#{{ orderDetails.details.orderCode }}</span
                      >
                      <h4 class="header-text text-color-2">
                        {{ orderDetails.details.customer.name }}
                      </h4>
                      <span class="text-color-5">{{
                        orderDetails.details.customer.email
                      }}</span>
                    </div>
                    <div class="header-right">
                      <button
                        :class="
                          'status-button bg-' +
                          orderStatus(orderDetails.details.status).variation +
                          ' btn align-self-center mt-2'
                        "
                      >
                        <i class="fas fa-truck mr-3 ml-1"></i>
                        <span class="body-text">{{
                          orderStatus(orderDetails.details.status).title
                        }}</span>
                      </button>
                      <br />

                      <span class="weight text-color-4 mr-2"
                        >{{ orderDetails.details.weight }} gm</span
                      >
                      <span
                        class="weight float-right text-color-4"
                        v-if="orderDetails.details.actualWeight !== 'N/A'"
                      >
                        <mark>{{ orderDetails.details.actualWeight }} gm</mark>
                      </span>
                    </div>
                  </div>
                  <p class="text-color-4 --sm">
                    {{ $t("general.created_at") }}:
                    {{ orderDetails.createdAt | formatDateTime }}
                  </p>

                  <hr class="mt-0" />
                  <div class="customer-body">
                    <h6 class="text-color-2 header-text">
                      {{ $t("shipment.view.contact_information.label") }}
                    </h6>
                    <br />
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-6">
                          <table border="0" class="w-100">
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.phone"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  <a
                                    :href="
                                      'tel:' +
                                      orderDetails.details.customer.phoneNo
                                    "
                                    v-if="orderDetails.details.customer.phoneNo"
                                    >{{
                                      orderDetails.details.customer.phoneNo
                                    }}</a
                                  >
                                  <span v-else>"N/A"</span>
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.mobile"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  <a
                                    :href="
                                      'tel:' +
                                      orderDetails.details.customer.mobileNo
                                    "
                                    v-if="
                                      orderDetails.details.customer.mobileNo
                                    "
                                    >{{
                                      orderDetails.details.customer.mobileNo
                                    }}</a
                                  >
                                  <span v-else>"N/A"</span>
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("form.shipment.customer_fb_line") }}
                                  <span
                                    v-if="
                                      orderDetails.details.customer
                                        .showSocialLinkOnLabel
                                    "
                                  >
                                    <i
                                      class="fal fa-print text-success ml-1"
                                    ></i>
                                  </span>
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer.socialLink ||
                                    "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("form.shipment.sales_channel") }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer
                                      .salesChannel || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.province"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer.address
                                      .province || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.district"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer.address
                                      .district || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.sub_district"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer.address
                                      .subDistrict || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.postal_code"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer.address
                                      .zipcode || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>
                            <tr v-if="orderDetails.details.customer.address">
                              <td class="text-left" colspan="2">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.address"
                                    )
                                  }}
                                </p>
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.customer.address
                                      .address || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-md-6">
                          <table border="0" class="w-100 mt-4 mt-md-0">
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{ $t("shipment.edit.fields.sales_person") }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ salesPerson }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.agent"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.user || "N/A" }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.shipper"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.shippingMethod || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <!-- payment Mode -->
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  Payment Mode
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.paymentMode || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <!-- Total Sales Price -->
                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
                                    $t("shipment.edit.fields.total_sales_price")
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.totalSalesPrice ||
                                    "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <!-- Discount -->
                            <tr v-if="orderDetails.details.discountAmount">
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
                                    $t("shipment.edit.fields.discount_field")
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.discountAmount || "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
                                    $t(
                                      "shipment.edit.fields.shipping_cost_charged"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{
                                    orderDetails.details.shippingCostCharged ||
                                    "N/A"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left">
                                <p class="text-color-5 body-text">
                                  {{
                                    $t("shipment.view.contact_information.cod")
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="text-color-4">
                                  {{ orderDetails.details.codAmount || "N/A" }}
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-left" colspan="2">
                                <p class="text-color-5">
                                  {{
                                    $t(
                                      "shipment.view.contact_information.remarks"
                                    )
                                  }}
                                </p>
                                <p class="text-color-4">
                                  {{ orderDetails.details.remarks || "N/A" }}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="order-items-list">
              <Card v-if="products">
                <div slot="body">
                  <h5 class="header-text text-color-4 mb-3">
                    {{ $t("shipment.view.order_items.label") }}
                  </h5>

                  <table class="_datatable table --small-height">
                    <thead>
                      <th>{{ $t("general.item") }}</th>
                      <th>{{ $t("general.quantity") }}</th>
                      <th>{{ $t("general.price") }}</th>
                      <th>{{ $t("general.weight") }}</th>
                    </thead>
                    <tbody>
                      <tr v-for="(product, index) in products" :key="index">
                        <td class="--width-sm --image">
                          <a
                            :href="
                              product.id
                                ? '#/seller/products/' + product.id
                                : null
                            "
                          >
                            {{ product.sku + " " + product.name }}
                          </a>
                        </td>
                        <td class="--width-sm">{{ product.qty }}</td>
                        <td class="--width-sm">
                          <span class="--text-sm text-color-4"
                            >{{
                              calculateProductPrice(
                                product.qty,
                                product.price,
                                product.bundles
                              ).rate
                            }}x{{ product.qty }}</span
                          >
                          <br />
                          {{
                            calculateProductPrice(
                              product.qty,
                              product.price,
                              product.bundles
                            ).price
                          }}
                          THB
                        </td>
                        <td class="--width-sm">
                          <span class="--text-sm text-color-4"
                            >{{ product.weight }}x{{ product.qty }}</span
                          >
                          <br />
                          {{ product.weight * product.qty }} gm
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
            <div class="order-history-list">
              <Card>
                <div class="title"></div>
                <div slot="body">
                  <h5 class="header-text text-color-4">
                    {{ $t("shipment.view.order_history.label") }}
                  </h5>
                  <br />
                  <table class="_datatable table --small-height">
                    <thead>
                      <th>
                        {{ $t("shipment.view.contact_information.agent") }}
                      </th>
                      <th>{{ $t("status.label") }}</th>
                      <th>{{ $t("general.date") }}</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(history, index) in orderDetails.details
                          .histories"
                        :key="index"
                      >
                        <td class="--width-sm">{{ history.user }}</td>
                        <td
                          class="--width-sm"
                          v-html="status(history.status)"
                        ></td>
                        <td class="--width-md">
                          {{ history.timestamp | formatDateTime }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>

            <div class="bank-details" v-if="orderDetails.details.bank && orderDetails.details.bank.name">
              <Card>
                <div slot="body">
                  <h5 class="header-text text-color-3 mb-4">
                    {{ orderDetails.details.bank.name }}
                  </h5>
                  <p class="text-color-4">
                    {{ $t("bank_input.amount") }}:
                    {{ orderDetails.details.bank.amount }}
                  </p>
                  <p class="text-color-4">
                    {{ $t("bank_input.date") }}:
                    {{ orderDetails.details.bank.date | formatDate }} <br />
                    {{ $t("bank_input.time") }}:
                    {{ orderDetails.details.bank.time }}
                  </p>

                  <template
                    v-if="
                      orderDetails.details.bank.slip &&
                      orderDetails.details.bank.slip.length
                    "
                  >
                    <img
                      v-for="slip in orderDetails.details.bank.slip"
                      :src="slip"
                      :key="slip"
                      class="mt-3 img-fluid"
                    />
                  </template>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Alert } from "@/helpers/alert";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import DataTable from "@/components/core/DataTable";
import Spinner from "@/components/core/Spinner";
import Modal from "@/components/core/Modal";
import { getOrderStatus } from "@/helpers/core";
import moment from "moment";
import { i18n } from "@/i18n";
import { http } from "@/helpers/http";

export default {
  name: "Orders-Show",
  components: {
    Card,
    Button,
    DataTable,
    Spinner,
    Modal,
  },
  data() {
    return {
      allowedStatus: [1],
      loading: false,
      statusUpdateLoading: false,
      printLabelLoading: false,
      cancelOrderLoading: false,
      shipmentTrackingsLoading: false,
      products: null,
      orderDetails: null,
      printLabels: null,
      selectedPaperSize: localStorage.getItem("paperSize"),

      shipmentTrackings: [],
      thpDetails: null,
      printUrl: null,
    };
  },

  created() {
    this.fetchOrder();
  },

  methods: {
    fetchOrder(callback) {
      this.loading = true;
      this.$store.dispatch("orders/fetchOrderDetail", {
        id: this.$route.params.id,
        callback: async (status, data) => {
          if (status) {
            this.orderDetails = data;
            this.orderDetails.details.status
              ? this.orderDetails.details.status
              : 1;
            this.loading = false;

            if (data.details.products) {
              this.products = [];
              let keys = Object.keys(data.details.products);
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                let bundles = await this.fetchProductsBundlePrice(
                  data.details.products[key].id
                );
                this.products.push({
                  id: data.details.products[key].id,
                  sku: data.details.products[key].sku || key,
                  name: data.details.products[key].name,
                  code: key,
                  qty: data.details.products[key].qty,
                  price: data.details.products[key].price || 0,
                  weight: data.details.products[key].weight,
                  bundles,
                });
              }
            }
            if (callback) callback();
          }
        },
      });
    },

    async fetchProductsBundlePrice(id) {
      try {
        let bundlesArr = (
          await http.get(
            "https://oyljzciuy1.execute-api.ap-southeast-1.amazonaws.com/dev/v1/products/" +
              id
          )
        ).data.details.bundles;
        if (!bundlesArr) return null;

        let bundles = {};
        for (let i = 0; i < bundlesArr.length; i++) {
          const b = bundlesArr[i];
          bundles[b.amount] = b.price;
        }
        return bundles;
      } catch (e) {
        console.log(e);
        return null;
      }
    },

    orderStatus(i) {
      var status = getOrderStatus(i);
      return status;
    },

    deleteOrder() {
      Alert(
        i18n.t("general.cancel"),
        i18n.t("alert.cancel.message"),
        null,
        () => {
          this.cancelOrderLoading = true;
          this.$store.dispatch("preShipments/cancelPreShipment", {
            id: this.$route.params.id,
            callback: (status) => {
              if (status)
                // this.$router.push({ name: "Seller-PreShipment-List" });
              this.cancelOrderLoading = false;
            },
          });
        }
      );
    },

    status(value) {
      let statusData = getOrderStatus(value);
      return `<span class="badge badge-pill text-uppercase status-badge badge-${statusData.variation}">${statusData.title}</span>`;
    },

    formatEmsTrackingDate(date) {
      return moment(date, "DD/MM/YYYY HH:mm:ss+-").format(
        "DD MMM YYYY, h:mm:ss A"
      );
    },

    back() {
      this.$router.push({ name: "Seller-Order-List" });
    },

    calculateProductPrice(qty, unitPrice, bundles) {
      if (!bundles) return qty * unitPrice;
      else {
        let price = 0;
        let sortedAmounts = Object.keys(bundles)
          .map((p) => parseInt(p))
          .sort((a, b) => a - b);
        let runLoop = true;
        let count = sortedAmounts.length * 10;

        while (runLoop && count > 0) {
          let maxAmount = sortedAmounts.pop();
          if (qty >= maxAmount) {
            price += qty * bundles[maxAmount];
            return { price, rate: bundles[maxAmount] };
          } else {
            if (sortedAmounts.length === 0 || count === 0) runLoop = false;
          }
          count--;
        }

        return { price: price + qty * unitPrice, rate: unitPrice };
      }
    },
  },

  computed: {
    salesPerson() {
      if (this.orderDetails.details.salesPerson) {
        if (this.orderDetails.details.salesPerson.name)
          return this.orderDetails.details.salesPerson.name;
        else this.orderDetails.details.salesPerson;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  margin-top: 100px;
}
.shipping-label {
  width: toRem(150px);
  height: toRem(100px);
  text-align: center;
  line-height: toRem(100px);
  border-radius: toRem(10px);
  margin: 1rem;
  cursor: pointer;
}

.orders-page {
  // margin: 35px;
  .content-body {
    .card-body {
      // padding: 20px;
      // col-6 span {
      //   margin-right: 10px;
      // }

      .right-info {
        background: $dashboard-background;
      }
    }
  }
}
@include for-phone-only {
  .orders-page {
    margin: auto 0;
    .content-body .card-body {
      margin: 0;
      .row {
        margin: 0 !important;
        padding: 0 !important;
        .col-6 {
          padding: 0 !important;
        }
      }
      .right-info {
        background: #fff;
      }
    }
  }
}

.productListImage {
  height: 10px;
  width: auto;
  max-width: 100px;
}

.shipping-label-format {
  text-align: center;
  padding: 0.5rem;
  background-color: rgba($brand-blue-light, 0.1);
  border-radius: toRem(10px);
  margin: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    height: toRem(100px);
    width: auto;
  }
  span {
    margin-top: 0.25rem;
    font-size: 0.8rem;
  }
}

.printerFormatContainer {
  width: 210px;

  @include for-larger-than-phone {
    width: 420px;
  }

  margin: 0 auto;
  overflow: auto;
  .printerFormat {
    width: 200px;
    min-height: 50px;
    background-color: rgb(252, 252, 252);
    padding: 0.5rem;
    border-radius: 3px;
    margin: 0.25rem;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    .icon {
      height: 2rem;
      width: auto;
      margin-right: 0.5rem;
      margin-top: 0.4rem;
    }
  }
}
</style>
