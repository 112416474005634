<template>
  <div class="text-center">
    <OnboardHeader
      :title="$t('onboard.shipping_method.title')"
      :subtitle="$t('onboard.shipping_method.subtitle')"
    >
      <img src="@/assets/images/onboard/truck.svg" class="onboard-icon" slot="img" />
    </OnboardHeader>

    <div class="onboard-content">
      <div class="onboard-form-wrapper">
        <ShippingMethodInput v-model="shippingMethods" :values="['ems']" rules="required" :options="shippingMethodOptions" />
      </div>
    </div>

    <OnboardButtons
      :on_next="next"
      :active="invalid"
      :on_secondary_button="back"
      :loading="loading"
    />
  </div>
</template>

<script>
import Select from "@/components/core/Select";
import Card from "@/components/core/Card";
import ShippingMethodInput from "@/components/derived/ShippingMethodInput";
import OnboardButtons from "@/components/derived/onboard/OnboardButtons";
import OnboardHeader from "@/components/derived/onboard/OnboardHeader";
import { shippingInfo } from "@/helpers/core";

export default {
  name: "Onboard-Shipping",
  components: {
    Select,
    Card,
    OnboardButtons,
    OnboardHeader,
    ShippingMethodInput
  },

  data() {
    return {
      loading: false,
      shippingMethods: [],
      shippingMethodOptions: ['ems', 'self-ship'].map(sm => shippingInfo(sm))
    };
  },

  methods: {
    next() {
      this.loading = true;
      if (this.shippingMethods) {
        this.$store.dispatch("onboard/storeshippingMethods", {
          shippingMethods: this.shippingMethods,
          callback: data => {
            this.$store.dispatch("onboard/submitOnboardData", {
              callback: (status, data) => {
                if (status)
                  this.$router.push({
                    name: "Onboard-Final",
                    params: { orderId: data.orderId }
                  });
                this.loading = false;
              }
            });
          }
        });
      }
    },
    back() {
      this.$router.go(-1);
    }
  },

  computed: {
    invalid() {
      return !(this.shippingMethods && this.shippingMethods.length > 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.option-icon {
  &.--shipping {
    width: 66px;
    height: 40px;
  }
}
</style>