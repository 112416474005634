<template>
  <div>
    <div v-if="paginatedOrders && paginatedOrders.length && !loading">
      <div>
        <!-- Mini Loading -->
        <div class="d-flex justify-content-center" v-if="miniLoading">
          <span class="align-self-center text-primary --text-lg mr-2">
            <i class="fas fa-circle-notch fa-spin"></i>
          </span>
          <span class="align-self-center">ระบบกำลังดึงข้อมูล</span>
        </div>

        <div class="d-flex justify-content-end mb-2">
          <button
            class="btn btn-success btn-sm"
            :disabled="downloadLoading"
            @click="fetchOrders(false, true)"
          >
            <i
              class="fas fa-circle-notch fa-spin mr-2"
              v-if="downloadLoading"
            ></i>
            <i class="fal fa-print mr-2" v-else></i>
            Download report
          </button>
        </div>

        <table
          class="
            table
            _datatable
            orders-table
            --very-small-height
            table-responsive
          "
        >
          <thead>
            <tr>
              <th>{{ $t("general.created_at") }}</th>
              <th>{{ $t("shipment.table_labels.customer") }}</th>
              <th>{{ $t("general.phone") }}</th>
              <th>{{ $t("shipment.table_labels.status") }}</th>
              <th>{{ $t("shipment.table_labels.shipping") }}</th>
              <th>{{ $t("shipment.table_labels.tracking_no") }}</th>
              <th>COD</th>
              <th>{{ $t("general.actions") }}</th>
            </tr>
          </thead>
          <tbody :class="{ '--loading': miniLoading }">
            <tr v-for="(row, index) in paginatedOrders" :key="index">
              <td v-html="tdHtml('createdAt', row.createdAt)"></td>

              <td
                v-html="tdHtml('ellipsis', row.customerName || 'N/A')"
                class="text-nowrap"
              ></td>

              <td>{{ row.phoneNo || "N/A" }}</td>

              <td v-html="tdHtml('status', row.status)" class="--width-md"></td>

              <td class="--width-md">
                {{ row.shippingMethod }}
              </td>

              <td>
                <a
                  :href="
                    'https://track.proship.co.th/#/?barcode=' + row.trackingNo
                  "
                  class="mr-1"
                  target="_blank"
                  >{{ row.trackingNo }}</a
                >

                <i
                  class="fas fa-check-circle text-success"
                  v-if="row.codPaid"
                ></i>

                <i
                  class="fas fa-store text-color-3"
                  v-if="row.returnReason === 'shop'"
                ></i>
                <i
                  class="fas fa-user text-color-3"
                  v-else-if="row.returnReason === 'customer'"
                ></i>
              </td>

              <td class="--width-md">
                {{ row.codAmount }}
              </td>

              <td>
                <span
                  v-if="row.hasError"
                  class="text-danger mr-2"
                  :title="$t('warning.import_error_message')"
                >
                  <i class="fal fa-exclamation-triangle"></i>
                </span>

                <Button
                  variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-show"
                  @click="goto('Show', row.id, true)"
                >
                  <i class="fal fa-eye"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex justify-content-between">
          <div>
            <select
              class="form-control"
              v-model="perPage"
              @change="handlePerPageChange()"
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="500">500</option>
            </select>
          </div>

          <div class="d-flex">
            <p class="align-self-center mr-2 text-color-4">
              Loaded {{ orderRows.length }} | Page {{ page }}
            </p>

            <div class="btn-group" role="group">
              <Button
                type="button"
                variant="outline-primary"
                size="lg"
                id="btn-paginate-prev"
                @click="paginate(false)"
                :disabled="page === 1"
              >
                <i class="fal fa-long-arrow-left"></i>
              </Button>

              <Button
                type="button"
                variant="outline-primary"
                size="lg"
                id="btn-paginate-prev"
                @click="paginate(true)"
                :disabled="page === lastPage"
              >
                <i class="fal fa-long-arrow-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning" v-else>ไม่พบคำสั่งซื้อ</div>

    <div v-if="loading">
      <Spinner size="md" />
    </div>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import DataTable from "@/components/core/DataTable";
import Button from "@/components/core/Button";
import Spinner from "@/components/core/Spinner";
import CopyButton from "@/components/derived/CopyButton";
import _ from "lodash";
import { getOrderStatus } from "@/helpers/core";
import Select from "@/components/core/Select";
import { FILTERS } from "@/helpers/filters";

export default {
  name: "OrdersTable",
  components: {
    TextBox,
    DataTable,
    Button,
    Spinner,
    Select,
    CopyButton,
  },
  props: {
    _searchParams: {
      type: null,
    },
    detail_path_name: {
      type: String,
    },
  },
  data() {
    return {
      orderRows: null,
      paginatedOrders: null,
      loading: false,
      miniLoading: false,
      downloadLoading: false,
      page: 1,
      perPage: 10,
      lastPage: null,

      searchParams: null,
    };
  },

  created() {
    this.searchParams = JSON.parse(JSON.stringify(this._searchParams));
    this.fetchOrders(true);
  },

  methods: {
    tdHtml(type, value) {
      const types = {
        status: (value) => {
          let statusData = getOrderStatus(value);
          if (!statusData) return value;
          return `<span class="badge badge-pill text-uppercase status-badge --${statusData.variation}">${statusData.title}</span>`;
        },

        createdAt: (value) => {
          let dateTime = FILTERS.formatDateTime(value).split(",");
          return `${dateTime[0]}<br><span class="--text-vsm text-color-4 mt-n5">${dateTime[1]}</span>`;
        },

        ellipsis: (value) => {
          return FILTERS.ellipsis(value, 15);
        },
      };
      return types[type] ? types[type](value) : value;
    },

    paginate(forward = true) {
      if (forward) {
        if (!(this.lastPage && this.page === this.lastPage))
          this.page = this.page + 1;
      } else {
        this.page = Math.max(--this.page, 1);
      }
      this.handlePageChange();
    },

    fetchOrders(firstPage = true, download = false) {
      if (!this.searchParams) return;

      if (firstPage) this.loading = true;
      else if (download) this.downloadLoading = true;
      else this.miniLoading = true;

      this.$store.dispatch("orders/fetchOrdersReportV2", {
        query: this.searchParams,
        format: download ? "csv" : "json",
        callback: (status, data) => {
          if (status) {
            if (download) {
              this.downloadLoading = false;
              window.open(data.message, "_blank");
              return;
            }

            this.orderRows = data;
            this.page = 1;
            this.lastPage = null;

            this.handlePageChange();
          }

          if (firstPage) this.loading = false;
          else this.miniLoading = false;
        },
      });
    },

    copyHandler(type) {
      let message = "Copied to Clipboard";
      if (type === "danger") message = "Error copying";
      Toast(message, type);
    },

    handlePageChange() {
      let startIndex = (this.page - 1) * this.perPage;
      let endIndex = this.perPage * this.page - 1;

      this.paginatedOrders = this.orderRows.slice(startIndex, endIndex + 1);

      //handle empty page
      if (this.paginatedOrders.length === 0) {
        this.page--;
        this.lastPage = this.page;
        this.handlePageChange();
      }
    },

    handlePerPageChange() {
      this.page = 1;
      this.handlePageChange();
    },

    goto(type, id, newTab = false) {
      let routerData;

      if (type === "Show")
        routerData = { name: this.detail_path_name, params: { id } };

      if (newTab) window.open(this.$router.resolve(routerData).href, "_blank");
      else this.$router.push(routerData);
    },
  },

  watch: {
    _searchParams: {
      handler: function (newVal) {
        this.searchParams = newVal;
        this.fetchOrders(
          !(this.paginatedOrders && this.paginatedOrders.length)
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
</style>