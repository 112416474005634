<template>
  <div id="auth-body">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Auth"
};
</script>

<style>
#auth-body {
  position: relative;
  background-color: #d2d6de;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.auth-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  /* margin: 5% auto; */
  padding: 20px;
}

.site-logo {
  margin-bottom: 25px; 
  height: 50x;
  width: 150px;
}

.auth-box__body {
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
}

.auth-box-body__msg {
  margin: 0;
   padding: 0 20px 20px 20px;
}

#submit-login-form-button {
  width: 100%;
}

.auth-box__cta-wrapper {
  align-items: center;
}

#remember-me {
  margin-bottom: 0;
}

.forgot-password-msg {
  text-align: center;
  margin-top: 10px;
}
</style>