<template>
  <div>
    <div v-if="rows || loading">
      <div class="toolbar d-flex justify-content-end mb-4">
        <!-- <div class="d-flex"> -->
        <!-- <button
            class="btn btn-outline-primary btn-sm mr-2"
            data-toggle="modal"
            :data-target="'#' + modalId"
          >
            <i class="fal fa-cloud-download mr-2"></i>
            {{$t('general.import')}}
          </button> -->
        <Button class="btn btn-outline-primary btn-sm mr-2"
                :loading="reportsLoading"
                :disabled="reportsLoading"
                @click="exportData()">
          <i class="fal fa-cloud-download mr-2"></i>
          {{ $t('general.export') }}
        </Button>
        <!-- </div> -->

        <router-link :to="{ name: create_path_name }"
                     class="btn btn-success btn-sm">
          <i class="fal fa-plus-circle mr-2"></i>
          {{ $t("products.create_label") }}
        </router-link>
      </div>

      <slot name="top"></slot>

      <DataTable :id="id"
                 :columns="columns"
                 :rows="rows"
                 :per_page="25"
                 :per_page_range="[25, 50, 100]"
                 responsive
                 custom_class="products-table"
                 searchable
                 :search_fields="[
                   'details.itemCode',
                   'details.name',
                   'details.salePrice',
                 ]"
                 :search_placeholder="$t('shipment.create.select_products_field.placeholder')
                   ">
        <div slot="actions"
             slot-scope="props">
          <Button variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-view"
                  @click="goto('Show', props.props.rowData.id)">
            <i class="fal fa-eye"></i>
          </Button>
          <Button v-if="inventory"
                  variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-manage"
                  @click="goto('ManageInventory', props.props.rowData.id)">
            <i class="fa fa-box-full"></i>
          </Button>
          <Button variant="link"
                  size="sm"
                  custom_class="mr-2 p-0 text-color-5"
                  id="btn-action-edit"
                  @click="goto('Edit', props.props.rowData.id)">
            <i class="fal fa-edit"></i>
          </Button>
          <Button variant="link"
                  custom_class="p-0 text-color-5"
                  size="sm"
                  id="btn-action-delete"
                  @click="deleteItem(props.props.rowData.id)">
            <i class="fal fa-trash-alt"></i>
          </Button>
        </div>

        <div slot="id-slot"
             slot-scope="props">
          <input type="checkbox"
                 @change="
                   toggleSelect($event, props.props.rowIndex, props.props.rowData.id)
                   " />
        </div>
      </DataTable>

      <!-- <div class="d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                Prev
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                Next
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>-->

      <Modal :id="modalId">
        <template slot="header">
          <h5 class="modal-title">
            <i class="fal fa-file-import mr-2"></i>{{ $t("alert.import.title") }}
          </h5>
        </template>
        <template slot="body">
          <p class="lead text-center">{{ $t("alert.import.lead_text") }}</p>
          <p class="text-center"
             v-if="sample_file_link">
            {{ $t("alert.import.format_text") }}
            <a :href="sample_file_link">{{
              $t("alert.import.sample_file_text")
            }}</a>
          </p>
          <DropZone @uploaded="importProducts" />
        </template>
      </Modal>
    </div>
    <div v-else>
      <Spinner :variant="color_scheme"
               size="md" />
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/core/DataTable";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import Swal from "sweetalert2";
import { Alert } from "@/helpers/alert";
import Spinner from "@/components/core/Spinner";
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin";
import DropZone from "@/components/derived/DropZone";
import { mapGetters } from "vuex";
import { i18n } from "@/i18n";

export default {
  name: "ProductsTable",
  components: {
    DataTable,
    Modal,
    Button,
    Spinner,
    DropZone,
  },
  mixins: [VuetableFieldMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: null,
      required: true,
    },
    create_path_name: {
      type: String,
    },
    detail_path_name: {
      type: String,
    },
    edit_path_name: {
      type: String,
    },
    color_scheme: {
      type: String,
    },
    sample_file_link: {
      type: String,
    },
  },

  data() {
    return {
      inventory: false,
      loading: false,
      selectedProducts: [],
      reportsLoading: false
    };
  },

  created() {
    this.inventory =
      this.$store.getters["settings/generalSettings"].inventoryMgt;
  },

  methods: {
    exportData() {
      this.reportsLoading = true;
      this.$store.dispatch("products/exportProducts", {
        callback: (status, data) => {
          if (status && data && data.message) {
            let anchor = document.createElement("a");
            anchor.href = data.message;
            anchor.target = "_blank";
            anchor.download = "Products.csv";
            anchor.click();
          }
          this.reportsLoading = false;
        },
      });
    },

    importProducts(value) {
      var url = value[0];
      this.$emit("imported", url);
      $("#" + this.modalId).modal("hide");
    },

    goto(type, id) {
      if (type === "Show")
        this.$router.push({ name: this.detail_path_name, params: { id } });
      else if (type === "Edit")
        this.$router.push({ name: this.edit_path_name, params: { id } });
      else if (type === "ManageInventory")
        this.$router.push({
          name: this.detail_path_name,
          params: { id: id, manageInventory: true },
        });
    },
    deleteItem(id) {
      Alert(
        i18n.t("general.delete"),
        i18n.t("alert.delete.message"),
        null,
        () => {
          // Make Delete API call here
          this.$store.dispatch("products/deleteProduct", {
            id: id,
            callback: (status) => {
              if (status) this.$emit("deleted", id);
            },
          });
        }
      );
    },

    toggleSelect(event, rowIndex, productId) {
      let checked = event.target.checked;
      let tableRow = document.querySelector(
        `.products-table tr[item-index='${rowIndex}']`
      );
      if (checked) {
        tableRow.classList.add("--selected");
        this.selectedProducts.push(productId);
      } else {
        let index = this.selectedProducts.findIndex((a) => a === productId);
        this.selectedProducts.splice(index, 1);
        tableRow.classList.remove("--selected");
      }
    },
  },

  computed: {
    modalId() {
      return "import-" + this.id + "-modal";
    },

    buttonClass() {
      return `btn btn-sm btn-outline-${this.color_scheme} mr-2`;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: toRem(1.5px) solid;
}
</style>