import { http } from "@/helpers/http";
import Vue from 'vue'

const baseURL = 'https://x1pukio3fj.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
    namespaced: true,
    state: {
        firstVisit: true,
        details: null,
        options: null,
        product: null,
        shippingMethods: null
    },

    getters: {
        firstVisit(state) {
            return state.firstVisit;
        },
        details(state) {
            return state.details;
        },
        options(state) {
            return state.options;
        },
        product(state) {
            return state.product;
        },
        shippingMethods(state) {
            return state.shippingMethods;
        },
    },

    mutations: {
        setFirstVisit(state, firstVisit) {
            Vue.set(state, 'firstVisit', firstVisit);
        },

        setDetails(state, details) {
            Vue.set(state, 'details', details);
        },

        setOptions(state, options) {
            Vue.set(state, 'options', options);
        },

        setProduct(state, product) {
            Vue.set(state, 'product', product);
        },

        setshippingMethods(state, shippingMethods) {
            Vue.set(state, 'shippingMethods', shippingMethods);
        }
    },
    actions: {
        storeDetails({ state, commit, rootState }, payload) {
            commit('setDetails', payload.details);
            if (payload.callback) payload.callback(true, null);
        },
        storeOptions({ state, commit, rootState }, payload) {
            commit('setOptions', payload.options);
            if (payload.callback) payload.callback(true, null);
        },
        storeProduct({ state, commit, rootState }, payload) {
            commit('setProduct', payload.product);
            if (payload.callback) payload.callback(true, null);
        },
        storeshippingMethods({ state, commit, rootState }, payload) {
            commit('setshippingMethods', payload.shippingMethods);
            if (payload.callback) payload.callback(true, null);
        },

        submitOnboardData({ state, commit, rootState }, payload) {
            let onboardData = {
                shop: state.details,
                general: state.options,
                product: state.product,
                shippingMethods: state.shippingMethods
            }
            http.post(baseURL + "/onboard", onboardData)
                .then(response => {
                    commit('setFirstVisit', false);
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        }
    }
}