
<template>
  <div class="side-stepper">
    <ul class="steps">
      <li
        v-for="(step,i) in steps"
        :key="i"
        class="step"
        :class="{'--active':currentStepIndex - 1 == i,'--completed':i<currentStepIndex - 1}"
      >
        <span class="step-count" v-if="i<currentStepIndex - 1">
          <img src="@/assets/images/onboard/check.svg" class="step-checkmark" alt />
        </span>
        <span class="step-count" v-else>{{i+1}}</span>
        {{step}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Stepper",

  props: {
    steps: {
      type: null,
      required: true
    },
    currentStepIndex: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
ul.steps {
  list-style: none;
}

.step-count {
  font-size: 36px ;
  font-weight: bold;
  margin-right: 16px;

  @include for-medium-to-laptops {
    font-size: 24px;
  }
}

.step-checkmark {
  width: 32px;
  height: 32px;

  @include for-medium-to-laptops {
    width: 20px;
    height: 20px;
  }
  @include opacity(0.5);
}

.step {
  color: rgba(255, 255, 255, 0.5);
  font-weight:bold;
  font-size: 22px;
  @include for-medium-to-laptops {
    font-size: 18px;
  }
  margin-bottom: 40px;

  @include for-medium-to-laptops {
    margin-bottom: 25px;
  }
  &.--active {
    color: white;
  }
  &.--done {
    .step-count {
      content: "ASDF";
    }
  }
}
</style>