<template>
  <div>
    <canvas :id="'linechart-' + id"></canvas>
  </div>
</template>

<script>
export default {
  name: "OrdersLineChart",
  props: {
    id: String,
    labels: Array,
    data: Array
  },
  mounted() {
    let canvas = document.getElementById(`linechart-${this.id}`)
    var ticksStyle = {
      fontColor: "#495057",
      fontStyle: "bold"
    };

    var mode = "index";
    var intersect = true;

    var gradient = canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(123,181,174,0.4234068627450981)");
    gradient.addColorStop(1, "rgba(255,255,255,0)");


    // console.log("=================", canvasId);
    new Chart(canvas, {
      data: {
        labels: this.labels,
        datasets: [
          {
            type: "line",
            data: this.data,
            backgroundColor: gradient,
            borderWidth: 1,
            borderColor: "#1c75bb",
            pointBorderColor: "#c49a6c",
            pointBackgroundColor: "#d6df22",
            fill: true
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 25
          }
        },
        tooltips: {
          // enabled: false
        },
        hover: {
          enabled: false
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              // display: false
            }
          ],
          xAxes: [
            {
              // display: false
            }
          ]
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped></style>