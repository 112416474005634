<template>
  <div class="shipping-chart">
    <div class="chart ml-auto mr-auto mt-4">
      <canvas id="topProvincesPieChart" class="canvas-doughnut"></canvas>
    </div>
    <div class="info d-flex flex-column">
      <p
        v-for="(label, index) in chartdata.data.labels"
        :key="index"
        :style="'color: ' + getColor(index)"
      >
        <span
          class="order-badge"
          :style="'background-color: ' + getColor(index)"
        >
          {{ chartdata.data.datasets[0].data[index] }}
        </span>
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopProvincesChart",
  props: {
    chartdata: {
      type: Object,
    },
  },

  mounted() {
    var doughnutChart = new Chart($("#topProvincesPieChart"), {
      type: "pie",
      data: this.chartdata.data,
      options: {
        aspectRatio: 1,
        responsive: true,
        legend: false,
        // tooltips: false,
      },
    });
  },

  methods: {
    getColor(index) {
      return this.chartdata.data.datasets[0].backgroundColor[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-chart {
  .info {
    p {
      font-size: 14px;
    }
    .badge {
      color: white;
    }
  }

  .chart {
    height: 200px;
    width: 200px;
    margin-bottom: 1rem;
  }

  .order-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: white;
  }
}
</style>