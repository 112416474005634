<template>
  <div class="row">
    <div class="col-12">
      <!-- Variation List -->
      <div class="variation-list">
        <div
          class="variation-list-item flex-md-row mt-2 mb-2"
          v-for="(variation, index) in variationData.variations"
          :key="index"
        >
          <div class="variation-count">
            <!-- Desktop -->
            <div class="d-flex justify-content-between align-items-center">
              <p class="body-text">
                {{ $t("widget.variation.label") }} {{ index + 1 }}
              </p>
              <Button type="button" @click="removeVariation(index)">
                <i class="fal fa-trash-alt"></i>
              </Button>
            </div>
          </div>

          <div class="variation-details pb-1">
            <TextBox
              type="text"
              :id="'variation-name-' + index"
              v-model="variationData.variations[index].name"
              :placeholder="
                $t('widget.variation.variation_name_field.placeholder')
              "
              :label="$t('widget.variation.variation_name_field.label')"
              @input="generateData()"
              rules="required"
              wrapper_class="mb-1"
            />

            <div class="variation-options-list d-flex flex-column">
              <div
                class="variation-options-list-item d-flex"
                v-for="(option, index2) in variation.options"
                :key="index2"
              >
                <div class="variation-option d-flex w-100">
                  <div class="align-self-center flex-grow-1">
                    <TextBox
                      type="text"
                      :id="'variation-' + index + '-option-name-' + index2"
                      v-model="
                        variationData.variations[index].options[index2].name
                      "
                      :placeholder="
                        $t('widget.variation.option.label') + (index2 + 1)
                      "
                      wrapper_class="mb-1"
                      rules="required"
                      @input="generateData()"
                    />
                  </div>

                  <!-- <div class="align-self-center">
                  <TextBox
                    type="text"
                    :id="'variation-' + index + '-option-quantity-' + index2"
                    v-model="variations[index].options[index2].quantity"
                    placeholder="Quantity"
                    label="Quantity"
                    wrapper_class="mr-2"
                    rules="required"
                    @input="emitInput()"
                  />
                  </div>-->

                  <Button
                    type="button"
                    variant="default"
                    size="sm"
                    custom_class="align-self-start mt-1 ml-1"
                    @click="removeVariationOption(index, index2)"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </Button>
                </div>
              </div>

              <div class="d-flex justify-content-center mt-2">
                <a @click="addVariationOption(index)">
                  <i class="fas fa-plus-circle mr-2"></i>
                  <span class="body-text">{{
                    $t("widget.variation.option.add_new")
                  }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Add variation button -->
      <div class="add-variation d-flex justify-content-center">
        <a @click="addVariation()">
          <i class="fas fa-plus-circle mr-2"></i>
          {{ $t("widget.variation.add_new") }}
        </a>
      </div>

      <!-- Variation Table -->
      <table
        class="table table-bordered variations-table"
        v-if="variations && variations.length"
      >
        <thead>
          <th v-for="variation in variations" :key="variation.name">
            {{ variation.name }}
          </th>
          <th style="min-width: 130px">SKU</th>
          <th>{{ $t("widget.variation.quantity") }}</th>
        </thead>
        <tbody v-if="variations.length === 1">
          <tr
            v-for="variationOption in variations[0].options"
            :key="variationOption.name"
          >
            <td class="vertical-align: center">{{ variationOption.name }}</td>
            <td class="vertical-align: center">{{ variationOption.sku }}</td>
            <td class="p-0">
              <p class="p-0 d-flex" style="border-bottom: 1px solid #dee2e6">
                <NumberField
                  :id="'variation-quanity-' + variationOption.sku"
                  :placeholder="$t('widget.variation.quantity')"
                  rules="required"
                  hide_error
                  small
                  wrapper_class="pb-0 mb-x"
                  input_wrapper_class="mb-0 pb-0"
                  v-model.number="qtys[variationOption.proshipCode]"
                  @input="() => qtyChanged(variationOption.proshipCode)"
                />
              </p>
            </td>
          </tr>
        </tbody>
        <tbody v-if="variations.length === 2">
          <tr
            v-for="variationOption in variations[0].options"
            :key="variationOption.name"
          >
            <td class="vertical-align: center">{{ variationOption.name }}</td>
            <td class="p-0">
              <p
                class="p-2"
                style="border-bottom: 1px solid #dee2e6"
                v-for="childVariation in variationOption.variations"
                :key="childVariation.name"
              >
                {{ childVariation.name }}
              </p>
            </td>
            <td class="p-0">
              <p
                class="p-2 d-flex"
                style="border-bottom: 1px solid #dee2e6"
                v-for="childVariation in variationOption.variations"
                :key="childVariation.sku"
              >
                {{ childVariation.sku }}
              </p>
            </td>

            <td class="p-0">
              <p
                class="d-flex"
                style="border-bottom: 1px solid #dee2e6; padding: 1px"
                v-for="childVariation in variationOption.variations"
                :key="childVariation.sku"
              >
                <NumberField
                  :id="'variation-quanity-' + childVariation.sku"
                  placeholder="Qty"
                  rules="required"
                  hide_error
                  small
                  fg_wrapper_class="mb-0"
                  wrapper_class="pb-0 mb-x"
                  input_wrapper_class="mb-0 pb-0"
                  v-model.number="qtys[childVariation.proshipCode]"
                  @input="() => qtyChanged(childVariation.proshipCode)"
                />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button.vue";
import TextBox from "@/components/core/TextBox.vue";
import NumberField from "@/components/core/NumberField.vue";
import { i18n } from "@/i18n";
import { getProshipCode } from "@/helpers/product";

export default {
  name: "ProductVariation",
  props: {
    product: Object,
    color_scheme: String,
    value: null,
    userName: String,
    product_item_code: {
      type: String,
      default: "###",
    },
  },
  components: {
    Button,
    TextBox,
    NumberField,
  },
  data() {
    return {
      MAX_VARIATION: 2,
      variationData: {
        variations: [],
        childProducts: null,
      },
      qtys: {},
      variations: null,
    };
  },
  created() {
    if (this.value) this.variationData = this.value;
    // else this.addVariation();
    this.generateData();
    // console.log(this.variationData.variations);
  },
  methods: {
    qtyChanged(proshipCode) {
      let index = this.variationData.childProducts.findIndex((p) => {
        return p.proshipCode === proshipCode;
      });
      this.variationData.childProducts[index].qty = this.qtys[proshipCode];
      this.emitInput();
    },
    addVariation() {
      // debugger;
      if (this.variationData.variations.length >= this.MAX_VARIATION) {
        alert("Only " + this.MAX_VARIATION + " variations allowed.");
        return;
      }
      this.variationData.variations.push({ name: null, options: [] });
      this.addVariationOption(this.variationData.variations.length - 1);
      this.generateData();
      this.emitInput();
    },
    removeVariation(variationIndex) {
      this.variationData.variations.splice(variationIndex, 1);
      this.generateData();
      if (this.variationData.variations.length === 0)
        this.variationData.childProducts = [];
      this.emitInput();
      // console.log(this.variationData);
    },
    addVariationOption(variationIndex) {
      this.variationData.variations[variationIndex].options.push({
        name: null,
        sku: i18n.t("widget.variation.not_set"),
        proshipCode: getProshipCode(),
        qty: 0,
      });
      this.generateData();
      this.emitInput();
    },
    removeVariationOption(variationIndex, optionIndex) {
      this.variationData.variations[variationIndex].options.splice(
        optionIndex,
        1
      );
      this.generateData();
      this.emitInput();
    },
    emitInput() {
      this.$emit("input", this.variationData);
    },
    generateData() {
      const VARIATIONS = JSON.parse(
        JSON.stringify(this.variationData.variations)
      );
      var LEN = VARIATIONS.length;
      var childProducts = [];
      if (LEN === 1) {
        let firstVariation = VARIATIONS[0];
        for (let i = 0; i < firstVariation.options.length; i++) {
          let firstVariationOption = firstVariation.options[i];
          if (firstVariationOption.name) {
            firstVariationOption.sku =
              this.product_item_code +
              "-" +
              firstVariationOption.name.toUpperCase();
            let obj = {
              sku: firstVariationOption.sku,
              proshipCode: firstVariationOption.proshipCode,
              qty: firstVariationOption.qty,
            };
            obj.variationData = {};
            obj.variationData[firstVariation.name] = firstVariationOption.name;
            childProducts.push(obj);
          }
        }
      }
      if (LEN === 2) {
        let firstVariation = VARIATIONS[0];
        let secondVariation = VARIATIONS[1];

        for (let i = 0; i < firstVariation.options.length; i++) {
          let firstVariationOption = firstVariation.options[i];
          firstVariationOption.variations = [];

          for (let j = 0; j < secondVariation.options.length; j++) {
            let secondVariationOption = secondVariation.options[j];
            secondVariationOption.proshipCode =
              secondVariationOption.proshipCode + j + i;
            secondVariationOption.qty = 0;
            if (firstVariationOption.name && secondVariationOption.name) {
              let sku = `${
                this.product_item_code
              }-${firstVariationOption.name.toUpperCase()}-${secondVariationOption.name.toUpperCase()}`;
              firstVariationOption.variations.push({
                ...secondVariationOption,
                sku,
              });
              let obj = {
                sku,
                qty: 0,
                proshipCode: secondVariationOption.proshipCode,
              };
              obj.variationData = {};
              obj.variationData[firstVariation.name] =
                firstVariationOption.name;
              obj.variationData[secondVariation.name] =
                secondVariationOption.name;
              childProducts.push(obj);
            }
          }
        }

        VARIATIONS[0] = firstVariation;
        VARIATIONS[1] = secondVariation;
      }
      if (childProducts && childProducts.length) {
        this.variationData.childProducts = childProducts;
        this.variations = VARIATIONS;
        this.variationData.qtys = new Array(childProducts.length);
      } else {
        this.variationData.childProducts = [];
        this.variationData.qtys = [];
        this.variations = VARIATIONS;
      }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.variationTable {
  .tableHead {
    .tableHeadColumn {
      padding: 0.2rem 0.5rem;
      margin: 0 0.2rem;
    }
  }
}
.variation-list {
  .variation-list-item {
    .fa-trash-alt {
      color: $brand-pink;
    }
    .variation-details {
      border: 1px solid #cdcdcd;
      padding: 24px;
      border-radius: 10px;
      .variation-options-list {
        a {
          cursor: pointer;
          color: $brand-blue-mid;
        }
      }
    }
  }
}
.add-variation {
  border: 1px dashed #cdcdcd;
  margin: 25px 0;
  border-radius: 10px;
  a {
    cursor: pointer;
    color: $brand-blue-mid !important;
    padding: 11px;
  }
}
.mb-x {
  margin-bottom: 1px;
}
.variations-table {
  p,
  span {
    font-size: 0.8rem;
  }
}
// @media (max-width: 700px) {
//   [class*="col-md-5"] {
//     width: 40%;
//     padding-right: 0;
//   }
//   [class*="col-md-2"] {
//     width: 20%;
//     padding-right: 0;
//   }
// }
</style>