import { http } from "@/helpers/http";
const baseUrl = "https://x1pukio3fj.execute-api.ap-southeast-1.amazonaws.com/dev/v1";
// const baseUrl = "http://localhost:3000/dev/v1";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {
        fetchCustomers({ state, commit, rootState }, payload) {
            http.get(`${baseUrl}/customers?afterKey=${payload.afterKey || ''}&column=${payload.searchConfig.column}&keyword=${payload.searchConfig.keyword}`).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                console.log(error);
                if (payload.callback) payload.callback(false);
            })
        },

        searchCustomers({ state, commit, rootState }, payload) {
            http.get(`${baseUrl}/customers/search?column=${payload.column}&keyword=${payload.keyword}`).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                console.log(error);
                if (payload.callback) payload.callback(false);
            })
        },
    }
}