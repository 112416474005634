<template>
  <div class="container-fluid">
    <div class="main-content">
      <div class="d-flex justify-content-between">
        <div class="header-content align-self-center">
          <header class="text-center text-md-left">
            <h4 class="header-text-top align-self-center">
              <span class="font-weight-light">Hi</span>
              {{authData.user.username || 'User'}},
            </h4>
            <br>
            <h3 class="title-text title text-color-2">Welcome to Proship</h3>
            <p
              class="body-text description text-color-2 mb-4 mb-md-0"
            >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
            <div class="mb-4"></div>
            <div class="mb-4"></div>
          </header>
        </div>
        <div class="align-self-end d-flex justify-content-end">
          <img src="@/assets/images/Dashboard/truck.png" class="deliver-img d-none d-md-block" />
        </div>
      </div>
      <div class="card shortcuts-wrapper col-md-12">
        <header>
          <h3 class="text-center mb-4 d-none d-md-block">
            All Your Shipping
            <br />Centrally Managed
          </h3>
          <h5 class="d-md-none mb-4 text-center">All Your Shipping Centrally Managed</h5>
        </header>
        <div class="mb-4 mb-md-5"></div>
        <div class="row">
          <div class="col-12 col-md-4 pb-4 pb-md-0">
            <div class="shortcut text-center mb-4 mb-md-0">
              <div class="shortcut__icon wallet">
                <i class="far fa-wallet"></i>
              </div>
              <div class="shortcut__title">
                <h6 class="title">Manage Shops</h6>
              </div>
              <div class="shortcut__text">
                <p
                  class="text-color-4 --sm"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              </div>
              <div class="shortcut__button">
                <router-link
                  :to="{name: 'Seller-Settings-Payment'}"
                  class="button --primary font-weight-bold"
                >Go To Shops</router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 pb-4 pb-md-0">
            <div class="shortcut text-center mb-4 mb-md-0">
              <div class="shortcut__icon store">
                <i class="fas fa-store"></i>
              </div>
              <div class="shortcut__title">
                <h6 class="title">Manage Products</h6>
              </div>
              <div class="shortcut__text">
                <p
                  class="text-color-4 --sm"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              </div>
              <div class="shortcut__button">
                <router-link
                  :to="{name: 'Seller-Product-List'}"
                  class="button --primary font-weight-bold"
                >Go To Products</router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="shortcut text-center mb-4 mb-md-0">
              <div class="shortcut__icon headphones">
                <i class="fas fa-truck-loading"></i>
              </div>
              <div class="shortcut__title">
                <h6 class="header-text">Manage Orders</h6>
              </div>
              <div class="shortcut__text">
                <p
                  class="text-color-4 --sm"
                >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
              </div>
              <div class="shortcut__button">
                <router-link
                  :to="{name: 'Seller-Order-List'}"
                  class="button --primary font-weight-bold"
                >Go To Orders</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("user", ["authData"])
  }
};
</script>

<style scoped lang="scss">
.main-content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;  
  // margin-left: 12%;
}
.row {
  margin: 0;
}
.col-md-6,
.col-md-12 {
  padding: 0;
}
.header-content {
  width: 400px;
  // padding-bottom: 60px;
  // margin-right: 100px;
  // padding-left: 18px;
}
.header-text-top {
  padding-top: 20px;
  @include for-larger-than-phone {
    padding-top: 70px;
  }
}
.title-text {
  padding-bottom: 12px;
}

.deliver-img {
  margin-left: auto;
  width: 80%;
  height: auto;
}

.flex-container {
  display: flex;
  margin: 0;
}
.flex-container > div {
  margin: 5%;
}
.description {
  font-size: 18px;
}
.shortcut {
  .shortcut__icon {
    color: white;
    display: inline-block;
    border-radius: 10px;
    font-size: 24px;
    padding-top: 10px;
    margin-bottom: 24px;
    height: 54px;
    width: 64px;
    &.wallet {
      background-color: $brand-green-mid;
    }
    &.store {
      background-color: $brand-pink;
    }
    &.headphones {
      background-color: $brand-yellow-dark;
    }
  }
  .shortcut__title {
    margin-bottom: 8px;
  }
  .shortcut__text {
    margin-bottom: 12px;
  }
}

.shortcuts-wrapper {
  padding: 5%;
  margin-right: 10%;
  border: 0;
}

.button {
  &.--primary {
    cursor: pointer;
    color: $brand-blue-mid;
    font-size: 12px;
    border-radius: 50px;
  }
  &.--primary:hover {
    color: $brand-blue-dark;
  }
}
</style>