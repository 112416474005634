import { http } from "@/helpers/http";
import Vue from 'vue'

const baseURL = 'https://tbtrglpsa0.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
    namespaced: true,
    state: {
        registrationData: null,
        authData: null
    },

    getters: {
        registrationData(state) {
            return state.registrationData;
        },

        authData(state) {
            return state.authData;
        }
    },

    mutations: {
        setRegistrationData(state, registrationData) {
            Vue.set(state, 'registrationData', registrationData);
        },

        setAuthData(state, authData) {
            Vue.set(state, 'authData', authData);
        }
    },
    actions: {
        login({ state, commit, rootState }, payload) {
            http.post(baseURL + "/auth/login", payload.data)
                .then(response => {
                    commit('setAuthData', response.data);

                    // axios
                    http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

                    // local storage
                    localStorage.setItem("_auth", JSON.stringify(response.data));

                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        register({ state, commit, rootState }, payload) {
            http.post(baseURL + "/auth/register", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                    commit('setRegistrationData', payload.data);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        verify({ state, commit, rootState }, payload) {
            http.post(baseURL + "/auth/verify", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        resendOtp({ state, commit, rootState }, payload) {
            http.post(baseURL + "/auth/resend-otp", payload.data)
                .then(response => {
                    if (payload.callback) payload.callback(true, response.data);
                })
                .catch(error => {
                    if (payload.callback) payload.callback(false);
                })
        },

        logout({ state, commit, rootState }, payload) {
            commit('setAuthData', null);

            // axios
            http.defaults.headers.common['Authorization'] = null;

            // local storage
            localStorage.removeItem("_auth");

            if (payload.callback) payload.callback();
        },

    }
}