<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">
        {{ $t("settings.shipping_method_settings") }}
      </h4>
    </div>
    <div v-if="loading">
      <Spinner size="sm" />
    </div>
    <div v-else>
      <!-- shopee -->
      <ValidationObserver v-slot="{ invalid }">
        <Card
          grey
          v-if="showShipper('shopee') && shipmentForm && shipmentForm.shopee"
        >
          <div slot="body">
            <h5 class="text-color-4 mb-3">Shopee</h5>

            <TextBox
              type="text"
              id="shopee-user-id"
              :label="'User Id'"
              v-model="shipmentForm.shopee.userId"
              input_wrapper_class="pb-0"
            />

            <TextBox
              type="text"
              id="shopee-user-secret"
              :label="'User secret'"
              v-model="shipmentForm.shopee.userSecret"
              input_wrapper_class="pb-0"
            />

            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="pickup"
                value="1"
                class="form-check-input"
                v-model="shipmentForm.shopee.serviceType"
              />
              <label class="form-check-label" for="pickup">Pickup</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="dropoff"
                value="2"
                class="form-check-input"
                v-model="shipmentForm.shopee.serviceType"
              />
              <label class="form-check-label" for="dropoff">Dropoff</label>
            </div>

            <div class="text-center">
              <Button
                type="submit"
                variant="primary"
                size="sm"
                :disabled="invalid"
                :loading="formsLoading.shopee"
                @click="updateShippingSettings('shopee')"
                >{{ $t("general.update") }}</Button
              >
            </div>
          </div>
        </Card>
      </ValidationObserver>

      <!-- Kerry -->
      <ValidationObserver v-slot="{ invalid }">
        <Card grey v-if="showKerry && shipmentForm && shipmentForm.kerry">
          <div slot="body">
            <h5 class="text-color-4 mb-3">Kerry</h5>

            <TextBox
              type="text"
              id="kerry-merchant-id"
              :label="$t('form.shipping_methods.kerry_merchant_id')"
              v-model="shipmentForm.kerry.merchantId"
              input_wrapper_class="pb-0"
            />
            <div class="text-center">
              <Button
                type="submit"
                variant="primary"
                size="sm"
                :disabled="invalid"
                :loading="formsLoading.kerry"
                @click="updateShippingSettings('kerry')"
                >{{ $t("general.update") }}</Button
              >
            </div>
          </div>
        </Card>
      </ValidationObserver>

      <!-- Flash -->
      <Card grey v-if="showFlash && shipmentForm && shipmentForm.flash">
        <div slot="body">
          <h5 class="text-color-4 mb-3">Flash</h5>

          <p v-if="flashUrl" class="mb-4 --text-sm text-info">
            <a :href="flashUrl" target="_blank" rel="noopener noreferrer"
              >Complete FLASH registration to create orders</a
            >
          </p>

          <div class="text-center">
            <Button
              type="button"
              variant="primary"
              size="sm"
              :loading="formsLoading.flash"
              :disabled="formsLoading.flash"
              @click="generateFlashURL()"
              >กดเชื่อมต่อ Flash Express</Button
            >
          </div>
        </div>
      </Card>

      <!-- JT -->
      <ValidationObserver v-slot="{ invalid }">
        <Card grey v-if="showJt && shipmentForm && shipmentForm.jt">
          <div slot="body">
            <h5 class="text-color-4 mb-3">J&amp;T</h5>

            <div class="row">
              <div class="col">
                <TextBox
                  type="text"
                  id="jt-shop-id"
                  :label="$t('form.shipping_methods.jt_shop_id')"
                  v-model="shipmentForm.jt.shopId"
                  input_wrapper_class="pb-0"
                />

                <TextBox
                  type="text"
                  id="jt-shop-id"
                  :label="$t('form.shipping_methods.jt_shop_name')"
                  v-model="shipmentForm.jt.shopName"
                  input_wrapper_class="pb-0"
                />

                <!-- <TextBox
                  type="text"
                  id="jt-customer-id"
                  label="Customer ID"
                  v-model="shipmentForm.jt.customerId"
                  input_wrapper_class="pb-0"
                /> -->

                <div class="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    size="sm"
                    :disabled="invalid"
                    :loading="formsLoading.jt"
                    @click="updateShippingSettings('jt')"
                    >{{ $t("general.update") }}</Button
                  >
                </div>
              </div>
            </div>
          </div>
        </Card>
      </ValidationObserver>

      <!-- SCG -->
      <ValidationObserver v-slot="{ invalid }">
        <Card grey v-if="showScg && shipmentForm && shipmentForm.scg">
          <div slot="body">
            <h5 class="text-color-4 mb-3">SCG</h5>

            <TextBox
              type="text"
              id="scg-shipper-code"
              :label="'SCG Shipper Code'"
              v-model="shipmentForm.scg.shipperCode"
              input_wrapper_class="pb-0"
            />
            <div class="text-center">
              <Button
                type="submit"
                variant="primary"
                size="sm"
                :disabled="invalid"
                :loading="formsLoading.scg"
                @click="updateShippingSettings('scg')"
                >{{ $t("general.update") }}</Button
              >
            </div>
          </div>
        </Card>
      </ValidationObserver>

      <!-- ThaipostUser form  -->
      <ValidationObserver v-slot="{ invalid }">
        <Card grey v-if="showThaipost && shipmentForm && shipmentForm.thaipost">
          <div slot="body">
            <h5 class="text-color-4 mb-3">Thaipost</h5>

            <div v-if="thaipostInfoFilled">
              <table class="table table-sm">
                <tr>
                  <td>{{ $t("settings.thaipost.username") }}:</td>
                  <td>{{ shipmentForm.thaipost.username }}</td>
                </tr>
                <tr>
                  <td>{{ $t("settings.thaipost.password") }}:</td>
                  <td>{{ shipmentForm.thaipost.password | password }}</td>
                </tr>
                <tr>
                  <td>{{ $t("settings.thaipost.license_no") }}:</td>
                  <td>{{ shipmentForm.thaipost.licenseNo }}</td>
                </tr>
                <tr>
                  <td>{{ $t("settings.thaipost.prefix") }}:</td>
                  <td>{{ shipmentForm.thaipost.prefix }}</td>
                </tr>
                <tr>
                  <td>{{ $t("settings.thaipost.prefix_type") }}:</td>
                  <td>{{ shipmentForm.thaipost.prefixType }}</td>
                </tr>
              </table>
            </div>

            <form
              @submit.prevent="updateThaipostInfo"
              v-else-if="shipmentForm.thaipost"
            >
              <div class="row">
                <div class="col-6">
                  <TextBox
                    type="text"
                    id="username"
                    :label="$t('settings.thaipost.username')"
                    v-model="shipmentForm.thaipost.username"
                    rules="required"
                  />
                </div>
                <div class="col-6">
                  <TextBox
                    type="password"
                    id="password"
                    :label="$t('settings.thaipost.password')"
                    v-model="shipmentForm.thaipost.password"
                    rules="required"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <TextBox
                    type="text"
                    id="licenseNo"
                    :label="$t('settings.thaipost.license_no')"
                    v-model="shipmentForm.thaipost.licenseNo"
                    rules="required"
                  />
                </div>

                <div class="col-6">
                  <TextBox
                    type="text"
                    id="prefix"
                    :label="$t('settings.thaipost.prefix')"
                    v-model="shipmentForm.thaipost.prefix"
                    rules="required"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <label>{{ $t("settings.thaipost.prefix_type") }}</label>
                  <br />
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="E"
                      value="E"
                      class="form-check-input"
                      v-model="shipmentForm.thaipost.prefixType"
                    />
                    <label class="form-check-label" for="E">E(EMS)</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      type="radio"
                      id="O"
                      value="O"
                      class="form-check-input"
                      v-model="shipmentForm.thaipost.prefixType"
                    />
                    <label class="form-check-label" for="O">O(ECO-POST)</label>
                  </div>
                </div>
              </div>
              <hr />

              <div class="text-center">
                <Button
                  id="emsThaiPostFormSubmitBtn"
                  variant="primary"
                  size="sm"
                  :loading="formsLoading.thp"
                  :disabled="thaipostFormLoading || invalid"
                >
                  {{ $t("general.update") }}
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import { mapGetters } from "vuex";
import ShippingMethodInput from "@/components/derived/ShippingMethodInput";
import { Toast } from "@/helpers/toastr";
import Spinner from "@/components/core/Spinner";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import axios from "axios";
export default {
  name: "SellerSettingsShipping",
  components: {
    Card,
    ShippingMethodInput,
    Spinner,
    Button,
    TextBox,
  },
  data() {
    return {
      loading: false,
      shipmentSettingsFormLoading: false,
      thaipostFormLoading: false,
      shippingMethods: null,
      shipmentForm: null,
      flashUrl: null,
      formsLoading: {
        shopee: false,
        thp: false,
        kerry: false,
        scg: false,
        jt: false,
        flash: false,
      },
    };
  },
  created() {
    this.fetchShippingMethods(() => {
      if (this.showKerry || this.showJt || this.showFlash)
        this.fetchShippingSettings();
      if (this.showThaipost)
        this.fetchThaipostInfo(() => {
          if (!this.shipmentForm.thaipost) this.initForm("thaipost");
        });
    });
  },
  methods: {
    async generateFlashURL() {
      this.flashUrl = null;
      this.formsLoading.flash = true;
      this.$store.dispatch("settings/generateFlashURL", {
        callback: (status, data) => {
          if (status) {
            this.shipmentForm.flash.merchantId = data.subMchId;
            this.flashUrl = data.url;
            window.open(data.url, "_blank").focus();
          }
          this.formsLoading.flash = false;
        },
      });
    },
    initForm(type) {
      const formDefaultValues = {
        shopee: {
          email: null,
          phone: null,
          userId: null,
          userSecret: null,
          serviceType: null,
        },
        kerry: {
          merchantId: null,
        },
        kerry: {
          merchantId: null,
        },
        flash: {
          merchantId: null,
        },
        scg: {
          shipperCode: null,
        },
        jt: {
          shopId: null,
          shopName: null,
          customerId: null,
        },
        thaipost: {
          username: null,
          password: null,
          licenseNo: null,
          prefix: null,
          boxSizes: null,
          weightPriceConfig: null,
          prefixType: null,
        },
      };
      if (type) {
        this.shipmentForm[type] = formDefaultValues[type];
        if (type === "thaipost") this.thaipostFetchedFromServer = false;
      } else this.shipmentForm = formDefaultValues;
    },
    fetchShippingSettings() {
      this.loading = true;
      this.$store.dispatch("settings/fetchShippingSettings", {
        callback: (status, data) => {
          if (status) {
            if (data && Object.keys(data).length > 0) {
              this.shipmentForm = {
                ...this.shipmentForm,
                kerry: data.kerry,
                jt: data.jt,
                flash: data.flash,
                scg: data.scg,
                shopee: data.shopee,
              };
              if (!this.shipmentForm.kerry) this.initForm("kerry");
              if (!this.shipmentForm.jt) this.initForm("jt");
              if (!this.shipmentForm.flash) this.initForm("flash");
              if (!this.shipmentForm.scg) this.initForm("scg");
              if (!this.shipmentForm.shopee) this.initForm("shopee");
            } else this.initForm();
          }
          this.loading = false;
        },
      });
    },
    fetchThaipostInfo(callback) {
      this.loading = true;
      this.$store.dispatch("settings/fetchThaipostInfo", {
        callback: (status, data) => {
          if (status) {
            this.shipmentForm = { ...this.shipmentForm, thaipost: data };
            this.thaipostFetchedFromServer = true;
          }
          this.loading = false;
          if (callback) callback();
        },
      });
    },
    fetchShippingMethods(callback) {
      this.loading = true;
      this.$store.dispatch("settings/fetchShippingMethods", {
        callback: (status, data) => {
          if (status) {
            this.shippingMethods = data;
            if (callback) callback();
          }
          this.loading = false;
        },
      });
    },
    updateShippingSettings(sm) {
      this.shipmentSettingsFormLoading = true;
      this.formsLoading[sm] = true;
      this.$store.dispatch("settings/updateShippingSettings", {
        data: this.shipmentForm,
        callback: (status, data) => {
          this.shipmentSettingsFormLoading = false;
          this.formsLoading[sm] = false;
        },
      });
    },

    updateThaipostInfo() {
      this.thaipostFormLoading = true;
      this.formsLoading.thp = true;
      this.$store.dispatch("settings/updateThaipostInfo", {
        data: this.shipmentForm.thaipost,
        callback: (status, data) => {
          if (status) {
            this.fetchThaipostInfo(() => {
              if (!this.shipmentForm.thaipost) this.initForm("thaipost");
            });
          }
          this.thaipostFormLoading = false;
          this.formsLoading.thp = false;
        },
      });
    },

    showShipper(shipper) {
      return this.shippingMethods && this.shippingMethods.includes(shipper);
    },
  },
  computed: {
    ...mapGetters("user", ["authData"]),

    showScg() {
      return this.shippingMethods && this.shippingMethods.includes("scg");
    },
    showJt() {
      return this.shippingMethods && this.shippingMethods.includes("jt");
    },
    showKerry() {
      return this.shippingMethods && this.shippingMethods.includes("kerry");
    },
    showFlash() {
      return this.shippingMethods && this.shippingMethods.includes("flash");
    },
    showThaipost() {
      return this.shippingMethods && this.shippingMethods.includes("thaipost");
    },
    thaipostInfoFilled() {
      return (
        this.shipmentForm &&
        this.shipmentForm.thaipost &&
        this.shipmentForm.thaipost.username &&
        this.shipmentForm.thaipost.password &&
        this.shipmentForm.thaipost.licenseNo &&
        this.shipmentForm.thaipost.prefix &&
        this.shipmentForm.thaipost.prefixType &&
        this.thaipostFetchedFromServer
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>