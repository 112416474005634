<template>
  <div id="developer-body">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Developer"
};
</script>

<style>
#developer-body {
  background-color: #f2f2f3;
}
</style>