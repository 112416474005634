import { http } from "@/helpers/http";
import Vue from 'vue'

const baseURL = 'https://x1pukio3fj.execute-api.ap-southeast-1.amazonaws.com/dev/v1';
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
  namespaced: true,
  state: {
    shops: null
  },

  getters: {
    shops(state) {
      return state.shops;
    },
  },

  mutations: {
    setShops(state, shops) {
      Vue.set(state, 'shops', shops);
    },
  },

  actions: {
    fetchShops({ state, commit, rootState }, payload) {
      http.get(baseURL + "/shops?" + (payload.query || ''))
        .then(response => {
          if (payload.callback) payload.callback(true, response.data);
          commit("setShops", response.data);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },

    createShop({ state, commit, rootState }, payload) {
      http.post(baseURL + "/shops", payload.shop)
        .then(response => {
          if (payload.callback) payload.callback(true);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },

    updateShop({ state, commit, rootState }, payload) {
      http.put(baseURL + "/shops/" + payload.id, payload.data)
        .then(response => {
          if (payload.callback) payload.callback(true);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },

    deleteShop({ state, commit, rootState }, payload) {
      http.delete(baseURL + "/shops/" + payload.id)
        .then(response => {
          if (payload.callback) payload.callback(true);
        })
        .catch(error => {
          if (payload.callback) payload.callback(false);
        })
    },
  },
};
