<template>
  <div :class="wrapper_class">
    <div class="dropdown border">
      <button
        class="btn btn-default btn-sm dropdown-toggle w-100 d-flex align-items-center"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-expanded="false"
      >
      <div v-if="value" class="d-flex align-items-center">
        <div
          class="bankName"
          :style="'background-color:' + getBank(value).color"
        >
          <img
            class="bankImage"
            :src="require('@/assets/images/bank_logos/' + value + '.svg')"
          />
        </div>
        <div class="m-1"></div>
        <span class="--text-sm"> {{ getBank(value).nice_name }}</span>
      </div>
      <span v-else>
        Select a bank
      </span>
      </button>
      <div class="dropdown-menu">
        <a
          class="dropdown-item d-flex align-items-center"
          href="#"
          v-for="(value, key, index) in options"
          :key="index"
          @click="selectBank(key)"
        >
          <div class="bankName" :style="'background-color:' + value.color">
            <img
              class="bankImage"
              :src="require('@/assets/images/bank_logos/' + key + '.svg')"
            />
          </div>
          <div class="m-1"></div>
          <span class="--text-sm"> {{ value.nice_name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BankSelectDropdown",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          bbl: { code: "002", color: "#1e4598", nice_name: "Bangkok Bank" },
          bay: {
            code: "025",
            color: "#fec43b",
            nice_name: "Bank of Ayudhya (Krungsri)",
          },
          gsb: {
            code: "030",
            color: "#eb198d",
            nice_name: "Government Savings Bank",
          },
          ghb: {
            code: "033",
            color: "#f57d23",
            nice_name: "Government Housing Bank",
          },
          ibank: {
            code: "066",
            color: "#184615",
            nice_name: "Islamic Bank of Thailand",
          },
          kbank: { code: "004", color: "#138f2d", nice_name: "Kasikornbank" },
          ktb: { code: "006", color: "#1ba5e1", nice_name: "Krungthai Bank" },
          kk: { code: "069", color: "#199cc5", nice_name: "Kiatnakin Bank" },
          lhb: {
            code: "073",
            color: "#6d6e71",
            nice_name: "Land and Houses Bank",
          },
          scb: {
            code: "014",
            color: "#4e2e7f",
            nice_name: "Siam Commercial Bank",
          },
          tbank: {
            code: "065",
            color: "#fc4f1f",
            nice_name: "Thanachart Bank",
          },
          tmb: { code: "011", color: "#1279be", nice_name: "TMB Bank" },
          uob: {
            code: "024",
            color: "#0b3979",
            nice_name: "United Overseas Bank (Thai)",
          },
        };
      },
    },
    value: {
      type: String,
    },
    wrapper_class: {
      type: String,
    },
    rules: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      selectedBank: null,
    };
  },

  created() {
    this.selectedBank = this.value;
    this.emitUpdate();
  },

  methods: {
    getBank(bankCode) {
      return this.options[bankCode];
    },

    selectBank(bankName) {
      this.selectedBank = bankName;
      this.emitUpdate();
    },

    checkIfSelected(bankName) {
      return this.selectedBank === bankName;
    },

    emitUpdate() {
      this.$emit("input", this.selectedBank);
    },
  },

  watch: {
    values: function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        // debugger;
        this.selectedBank = newValue;
        this.emitUpdate();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bankName {
  // margin: 0.5rem 0.25rem;
  border-radius: toRem(10px);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 1.5rem;
  height: 1.5rem;

  .bankImage {
    width: 100%;
  }

  .selectedCheck {
    position: absolute;
    bottom: 3px;
    left: 3px;

    img {
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
    }
  }

  &.--sm {
    margin: 0.15rem 0.25rem;
    padding: 0.15rem;
    font-size: 12px;
    border-width: 1px;
    min-width: 50px;
  }

  &.--cod {
    padding: 0;

    .bankImage {
      height: 100%;
      width: auto;
    }
  }
}
</style>