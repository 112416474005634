import { http } from "@/helpers/http";

const baseUrl = "https://pkp9f3293d.execute-api.ap-southeast-1.amazonaws.com/dev/v1";
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},

    actions: {

        checkOrder({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/orders/" + payload.id + '/exists').then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        fetchSlipDetails({ state, commit, rootState }, payload) {
            http.get(baseUrl + "/orders/" + payload.id + '/slip-details').then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        processSlip({ state, commit, rootState }, payload) {
            http.post(baseUrl + "/orders/" + payload.id + '/process-slip', payload.data).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }
    }
}