<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <h4 class="font-weight-light">
          {{ $t("general.add") }}
          <span class="font-weight-bold">{{
            $t("form.product.a_new_product")
          }}</span>
        </h4>
      </div>
    </div>
    <div v-if="pageLoading">
      <Spinner size="md" />
    </div>
    <div v-else class="content">
      <div class="container-fluid">
        <ValidationObserver v-slot="{ invalid }">
          <form>
            <div class="row">
              <!-- Product info -->
              <div class="col-12 col-md-6">
                <Card>
                  <div slot="body">
                    <div class="product-details">
                      <TextBox
                        type="text"
                        id="name"
                        :label="$t('form.product.product_name')"
                        v-model="productCreateForm.name"
                        rules="required"
                      />

                      <div class="row">
                        <div class="col-6">
                          <TextBox
                            type="text"
                            id="item-code"
                            :label="$t('form.product.item_code')"
                            v-model="productCreateForm.itemCode"
                            rules="required"
                          />
                        </div>
                        <div class="col-6">
                          <br />
                          <br />
                          <Toggle
                            id="available"
                            :value="true"
                            variant="primary"
                            v-model="productCreateForm.available"
                            :labels="{
                              true: $t('form.product.available'),
                              false: $t('form.product.unavailable'),
                            }"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div
                          class="col"
                          v-if="generalSettings && generalSettings.inventoryMgt"
                        >
                          <NumberField
                            id="quantity"
                            :label="$t('form.product.quantity')"
                            rules="required"
                            v-model.number="productCreateForm.quantity"
                          />
                        </div>

                        <div class="col">
                          <NumberField
                            id="weight-approx"
                            :label="$t('form.product.weight_approx')"
                            placeholder="(gm)"
                            v-model.number="productCreateForm.weightApprox"
                            rules="required"
                          />
                        </div>
                      </div>

                      <TextArea
                        id="description"
                        :label="$t('form.product.description')"
                        v-model="productCreateForm.description"
                        rows="5"
                      />
                      <div class="row">
                        <div class="col-6">
                          <NumberField
                            id="cost"
                            :label="$t('form.product.cost_price')"
                            v-model.number="productCreateForm.cost"
                          />
                        </div>
                        <div class="col-6">
                          <NumberField
                            id="sale-price"
                            :label="$t('form.product.sale_price')"
                            v-model.number="productCreateForm.salePrice"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="additional-product-details">
                      <ImageUpload
                        :label="'อัปโหลดภาพสินค้า'"
                        :values="productCreateForm.images"
                        v-model="productCreateForm.images"
                      />
                    </div>
                  </div>
                </Card>
              </div>

              <!-- Product details -->
              <div class="col-12 col-md-6">
                <Card>
                  <div slot="body">
                    <div class="product-variations">
                      <Toggle
                        id="has-bundles"
                        :value="false"
                        variant="primary"
                        v-model="productHasBundles"
                        :labels="{
                          true: $t('form.product.bundle_price_yes'),
                          false: $t('form.product.bundle_price_no'),
                        }"
                      />

                      <div class="mb-2"></div>
                      <div v-if="productHasBundles">
                        <RowControls
                          :config="bundlePriceConfig"
                          v-model="productCreateForm.bundles"
                        />
                      </div>

                      <hr />

                      <Toggle
                        id="has-variations"
                        :value="false"
                        variant="primary"
                        v-model="productHasVariations"
                        :labels="{
                          true: $t('form.product.product_variation_yes'),
                          false: $t('form.product.product_variation_no'),
                        }"
                      />

                      <div v-if="productHasVariations">
                        <ProductVariation
                          color_scheme="primary"
                          :product="productCreateForm"
                          v-model="productCreateForm.variations"
                          :userName="authData.user.username"
                          :product_item_code="productCreateForm.itemCode"
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="d-flex justify-content-center">
                  <Button
                    id="product-form-button-submit"
                    type="button"
                    variant="primary"
                    class="px-4"
                    :disabled="invalid || invalidProductVariation()"
                    @click="handleFormSubmit"
                    :loading="loading"
                    >{{ $t("form.product.create_product") }}</Button
                  >
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import Toggle from "@/components/core/Toggle";
import TextArea from "@/components/core/TextArea";
import Stepper from "@/components/core/Stepper";
import NumberField from "@/components/core/NumberField";
import ImageUpload from "@/components/core/ImageUpload";
import Select from "@/components/core/Select";
import Spinner from "@/components/core/Spinner";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import RowControls from "@/components/core/RowControls";
import ProductVariation from "@/components/derived/ProductVariation";
import { mapGetters } from "vuex";
import { i18n } from "@/i18n";

export default {
  name: "Seller-Product-Create",
  components: {
    TextBox,
    NumberField,
    TextArea,
    ImageUpload,
    Select,
    CheckBoxGroup,
    RowControls,
    ProductVariation,
    Toggle,
    Button,
    Stepper,
    Card,
    Spinner,
  },
  data() {
    return {
      breadcrumbLinks: [
        {
          title: "Dashboard",
          isActive: false,
          pathName: "Seller-Dashboard",
        },
        {
          title: "Products",
          isActive: false,
          pathName: "Seller-Product-List",
        },
        {
          title: "New Product",
          isActive: true,
          pathName: "Seller-Product-Create",
        },
      ],
      step: 1,
      loading: false,
      pageLoading: true,
      productCreateForm: {
        name: null,
        proshipCode: null,
        itemCode: null,
        cost: null,
        quantity: null,
        description: null,
        available: true,
        salePrice: null,
        weightApprox: 10,
        bundles: null,
        variations: null,
        images: [],
      },
      productHasVariations: false,
      productHasBundles: false,

      bundlePriceConfig: {
        items: {
          amount: {
            type: "number",
            rules: "required",
            id: "bundle-amount",
            placeholder: i18n.t("form.product.quantity"),
          },

          price: {
            type: "number",
            id: "bundle-price",
            rules: "required",
            placeholder: i18n.t("form.product.cost"),
          },
        },
        numInstances: 1,
        hasControls: true,
      },
    };
  },

  created() {
    this.fetchRequiredValues();
  },

  methods: {
    fetchRequiredValues() {
      this.loading = true;
      this.pageLoading = true;
      this.$store.dispatch("products/fetchProshipCode", {
        callback: (status, data) => {
          if (status) this.productCreateForm.proshipCode = data;
          this.$store.dispatch("products/fetchSku", {
            callback: (status, data) => {
              if (status) {
                this.productCreateForm.itemCode = data;
              }
              this.loading = false;
              this.pageLoading = false;
            },
          });
        },
      });
    },

    handleFormSubmit() {
      this.loading = true;
      if (!this.productHasVariations) this.productCreateForm.variations = null;
      if (!this.productHasBundles) this.productCreateForm.bundles = null;
      if (!this.productCreateForm.quantity) this.productCreateForm.quantity = 1;

      this.productCreateForm.itemCode = this.productCreateForm.itemCode
        .trim()
        .replace(/\s/g, "");

      this.$store.dispatch("products/createProduct", {
        product: this.productCreateForm,
        callback: (status, data) => {
          this.loading = false;
          if (status) {
            if (data.id)
              this.$router.push({
                name: "Seller-Product-Detail",
                params: { id: data.id },
              });
            else
              this.$router.push({
                name: "Seller-Product-List",
              });
          }
        },
      });
    },

    cancel() {
      let msg = "Are you sure you want to cancel ? All the data will be lost.";
      if (confirm(msg)) this.$router.push({ name: "Seller-Product-List" });
    },

    invalidProductVariation() {
      // debugger;
      // if (this.productHasVariations === false) return false;
      // if(!this.productCreateForm.variations) return true;
      // if(this.productCreateForm.variations && !this.productCreateForm.variations.childProduct) return true;
      // if(this.productCreateForm.variations.childProduct.length == 0) return true;
      return false;
    },
  },

  computed: {
    ...mapGetters({ generalSettings: "settings/generalSettings" }),
    ...mapGetters("user", ["authData"]),
  },
};
</script>

<style lang="scss" scoped>
.products-create-page {
  max-width: toRem(600px);
  width: 100%;
  margin: 0 auto;
}
</style>
