<template>
  <div>
    <div id="top-provinces-map">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import L, { Map } from "leaflet"
import "leaflet/dist/leaflet.css";
import GeoJSON from "../../../assets/thailand.json"

export default {
  name: "TopProvincesMapChart",
  components: {
  },
  props: {
    id: {
      type: String
    },
    chartdata: {
      type: Object,
    },
  },
  data() {
    return {
      chart: null,
      geoJSON: null,
      map: null,
      layerGroup: null,
    };
  },

  mounted() {
    this.initChart();
  },

  methods: {
    async initChart() {

      let { data } = await axios.get("https://gist.githubusercontent.com/jeepkd/4e31e6a10f8297b9de50c62856927ecf/raw/9899d9f1ca4cd7c5f103a9b2455d9a01f0c8f895/thailand.json");
      this.geoJSON = data;

      this.map = L.map('top-provinces-map', {
        zoomControl: false,
        zoomSnap: 0.25,
        zoomDelta: 1
      }).setView([13.7563, 100.5018], 19)
      this.layerGroup = new L.LayerGroup();
      this.layerGroup.addTo(this.map);

      let chartData = {
        "กรุงเทพมหานคร": 5941,
        "ชลบุรี": 31,
        "นครปฐม": 30,
        "นนทบุรี": 24,
        "มุกดาหาร": 75,
        "สมุทรปราการ": 24
      }
      let total = Object.values(chartData).reduce((partialSum, a) => partialSum + a, 0);
      let inPercent = {};
      for (const key in chartData) {
        inPercent[key] = chartData[key] / total;
      }

      console.log(inPercent);


      let jsonLayer = L.geoJSON(this.geoJSON, {
        style: (feature) => {

          let activeProvince = false;
          let percent = 0;
          for (const key in chartData) {
            activeProvince = feature.properties.NL_NAME_1 && key.includes(feature.properties.NL_NAME_1) || feature.properties.NL_NAME_1.includes(key)
            if (activeProvince) percent = inPercent[key];
          }

          return {
            fillColor: "skyblue",
            weight: 1,
            opacity: 1,
            color: '#1B5E20',  //Outline color
            fillOpacity: activeProvince ? 1 : 0
          }
        },

        onEachFeature: function (feature, layer) {

          let text = feature.properties.NL_NAME_1;
          let activeProvince = false;
          for (const key in chartData) {
            activeProvince = feature.properties.NL_NAME_1 && key.includes(feature.properties.NL_NAME_1) || feature.properties.NL_NAME_1.includes(key)
            if (activeProvince) text = `${feature.properties.NL_NAME_1}: ${chartData[key]}`;
          }

          layer.bindPopup(text, { closeButton: false, offset: L.point(0, -20) });
          layer.on('mouseover', function () { layer.openPopup(); });
          layer.on('mouseout', function () { layer.closePopup(); });
        },
      })
      this.layerGroup.addLayer(jsonLayer);
      this.map.fitBounds(jsonLayer.getBounds());
    },

    getColor(index) {
      return this.chartdata.data.datasets[0].backgroundColor[index];
    },
  },

  watch: {
    chartdata: function () {
      this.initChart();
    },
  },
};
</script>

<style lang="scss">
#top-provinces-map {
  height: 32rem;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-container {
  background: whitesmoke !important;
  border-radius: 0.5rem;
}
</style>