<template>
  <div class="form-group">    
      <TextBox
        :derived="true"
        type="Number"
        :maxval="10"
        :placeholder="placeholder"
        :id="id"
        :value="value"
        :label="label"
        @input="emitUpdate()"
        v-model="phone"
        :rules="'phone|' + rules"
      />
  </div>
</template>

<script>
import TextBox from "@/components/core/TextBox";
export default {
  name: "PhoneNumber",
  components: {
    TextBox
  },
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    id: {
      type: String
    },
    rules: {
      type: String,
      default: ""
    }
  },

  created() {
    this.phone = this.value;
    this.emitUpdate();
  },

  data() {
    return {
      phone: null,
      timer: null
    };
  },

  methods: {
    emitUpdate() {
      this.$emit("input", this.phone);

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit("change")
      }, 500);
    }
  },

  watch: {
    value: function(newVal, oldVal) {
      this.phone = newVal;
      this.emitUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>