var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h4',{staticClass:"text-color-2"},[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t("general.edit")))]),_vm._v(" "+_vm._s(_vm.$t("products.products_label"))+" ")])]),_c('div',{staticClass:"col-12 col-md-6"})])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[(_vm.productEditForm)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('Card',[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('h5',{staticClass:"text-color-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("products.edit.label"))+" ")]),_c('TextBox',{attrs:{"type":"text","id":"name","label":_vm.$t('form.product.product_name'),"rules":"required"},model:{value:(_vm.productEditForm.details.name),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "name", $$v)},expression:"productEditForm.details.name"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('TextBox',{attrs:{"type":"text","id":"item-code","label":_vm.$t('form.product.item_code'),"rules":"required","disabled":true},model:{value:(_vm.productEditForm.details.itemCode),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "itemCode", $$v)},expression:"productEditForm.details.itemCode"}})],1),_c('div',{staticClass:"col-6"},[_c('label',[_vm._v(_vm._s(_vm.$t("general.availability")))]),_c('Toggle',{attrs:{"id":"available","color":"blue","value":false,"labels":{
                            true: _vm.$t('form.product.available'),
                            false: _vm.$t('form.product.unavailable'),
                          }},model:{value:(_vm.productEditForm.details.available),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "available", $$v)},expression:"productEditForm.details.available"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[(_vm.isSeller)?_c('NumberField',{attrs:{"id":"cost","label":_vm.$t('form.product.cost_price')},model:{value:(_vm.productEditForm.details.cost),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "cost", _vm._n($$v))},expression:"productEditForm.details.cost"}}):_vm._e()],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('NumberField',{attrs:{"id":"sale-price","label":_vm.$t('form.product.sale_price')},model:{value:(_vm.productEditForm.details.salePrice),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "salePrice", _vm._n($$v))},expression:"productEditForm.details.salePrice"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('NumberField',{attrs:{"id":"weight-approx","label":_vm.$t('form.product.weight_approx'),"placeholder":"(gm)","rules":"required"},model:{value:(
                            _vm.productEditForm.details.weightApprox
                          ),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "weightApprox", _vm._n($$v))},expression:"\n                            productEditForm.details.weightApprox\n                          "}})],1),_c('div',{staticClass:"col-12 col-md-6"},[(
                            _vm.generalSettings && _vm.generalSettings.inventoryMgt
                          )?_c('NumberField',{attrs:{"id":"quantity","label":_vm.$t('form.product.quantity'),"placeholder":"Product quantity","rules":"required"},model:{value:(_vm.productEditForm.details.quantity),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "quantity", _vm._n($$v))},expression:"productEditForm.details.quantity"}}):_vm._e()],1)]),_c('ImageUpload',{attrs:{"label":_vm.$t('form.product.product_images'),"dragText":_vm.$t('form.product.upload_product_images'),"values":_vm.productEditForm.details.images},model:{value:(_vm.productEditForm.details.images),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "images", $$v)},expression:"productEditForm.details.images"}}),_c('br'),_c('TextArea',{attrs:{"id":"description","label":_vm.$t('form.product.description'),"rows":"4"},model:{value:(_vm.productEditForm.details.description),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "description", $$v)},expression:"productEditForm.details.description"}})],1)])],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('Card',[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('h5',{staticClass:"text-color-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("form.product.bundle_prices"))+" ")]),_c('Toggle',{attrs:{"id":"has-bundles","value":_vm.productHasBundles,"color":"blue","labels":{
                        true: _vm.$t('form.product.bundle_price_yes'),
                        false: _vm.$t('form.product.bundle_price_no'),
                      }},model:{value:(_vm.productHasBundles),callback:function ($$v) {_vm.productHasBundles=$$v},expression:"productHasBundles"}}),(_vm.productHasBundles)?_c('div',[_c('div',{staticClass:"mb-3"}),_c('RowControls',{attrs:{"config":_vm.bundlePriceConfig,"value":_vm.productEditForm.details.bundles},model:{value:(_vm.productEditForm.details.bundles),callback:function ($$v) {_vm.$set(_vm.productEditForm.details, "bundles", $$v)},expression:"productEditForm.details.bundles"}})],1):_vm._e()],1)]),_c('Card',[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('h5',{staticClass:"text-color-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("form.product.product_variations"))+" ")]),(_vm.productEditForm.details.variationData)?_c('div',_vm._l((_vm.productEditForm.details
                          .variationData),function(value,key){return _c('div',{key:key},[_c('TextBox',{attrs:{"type":"text","id":key,"label":key,"rules":"required","wrapper_class":"mb-1"},model:{value:(_vm.productEditForm.details.variationData[key]),callback:function ($$v) {_vm.$set(_vm.productEditForm.details.variationData, key, $$v)},expression:"productEditForm.details.variationData[key]"}})],1)}),0):_vm._e()])])],1)]),_c('div',{staticClass:"d-flex mt-4 mb-4 justify-content-center"},[_c('Button',{attrs:{"id":"btn-submit","type":"submit","variant":"primary","custom_class":"mr-4","size":"lg","disabled":invalid,"loading":_vm.updateLoading}},[_vm._v(_vm._s(_vm.$t("general.submit")))]),_c('Button',{attrs:{"id":"btn-cancel","type":"button","variant":"secondary","custom_class":"mr-4","size":"lg"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("general.cancel")))])],1)])]}}],null,false,1362093968)})],1):_c('div',[_c('Spinner',{attrs:{"size":"md","variation":"primary"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }