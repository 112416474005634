import _ from "lodash";
// const URL = "ws://localhost:3001";
const URL = "wss://n502fvyld2.execute-api.ap-southeast-1.amazonaws.com/dev";

const closeConnection = (ws) => {
    ws.close();
}

export const initWs = () => {
    try {
        var ws = new WebSocket(URL + "?userId=" + _.get(JSON.parse(localStorage.getItem('_auth')), 'user.id', null));
        window.onbeforeunload = () => closeConnection(ws);
        return ws;
    } catch (e) {
        console.log(e);
    }
}

export const handleMessage = (event, callback) => {
    try {
        let data = JSON.parse(event.data);
        if (data.topic == "print") {
            if (data.f)
                document.dispatchEvent(new CustomEvent('print-success', { detail: data.msg }));
        }

        // this.socketMsg = data;
        if (callback) callback(data);
    } catch (e) {
        console.log(e, event.data);
    }
}