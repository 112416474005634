<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">Payment</h4>

      <Button
        id="btn-setup-payment"
        variant="success"
        size="sm"
        data-toggle="modal"
        data-target="#paymentModal"
        @click="handleBankAdd()"
      >
        <i class="fal fa-plus-circle mr-1"></i>
        Add new
      </Button>
    </div>
    <div v-if="loading">
      <Spinner size="sm" />
    </div>
    <div v-else class="bankAccountList">
      <div v-if="bankAccounts.length">
        <div
          class="backAccountListItem d-flex mb-2"
          v-for="(bankAccount, index) in bankAccounts"
          :key="index"
        >
          <div
            class="bankImage mr-2 align-self-center d-none d-md-block"
            :style="'background-color: ' + bankInfo(bankAccount.bank).color"
          >
            <img
              :src="require('@/assets/images/bank_logos/' + bankAccount.bank + '.svg')"
              height="40px"
            />
          </div>
          <div class="bankInfo flex-grow-1">
            <div class="toolbar d-flex justify-content-between">
              <h5 class="text-color-4">{{bankAccount.accountHolderName}}</h5>
              <div>
                <div>
                  <Button
                    custom_class="mr-2 text-priamary"
                    data-toggle="modal"
                    data-target="#paymentModal"
                    @click="editBank(index)"
                    variant="default"
                    size="sm"
                  >
                    <i class="fal fa-pen"></i>
                  </Button>
                  <Button
                    custom_class="mr-2 text-danger"
                    type="button"
                    @click="deleteBank(index)"
                    variant="default"
                    size="sm"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </Button>
                </div>
              </div>
            </div>
            <p class="text-color-4">{{bankAccount.bank[0]}}</p>
            <p class="text-color-4">{{bankAccount.accountNo}}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-warning mt-5" role="alert">
          <i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
          <span class="text-color-4">No payment options found</span>
        </div>
      </div>
    </div>

    <Modal id="paymentModal" v-if="bankForm">
      <template slot="header">
        <h5 class="modal-title">
          <i class="mr-2 fal fa-coins"></i>
          <span v-if="formMode === 0">Add New Bank Info</span>
          <span v-else-if="formMode === 1">Edit Bank Info</span>
        </h5>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit.prevent="handleFormSubmit">
            <!-- Account NUmber && Name -->
            <div class="row">
              <div class="col-md-6 sol-sm-12">
                <TextBox
                  type="text"
                  size="sm"
                  id="account-no"
                  label="Account Number"
                  placeholder="Account Number"
                  :rules="isCod ? '' : 'required'"
                  v-model="bankForm.accountNo"
                  :disabled="isCod"
                />
              </div>
              <div class="col-md-6 sol-sm-12">
                <TextBox
                  type="text"
                  size="sm"
                  id="account-holder-name"
                  label="Account Holder Name"
                  placeholder="Account Holder Name"
                  :rules="isCod ? '' : 'required'"
                  v-model="bankForm.accountHolderName"
                  :disabled="isCod"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label>Select Bank</label>
                <validation-provider rules="required">
                  <BankSelectInput
                    v-model="bankForm.bank"
                    :values="bankForm.bank"
                    @codSelected="handleCodSelect"
                  />
                </validation-provider>
              </div>
            </div>

            <hr />
            <div class="d-flex justify-content-center">
              <Button
                custom_class="btn-block"
                type="submit"
                id="btn-update-payment"
                size="md"
                :disabled="invalid"
                variant="primary"
                :loading="formLoading"
              >Submit</Button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import Spinner from "@/components/core/Spinner";
import Modal from "@/components/core/Modal";
import TextArea from "@/components/core/TextArea";
import BankSelectInput from "@/components/derived/BankSelectInput";
import { bankInfo } from "@/helpers/core";

export default {
  name: "SellerSettingsPayment",
  components: {
    Card,
    Button,
    Modal,
    TextBox,
    TextArea,
    BankSelectInput,
    Spinner
  },
  data() {
    return {
      formLoading: false,
      formMode: 0, //0: create, 1: edit
      bankForm: null,
      bankAccounts: [],
      loading: false,
      activeBankIndex: null
    };
  },

  created() {
    this.fetchPayments();
  },

  methods: {
    initBankForm() {
      this.bankForm = {
        bank: null,
        accountNo: null,
        accountHolderName: null
      };
    },

    handleBankAdd() {
      this.formMode = 0;
      this.initBankForm();
    },

    editBank(index) {
      this.formMode = 1;
      this.bankForm = JSON.parse(JSON.stringify(this.bankAccounts[index]));
      this.activeBankIndex = index;
    },

    deleteBank(index) {
      if (confirm("Are you sure ?")) this.bankAccounts.splice(index, 1);
      this.update(() => {
        this.fetchPayments(false);
      });
    },

    handleFormSubmit() {
      // add a new one or update existing
      if (this.formMode)
        this.bankAccounts[this.activeBankIndex] = this.bankForm;
      else this.bankAccounts.push(this.bankForm);
      this.formLoading = true;
      this.update(() => {
        this.loading = false;
        this.fetchPayments();
        this.formLoading = false;
        $("#paymentModal").modal("hide");
      });
    },

    update(callback) {
      this.bankAccounts;
      this.$store.dispatch("settings/updatePayment", {
        payment: this.bankAccounts,
        callback: () => callback()
      });
    },

    bankInfo(bankName) {
      return bankInfo(bankName);
    },

    fetchPayments(showLoading = true) {
      if (showLoading) this.loading = true;
      this.$store.dispatch("settings/fetchPayment", {
        callback: (status, data) => {
          if (status) this.bankAccounts = data || [];
          if (showLoading) this.loading = false;
        }
      });
    },

    handleCodSelect() {
      this.bankForm.accountNo = null;
      this.bankForm.accountHolderName = null;
    }
  },

  computed: {
    isCod() {
      return this.bankForm.bank && this.bankForm.bank.includes("cod");
    }
  }
};
</script>

<style lang="scss" scoped>
.backAccountListItem {
  background: $dashboard-background;
  padding: toRem(20px);
  border-radius: toRem(10px);

  .bankImage {
    padding: toRem(15px);
    border-radius: toRem(10px);

    img {
      height: 30px;
      width: 30px;
    }
  }
}
</style>