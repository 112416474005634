<template>
  <div class="orders-create-page">
    <div class="content-header">
      <div class="container-fluid">
        <h4 class="font-weight-light text-center">
          {{ $t("general.add") }}
          <span class="font-weight-bold">{{
            $t("shipment.a_new_shipment")
          }}</span>
        </h4>
      </div>
    </div>

    <div class="content">
      <div class="container-fluid">
        <Card>
          <div slot="body" v-if="pageLoading">
            <Spinner size="md" />
          </div>

          <div slot="body" v-else>
            <div class="stepper">
              <Stepper :steps="3" :currentStepIndex="step" variant="primary" />
            </div>

            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="() => {}">
                <!--Step 1-->
                <div class="order-details" v-if="step == 1">
                  <div class="row">
                    <div class="col-12 col-md-6 pr-md-1">
                      <!-- Either Seller or Sales Agent is filled by default -->
                      <TextBox
                        :label="$t('shipment.edit.fields.user')"
                        type="text"
                        size="sm"
                        id="user"
                        v-model="orderCreateForm.user"
                        :disabled="true"
                        input_wrapper_class="pb-0"
                      />
                    </div>
                    <div class="col-12 col-md-6 pl-md-1">
                      <!-- Automatically generated -->
                      <TextBox
                        type="text"
                        id="order"
                        :label="$t('shipment.edit.fields.order_no')"
                        v-model="orderCreateForm.orderCode"
                        rules="required"
                        :disabled="true"
                        input_wrapper_class="pb-0"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <Select
                        id="sales-person"
                        label="Sales Person"
                        :options="salesPersonOptions"
                        v-model="orderCreateForm.salesPerson"
                      />
                    </div>
                  </div>

                  <label>{{
                    $t("shipment.edit.fields.shipping_methods")
                  }}</label>
                  <ValidationProvider rules="required">
                    <ShippingMethodInput
                      :values="orderCreateForm.shippingMethod"
                      :options="shippingMethods"
                      v-model="orderCreateForm.shippingMethod"
                      variant="info"
                      :multiple="false"
                      size="sh"
                      justify="left"
                      wrapper_class="mt-2 mb-2"
                      @input="handleShippingChange"
                    />
                  </ValidationProvider>
                  <div class="row mt-3">
                    <div class="col-6 pr-md-1">
                      <ValidationProvider
                        rules="required"
                        v-if="shopOptions && shopOptions.length > 0"
                      >
                        <Select
                          id="shops"
                          :label="$t('shipment.edit.fields.shop')"
                          :options="shopOptions"
                          v-model="orderCreateForm.shopId"
                          @input="defaultWeight"
                        />
                      </ValidationProvider>

                      <div v-else>
                        <label>{{ $t("shipment.edit.fields.shop") }}</label>
                        <div class="alert alert-warning" role="alert">
                          <i
                            class="fa fa-exclamation-triangle mr-2"
                            aria-hidden="true"
                          ></i>
                          <span
                            @click="redirectToShops()"
                            class="shipping-warning text-color-4"
                            >Please add Shops</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <label>{{
                        $t("shipment.edit.fields.payment_mode")
                      }}</label>
                      <Toggle
                        id="is-cod"
                        :value="true"
                        variant="primary"
                        :disabled="!showCod"
                        v-model="codToggle"
                        :labels="{
                          true: $t('shipment.edit.fields.payment_method_cod'),
                          false: $t(
                            'shipment.edit.fields.payment_method_online'
                          ),
                        }"
                      />
                    </div>
                  </div>

                  <div class="row" v-if="codToggle && showCod">
                    <div class="col-12">
                      <NumberField
                        id="codAmount"
                        v-model.number="orderCreateForm.codAmount"
                        :label="$t('shipment.edit.fields.cod_amount')"
                        wrapper_class="pb-0"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <NumberField
                        id="weight"
                        v-model.number="orderCreateForm.weight"
                        :label="$t('general.weight')"
                        wrapper_class="pb-0"
                        rules="required"
                      />
                    </div>
                  </div>

                  <TextArea
                    id="remark"
                    :label="$t('shipment.edit.fields.remarks')"
                    v-model="orderCreateForm.remarks"
                    rows="2"
                  />

                  <div
                    class="auth-box__cta-wrapper d-flex justify-content-center"
                  >
                    <Button
                      id="details-form-button"
                      variant="primary"
                      type="button"
                      custom_class="btn-block"
                      size="md"
                      :disabled="invalid"
                      @click="step = 2"
                      >Add Customer Details</Button
                    >
                  </div>
                  <div class="mb-4"></div>
                </div>

                <!--Step 2-->
                <div class="customer-details" v-if="step == 2">
                  <!-- Either Mobile or Phone is required -->
                  <TextBoxAddon
                    type="number"
                    id="customer-mobile"
                    :label="$t('general.mobile')"
                    v-model="orderCreateForm.customer.mobileNo"
                    :maxval="10"
                    :rules="
                      'phone' +
                      (orderCreateForm.customer.phoneNo ? '' : '|required')
                    "
                    :addon_btn_text="$t('shipment.edit.fields.check')"
                    @addonClicked="checkPhoneNo"
                    :loading="phoneCheckLoading"
                  />
                  <ValidationProvider rules="required|address">
                    <SmartAddress
                      id="customer-address"
                      :label="$t('general.address.address')"
                      v-model="orderCreateForm.customer.address"
                      rows="3"
                      rules="required"
                      @input="autofillCustDetails"
                    />
                  </ValidationProvider>
                  <div class="row">
                    <div class="col-12 col-md-6 pr-md-1">
                      <TextBox
                        type="text"
                        id="customer-phone"
                        :label="$t('general.phone')"
                        v-model="orderCreateForm.customer.phoneNo"
                        :rules="
                          orderCreateForm.customer.mobileNo ? '' : 'required'
                        "
                      />
                    </div>
                    <div class="col-12 col-md-6 pl-md-1">
                      <TextBox
                        type="text"
                        id="customer-email"
                        :label="$t('general.email')"
                        v-model="orderCreateForm.customer.email"
                      />
                    </div>
                  </div>

                  <TextBox
                    type="text"
                    id="customer-name"
                    :label="$t('general.name')"
                    v-model="orderCreateForm.customer.name"
                  />

                  <div class="row">
                    <div class="col-6">
                      <ValidationProvider>
                        <Select
                          id="sales-channel"
                          :label="$t('form.shipment.sales_channel')"
                          :options="[
                            { name: 'Facebook', value: 'facebook' },
                            { name: 'Line', value: 'line' },
                            { name: 'Instagram', value: 'instagram' },
                            { name: 'Web', value: 'web' },
                            { name: 'Other', value: 'other' },
                          ]"
                          v-model="orderCreateForm.customer.salesChannel"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="col-6">
                      <TextBox
                        type="text"
                        id="customer-social"
                        :label="$t('form.shipment.customer_fb_line')"
                        v-model="orderCreateForm.customer.socialLink"
                        input_wrapper_class="pb-0"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <!-- Show social link toggle  -->
                      <Toggle
                        id="is-cod"
                        :value="orderCreateForm.customer.showSocialLinkOnLabel"
                        variant="primary"
                        v-model="orderCreateForm.customer.showSocialLinkOnLabel"
                        :labels="{
                          true: $t('form.shipment.show_customer_fb_line'),
                          false: $t('form.shipment.not_show_customer_fb_line'),
                        }"
                      />
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col">
                      <Button
                        id="additional-detail-form-button-go-back"
                        type="button"
                        variant="outline-primary"
                        custom_class="btn-block"
                        @click="step = 1"
                      >
                        {{ $t("general.back") }}
                      </Button>
                    </div>

                    <div class="col">
                      <Button
                        v-if="generalSettings && generalSettings.productMgt"
                        id="additional-detail-form-button"
                        type="button"
                        variant="primary"
                        size="md"
                        custom_class="btn-block"
                        :disabled="invalid"
                        @click="step = 3"
                      >
                        {{ $t("shipment.create.buttons.add_products") }}
                      </Button>
                      <Button
                        v-else
                        id="btn-submit"
                        type="submit"
                        variant="primary"
                        custom_class="btn-block"
                        size="md"
                        :disabled="invalid"
                        :loading="formLoading"
                        @click="handleFormSubmit()"
                        >Create Order</Button
                      >
                    </div>
                  </div>
                  <div class="mb-4"></div>
                </div>

                <!--Step 3 -->
                <div class="products" v-if="step == 3">
                  <div class="d-flex justify-content-between">
                    <div>
                      <h5 class="text-color-1">
                        {{ $t("shipment.edit.fields.total_weight") }}
                        {{ selectedProductsWeight }} gm
                      </h5>
                    </div>
                  </div>

                  <div class="toolbar d-flex justify-content-between mt-4">
                    <p class="font-weight-normal">
                      {{ $t("shipment.create.select_products_field.label") }}
                    </p>

                    <div
                      class="product-basket d-md-none"
                      v-if="generalSettings && generalSettings.productMgt"
                    >
                      <button
                        class="btn btn-primary --circle"
                        data-toggle="modal"
                        data-target="#selected-products-modal"
                      >
                        <span class="item-count">{{
                          selectedProductsCount
                        }}</span>
                        <i class="fal fa-shopping-basket"></i>
                      </button>
                    </div>
                  </div>

                  <DataTable
                    id="order-create-all-products"
                    :columns="product.columns"
                    :rows="product.rows"
                    :per_page="5"
                    searchable
                    :search_fields="['details.itemCode', 'details.name']"
                    :search_placeholder="
                      $t('shipment.create.select_products_field.placeholder')
                    "
                    custom_class="--small-height"
                  >
                    <div slot="actions" slot-scope="props">
                      <Button
                        type="button"
                        variant="link"
                        size="sm"
                        custom_class="mr-1 position-relative"
                        id="btn-action-detail"
                        @click="addProduct(props.props.rowData)"
                      >
                        <i class="fal fa-plus-circle"></i>
                      </Button>
                    </div>
                  </DataTable>

                  <div class="row mt-4">
                    <div class="col">
                      <Button
                        id="additional-detail-form-button-go-back"
                        type="button"
                        variant="outline-primary"
                        custom_class="btn-block"
                        @click="step = 2"
                        >{{ $t("general.back") }}</Button
                      >
                    </div>

                    <div class="col">
                      <Button
                        id="btn-submit"
                        type="submit"
                        variant="primary"
                        custom_class="btn-block"
                        size="md"
                        :disabled="invalid"
                        :loading="formLoading"
                        @click="handleFormSubmit()"
                        >{{
                          $t("shipment.create.buttons.create_order")
                        }}</Button
                      >
                    </div>
                  </div>
                  <div class="mb-4"></div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </Card>
      </div>
    </div>

    <!-- For Desktop -->
    <popper
      v-if="generalSettings && generalSettings.productMgt"
      trigger="clickToOpen"
      :options="{
        placement: 'top',
        modifiers: { offset: { offset: '0,10px' } },
      }"
    >
      <div class="popper selected-products-popper">
        <table
          class="_datatable table --small-height"
          v-if="selectedProductsCount"
        >
          <thead>
            <th>{{ $t("shipment.edit.fields.product_code") }}</th>
            <th>{{ $t("general.quantity") }}</th>
            <th>{{ $t("general.actions") }}</th>
          </thead>
          <tbody>
            <tr
              v-for="(count, product_code) in orderCreateForm.products"
              :key="product_code"
            >
              <td style="text-align: left" class="d-flex flex-column">
                <span>{{ count["name"] }}</span>
                <span class="--sm" style="font-size: 0.5rem">
                  ({{ product_code }})
                </span>
              </td>
              <td class="--width-sm">
                {{ count["qty"] ? count["qty"] : "N/A" }}
              </td>
              <td style="width: 30px">
                <Button
                  type="button"
                  variant="default"
                  size="sm"
                  :id="'btn-delete-product-' + product_code"
                  @click="removeProduct(product_code)"
                >
                  <i class="fal fa-trash-alt text-danger"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <h5 class="mt-4" v-else>No products selected</h5>
      </div>

      <div class="product-basket d-none d-md-block" slot="reference">
        <button class="btn btn-primary --circle">
          <span class="item-count">{{ selectedProductsCount }}</span>
          <i class="fal fa-shopping-basket"></i>
        </button>
      </div>
    </popper>

    <!-- For Mobile -->
    <Modal
      id="selected-products-modal"
      custom_dialog_class="modal-dialog-centered"
      v-if="generalSettings && generalSettings.productMgt"
    >
      <div slot="body">
        <table
          class="_datatable table --small-height"
          v-if="selectedProductsCount"
        >
          <thead>
            <th>{{ $t("shipment.edit.fields.product_code") }}</th>
            <th>{{ $t("general.quantity") }}</th>
            <th>{{ $t("general.actions") }}</th>
          </thead>
          <tbody>
            <tr
              v-for="(count, product_code) in orderCreateForm.products"
              :key="product_code"
            >
              <td style="text-align: left" class="d-flex flex-column">
                <span>{{ count["name"] }}</span>
                <span class="--sm" style="font-size: 0.5rem">
                  ({{ product_code }})
                </span>
              </td>
              <td class="--width-sm">
                {{ count["qty"] ? count["qty"] : "N/A" }}
              </td>
              <td style="width: 30px">
                <Button
                  type="button"
                  variant="default"
                  size="sm"
                  :id="'btn-delete-product-' + product_code"
                  @click="removeProduct(product_code)"
                >
                  <i class="fal fa-trash-alt text-danger"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else class="alert alert-warning mt-4" role="alert">
          {{ $t("shipment.edit.fields.no_products") }}
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Spinner from "@/components/core/Spinner";
import Modal from "@/components/core/Modal";
import Button from "@/components/core/Button";
import Toggle from "@/components/core/Toggle";
import TextBox from "@/components/core/TextBox";
import TextBoxAddon from "@/components/core/TextBoxAddon";
import TextArea from "@/components/core/TextArea";
import NumberField from "@/components/core/NumberField";
import ImageUpload from "@/components/core/ImageUpload";
import Select from "@/components/core/Select";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import RowControls from "@/components/core/RowControls";
import RadioGroup from "@/components/core/RadioGroup";
import FileUpload from "@/components/core/FileUpload";
import DataTable from "@/components/core/DataTable";
import Stepper from "@/components/core/Stepper";
import Vue from "vue";
import { generateOrderCode } from "@/helpers/core";
import { mapGetters } from "vuex";
import SmartAddress from "@/components/derived/SmartAddress";
import Popper from "vue-popperjs";
import ShippingMethodInput from "@/components/derived/ShippingMethodInput";
import { shippingInfo } from "@/helpers/core";
import "vue-popperjs/dist/vue-popper.css";
import { FILTERS } from "@/helpers/filters";
import { i18n } from "@/i18n";
import axios from "axios";

export default {
  name: "Seller-Order-Create",
  components: {
    Card,
    Spinner,
    TextBox,
    NumberField,
    TextArea,
    ImageUpload,
    Select,
    CheckBoxGroup,
    RowControls,
    RadioGroup,
    FileUpload,
    DataTable,
    TextBoxAddon,
    Button,
    SmartAddress,
    Stepper,
    Popper,
    Modal,
    ShippingMethodInput,
    Toggle,
  },
  data() {
    return {
      shops: null,
      step: 1,
      pageLoading: false,
      formLoading: false,
      phoneCheckLoading: false,
      shopOptions: [],
      salesPersonOptions: [],

      codToggle: false,
      showCod: true,
      orderCreateForm: {
        user: null,
        salesPerson: null,
        orderCode: null,
        shippingMethod: [],
        weight: null,
        codAmount: 0,
        remarks: null,
        shop: null,
        customer: {
          name: "กรุณาจัดส่งคุณ:",
          address: null,
          mobileNo: null,
          phoneNo: null,
          email: null,
          salesChannel: null,
          socialLink: null,
          showSocialLinkOnLabel: false,
        },
        products: {},
      },

      shippingMethods: [],
      product: {
        columns: [
          {
            name: "details.itemCode",
            title: i18n.t("general.item") + " #",
            sortField: "details.itemCode",
            dataClass: "--width-sm",
            formatter: (val) => {
              return `<span title="${val}">${FILTERS.ellipsis(val, 10)}</span>`;
            },
          },

          {
            name: "details.name",
            title: "Product",
            sortField: "details.name",
            dataClass: "--width-md",
            formatter: (val) => {
              return `<span title="${val}">${FILTERS.ellipsis(val, 10)}</span>`;
            },
          },
          {
            name: "actions",
            title: i18n.t("general.actions"),
            dataClass: "--width-sm",
          },
        ],
        rows: [],
        selectedProducts: {},
      },
    };
  },

  created() {
    this.pageLoading = true;
    this.fetchOrderCode(() => {
      this.fetchShops(() => {
        this.fetchSalesAgents(() => {
          this.fetchShipping(() => {
            if (this.generalSettings && this.generalSettings.productMgt)
              this.fetchProducts(() => (this.pageLoading = false));
            else this.pageLoading = false;
          });
        });
      });
    });
  },

  mounted() {
    $('product-basket btn.--circle[data-toggle="popover"]').popover();
  },

  methods: {
    fetchOrderCode(callback) {
      this.$store.dispatch("orders/fetchOrderCode", {
        callback: (status, data) => {
          if (status) this.orderCreateForm.orderCode = data;
          this.orderCreateForm.user = this.authData.user.username;
          if (callback) callback();
        },
      });
    },

    autofillCustDetails(value) {
      if (!value) return;
      if (value.phone) this.orderCreateForm.customer.phoneNo = value.phone;
      if (value.name) this.orderCreateForm.customer.name = value.name;
    },

    defaultWeight(shopId = this.orderCreateForm.shopId) {
      let shop = this.shops.find((shop) => shop.id === shopId);
      if (shop) {
        this.orderCreateForm.weight = shop.details.defaultWeight;
        if (
          shop.details.shippingMethods &&
          shop.details.shippingMethods.length
        ) {
          this.shippingMethods = shop.details.shippingMethods.map((sm) =>
            shippingInfo(sm)
          );
          this.orderCreateForm.shippingMethod = [this.shippingMethods[0].name];
        }
      }
    },

    fetchShops(callback) {
      this.pageLoading = true;
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shops = data;
            if (this.shops && this.shops.length) {
              this.shopOptions = this.shops.map((shop) => {
                return { name: shop.details.name, value: shop.id };
              });

              // default shop and weight
              this.orderCreateForm.shop = this.shopOptions[0].value;
              this.orderCreateForm.weight = this.shops[0].details.defaultWeight;
            }
          }
          if (callback) callback();
        },
      });
    },

    fetchProducts(callback) {
      this.$store.dispatch("products/fetchProducts", {
        callback: (status, data) => {
          if (status) this.product.rows = data;
        },
      });
      if (callback) callback();
    },

    handleFormSubmit() {
      this.formLoading = true;
      this.orderCreateForm.shippingMethod = this.orderCreateForm.shippingMethod.toString();

      if (this.orderCreateForm.salesPerson)
        this.orderCreateForm.salesPerson = JSON.parse(
          this.orderCreateForm.salesPerson
        );

      this.$store.dispatch("orders/createOrder", {
        order: this.orderCreateForm,
        callback: (status, data) => {
          if (status) {
            this.$router.push({
              name: "Seller-Order-Detail",
              params: { id: data.id },
            });
          }
          this.formLoading = false;
        },
      });
    },

    fetchShipping(callback) {
      this.$store.dispatch("settings/fetchShippingMethods", {
        callback: (status, data) => {
          if (status) {
            if (!data || data.length === 0)
              data = ["kerry", "ems-cod", "self-ship", "ems"];
            data.forEach((method) => {
              this.shippingMethods.push(shippingInfo(method));
            });
            this.orderCreateForm.shippingMethod = [
              this.shippingMethods[0].name,
            ];
            if (callback) callback();
          }
        },
      });
    },

    fetchSalesAgents(callback) {
      this.$store.dispatch("salesAgent/fetchSalesAgents", {
        callback: (status, data) => {
          if (status) {
            this.salesPersonOptions = data.map((d) => {
              return {
                name: d.details.username,
                value: JSON.stringify({ name: d.details.username, id: d.id }),
              };
            });
            this.salesPersonOptions.unshift({ name: "None", value: null });
          }
          if (callback) callback();
        },
      });
    },

    handleShippingChange(shippingMethods) {
      let sm = shippingMethods[0];
      if (sm === "ems") {
        this.codToggle = false;
        this.showCod = false;
        this.orderCreateForm.codAmount = null;
      } else {
        this.showCod = true;
        this.codToggle = true;
        this.orderCreateForm.codAmount = 0;
      }
    },

    cancel() {
      this.$router.push({ name: "Seller-Order-List" });
    },

    checkPhoneNo(phone) {
      if (!phone) return;
      this.phoneCheckLoading = true;
      let url = `https://iwjkvg2m94.execute-api.ap-southeast-1.amazonaws.com/dev/get-address-by-phone/?phone=${phone}&secret=zaa123cvberty`;
      axios.get(url).then((response) => {
        if (response.data.not_found) alert("Not found");
        this.orderCreateForm.customer.address = {
          raw: response.data.original,
        };
        if (response.data.parsed && response.data.parsed.phone)
          this.orderCreateForm.customer.phoneNo = response.data.parsed.phone;
        this.phoneCheckLoading = false;
      });
    },

    addProduct(product) {
      if (!this.orderCreateForm.products)
        Vue.set(this.orderCreateForm, "products", {});
      let proshipCode = product.details.itemCode;
      if (this.orderCreateForm.products[proshipCode]) {
        Vue.set(
          this.orderCreateForm.products[proshipCode],
          "qty",
          this.orderCreateForm.products[proshipCode].qty + 1
        );
      } else
        Vue.set(this.orderCreateForm.products, proshipCode, {
          id: product.id,
          qty: 1,
          name: product.details.name,
          image:
            product.details.images && product.details.images.length
              ? product.details.images[0]
              : null,
          price: product.details.salePrice,
          weight: product.details.weightApprox,
        });
      this.syncProductsTableValues();
    },

    removeProduct(proshipCode) {
      if (this.orderCreateForm.products[proshipCode]) {
        Vue.set(
          this.orderCreateForm.products[proshipCode],
          "qty",
          this.orderCreateForm.products[proshipCode].qty - 1
        );
        if (this.orderCreateForm.products[proshipCode].qty === 0)
          Vue.delete(this.orderCreateForm.products, proshipCode);
      }
      this.syncProductsTableValues();
    },

    redirectToShipping() {
      this.$router.push({ name: "Seller-Settings-Shipping" });
    },

    redirectToShops() {
      this.$router.push({ name: "Seller-Settings-Shops" });
    },

    syncProductsTableValues() {
      this.orderCreateForm.weight = this.selectedProductsWeight;
    },
  },

  computed: {
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    selectedProductsCount() {
      if (this.orderCreateForm.products) {
        let total = 0;
        Object.values(this.orderCreateForm.products).forEach(
          (p) => (total += p.qty)
        );
        return total;
      }
      return 0;
    },

    selectedProductsWeight() {
      if (this.orderCreateForm.products) {
        let total = 0;
        Object.values(this.orderCreateForm.products).forEach(
          (p) => (total += p.weight * p.qty)
        );
        return total;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-warning:hover {
  text-decoration: underline;
  cursor: pointer;
}
.orders-create-page {
  max-width: toRem(600px);
  width: 100%;
  margin: 0 auto;

  .btn-submit {
    margin-top: toRem(40px);
  }

  .product-basket {
    $btn-size: toRem(70px);
    $count-size: toRem(35px);

    .btn {
      &.--circle {
        position: absolute;
        $size: toRem(72px);
        height: $btn-size;
        width: $btn-size;
        border-radius: 50%;
        font-size: toRem(24px);
        transform: translate(-25%, -50%);
        z-index: 1000;

        span.item-count {
          $size: toRem(30px);
          position: absolute;
          display: block;
          height: $count-size;
          width: $count-size;
          top: 0;
          left: 0;
          transform: translate(-25%, -25%);
          border-radius: 50%;
          text-align: center;
          line-height: $count-size;
          background-color: white;
          font-size: toRem(12px);
          color: map-get($text-colors, 4);
        }
      }
    }

    @include for-larger-than-phone {
      position: fixed;
      bottom: toRem(70px);
      right: toRem(80px);

      $btn-size: toRem(72px);
      $count-size: toRem(30px);

      &.--circle {
        height: $btn-size;
        width: $btn-size;
        font-size: toRem(24px);

        span.item-count {
          height: $count-size;
          width: $count-size;
          font-size: toRem(14px);
        }
      }
    }
  }

  .popper {
    min-width: 300px !important;
    min-height: 300px !important;
    @include box-shadow(0, 2px, 5px, map-get($text-colors, 6));
  }
}

.variationsList {
  display: none;
  position: absolute;
  width: 150px;
  max-height: 150px;
  overflow: auto;
  top: 0;
  left: 0;
  transform: translate(-100%, -50%);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: whitesmoke;
  text-align: left;
  z-index: 100;

  .variationsListItem {
    background-color: white;
    padding: 0.2rem 1rem;
    margin: 0.2;

    &:hover {
      background-color: whitesmoke;
    }
  }
}

.childProductsList {
  max-width: 200px;
  .childProductsListItem {
    border: 1px solid map-get($variants, "success");
    padding: 0.1rem;
    margin: 0.1rem;
    border-radius: 0.2rem;
    font-size: 0.7rem;

    .btnCancel {
      cursor: pointer;
      margin-right: 0.2rem;
    }
  }
}

.btn-caret-up:hover .variationsList {
  display: block;
}

.fa-plus-circle,
.fa-expand {
  color: $brand-green-mid;
  font-size: 18px;
}
</style>
