import Vue from "vue";
import store from "@/store";
import { http } from "@/helpers/http";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
// import Register from "../views/Register.vue";

import SellerDashboard from "../views/Seller/Dashboard.vue";
import SellerDashboardV2 from "../views/Seller/DashboardV2.vue";
// import SellerTestDashboard from "../views/Seller/DashboardTest.vue";
import DashboardIntro from "../views/Seller/Dashboard-Intro.vue";

import SellerProduct from "../views/Seller/Products/index.vue";
import SellerProductList from "../views/Seller/Products/List.vue";
import SellerProductCreate from "../views/Seller/Products/Create.vue";
import SellerProductEdit from "../views/Seller/Products/Edit.vue";
import SellerProductDetail from "../views/Seller/Products/Show.vue";

import SellerCustomer from "../views/Seller/Customers/index.vue";
import SellerCustomerList from "../views/Seller/Customers/List.vue";

import SellerOrder from "../views/Seller/Orders/index.vue";
import SellerOrderList from "../views/Seller/Orders/List.vue";
import SellerOrderSalesAgentList from "../views/Seller/Orders/SalesAgentList.vue";
import SellerOrderSalesAgentListV2 from "../views/Seller/Orders/SalesAgentListV2.vue";
import SellerOrderCreate from "../views/Seller/Orders/Create.vue";
import SellerOrderQuickCreate from "../views/Seller/Orders/QuickCreate.vue";
import SellerOrderSuperQuickCreate from "../views/Seller/Orders/SuperQuickCreate.vue";
// import SellerOrderTaxInvoice from "../views/Seller/Orders/TaxInvoice.vue";
// import SellerOrderTaxInvoicePrintPreview from "../views/Seller/Orders/TaxInvoicePrintPreview.vue";
import SellerOrderDetail from "../views/Seller/Orders/Show.vue";
import SellerOrderEdit from "../views/Seller/Orders/Edit.vue";

import SellerImportOrders from "../views/Seller/SellerImportOrders.vue";

import SellerPreShipment from "../views/Seller/PreShipments/index.vue";
import SellerPreShipmentList from "../views/Seller/PreShipments/List.vue";
import SellerPreShipmentCreate from "../views/Seller/PreShipments/Create.vue";
import SellerPreShipmentEdit from "../views/Seller/PreShipments/Edit.vue";
import SellerPreShipmentShow from "../views/Seller/PreShipments/Show.vue";


import SellerSalesAgentList from "../views/Seller/SalesAgents/List.vue";
import SuperSalesAgentList from "../views/Seller/Orders/SuperSalesAgentList.vue";
// import SellerSalesAgentCreate from "../views/Seller/SalesAgents/Create.vue";
// import SellerSalesAgentDetail from "../views/Seller/SalesAgents/Show.vue";
// import SellerSalesAgentEdit from "../views/Seller/SalesAgents/Edit.vue";

import SellerSettings from "../views/Seller/Settings/index.vue";
import SellerSettingsGeneral from "../views/Seller/Settings/general.vue";
import SellerSettingsShops from "../views/Seller/Settings/shops.vue";
import SellerSettingsPayment from "../views/Seller/Settings/payment.vue";
import SellerSettingsShipping from "../views/Seller/Settings/shipping.vue";
import SellerSettingsAccount from "../views/Seller/Settings/account.vue";
import SellerSettingsSalesAgents from "../views/Seller/Settings/salesAgents.vue";
import SellerSettingsApi from "../views/Seller/Settings/api.vue";
import SellerSettingsCheckout from "../views/Seller/Settings/checkout.vue";
import SellerSettingsThaipost from "../views/Seller/Settings/thaipost.vue";

import Onboard from "../views/Onboard/index.vue";
import OnboardWelcome from "../views/Onboard/welcome.vue";
import OnboardDetails from "../views/Onboard/details.vue";
import OnboardOptions from "../views/Onboard/options.vue";
import OnboardProduct from "../views/Onboard/product.vue";
import OnboardShipping from "../views/Onboard/shipping.vue";
import OnboardFinal from "../views/Onboard/final.vue";

// import PaymentDetails from "../views/Buyer/PaymentDetails.vue";
// import BuyerThanks from "../views/Buyer/Thanks.vue";

import SellerReportsList from "../views/Seller/Reports/ReportsList.vue";
// import SellerManifestReports from "../views/Seller/Reports/ManifestReports.vue";
import SellerShipmentsReport from "../views/Seller/Reports/ShipmentsReport.vue";
import SellerProductsReport from "../views/Seller/Reports/ProductsReport.vue";
import SellerShipmentsReportV2 from "../views/Seller/Reports/ShipmentsReportV2.vue";
import SellerReportsPrintPreview from "../views/Seller/Reports/PrintPreview.vue";
import Test from "../views/Test.vue";
import PageNotFound from "../views/PageNotFound.vue";

import DeveloperApiDocs from "../views/Developer/ApiDocs.vue"

Vue.use(VueRouter);

var routes = [
  {
    path: "/",
    name: "Seller-Home",
    meta: { layout: "seller" },
    component: SellerDashboard,
  },

  {
    path: "/dashboard-v2",
    name: "Seller-DashboardV2",
    meta: { layout: "seller" },
    component: SellerDashboardV2,
  },

  // {
  //   path: "/test-dash",
  //   name: "Seller-Test-Dashboard",
  //   meta: { layout: "seller" },
  //   component: SellerTestDashboard,
  // },

  {
    path: "/dash-intro",
    name: "Dash-Intro",
    meta: { layout: "seller" },
    component: DashboardIntro,
  },

  {
    path: "/login",
    name: "Login",
    meta: { layout: "auth" },
    component: Login,
  },

  // {
  //   path: "/register",
  //   name: "Register",
  //   meta: { layout: "auth" },
  //   component: Register,
  // },

  {
    path: "/seller/dashboard",
    name: "Seller-Dashboard",
    meta: { layout: "seller" },
    component: SellerDashboard,
  },

  // Products
  {
    path: "/seller/products",
    meta: { layout: "seller" },
    component: SellerProduct,
    children: [
      {
        path: "",
        name: "Seller-Product-List",
        component: SellerProductList,
      },

      {
        path: "create",
        name: "Seller-Product-Create",
        component: SellerProductCreate,
      },

      {
        path: ":id",
        name: "Seller-Product-Detail",
        component: SellerProductDetail,
      },
      {
        path: ":id/edit",
        name: "Seller-Product-Edit",
        component: SellerProductEdit,
      },
    ],
  },

  // Customers
  {
    path: "/seller/customers",
    meta: { layout: "seller" },
    component: SellerCustomer,
    children: [
      {
        path: "",
        name: "Seller-Customer-List",
        component: SellerCustomerList,
      }
    ],
  },

  // orders
  {
    path: "/seller/orders",
    meta: { layout: "seller" },
    component: SellerOrder,
    children: [
      {
        path: "",
        name: "Seller-Order-List",
        component: SellerOrderList,
      },

      {
        path: "/sales-agent-orders",
        name: "Seller-Order-SalesAgent-List",
        component: SellerOrderSalesAgentList,
      },

      {
        path: "/sales-agent-orders-v2",
        name: "Seller-Order-SalesAgent-List-V2",
        component: SellerOrderSalesAgentListV2,
      },

      {
        path: "/super-sales-agent-orders",
        name: "Seller-Order-Super-SalesAgent-List",
        component: SuperSalesAgentList,
      },

      {
        path: "create",
        name: "Seller-Order-Create",
        component: SellerOrderCreate,
      },

      {
        path: "quick-create",
        name: "Seller-Order-Quick-Create",
        component: SellerOrderQuickCreate,
      },

      {
        path: "super-quick-create",
        name: "Seller-Order-Super-Quick-Create",
        component: SellerOrderSuperQuickCreate,
      },

      // {
      //   path: "tax-invoice-print",
      //   meta: { layout: "default" },
      //   name: "Seller-Order-Tax-Invoice-Print",
      //   component: SellerOrderTaxInvoicePrintPreview,
      // },

      {
        path: ":id",
        name: "Seller-Order-Detail",
        component: SellerOrderDetail,
      },
      {
        path: ":id/edit",
        name: "Seller-Order-Edit",
        component: SellerOrderEdit,
      },

      // {
      //   path: ":id/tax-invoice",
      //   name: "Seller-Order-Tax-Invoice",
      //   component: SellerOrderTaxInvoice,
      // }
    ],
  },

  // Import orders
  {
    path: "/seller/import-orders",
    name: "Seller-Import-Orders",
    meta: { layout: "seller" },
    component: SellerImportOrders,
  },

  // pre-orders
  // {
  //   path: "/seller/pre-shipments",
  //   meta: { layout: "seller" },
  //   component: SellerPreShipment,
  //   children: [
  //     {
  //       path: "",
  //       name: "Seller-PreShipment-List",
  //       component: SellerPreShipmentList,
  //     },

  //     {
  //       path: "create",
  //       name: "Seller-PreShipment-Create",
  //       component: SellerPreShipmentCreate,
  //     },

  //     {
  //       path: ":id",
  //       name: "Seller-PreShipment-Show",
  //       component: SellerPreShipmentShow,
  //     },

  //     {
  //       path: ":id/edit",
  //       name: "Seller-PreShipment-Edit",
  //       component: SellerPreShipmentEdit,
  //     },
  //   ],
  // },

  // Sales Agents
  {
    path: "/seller/sales-agents",
    name: "Seller-Sales-Agent-List",
    meta: { layout: "seller" },
    component: SellerSalesAgentList,
  },

  {
    path: "/seller/reports",
    name: "Reports-List",
    meta: { layout: "seller" },
    component: SellerReportsList,
  },

  // {
  //   path: "/seller/reports/manifest",
  //   name: "Reports-Manifest",
  //   meta: { layout: "seller" },
  //   component: SellerManifestReports,
  // },

  {
    path: "/seller/reports/shipments",
    name: "Reports-Shipments",
    meta: { layout: "seller" },
    component: SellerShipmentsReport,
  },

  {
    path: "/seller/reports/products",
    name: "Reports-Products",
    meta: { layout: "seller" },
    component: SellerProductsReport,
  },

  {
    path: "/seller/search-shipments",
    name: "Search-Orders",
    meta: { layout: "seller" },
    component: SellerShipmentsReportV2,
  },


  {
    path: "/settings",
    meta: { layout: "seller" },
    component: SellerSettings,
    children: [
      {
        path: "",
        name: "Seller-Settings",
        component: SellerSettingsShops,
      },
      {
        path: "general",
        name: "Seller-Settings-General",
        component: SellerSettingsGeneral,
      },
      {
        path: "shops",
        name: "Seller-Settings-Shops",
        component: SellerSettingsShops,
      },
      {
        path: "account",
        name: "Seller-Settings-Account",
        component: SellerSettingsAccount,
      },
      {
        path: "payment",
        name: "Seller-Settings-Payment",
        component: SellerSettingsPayment,
      },
      {
        path: "shipping",
        name: "Seller-Settings-Shipping",
        component: SellerSettingsShipping,
      },
      {
        path: "sales-agents",
        name: "Seller-Settings-Sales-Agents",
        component: SellerSettingsSalesAgents,
      },
      {
        path: "api",
        name: "Seller-Settings-Api",
        component: SellerSettingsApi,
      },

      {
        path: "checkout",
        name: "Seller-Settings-Checkout",
        component: SellerSettingsCheckout,
      },
    ],
  },

  // Onboard
  {
    path: "/onboard",
    meta: { layout: "default" },
    name: "Onboard",
    component: OnboardWelcome,
  },
  {
    path: "/onboard",
    component: Onboard,
    children: [
      {
        path: "details",
        meta: { layout: "onboard" },
        name: "Onboard-Details",
        component: OnboardDetails,
      },

      {
        path: "options",
        meta: { layout: "onboard" },
        name: "Onboard-Options",
        component: OnboardOptions,
      },

      {
        path: "product",
        meta: { layout: "onboard" },
        name: "Onboard-Product",
        component: OnboardProduct,
      },

      {
        path: "shipping",
        meta: { layout: "onboard" },
        name: "Onboard-Shipping",
        component: OnboardShipping,
      },

      {
        path: "final",
        meta: { layout: "onboard" },
        name: "Onboard-Final",
        component: OnboardFinal,
      },
    ],
  },

  // Developer
  {
    path: "/developers",
    meta: { layout: "developer" },
    name: "Developer",
    component: DeveloperApiDocs,
  },
  {
    path: "/developers/api-docs",
    meta: { layout: "developer" },
    name: "Developer-APiDocs",
    component: DeveloperApiDocs,
  },

  {
    path: "/thaipost-manifest-print-preview",
    meta: { layout: "default" },
    name: "ThaiPost-Print-Preview",
    component: SellerReportsPrintPreview
  },


  {
    path: "/test",
    meta: { layout: "default" },
    name: "Test",
    component: Test
  },

  {
    path: "*",
    meta: { layout: "default" },
    name: "404",
    component: PageNotFound,
  },

];

const router = new VueRouter({
  routes,
});

// Auth Guard
router.beforeEach((to, from, next) => {
  // loadLanguageAsync("th").then(() => {
  if (needToCheckAuth(to.name)) {
    if (store.state.user.authData)
      http.defaults.headers.common["Authorization"] =
        "Bearer " + store.state.user.authData.token;
    else next({ name: "Login" });
  }
  next();
  // });
});

function needToCheckAuth(routeName) {
  // add other pathNames that doesn't require auth
  let except = ["Login", "Register", "Receipt", "Receipt-Paid", "Test"];
  return !except.includes(routeName);
}

export default router;
