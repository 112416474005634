<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <h4 class="font-weight-light">สร้างออเดอร์ แบบก๊อปวาง</h4>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div v-if="loading">
          <Spinner size="md"
                   variation="primary" />
        </div>
        <div v-else>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <Card>
                <div slot="body">
                  <div class="row">
                    <div class="col">
                      <Select id="shops"
                              :label="$t('shipment.edit.fields.shop')"
                              :options="shopOptions"
                              v-model="defaultShopId"
                              :value="defaultShopId"
                              @input="handleShopChange" />
                    </div>

                    <div class="col">
                      <Select id="sm"
                              :label="$t('shipment.edit.fields.shipping_methods')"
                              :options="smOptions"
                              v-model="defaultSm"
                              :value="defaultSm" />
                    </div>
                  </div>

                  <textarea style="height: 75vh"
                            class="form-control"
                            placeholder="ก้อป้ขอมูลมาวางตามนี้ได้เลย
หากมีหลายรายการให้คั่นด้วย -End-

ชื่อลูกค้า นามสกุล
ยอดเก็บเงินปลายทาง
เบอร์โทร
ที่อยู่ จังหวัด รหัส ปณ.
หมายเหตุ"
                            v-model="inputText"
                            @input="parseMultiInput()"></textarea>
                </div>
              </Card>

              <div class="text-center"
                   v-if="orderCreateFormList.length">
                <Button type="button"
                        class="btn btn-primary mr-4"
                        size="md"
                        :loading="createLoading"
                        :disabled="inputTextList.length != orderCreateFormList.length"
                        @click="handleFormSubmit()">
                  สร้าง {{ orderCreateFormList.length }} รายการ</Button>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-8">
              <div class="d-flex flex-wrap justify-content-center"
                   style="max-height: 80vh; overflow-y: auto"
                   v-if="inputText &&
          orderCreateFormList.length &&
          inputTextList.length &&
          orderCreateFormList &&
          orderCreateFormList.length &&
          orderCreateFormList.length == inputTextList.length
          ">
                <div v-for="(inputText, index) in inputTextList"
                     :key="index"
                     class="m-2"
                     style="max-width: 15rem">
                  <Card>
                    <div slot="body">
                      <div class="row">
                        <div class="col-12">
                          <ValidationProvider rules="required|address">
                            <SmartAddress :id="'address' + index"
                                          :label="$t('general.address.address')"
                                          :value="{ raw: inputTextList[index] }"
                                          rows="3"
                                          rules="required"
                                          @input="(value) => autofillCustDetails(value, index)
          " />

                            <div v-if="orderCreateFormList[index] &&
          orderCreateFormList[index].customer.address
          ">
                              Name:
                              {{ orderCreateFormList[index].customer.name }}
                              <br />
                              Phone:
                              {{ orderCreateFormList[index].customer.phoneNo }}
                              <br />
                              Address:
                              {{
          orderCreateFormList[index].customer.address
            .address
        }}
                              <br />
                              SubDistrict:
                              {{
            orderCreateFormList[index].customer.address
              .subDistrict
          }}
                              <br />
                              District:
                              {{
            orderCreateFormList[index].customer.address
              .district
          }}
                              <br />
                              Province:
                              {{
            orderCreateFormList[index].customer.address
              .province
          }}
                              <br />
                              Zip:
                              {{
            orderCreateFormList[index].customer.address
              .zipcode
          }}
                              <br />
                              COD: {{ orderCreateFormList[index].codAmount }}
                              <br />
                              Remarks: {{ orderCreateFormList[index].remarks }}
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="container logs d-flex flex-column">
            <p
              v-for="(log, index) in logs"
              :key="index"
              :class="'text-' + log.type"
            >
              Order {{ index }}
              <span>{{ log.id || "" }}</span>
              <span>{{ log.message || "" }}</span>
            </p>
          </div> -->
        </div>
      </div>
    </section>

    <Modal id="orders-print-labels"
           custom_dialog_class="modal-dialog-centered">
      <template slot="body"
                v-if="printLabels">
        <div class="d-flex justify-content-between mb-4">
          <p class="lead text-center align-self-center">
            {{ $t("shipment.shipping_label.message") }}
          </p>

          <Button id="btn-close-print-dialog"
                  variant="default"
                  size="sm"
                  @click="cancelModal('orders-print-labels')"
                  :disabled="printLabelLoading">
            <i class="fa fa-times"></i>
          </Button>
        </div>

        <div class="alert alert-success mb-2"
             v-if="printLabelLoading"
             style="font-size: 1rem">
          <i class="fal fa-circle-notch fa-spin"></i>
          {{ $t("print_dialog.generating_link") }}
        </div>

        <div class="text-center mb-2 mt-2">
          <a :href="printUrl"
             target="_blank"
             rel="noopener noreferrer"
             v-if="printUrl"
             class="btn btn-success">
            {{ $t("print_dialog.click_to_download") }}
          </a>
        </div>

        <label>โปรดเลือกขนาดกระดาษที่ต้องการใช้งาน</label>
        <select class="form-control form-control-sm"
                v-model="selectedPaperSize"
                @change="setPaperSize">
          <option :value="paperSize"
                  v-for="paperSize in Object.keys(printLabels.paperSizes)"
                  :key="paperSize">
            {{ paperSize }}
          </option>
        </select>

        <div class="d-flex flex-wrap printerFormatContainer mt-4">
          <div class="printerFormat"
               v-for="(prtFr, index) in printLabels.paperSizes[selectedPaperSize]"
               :key="index"
               @click="printLabel(prtFr.size, prtFr.printer)">
            <div>
              <div class="d-flex">
                <img :src="prtFr.image"
                     class="icon align-self-start" />
                <div class="d-flex flex-column align-self-center">
                  <p class="mb-0 --text-vsm">{{ prtFr.name }}</p>
                  <p class="--text-vsm text-color-5 mt-n2"
                     v-if="prtFr.sizeToShow">
                    {{ prtFr.sizeToShow }}
                  </p>
                </div>
              </div>

              <p class="--text-vvsm text-color-5">{{ prtFr.description }}</p>
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <Modal id="copy-link-modal"
           custom_dialog_class="modal-dialog-centered">
      <div slot="body">
        <h5 class="header-text text-color-4 mb-4">Order created</h5>
        <p>
          <a :href="printUrl"
             target="_blank"> Download Label </a>
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import Spinner from "@/components/core/Spinner";
import TextArea from "@/components/core/TextArea";
import Toggle from "@/components/core/Toggle";
import NumberField from "@/components/core/NumberField";
import PhoneNumber from "@/components/derived/PhoneNumber";
import TextBoxAddon from "@/components/core/TextBoxAddon";
import Select from "@/components/core/Select";
import Modal from "@/components/core/Modal";
import SmartAddress from "@/components/derived/SmartAddress";
import { Toast, ToastFix } from "@/helpers/toastr";
import { mapGetters } from "vuex";
import { printLabels } from "@/helpers/print";
export default {
  name: "Seller-Order-SuperQuickCreate",
  components: {
    Card,
    TextBox,
    NumberField,
    Modal,
    TextArea,
    Select,
    Button,
    Spinner,
    SmartAddress,
    Toggle,
    PhoneNumber,
    TextBoxAddon,
  },
  data() {
    return {
      createLoading: false,
      loading: false,
      printUrl: null,
      defaultShopId: null,
      defaultWeight: null,
      defaultBoxSize: null,
      defaultSm: null,
      shops: null,
      shopOptions: null,
      smOptions: null,
      logs: [],

      inputText: null,
      inputTextList: null,
      orderCreateFormList: [],
      orderIds: [],

      printLabelLoading: false,
      printLabels: null,
      selectedPaperSize: localStorage.getItem("paperSize"),
      printUrl: null,
    };
  },

  created() {
    this.init();
    this.printLabels = printLabels();
  },

  methods: {
    setPaperSize() {
      localStorage.setItem("paperSize", this.selectedPaperSize);
      this.printUrl = null;
    },

    printLabel(size, printer) {
      this.printLabelLoading = true;
      this.printUrl = null;
      this.$store.dispatch("orders/printLabels", {
        data: {
          orders: this.orderIds,
          size,
          printer,
        },
        callback: (status, data) => {
          if (status) {
            this.printUrl = data.url;
          }
          this.printLabelLoading = false;
        },
      });
    },

    cancelModal(id) {
      $("#" + id).modal("hide");
    },

    async parseMultiInput() {
      this.inputTextList = this.inputText
        .trim()
        .split("-End-")
        .join("-end-")
        .split("-end-")
        .filter((t) => t.length)
        .map((singleInput) => {
          singleInput = singleInput.trim().replace(/^\s*\n/gm, "");
          singleInput = singleInput
            .split("\n")
            .map((line) => line.trim())
            .join("\n");
          return singleInput;
        });

      this.orderCreateFormList = [];
      for (let i = 0; i < this.inputTextList.length; i++) {
        this.orderCreateFormList.push({
          user: null,
          salesPerson: null,
          orderCode: await this.fetchOrderCode(),
          shippingMethod: this.defaultSm,
          weight: this.defaultWeight,
          boxSize: this.defaultBoxSize,
          codAmount: 0,
          remarks: null,
          shopId: this.defaultShopId,
          customer: {
            name: "กรุณาจัดส่งคุณ:",
            address: null,
            mobileNo: null,
            phoneNo: null,
            email: null,
            salesChannel: null,
            socialLink: null,
            showSocialLinkOnLabel: false,
          },
          products: {},
        });
      }
    },

    init() {
      this.loading = true;
      this.fetchShops(() => {
        this.fetchDefaultBoxSize(() => {
          if (this.$route.query.shop) {
            let found = this.shopOptions.find(
              (s) => s.value == this.$route.query.shop
            );
            if (found) this.defaultShopId = this.$route.query.shop;
          }
          this.loading = false;
        });
      });
    },

    autofillCustDetails(value, index) {
      if (!value) return;

      // name
      let lines = value.raw.split("\n");
      let name = lines[0];

      // cod
      let phoneNo = lines[1].trim();
      let cod = null;
      if (!(phoneNo.charAt(0) === "0" && phoneNo.length == 10)) {
        cod = parseFloat(lines[1]) || null;
      }

      // remark
      let remark = lines.pop();

      this.orderCreateFormList[index].customer.address = value;
      this.orderCreateFormList[index].customer.phoneNo = value.phone;
      this.orderCreateFormList[index].customer.name = name;
      this.orderCreateFormList[index].remarks = remark;
      this.orderCreateFormList[index].codAmount = cod;

      this.orderCreateFormList[index].customer.address.address = value.address
        .replace(remark, "")
        .replace(cod, "")
        .replace(name, "")
        .replace(phoneNo, "");
    },

    async fetchOrderCode() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("orders/fetchOrderCode", {
          callback: (status, data) => {
            if (status) resolve(data);
            else reject();
          },
        });
      });
    },

    async createOrder(order) {
      return new Promise((resolve, reject) => {
        // 1. create order
        this.$store.dispatch("orders/createOrder", {
          order,
          callback: (status, data) => {
            // 2. get order details
            if (status) {
              resolve(data.id);
              // this.$store.dispatch("orders/fetchOrderDetail", {
              //   id: data.id,
              //   callback: async (status2, data2) => {
              //     if (status2) {
              //       resolve(data2.id + "|" + data2.createdAt);
              //     } else reject();
              //   },
              // });
            } else reject();
          },
        });
      });
    },

    async handleFormSubmit() {
      this.createLoading = true;
      this.orderIds = [];

      for (let i = 0; i < this.orderCreateFormList.length; i++) {
        const valueToSend = JSON.parse(
          JSON.stringify(this.orderCreateFormList[i])
        );
        if (!valueToSend.boxSize) delete valueToSend.boxSize;
        try {
          let orderId = await this.createOrder(valueToSend);
          this.orderIds.push(orderId);
          this.logs.push({ type: "success", id: orderId.split('|"').shift() });
        } catch (e) {
          this.logs.push({ type: "fail", message: "Order " + i + " failed." });
        }
      }
      let success = this.logs.filter(l => l.type === "success").length;
      let fail = this.logs.filter(l => l.type === "fail").length;
      ToastFix(`สำเร็จ: ${success}, ผิดพลาด: ${fail}`, "info");

      $("#orders-print-labels").modal("show");

      this.createLoading = false;

      // this.$store.dispatch("orders/printLabels", {
      //   data: {
      //     orders: this.orderIds,
      //     size: "normal",
      //     printer: "proship_v3p",
      //   },
      //   callback: (status3, data3) => {
      //     if (status3) {
      //       this.printUrl = data3.url;
      //       $("#copy-link-modal").modal("show");
      //     }
      //     this.createLoading = false;
      //   },
      // });
    },

    fetchShops(callback) {
      this.$store.dispatch("shops/fetchShops", {
        callback: (status, data) => {
          if (status) {
            this.shops = data;
            this.shopOptions = data.map((shop) => {
              return {
                name: shop.details.name,
                value: shop.id,
              };
            });
            this.defaultShopId = data[0].id;
            this.defaultWeight = data[0].details.defaultWeight || 0;
            this.handleShopChange();
          }
          if (callback) callback();
        },
      });
    },

    fetchDefaultBoxSize(callback) {
      if (this.generalSettings.thaiPostUserName) {
        this.$store.dispatch("orders/fetchBoxSizes", {
          username: this.generalSettings.thaiPostUserName,
          callback: (status, data) => {
            if (status) {
              this.defaultBoxSize = this.boxSizeOptions[0].value;
            }
            if (callback) callback();
          },
        });
      }
      if (callback) callback();
    },

    copy(text = this.printUrl) {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then((result) => {
          if (result.state === "granted") {
            const type = "text/plain";
            const blob = new Blob([text], { type });
            let data = [new ClipboardItem({ [type]: blob })];
            navigator.clipboard.write(data).then(
              function () {
                Toast("Copied to clipboard", "success");
              },
              function () {
                Toast("Failed to copy", "danger");
              }
            );
          }
        });
    },

    handleShopChange() {
      let shop = this.shops.find((shop) => shop.id == this.defaultShopId);
      if (shop !== -1) {
        this.smOptions = shop.details.shippingMethods.map((sm) => {
          return {
            name: sm,
            value: sm,
          };
        });
        this.defaultSm = this.smOptions[0].value;
      }
    },
  },
  computed: {
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),
  },
};
</script>

<style lang="scss"
       scoped>

      .printerFormatContainer {
        width: 210px;

        @include for-larger-than-phone {
          width: 420px;
        }

        margin: 0 auto;
        overflow: auto;

        .printerFormat {
          width: 200px;
          min-height: 50px;
          background-color: rgb(252, 252, 252);
          padding: 0.5rem;
          border-radius: 3px;
          margin: 0.25rem;
          cursor: pointer;

          &:hover {
            background-color: whitesmoke;
          }

          .icon {
            height: 2rem;
            width: auto;
            margin-right: 0.5rem;
            margin-top: 0.4rem;
          }
        }
      }

    </style>
