<template>
  <div :class="custom_class">
    <label v-if="label">{{ label }}</label>
    <br />

    <validation-provider :rules="rules" v-slot="{ errors }">
      <!-- <vue-datepicker
        :input-class="input_class"
        @selected="handleSelected"
        v-model="data"
        :placeholder="placeholder"
      ></vue-datepicker> -->

      <vue-timepicker
        v-model="data"
        @change="handleSelected"
        input-class="form-control custom-styles"
      ></vue-timepicker>
      <template v-if="errors">
        <span
          class="text-danger text-sm"
          v-for="(error, index) in errors"
          :key="index"
          >{{ error }}</span
        >
      </template>
    </validation-provider>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "TimePicker",
  components: {
    VueTimepicker,
  },
  data() {
    return {
      data: this.value,
    };
  },
  props: {
    label: {
      type: String,
    },

    value: {
      type: null,
    },

    rules: {
      type: String,
    },

    custom_class: {
      type: String,
      default: "",
    },
  },

  methods: {
    handleSelected(date) {
      this.$emit("input", date.displayTime && date.displayTime.length ? date.displayTime : null);
    },
  },

  computed: {
    input_class() {
      let c = "form-control";
      if (this.errors && this.errors.length > 0) c += " is-invalid";
      return c;
    },
  },
};
</script>

<style lang="scss">
.vue__time-picker {
  .custom-styles {
    padding: 1rem !important;
    font-size: 1.1rem !important;
    font-family: "CeraPro", sans-serif;
    color: #495057 !important;
  }

  .select-list {
    ul {
      li {
        &.active {
          background-color: $brand-blue-mid !important;
        }
      }
    }
  }
}
</style>
