import Vue from 'vue'
import Toasted from 'vue-toasted';

export const initToastr = function () {
    Vue.use(Toasted, {
        theme: "toasted-primary",
        position: "bottom-right",
        duration: 2000,
        iconPack: 'custom-class'
    });
}

export const Toast = function (message, type, action, duration = 3000) {

    const config = {
        'success': 'fal fa-check-circle',
        'danger': 'fal fa-times-circle',
        'info': 'fal fa-info-circle',
        'warning': 'fal fa-exclamation-triangle'
    }

    Vue.toasted.show(message, { icon: config[type] + ' mr-2', action, duration });
}

export const ToastFix = (message, type, autohideTime) => {
    if (!message || !message.length) return;

    const config = {
        'success': 'fal fa-check-circle',
        'danger': 'fal fa-times-circle',
        'info': 'fal fa-info-circle',
        'warning': 'fal fa-exclamation-triangle'
    }
    let toastList = document.getElementById("toastList");

    let toastListItem = document.createElement("div");
    toastListItem.id = "toast-" + Date.now();
    toastListItem.classList.add("toastListItem");
    toastListItem.innerHTML = `
        <div class="toolbar w-full d-flex justify-content-end">
            <i class="fal fa-times-square mr-n1 fixedToastBtnClose"> </i>
        </div>

        <div class="message">
            ${message}
        </div>
    `;

    toastList.appendChild(toastListItem);

    // add click listener
    document.querySelectorAll(".fixedToastBtnClose").forEach(btn => {
        btn.addEventListener('click', (e) => {
            e.target.parentElement.parentElement.remove();
        })
    })

    if (autohideTime) {
        setTimeout(() => {
            toastListItem.remove();
        }, autohideTime * 1000);
    }
}
