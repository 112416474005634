<template>
  <div class="form-group" :class="form_group_class">
    <label v-if="label" :for="id">{{ label }}</label>
    <select
      ref="input"
      :class="'form-control ' + (size ? 'form-control-' + size : '')"
      :id="id"
      @change="updateValue()"
    >
      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option.value"
        :selected="value && value === option.value"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    id: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
    },

    value: {
      type: null,
    },

    form_group_class: {
      type: String,
      default: "",
    },

    size: {
      type: String,
      default: "",
    },
  },

  created() {},

  methods: {
    updateValue() {
      this.$emit("input", this.$refs.input.value);
    },
  },

  mounted() {
    this.updateValue();
  },
};
</script>

<style scoped lang="scss">
</style>
