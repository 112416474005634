
import axios from 'axios';
import { http } from "@/helpers/http";
import Vue from 'vue'

// const baseURL = 'https://qz88s5gim3.execute-api.ap-southeast-1.amazonaws.com/dev/v1'; // v1
const baseURL = 'https://x1pukio3fj.execute-api.ap-southeast-1.amazonaws.com/dev/v1'; // v2
// const baseURL = 'http://localhost:3000/dev/v1';

export default {
    namespaced: true,
    state: {
        dashboardData: null,
        sideNav: true,
        cancelTokenSource: null,
    },

    mutations: {
        setCancelTokenSource(state, cancelTokenSource) {
            state.cancelTokenSource = cancelTokenSource;
        },

        setDashboardData(state, dashboardData) {
            Vue.set(state, 'dashboardData', dashboardData);
        },

        setSideNav(state, sideNav) {
            Vue.set(state, 'sideNav', sideNav);
        },
    },
    actions: {
        showSideNav({ state, commit, rootState }, payload) {
            commit("setSideNav", true);
        },

        hideSideNav({ state, commit, rootState }, payload) {
            commit("setSideNav", false);
        },

        getDashboardData({ state, commit, rootState }, payload) {
            let agentsParam = payload.agents ? "?salesAgent=true" : "";
            http.get(baseURL + "/dashboard" + agentsParam).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        },

        getDashboardDataV2({ state, commit, rootState }, payload) {
            if (state.cancelTokenSource && state.cancelTokenSource.cancel) {
                state.cancelTokenSource.cancel('Cancelled by client');
            }
            let cancelToken = axios.CancelToken;
            commit('setCancelTokenSource', cancelToken.source());

            http.get(`${baseURL}/dashboard-v2?from=${payload.dateRange.from}&to=${payload.dateRange.to}`, { cancelToken: state.cancelTokenSource.token }).then(response => {
                if (payload.callback) payload.callback(true, response.data);
            }).catch(error => {
                if (payload.callback) payload.callback(false);
            })
        }

    }
}