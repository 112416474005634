<template>
  <div class="shipping-chart">
    <div class="chart ml-auto mr-auto mt-4">
      <canvas id="salesChannelDoughnutChart" class="canvas-doughnut"></canvas>
    </div>
    <div class="info d-flex flex-wrap justify-content-between">
      <!-- 1st Column -->
      <div class="d-flex flex-column text-right mt-4">
        <p class="mb-1 --sm">
          Facebook
          <span
            class="order-badge"
            :style="'background-color: ' + getBackColor(0)"
            >{{ chartdata.data.datasets[0].data[0] }}</span
          >
        </p>
        <p class="text-right --sm">
          Instagram
          <span
            class="order-badge badge-to-ship"
            :style="'background-color: ' + getBackColor(1)"
            >{{ chartdata.data.datasets[0].data[1] }}</span
          >
        </p>
      </div>

      <!-- 2nd Column -->
      <div class="d-flex flex-column text-left mt-4">
        <p class="mb-1 --sm">
          <span
            class="order-badge badge-shipped"
            :style="'background-color: ' + getBackColor(2)"
            >{{ chartdata.data.datasets[0].data[2] }}</span
          >
          Line
        </p>
        <p class="text-right --sm">
          <span
            class="order-badge badge-delivered"
            :style="'background-color: ' + getBackColor(3)"
            >{{ chartdata.data.datasets[0].data[3] }}</span
          >
          Web
        </p>
      </div>

      <!-- 3rd Column -->
      <div class="d-flex flex-column text-left mt-4">
        <p class="mb-1 --sm">
          <span
            class="order-badge badge-shipped"
            :style="'background-color: ' + getBackColor(4)"
            >{{ chartdata.data.datasets[0].data[4] }}</span
          >
          Other
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalesChannelChart",
  props: {
    chartdata: {
      type: Object,
    },
  },

  mounted() {
    var doughnutChart = new Chart($("#salesChannelDoughnutChart"), {
      type: "doughnut",
      data: this.chartdata.data,
      options: {
        cutoutPercentage: 70,
        aspectRatio: 1,
        responsive: true,
        legend: false,
        tooltips: false,
      },
    });
  },

  methods: {
    getBackColor(index) {
      return this.chartdata.data.datasets[0].backgroundColor[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping-chart {
  .info {
    max-width: 300px;
    margin: 0 auto;

    p {
      font-size: 14px;
    }
    .badge {
      color: white;
    }
  }

  .chart {
    height: 110px;
    width: 110px;
  }
}

.order-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: white;
}
</style>