import Swal from "sweetalert2";
import {i18n} from "@/i18n";

export const Alert = function(title = "", message, type = "success", callback) {
  Swal.fire({
    title,
    text: message,
    icon: type,
    showCancelButton: true,
    cancelButtonText: i18n.t("general.cancel"),
    confirmButtonText: i18n.t("general.yes")
  }).then((willDelete) => {
    if (willDelete.value && callback) callback();
  });
};
