<template>
  <div class="fixedToast">
    <div class="toolbar d-flex justify-content-between">
      <h6 class="title mr-5 align-self-center text-capitalize">{{ title }}</h6>

      <div class="actionButtons align-self-center">
        <i class="fal fa-minus-square" v-if="mode" @click="toggleMode()"></i>
        <i class="fal fa-plus-square" v-else @click="toggleMode()"> </i>
        <i class="fal fa-times-square ml-1" @click="close()"> </i>
      </div>
    </div>
    <div class="body text-color-5" v-if="mode" v-html="message"></div>
  </div>
</template>

<script>
export default {
  name: "FixedToast",
  props: {
    title: {
      type: String,
    },

    message: {
      type: String,
    },
  },
  data() {
    return {
      mode: true,
    };
  },

  methods: {
    toggleMode() {
      this.mode = !this.mode;
    },
    close() {
      this.$emit("close");
    },
  },

  watch: {
    message(newVal, oldVal) {
      if (newVal != oldVal) {
        this.message = newVal;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fixedToast {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #373737;
  color: white;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  z-index: 1001;

  .toolbar {
    position: relative;
  }

  .actionButtons {
    i {
      cursor: pointer;
    }
  }
}
</style>
