<template>
  <div :id="id">
    <TextBox
      type="text"
      v-if="searchable"
      :id="id + '-search-input'"
      v-model="searchKeyword"
      :placeholder="search_placeholder"
      @input="refreshTable()"
      wrapper_class="mb-0"
      input_wrapper_class="mb-0 pb-1"
    />

    <vuetable
      ref="datatable"
      :api-mode="false"
      :fields="responsiveColumns()"
      :per-page="perPage"
      :data-manager="dataManager"
      :css="cssConfig.table"
      track-by="id"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
    >
      <div slot="actions" slot-scope="props">
        <slot name="actions" v-bind:props="props"></slot>
      </div>

      <div slot="id-slot" slot-scope="props">
        <slot name="id-slot" v-bind:props="props"></slot>
      </div>

      <div slot="agent-slot" slot-scope="props">
        <slot name="agent-slot" v-bind:props="props"></slot>
      </div>
    </vuetable>

    <div class="d-flex justify-content-between mt-4" v-if="rows">
      <select v-model="perPage" class="form-control form-control-sm" style="width: 70px" @change="onPerPageChange">
        <option v-for="r in per_page_range" :key="r" :value="r">{{r}}</option>
      </select>

      <div v-if="rows.length > perPage">
        <vuetable-pagination
          :css="cssConfig.pagination"
          ref="pagination"
          @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import TextBox from "@/components/core/TextBox";
import Select from "@/components/core/Select";
import { navigateObj } from "@/helpers/core";
export default {
  name: "DataTable",
  components: { Vuetable, VuetablePagination, TextBox },
  props: {
    id: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    search_placeholder: {
      default: "Search Keyword",
    },
    rows: {
      type: Array,
      required: true,
    },
    custom_class: {
      type: String,
    },
    responsive: {
      type: Boolean,
    },
    per_page: {
      type: Number,
    },
    per_page_range: {
      type: Array,
      default: () => [5, 10, 15, 20, 25],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    search_fields: {
      type: Array,
    },
    hidePaginationNumber:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      computedColumns: this.columns,
      searchKeyword: null,
      perPage: null,
      cssConfig: {
        table: {
          tableWrapper: "table-responsive " + (this.sm ? "table-sm" : ""),
          tableHeaderClass: "mb-0 text-uppercase",
          tableBodyClass: "mb-0",
          tableClass: "_datatable table " + this.custom_class,
          loadingClass: "loading",
          ascendingIcon: "fal fa-sort-up",
          descendingIcon: "fal fa-sort-down",
          ascendingClass: "sorted-asc",
          descendingClass: "sorted-desc",
          sortableIcon: "fal fa-sort",
          detailRowClass: "vuetable-detail-row",
          handleIcon: "fa fa-bars text-secondary",
          renderIcon(classes, options) {
            return `<i class="ml-1 ${classes.join(" ")}"></span>`;
          },
        },
        pagination: {
          edge: {},
          wrapperClass: "_pagination pagination d-flex justify-content-center" + (this.hidePaginationNumber ? " --hidden" : ""),
          activeClass: "active",
          disabledClass: "disabled",
          pageClass: "page-item text-center",
          linkClass: "page-link text-center",
          paginationClass: "pagination",
          paginationInfoClass: "float-left",
          dropdownClass: "form-control",
          icons: {
            first: "",
            prev: "fal fa-long-arrow-left",
            next: "fal fa-long-arrow-right",
            last: "",
          },
        },
      },
    };
  },
  created() {
    this.perPage = this.per_page_range[0];
  },
  methods: {
    onPaginationData(paginationData) {
      if (this.$refs.pagination)
        this.$refs.pagination.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$emit("pageChanged", page);
      this.$refs.datatable.changePage(page);
    },

    onPerPageChange() {
      this.$emit("perPageChanged", this.perPage);
    },

    dataManager(sortOrder, pagination) {
      if (this.rows.length < 1) return;
      var local = this.rows;
      // search
      if (
        this.searchable &&
        this.searchKeyword &&
        this.searchKeyword.length > 0
      ) {
        local = this.rows.filter((row) => {
          let exists = false;
          for (let i = 0; i < this.search_fields.length; i++) {
            const field = this.search_fields[i] + "";
            let rowData = _.get(row, field) + "";
            if(!rowData || !this.searchKeyword) continue;
            exists =
              rowData.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) >=
              0;
            if (exists) return exists;
          }
          return exists;
        });
      }
      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }
      pagination = this.$refs.datatable.makePagination(
        local.length,
        this.perPage
      );
      let from = pagination.from - 1;
      let to = from + this.perPage;
      return {
        pagination: pagination,
        data: _.slice(local, from, to),
      };
    },
    refreshTable() {
      this.$refs.datatable.refresh();
    },
    responsiveColumns() {
      return this.columns.map((col) => {
        if (col != "actions") {
          if (window.isMobile && col.hideMobile) col.visible = false;
          else col.visible = true;
        }
        return col;
      });
      return this.columns;
    },
    // checkIfMobile() {
    //   let os = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   );
    //   let width = window.innerWidth < 768;
    //   if (os || width) window.isMobile = true;
    //   else window.isMobile = false;
    // }
  },
  computed: {},
};
</script>