<template>
  <div>
    <div class="d-flex toolbar justify-content-between mb-4">
      <h4 class="text-color-4">{{ $t("settings.account.label") }}</h4>
    </div>
    <div v-if="loading">
      <Spinner size="sm" />
    </div>
    <div v-else id="account-form">
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="handleFormSubmit">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <!-- Name -->
              <TextBox
                type="text"
                id="account-name"
                :label="$t('settings.account.company_name')"
                v-model="accountSettingsForm.companyName"
              />

              <!-- Email -->
              <TextBox
                type="email"
                id="account-email"
                :label="$t('general.email')"
                v-model="accountSettingsForm.email"
                rules="required"
              />

              <VatField
                id="company-id"
                label="Company ID"
                v-model="accountSettingsForm.companyId"
                rules="vat|required"
              />

              <!-- Phone -->
              <PhoneNumber
                id="account-phone-no"
                :label="$t('general.phone')"
                v-model="accountSettingsForm.phoneNo"
                rules="required"
              />

              <validation-provider rules="required|address">
                <SmartAddress
                  :label="$t('general.address.address')"
                  placeholder="Address"
                  id="account-address"
                  rules="required"
                  :value="accountSettingsForm.address"
                  v-model="accountSettingsForm.address"
                  rows="3"
                  @input="autofillCustDetails"
                />
              </validation-provider>

              <!-- Submit & Cancel Buttons -->
              <Button
                type="submit"
                variant="primary"
                custom_class="btn-block mr-4"
                :disabled="invalid"
                :loading="formLoading"
                >{{ $t("general.update") }}</Button
              >
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Button from "@/components/core/Button";
import NumberField from "@/components/core/NumberField";
import Card from "@/components/core/Card";
import TextBox from "@/components/core/TextBox";
import PhoneNumber from "@/components/derived/PhoneNumber";
import VatField from "@/components/derived/VatField";
import SmartAddress from "@/components/derived/SmartAddress";
import Spinner from "@/components/core/Spinner";

export default {
  name: "SellerSettingsAccount",
  components: {
    Card,
    TextBox,
    PhoneNumber,
    Spinner,
    Button,
    SmartAddress,
    NumberField,
    VatField,
  },

  data() {
    return {
      loading: false,
      formLoading: false,
      accountSettingsForm: null,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    initForm() {
      return {
        companyName: null,
        companyId: null,
        email: null,
        phoneNo: null,
        address: null,
        preOrderMessageFormat: null,
      };
    },

    fetchData() {
      this.loading = true;
      this.$store.dispatch("settings/fetchAccount", {
        callback: (status, data) => {
          if (status) {
            this.accountSettingsForm = data ? data : this.initForm();
          }
          this.loading = false;
        },
      });
    },
    autofillCustDetails(value) {
      if (value && value.phone) this.accountSettingsForm.phoneNo = value.phone;
    },

    handleFormSubmit() {
      this.formLoading = true;
      this.$store.dispatch("settings/updateAccount", {
        account: this.accountSettingsForm,
        callback: (status) => (this.formLoading = false),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.setting__header {
  font-weight: bold;
  font-size: 18px;
}
.setting__description {
  font-weight: thin;
  font-size: 13px;
}

.setting {
  &.--inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.--table {
    .setting__text {
      margin-bottom: 20px;
    }
  }

  margin-bottom: 30px;
}

.settings-wrapper {
  margin-top: 24px;
}

.setting-status-tag {
  text-transform: uppercase;

  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 2px;
  text-align: center;
  width: 68px;
  height: 29px;

  &.--warning {
    background-color: #ffc107;
    color: black;
  }

  &.--success {
    background-color: #28a745;
    color: white;
  }

  &.--danger {
    background-color: #dc3545;
    color: white;
  }

  &.--info {
    background-color: #007bff;
    color: white;
  }
}
</style>